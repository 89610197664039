import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import Lottie from 'react-lottie'
import { Button, Modal } from 'antd'
import NumberFormat from 'react-number-format';

import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import NumberInput from '../Input/NumberInput'
import RangeInput from '../Input/RangeInput'
import InfoLockPeriod from '../CommonStaking/StakingList/StakingItem/InfoLockPeriod'
import InfoStakingRight from '../CommonStaking/StakingList/StakingItem/InfoStakingRight'
import ButtonStaking from '../CommonStaking/StakingList/StakingItem/ButtonStaking';
import { formatNumber, getFullDisplayBalance } from '../../utils/formatBalance'
import { handleResult, formatNumberEx, isNotEqual, formatBigNumber, getMonthsShort, getDescription } from '../../utils/common';
import { onUnstake } from '../../hooks/onUnstake'
import { onStake } from '../../hooks/onStake';
import { setToast } from '../../actions/common';

import CoinImage from '../../assets/img/undead_token.png'
import ButtonStakingModal from '../../assets/img/buttonBg/orange.png';
// import SilverButton from '../../assets/img/buttonBg/silver.png';
import StakeIcon from '../../assets/img/stake.png';
import MintIcon from '../../assets/img/shotgun.png';
import './style.css';

// const ERROR_LIST = {
//   NONE: 'NONE',
//   ERR_MIN: 'ERR_MIN',
//   ERR_MAX: 'ERR_MAX',
//   ERR_MAX_BALANCE: 'ERR_MAX_BALANCE',
//   ERR_MAX_POOL: 'ERR_MAX_POOL'
// }

const BUTTON_LIST = [25, 50, 75, 100]


const ButtonStake = ({ stakeAmount, pendingTx, handleConfirmClick, isDisableConfirm, checkDisableConfirm, isMintStake, status }) => {
  return <ButtonStaking
    disabled={checkDisableConfirm(stakeAmount, pendingTx, isDisableConfirm, status)}
    title={isMintStake ? 'Mint + Stake' : 'Stake'}
    event={handleConfirmClick}
    icon={isMintStake ? <StakeImage src={MintIcon} /> : <StakeImage src={StakeIcon} />}
  />
}

const ButtonWithdraw = ({ isDisable, onClick }) => {
  return <ButtonLast>
    <ButtonStaking
      disabled={isDisable}
      title={'Withdraw & Claim'}
      event={onClick}
    />
  </ButtonLast>
}

const ButtonList = ({ pendingTx, handleChangePercent, percent }) => {
  return BUTTON_LIST.map((item, index) =>
    <ButtonPercentItem key={index} disabled={pendingTx} onClick={() => handleChangePercent(item)}
      style={percent === item ? {
        background: '#af0000',
        color: '#ffffff'
      } : {
        backgroundColor: 'rgb(43, 49, 53)',
        color: '#af0000'
      }}>
      <span style={percent === item ? {
        color: '#ffffff'
      } : {
        color: '#af0000'
      }}>
        {item === 100 ? 'MAX' : `${item}%`}
      </span>
    </ButtonPercentItem>
  )
}

const useUpdateBalance = (stakingTokenBalance) => {
  return useMemo(() => new BigNumber(stakingTokenBalance || 0), [stakingTokenBalance])
}

const StakeProjectModal = ({
  stakingMax,
  stakingTokenBalanceParam,
  isVisible,
  onClose,
  onCloseSuccess,
  onCloseSuccessMintStake,
  onShowWithdrawSchedule,
  pool,
  heading,
  stakingTokenPrice,
  account,
  loadingStake,
  pools,
  isHideHeader = false,
  setTransaction,
  isMobile,
  isTablet,
  isLoading,

  isUnstake,
  isStake,

  isMintStake,
  setIsMintStake,
  setMintStakeData,
  callBackStakeProcessing
}) => {
  const dispatch = useDispatch();

  const [pendingTx, setPendingTx] = useState(false)
  const [stakeAmount, setStakeAmount] = useState(new BigNumber(0))
  const [percent, setPercent] = useState(0);
  // const [error, setError] = useState(ERROR_LIST.NONE);
  const [isDisableConfirm, setIsDisableConfirm] = useState(false);
  const [, setIsValue] = useState(false)
  const [isSetPercent, setIsPercent] = useState(false);

  useEffect(() => {
    setStakeAmount(new BigNumber(0))
    setPercent(0)
  }, [])

  const { id,
    stakeTokenSymbol,
    lottie, stakeTokenDecimals, background, apy, lottieSparkle, description, poolTitle,
    lockDuration, isLotte, listDeposit, status
  } = pool;

  const progress = new BigNumber(pool?.progress || 0)
  const total = new BigNumber(pool?.total || 0)
  const stakedBalance = new BigNumber(pool?.stakedBalance || 0)
  // const stakingTokenBalance = new BigNumber(pool?.stakingTokenBalance || 0)
  const stakingTokenBalance = useUpdateBalance(stakingTokenBalanceParam);

  const usdValueStaked =
    stakeAmount && formatNumber(new BigNumber(stakeAmount).times(stakingTokenPrice).toNumber().toFixed(2))

  const handleStakeInputChange = inputValue => {
    if (isSetPercent) {
      setIsPercent(false);
      setIsValue(true);
      return;
    }
    setIsPercent(false);
    setIsValue(true);

    let valueSet = inputValue?.floatValue || 0;
    if (stakeAmount.eq(0)) {
      if (valueSet % 10 === 0) {
        valueSet = valueSet / 10;
      } 
      // else if (valueSet < 10) {
      //   valueSet = new BigNumber((valueSet % 10) || 0);
      // }
    }
    checkError(valueSet);
  }

  const handleChangePercent = sliderPercent => {
    setIsValue(false);
    setIsPercent(true);

    if (isNaN(sliderPercent)) {
      return;
    }

    const percentageOfStakingMax = stakingMax.multipliedBy(sliderPercent / 100)
    const valueSet = percentageOfStakingMax.gt(stakingTokenBalance) ? stakingTokenBalance : percentageOfStakingMax;

    setPercent(sliderPercent)
    setStakeAmount(valueSet)
    checkError(valueSet);
  }

  const isCloseInternal = () => {
    setPendingTx(false)
    loadingStake(false)
  }


  const checkError = (stakeAmount) => {
    if (!isUnstake) {
      let value = !isNaN(new BigNumber(stakeAmount)) ? new BigNumber(stakeAmount) : new BigNumber(0);

      // CHECK max stake = balanceOf Token staking
      if (value.isGreaterThanOrEqualTo(stakingTokenBalance) && value.minus(stakingTokenBalance).gt(1)) {
        value = stakingTokenBalance;
        setStakeAmount(stakingTokenBalance);
        setIsDisableConfirm(true);
      } else {
        setStakeAmount(value);
        setIsDisableConfirm(false);
      }

      // CHECK Stake/Mint+Stake
      if (value.plus(progress).gte(total)) {
        setIsMintStake(true);

        // calculate data mint+stake
        const mintNumber = value.plus(progress).div(total).integerValue(BigNumber.ROUND_DOWN);
        // const stakeAmount = value.plus(progress).mod(total);
        setMintStakeData({
          mintNumber,
          stakeAmount: value
        })
      } else {
        setIsMintStake(false);
        setMintStakeData({
          mintNumber: new BigNumber(0),
          stakeAmount: new BigNumber(0)
        })
      }

       // CHECK token balance < 0.005
       if (stakingTokenBalance.isLessThanOrEqualTo(new BigNumber(0.005))) {
        setStakeAmount(stakingTokenBalance);
        setIsDisableConfirm(true);
        setPercent(Math.min(0, 100));
        return;
      }

      const percentage = Math.ceil(value.dividedBy(stakingTokenBalance).multipliedBy(100).toNumber())
      setPercent(Math.min(percentage, 100));

    } else {
      const value = !isNaN(new BigNumber(stakeAmount)) ? new BigNumber(stakeAmount) : new BigNumber(0);

      if (value.isGreaterThan(stakedBalance)) {
        setStakeAmount(stakedBalance);
        setIsDisableConfirm(false);
      } else {
        setStakeAmount(value)
      }

      const percentage = Math.ceil(value.dividedBy(stakedBalance).multipliedBy(100).toNumber())
      setPercent(Math.min(percentage, 100));
    }
  }

  const checkDisableConfirm = (stakeAmountInput, pendingTxInput, isDisableConfirm, status) => {
    if (!stakeAmountInput || parseFloat(stakeAmountInput) === 0 || pendingTxInput || isDisableConfirm || status !== 'LIVE') {
      return true;
    }

    return false;
  }

  const handleResultStake = (receipt, error = null) => {
    handleResult(() => {
      dispatch(setToast({ title: 'Timeout', detail: `${error?.message}` }))
      isCloseInternal()
      onClose();
    }, () => {
      dispatch(setToast({ title: 'Canceled', detail: 'Please try again and confirm the transaction.' }))
      isCloseInternal()
      onClose();
    }, () => {
      isCloseInternal()
      if (isMintStake) {
        onCloseSuccessMintStake()
      } else {
        onCloseSuccess()
      }
      setTransaction(receipt)
    }, receipt, error)
  }

  const handleResultUnstake = (receipt, error = null) => {
    handleResult(() => {
      dispatch(setToast({ title: 'Timeout', detail: `${error?.message}` }))
      isCloseInternal()
      onClose();
    }, () => {
      dispatch(setToast({ title: 'Canceled', detail: 'Please try again and confirm the transaction.' }))
      isCloseInternal()
      onClose();
    }, () => {
      isCloseInternal()
      onCloseSuccess()
      setTransaction(receipt)
    }, receipt, error)
  }

  const handleConfirmClick = async () => {
    setPendingTx(true)
    loadingStake(true, isMintStake)
    if (isUnstake) {
      // unstaking
      try {
        await onUnstake(dispatch, id, account, pools, stakeAmount, stakeTokenDecimals, handleResultUnstake, callBackStakeProcessing);
      } catch (e) {
        setPendingTx(false)
      }
    }
    if (isStake) {
      try {
        // staking
        await onStake(dispatch, id, account, pools, stakeAmount, stakeTokenDecimals, handleResultStake, callBackStakeProcessing);
      } catch (e) {
        console.error(e)
        setPendingTx(false)
      }
    }
  }

  const renderShowHeader = () => {
    return (
      <Modal
        width={isMobile ? '1000rem' : '374rem'}
        title={heading}
        visible={isVisible}
        onCancel={onClose}
        wrapClassName={'unstake-project-modal'}
      >
        <div className='body-description'>
          <InfoCoin>
            <Left>
              <NumberInputBox>
                <NumberInput
                  value={stakeAmount}
                  onChange={handleStakeInputChange}
                  step={0.1}
                  disabled={pendingTx}
                  min={0}
                  max={stakingMax}
                />
                <Index>{`~${usdValueStaked || 0} USD`}</Index>
              </NumberInputBox>
            </Left>
            <RightCoin alignItems="center">
              <ZombieCoin src={CoinImage} />
              <Symbol>{stakeTokenSymbol}</Symbol>
            </RightCoin>
          </InfoCoin>

          <Balance>Balance: {getFullDisplayBalance(stakingMax, stakeTokenDecimals, 4)}</Balance>

          <RangeInput
            max={100}
            min={0}
            value={percent}
            onChange={handleChangePercent}
            step={1}
            format={false}
            displayHead={false}
            disabled={pendingTx}
            isShowValue={false}
          />

          <ButtonPercent>
            <ButtonList {...{ pendingTx, handleChangePercent, percent }} />
          </ButtonPercent>
        </div>

        <div className='body-button'>
          <ButtonSubmit>
            <div className='background'>
              <Button
                loading={pendingTx}
                onClick={handleConfirmClick}
                disabled={!stakeAmount || parseFloat(stakeAmount) === 0 || pendingTx}>
                <StakeImage src={StakeIcon} />
                {pendingTx ? 'Confirming' : 'Confirm'}
              </Button>
            </div>
          </ButtonSubmit>
        </div>

      </Modal>
    )
  }

  /**
   * Main render
   * @returns 
   */
  const renderHideHeader = () => {
    return (
      <Modal
        width={isMobile ? '1100rem' : '374rem'}
        visible={isVisible}
        onCancel={onClose}
        wrapClassName={'stake-project-modal'}
        centered
      >

        <BannerHeader>
          <InfoLockPeriod
            title={`Lock`}
            content={getMonthsShort(lockDuration) === 'N/A' ? '0 Mo' : getMonthsShort(lockDuration)}
            isMobile={isMobile}
            isTablet={isTablet}
            isLoading={isLoading}
          />

          <InfoStakingRight
            title={'APY'}
            pool={pool}
            stakingTokenPrice={stakingTokenPrice}
            content={apy}
            isMobile={isMobile}
          />
        </BannerHeader>

        <Background>
          <ImageBackground src={background} alt='' />

          {isLotte ? (
            <Lottie
              options={{
                loop: true,
                autoplay: false,
                animationData: lottie,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                }
              }}
              style={isMobile ? styleImageLottieMobile : isTablet ? styleImageLottieTablet : styleImageLottie}
            />
          ) : (
            <ImgItem src={lottie} />
          )}

          <Lottie
            options={{
              loop: false,
              autoplay: false,
              animationData: lottieSparkle,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              }
            }}
            style={styleImageLottieSparkle}
          />
        </Background>

        <div className='body-description'>
          <InfoRow>
            <Title>
              {poolTitle}
            </Title>

            <TitleDescription> {getDescription(description, total, progress)}</TitleDescription>
          </InfoRow>

          <InfoCoin>
            <Left>
              <NumberInputBox>
                {/* <div>{isNotEqual(stakeAmount, '~', 1)}</div> */}
                <NumberFormat
                  value={BigNumber.isBigNumber(stakeAmount) ? stakeAmount.toNumber() : stakeAmount}
                  onValueChange={handleStakeInputChange}
                  thousandSeparator={true}
                  decimalScale={2}
                  // fixedDecimalScale={true}
                  prefix={isNotEqual(stakeAmount, '~', 1)}
                  allowLeadingZeros={false}
                />
              </NumberInputBox>

              <Index>{`${formatBigNumber(usdValueStaked || 0, '~', 2)} USD`}</Index>
            </Left>

            <RightCoin alignItems="center">
              <ZombieCoin src={CoinImage} />
              <Symbol>{stakeTokenSymbol}</Symbol>
            </RightCoin>
          </InfoCoin>

          <Balance>Balance: {isUnstake ?
            formatNumber(stakedBalance, '~', 2) :
            formatNumberEx(stakingTokenBalance, '~', 2)
          } {stakeTokenSymbol}
          </Balance>

          <RangeInput
            max={100}
            min={0}
            value={percent}
            onChange={handleChangePercent}
            step={1}
            format={false}
            displayHead={false}
            disabled={pendingTx}
            isShowValue={false}
          />

          <ButtonPercent>
            <ButtonList {...{ pendingTx, handleChangePercent, percent }} />
          </ButtonPercent>

        </div>

        <ErrorStyle>
          {/* {error === ERROR_LIST.ERR_MIN &&
            `Amount is less than min deposit ${BigNumber.isBigNumber(minDepositAmount) ? minDepositAmount.toNumber() : minDepositAmount}`}

          {error === ERROR_LIST.ERR_MAX && `Staked is greater than max staked per user ${formatNumber(maxStakedPerUser || 0, '', 2)}`}
          {error === ERROR_LIST.ERR_MAX_POOL && `Staked is greater than max staked pool ${formatNumber(maxStakedPerPool || 0, '', 2)}`} 
          {error === ERROR_LIST.ERR_MAX_BALANCE && 'Staked is greater than balance'} */}
        </ErrorStyle>
        <div className='body-button'>
          <ButtonSubmitStake>
            <ButtonStake {...{
              stakeAmount,
              pendingTx,
              isDisableConfirm,
              handleConfirmClick,
              checkDisableConfirm,
              isMintStake,
              status
            }} />

            <ButtonWithdraw {...{
              onClick: onShowWithdrawSchedule,
              checkDisable: true,
              isDisable: (listDeposit || []) < 1
            }} />
          </ButtonSubmitStake>
        </div>
      </Modal >
    )
  }



  return isHideHeader ? renderHideHeader() : renderShowHeader();
}

export default StakeProjectModal

const ZombieCoin = styled.img`
  width: 17.5rem;

  @media (max-width: 767px) {
    width: 53rem;
  }
`

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const InfoCoin = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5rem;
  background-color: #202429;

  padding: 14rem 17rem 13.5rem 17.5rem;

  @media (max-width: 767px) {
    border-radius: 10rem;
    padding: 28rem 50rem 27rem;
  }
`

const Left = styled.span`
  color: #fff;
  font-size: 18rem;
  font-weight: bold;

  width: 58%;

  @media (max-width: 767px) {
  }
`
const RightCoin = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 39%;
`

const Symbol = styled.p`
  color: #fff;
  font-size: 15rem;
  font-weight: bold;
  margin-left: 5rem;

  margin-bottom: 0;
  line-height: 1.2;

  @media (max-width: 767px) {
    font-size: 45rem;

    margin-left: 12rem;
  }
`

const ButtonSubmit = styled.div`
  .background{
    border-radius: 3px;
    box-shadow: 0 0px 8px 1px #f59600;
    background-image: radial-gradient(#f59600,#38260c);
  }
  button {
    background: url(${ButtonStakingModal}) no-repeat;
    background-size: cover;
    margin: 15px auto 0px;
    display: flex;
    border: none;
    width: 100%;
    height: 38rem;
    align-items: center;
    justify-content: center;
    > span:last-child {
      font-size: 15rem;
      font-weight: 600;
      color: #000;
      padding: 7.5px 0;
      display: flex;

      @media (max-width: 767px) {
        font-size: 51.2rem;
      }
    }
    &.ant-btn-loading:before {
      display: none;
    }
    .ant-btn-loading-icon {
      color: #ffffff;
    }
    :disabled {
      background: url(${ButtonStakingModal}) no-repeat;
      background-size: cover;
      :hover {
        background: url(${ButtonStakingModal}) no-repeat;
        background-size: cover;
      }
    }
    :hover,
    :active,
    :focus {
      background: url(${ButtonStakingModal}) no-repeat;
      background-size: contain;
    }

    @media (max-width: 767px) {
      height: 118rem;
      background-size: contain;
    }
  }
`

const ButtonLast = styled.div`
  margin-top: 15rem;

  @media screen and (max-width: 767px) {
    margin-top: 50rem;
  }
`

const ButtonSubmitStake = styled.div`
`

const ButtonPercent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(60rem, 1fr));
  grid-gap: 16.5rem;
  margin-top: 15rem;
  width: 100%;

  @media (max-width: 767px) {
    margin-top: 50rem;
    grid-gap: 50rem;
  }
`
const ButtonPercentItem = styled(Button)`
  height: 30rem;
  width: 100%;
  border-radius: 5rem;
  background-color: rgb(43, 49, 53);
  border: none;

  @media (max-width: 767px) {
    height: 90rem;
    border-radius: 15rem;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  :hover,
  :active,
  :focus {
    background: #af0000;
    color: #ffffff;
    /* span {
      color: #ffffff;
      font-weight: bold;
    } */
  }
  :disabled {
    background-color: rgb(43, 49, 53);
  }

  span {
    font-size: 12rem;
    font-weight: normal;
    text-align: center;
    color: #af0000;

    @media (max-width: 767px) {
      font-size: 35rem;
    }
  }

  ${({ active }) =>
    active &&
    `
      background: #af0000;
      color: #ffffff;
      span {
        color: #ffffff;
      }
    `}
`
const NumberInputBox = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5rem;
  background-color: #202429;
  border: none !important;
  padding-left: 0 !important;

  input {
    outline: none;
    border: none !important;
    padding-left: 0 !important;
    font-size: 15rem;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    background-color: #202429;

    width: 100%;
 
    @media (max-width: 767px) {
      font-size: 51.2rem;
    }
  }

  .ant-input-number{
    padding: 0 0 0 0;
  }
`

const Index = styled.div`
  font-size: 12rem;
  font-weight: 600;
  line-height: 1;
  text-align: left;
  color: #aeaeae;
  padding: 0;

  @media (max-width: 767px) {
    font-size: 39.4rem;
  }
`

const Balance = styled.div`
  font-size: 11rem;
  line-height: 1.45;
  text-align: left;
  color: #8e8e8e;

  margin-top: 9rem;

  @media (max-width: 767px) {
    font-size: 34rem;
    margin-top: 26.3rem;
    margin-bottom: 35rem;
  }
`
const StakeImage = styled.img`
  width: 22rem;
  padding-right: 5rem;
  padding-bottom: 5px;

  @media (max-width: 767px) {
    width: 60rem;
    padding-right: 10rem;
    padding-bottom: 0;
  }
`

const Background = styled.div`
`

const styleImageLottie = {
  transition: `height 0.05s`,
  width: '150rem',
  height: '150rem',
  position: 'absolute',
  zIndex: 2,
  top: 35,
  right: 0,
  left: 0,
}

const Title = styled.p`
  font-size: 22.5rem;
  font-weight: bold;
  line-height: 0.8;
  text-align: left;
  color: #fff;

  margin-bottom: 10rem;
  margin-top: 18rem;

  @media (max-width: 767px) {
    font-size: 67rem;
    margin-top: 60rem;
    margin-bottom: 26rem;
  }
`
const TitleDescription = styled.p`
  font-size: 11rem;
  // line-height: 1.45;
  text-align: left;
  color: #8e8e8e;

  margin-bottom: 16.5rem;

  @media (max-width: 767px) {
    font-size: 33rem;

    margin-bottom: 48rem;
  }
`

const BannerHeader = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15rem 29rem;

  @media (max-width: 767px) {
    padding: 51.2rem;
  }
`
const ImageBackground = styled.img`
  width: 100%;
  border-radius: 7.5px 0 0 0;
  height: 217rem;

  @media screen and (max-width: 767px) {
    height: 712rem;
  }
`

const styleImageLottieSparkle = {
  transition: `height 0.05s`,
  width: '150rem',
  height: '150rem',
  position: 'absolute',
  zIndex: 2,
  top: 35,
  right: 0,
  left: 0,
}

const styleImageLottieMobile = {
  transition: `height 0.05s`,
  width: '50%',
  height: '550rem',
  position: 'absolute',
  zIndex: 2,
  top: 35,
  right: 0,
  left: 0,
}

const styleImageLottieTablet = {
  transition: `height 0.05s`,
  width: '234rem',
  height: '234rem',
  position: 'absolute',
  zIndex: 2,
  top: 65,
  right: 0,
  left: 0,
}

const ErrorStyle = styled.div`
  margin-bottom: 15rem;
  color: #f12257;
  font-size: 15rem;

  @media (max-width: 767px) {
    font-size: 51.2rem;
  }
`

const ImgItem = styled.img`
  height: 170rem;
  position: absolute;
  margin: 0 auto;
  top: 30rem;
  z-index: 2;
  right: 0;
  left: 0;

  @media (max-width: 767px) {
    height: 570rem;
    top: 85rem;
  }
`