import moment from 'moment';
import { formatNumber } from '../utils/common';
import { updateDataPool } from '../actions/pools'
import { storeTransactionLog } from '../actions/user';
import { stake, getPoolContractEx } from '../utils/callHelpers'
import UseWeb3 from './useWeb3'

export const onStake = async (dispatch, sourceId, account, stakingPoolsData, amount, decimals, cb, callBackStakeProcessing) => {
    const web3 = UseWeb3()
    const pool = stakingPoolsData.find(pool => pool.id === sourceId)
    const sourceContract = getPoolContractEx(pool.stakeContractAddress, web3)

    const callbackLog = (tx) => {
        let log = {
            chainId: pool?.chainId,
            walletAddress: account,
            eventName: `Stake ${formatNumber(amount || 0, '~', 2)} ${pool?.rewardTokenSymbol} to "${pool?.poolTitle}" pool.`,
            date: moment().valueOf(),
        };
        log.transactionHash = tx?.transactionHash;
        dispatch(storeTransactionLog(log))

        dispatch(updateDataPool(sourceId, account));
    }


    const tx = await stake(sourceContract, amount, decimals, account, cb, callbackLog, callBackStakeProcessing);
    return tx;
}
