import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, Link } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Drawer } from 'antd'
import { formatEther } from '@ethersproject/units'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import HomeLogo from '../../../../assets/img/home_logo.png'
// import { ReactComponent as Telegram } from '../../../../assets/svg/telegram.svg'
import Discord from '../../../../assets/img/discord-white.png'
import WalletIcon from '../../../../assets/img/wallet_icon.png'
import WalletBg from '../../../../assets/img/wallet_bg.png'
import telegram from '../../../../assets/img/button_telegram.png'
import ButtonMenu from '../../../../assets/img/button_menu.png'
import ButtonCloseImg from '../../../../assets/img/close.png'
import ButtonDisableBackground from '../../../../assets/img/staking_bg_disable_button.png'

import LeftMenu from './LeftMenu';

import { autoConnect } from '../../../../actions/user';
import { getAccountSymbol } from '../../../../utils/web3.js';
import { getEnvDisableButton } from '../../../../utils/common';
import { toggleAccountModal, toggleWalletModal, setToast } from '../../../../actions/common';

const antIcon = <LoadingOutlined style={{ fontSize: 15, color: 'white', fontWeight: 'bold' }} spin />;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;

  width: 100%;
  padding: 13rem 100rem 0 86.5rem;
  z-index: 1000;

  ${({ isStaking }) =>
    isStaking &&
    `
    position: absolute;
  `}

  @media (max-width: 767px) {
    position: absolute;
    padding: 52rem 75rem 0 43rem;
  }
`
const LogoWrapper = styled.div`
  width: 110rem;
  text-align: left;

  @media screen and (max-width: 767px) {
    width: 252rem;
  }
`
const Logo = styled(Link)`
  width: 110rem;
  text-align: left;

  img {
    width: 100%;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
`
const Menu = styled.div``
const Left = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`

const ButtonControl = styled(Button)`
  height: 52px;
  width: 52px;
  padding: 6px;
  display: none;
  background: url(${ButtonMenu});
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  border: none;

  @media (max-width: 767px) {
    display: inline-block;
    height: 117rem;
    width: 117rem;
  }
`
const BarButton = styled.span`
  display: block;
  width: 20px;
  height: 2px;
  position: relative;
`
const ButtonTelegram = styled(Button)`
  display: flex;
  align-items: center;
  border: unset;
  justify-content: center;
  font-size: 18px;

  padding: 0 25rem;

  background: url(${telegram}) no-repeat center / 100% 100%;
  height: 39rem;

  svg {
    margin-right: 10px;
    width: 20px;
    height: auto;
  }
  span {
    font-family: Poppins;
    font-size: 15rem;
    font-weight: bold;
    line-height: 1.73;
    letter-spacing: 0.75rem;
    text-align: center;
    color: #fff;
  }

  :hover,
  :active,
  :focus {
    background: url(${telegram}) no-repeat center / 100% 100%;
    transform: scale(1.03);
    transition: all 0.3s;
  }
  img {
    width: 22rem;
    margin-right: 5rem;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const ButtonTelegramMobile = styled(Button)`
  display: flex;
  align-items: center;
  background: url(${telegram}) no-repeat center / 100% 100%;
  height: 140rem;
  border: unset;
  justify-content: center;
  font-size: 18px;
  margin: 0 85rem;

  svg {
    margin-right: 10px;
    width: 20px;
    height: auto;
  }
  span {
    color: #fff;
    font-weight: 700;
    line-height: 1.7;
  }
  :hover,
  :active,
  :focus {
    background: url(${telegram});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  img {
    width: 25px;
    margin-right: 10px;
  }
`

const ButtonWallet = styled(ButtonTelegram)`
  background: url(${telegram}) no-repeat center / 100% 100%;
  height: 39rem;
  cursor: pointer;
  :hover,
  :active,
  :focus {
    background: url(${telegram}) no-repeat center / 100% 100%;
    transform: scale(1.03);
    transition: all 0.3s;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }

  &[disabled] {
    color: unset;
    border-color: transparent;
    background: url(${ButtonDisableBackground});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    :hover,
    :active,
    :focus {
      background: url(${ButtonDisableBackground});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
`

const ButtonWalletMobile = styled(ButtonTelegramMobile)`
  display: flex;
  align-items: center;
  padding: 0 50rem;
  background: url(${telegram}) no-repeat center / 100% 100%;
  height: 140rem;
  border: unset;
  justify-content: center;
  font-size: 18px;
  margin: 0 85rem 0 0;

  cursor: pointer;
  :hover,
  :active,
  :focus {
    background: url(${telegram});
  }

  &[disabled] {
    color: unset;
    border-color: transparent;
    background: url(${ButtonDisableBackground});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    :hover,
    :active,
    :focus {
      background: url(${ButtonDisableBackground});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
`

const ButtonCloseWrapper = styled.div`
  padding: 80rem 75rem 0 43rem;
  text-align: right;
`

const ButtonClose = styled(Button)`
  height: 52px;
  width: 52px;
  padding: 6px;
  display: none;
  background: url(${ButtonCloseImg});
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  border: none;

  @media (max-width: 767px) {
    display: inline-block;

    @media (max-width: 767px) {
      height: 117rem;
      width: 117rem;
    }
  }
`

const ButtonMobile = styled.div`
  padding: 0 80rem;
`

const User = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 7.5rem;
  background-color: #0d0d0f;

  height: 39rem;
  padding: 0 2.5rem 0 12rem;
  cursor: pointer;
  border: solid 1rem #202829;

  &:hover {
    transition: 0.3s;
    transform: scale(1.0);
  }

  ${({ desktop }) => desktop && `@media (max-width: 767px) {
    display: none;
  }`}

  @media (max-width: 767px) {
    border-radius: 15rem;
    border: solid 1px #202829;
    height: 120rem;
    padding: 0 2.5rem 0 28rem;
  }
`

const Address = styled.span`
  display: flex;
  align-items: center;
  height: 30rem;

  background: url(${WalletBg}) no-repeat center / 100% 100%;
  border-radius: 7.5rem;
  padding: 0 10rem;
  color: #fff;
  margin-left: 13rem;

  @media (max-width: 767px) {
    height: 95rem;
    padding: 0 30rem;
  }

  .wallet-address {
    font-family: Poppins;
    font-size: 12.5rem;
    line-height: 1.2;
    text-align: left;
    color: #fff;

    @media (max-width: 767px) {
      font-size: 43rem;
    }
  }
`

const Balance = styled.span`
  font-family: Poppins;
  font-size: 12.5rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: #8e8e8e;

  @media (max-width: 767px) {
    font-size: 43rem;
  }
`

const AddressIcon = styled.span`
  margin-left: 5rem;
  overflow: hidden;

  width: 17.5rem;
  height: 17.5rem;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    width: 50rem;
    height: 47.5rem;
  }
`

const AddressImage = styled.img`
  width: 80rem;
`

const DrawerWrapper = styled(Drawer)`
  .ant-drawer-content {
    background-color: #020204;
  }
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;

  border-radius: ${props => props.isBorder && '7.5rem'};
  border: ${props => props.isBorder && 'solid 1.5rem white'};

  @media (max-width: 767px) {
    padding: 5rem 20rem;
    border-radius: 12rem;
    border: solid 3rem #202829;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  span {
    font-family: Poppins;
    font-size: 12.5rem;
    font-weight: bold;
    line-height: 1.2;
    text-align: left;
    color: #8e8e8e;

    margin-right: 5rem;

    @media (max-width: 767px) {
      font-size: 35rem;
      margin-right: 15rem;
    }
  }
`

const FORBIDDEN_CONNECT = ['/', '/whitelist', '/privacy'];

export default function Header({ isStaking, isNotFound }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const { pathname = '' } = location;

  const [visible, setVisible] = useState(false)

  const isDisableAllButton = getEnvDisableButton();

  const showDrawer = () => {
    setVisible(true)
  }
  const onClose = () => {
    setVisible(false)
  }

  const isConnected = useSelector(state => state.user.connectWallet.isConnect);
  const account = useSelector(state => state.user.userAccount.accounts ? state.user.userAccount.accounts[0] : '');
  const balance = useSelector(state => state.user.userAccount.balance);
  const chainId = useSelector(state => state.user?.chainId);
  const loadingConnect = useSelector(state => state.user?.loadingConnectAccount);
  const connectError = useSelector(state => state.user?.connectWallet?.error);
  const {
    isMobile,
    isEndCountdown
  } = useSelector(state => state.common);

  const symbolNetwork = getAccountSymbol(chainId);

  useEffect(() => {
    if (!connectError) return;

    dispatch(setToast({ title: 'CONNECT FAILED:', detail: connectError?.message || 'Please try again' }));
  }, [dispatch, connectError])

  useEffect(() => {
    const dispatchAutoConnect = () => dispatch(autoConnect());

    // The list page are forbidden connect wallet
    if (FORBIDDEN_CONNECT.includes(pathname)) return;

    dispatchAutoConnect();
  }, [dispatch, pathname]);

  return (
    <Nav isStaking={isStaking}>
      <LogoWrapper>
        <Logo to="/">
          <img src={HomeLogo} alt={''} />
        </Logo>
      </LogoWrapper>

      <Menu>
        <Left>
          <LeftMenu />
        </Left>

        <ButtonControl type="primary" onClick={showDrawer}>
          <BarButton />
        </ButtonControl>

        <DrawerWrapper placement="right" closable={false} visible={visible}>
          <ButtonCloseWrapper>
            <ButtonClose type="primary" onClick={onClose}>
              <BarButton />
            </ButtonClose>
          </ButtonCloseWrapper>

          <LeftMenu />

          {isConnected ? (
              <ButtonMobile>
                <User onClick={() => dispatch(toggleAccountModal(true))}>
                  <Balance>{parseFloat(formatEther(balance)).toFixed(4)} {symbolNetwork}</Balance>

                  <Address>
                    <span className="wallet-address">
                      {account && account.substring(0, 5)}...{account && account.substring(account.length - 4)}
                    </span>

                    <AddressIcon>
                      <AddressImage src={WalletIcon} />
                    </AddressIcon>
                  </Address>
                </User>
              </ButtonMobile>
            ) : (
              (isNotFound || !isEndCountdown || FORBIDDEN_CONNECT.includes(pathname)) ? (
                <ButtonTelegramMobile
                  icon={<img src={Discord} alt="discord"/>}
                  size={'large'}
                  href="https://discord.com/invite/undeadblocks"
                  target="_blank"
                >
                  Discord
                </ButtonTelegramMobile>
              ) : (
                <ButtonMobile>
                  <ButtonWalletMobile
                    // disabled
                    disabled={isDisableAllButton}
                    size={'large'}
                    onClick={() => {
                      dispatch(toggleWalletModal(true));
                      onClose();
                    }}
                  >
                    {loadingConnect ? (
                      <LoadingWrapper>
                        <Spin indicator={antIcon} />
                      </LoadingWrapper>
                    ) : 'Connect Wallet'}
                  </ButtonWalletMobile>
                </ButtonMobile>
              )
          )}

          {/* {(isNotFound || FORBIDDEN_CONNECT.includes(pathname)) ? (
            <ButtonTelegram
              icon={<img src={Discord} alt='discord' />}
              size={'large'}
              href="https://discord.gg/undeadblocks"
              target="_blank"
            >
              Discord
            </ButtonTelegram>
          ) : (
            <ButtonMobile>
              {!isConnected ? (
                //   <ButtonWalletMobile onClick={showModal} size={'large'}>
                <ButtonWalletMobile
                  // disabled
                  disabled={isDisableAllButton}
                  size={'large'}
                  onClick={() => {
                    dispatch(toggleWalletModal(true));
                    onClose();
                  }}
                >
                  {loadingConnectAccount ? (
                    <Spin indicator={antIcon} />
                  ) : 'Connect Wallet'}
                </ButtonWalletMobile>
              ) : (isStaking && isConnected) ? (
                loadingConnectAccount ? (
                  <Spin indicator={antIcon} />
                ) : (
                  <User onClick={() => dispatch(toggleAccountModal(true))}>
                    <Balance>{parseFloat(formatEther(balance)).toFixed(4)} {symbolNetwork}</Balance>

                    <Address>
                      <span>
                        {account && account.substring(0, 6)}...{account && account.substring(account.length - 4)}
                      </span>

                      <AddressIcon>
                        <AddressImage src={WalletIcon} />
                      </AddressIcon>
                    </Address>
                  </User>
                )
              ) : (
                <ButtonTelegramMobile
                  icon={<img src={Discord} alt='discord' />}
                  size={'large'}
                  href="https://discord.gg/undeadblocks"
                  target="_blank"
                >
                  Discord
                </ButtonTelegramMobile>
              )}
            </ButtonMobile>
          )} */}
        </DrawerWrapper>
      </Menu>

      {(isNotFound || !isEndCountdown || FORBIDDEN_CONNECT.includes(pathname)) ? (
        <ButtonTelegram
          icon={<img src={Discord} alt="discord"/>}
          size={'large'}
          href="https://discord.com/invite/undeadblocks"
          target="_blank"
        >
          Discord
        </ButtonTelegram>
      ) : (
        !isConnected ? (
          // <ButtonTelegram onClick={showModal} size={'large'}>
          <ButtonWallet
            // disabled
            disabled={isDisableAllButton}
            size={'large'}
            onClick={() => {
              dispatch(toggleWalletModal(true));
              onClose();
            }}
          >
            {loadingConnect ? (
              <LoadingWrapper isBorder={false}>
                <span>Connecting</span>
                <Spin indicator={antIcon} />
              </LoadingWrapper>
            ) : 'Connect Wallet'}
          </ButtonWallet>
        ) : isConnected ? (
          loadingConnect ? (
            <LoadingWrapper isBorder={true}>
              <span>Signing...</span>
              <Spin indicator={antIcon} />
            </LoadingWrapper>
          ) : (
            <User desktop onClick={() => dispatch(toggleAccountModal(true))}>
              <Balance>{parseFloat(formatEther(balance)).toFixed(4)} {symbolNetwork}</Balance>

              <Address>
                <span className="wallet-address">
                  {account && account.substring(0, 4)}......{account && account.substring(account.length - 3)}
                </span>

                <AddressIcon>
                  {/* <AddressImage src={WalletIcon} /> */}
                  {/* <AddressImageJazz ref={iconContainerRef} /> */}
                  <Jazzicon
                    paperStyles={{ maxHeight: '100%', maxWidth: '100%' }}
                    diameter={isMobile ? 28 : 25}
                    seed={jsNumberForAddress(account || '')}
                  />
                </AddressIcon>
              </Address>
            </User>
          )
        ) : (
          <ButtonTelegram
            icon={<img src={Discord} alt="discord"/>}
            size={'large'}
            href="https://discord.com/invite/undeadblocks"
            target="_blank"
          >
            Discord
          </ButtonTelegram>
        )
      )}
    </Nav>
  )
}
