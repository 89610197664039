import React from 'react'
import styled from 'styled-components'
// import { isMobile } from '../../utils'

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  outline: none;
`
export default function VideoAnimation({ video, disableMobile }) {
  return (
    // <Video autoPlay={disableMobile && isMobile ? false : true} muted loop>
    <Video autoPlay playsInline muted loop>
      <source src={video} type="video/mp4"></source>
    </Video>
  )
}
