import styled from 'styled-components';
import WithDirection from '../../../settings/withDirection';

const Wrapper = styled.div`
    margin: 0;
    position: relative;
    z-index: 50;
    padding-top: 112.5rem;

    min-height: 770rem;

    .mintProgress {
        display: flex;
        justify-content: center;
        position: relative;
        bottom: 60rem;

        .loadout-box {
            display: flex;

            .loadouts {
                width: 220rem;
                margin-right: 5rem;

                @media screen and (max-width: 767px) {
                    width: 400rem;
                }
            }
        }

        .mint-value {
            display: flex;
            position: relative;

            .top {
                z-index: 20;
                width: 100%;
    
                font-family: HalloweenNight;
                font-size: 30rem;
                line-height: 0.78;
                letter-spacing: 1.65rem;
                text-align: center;
    
                background-image: linear-gradient(to top, #8c000c, #ff0007, #8c000c), linear-gradient(to bottom, #c70106, #c70106);
                -webkit-background-clip: text;
                background-clip: text;
        
                color: #c70106;
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke: 2rem #c70106;
    
                @media screen and (max-width: 767px) {
                    font-size: 60rem;
                }
            }
    
            .bottom {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                
                font-family: HalloweenNight;
                font-size: 30rem;
                line-height: 0.78;
                letter-spacing: 1.65rem;
                text-align: center;
    
                color: #4d0004;
                -webkit-text-stroke: 6rem #4d0004;
    
                @media screen and (max-width: 767px) {
                    font-size: 60rem;
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        position: relative;
        padding-top: 100px;
        z-index: 200;
    }

    @media screen and (min-width: 768px) and (max-width: 1279px) {
        min-height: 870rem;
    }
`;

export const TitlePage = styled.div`
    margin-bottom: 7.5rem;

    img {
        width: 615rem;

        @media screen and (max-width: 767px) {
            width: 85.8%;
        }
    }

    @media screen and (max-width: 767px) {
    }

    @media screen and (min-width: 768px) and (max-width: 1440px) {
    }
`;

export const SwiperList = styled.div`
    margin: 0;
    display: flex;
    align-items: center;

    padding: 0 12% 80px 12%;

    .swiper-wrapper {
        align-items: center;
    }

    @media (max-width: 767px) {
        padding: 0 0px;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
        padding: 0 200px;
    }

    @media (min-width: 1024px) and (max-width: 1279px) {
        padding: 0 15%;
    }

    @media (min-width: 1280px) {
        padding: 0 8%;
    }

    @media (min-width: 1600px) {
    }
`;

export const SwiperItem = styled.div`
    margin: 0;
    position: relative;

    .overlay {
        position: absolute;
        z-index: 100;
        left: 0;
        bottom: 0;
        background-color: ${props => !props.isActive && 'rgba(15, 16, 20, 0.75)'};
        height: 100%;
        width: 100%;

        @media screen and (max-width: 1279px) {
            display: none;
        }
    }

    .wrapped-null {
        border-radius: 7.5rem;
        padding: 30% 0;
        margin: 5rem 0;

        display: flex;
        flex-direction: column;
        align-items: center;

        animation: opacityPulse 2s ease-in infinite;

        @media (max-width: 767px) {
            padding: 15% 0;
            margin: 60px 90px 10px;
        }

        @media (min-width: 768px) and (max-width: 1023px) {
            margin: 10px 16% 10px;
            padding: 20% 0;
        }

        @media (min-width: 1024px) and (max-width: 1279px) {
            padding: 18% 0;
            margin: 0 20%;
        }

        .content-null {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .img-null {
                width: 200rem;
                height: auto;

                @media (max-width: 767px) {
                    width: 65%;
                }
    
                @media (min-width: 768px) and (max-width: 1023px) {
                    width: 60%;
                }
    
                @media (min-width: 1024px) and (max-width: 1279px) {
                    width: 60%;
                }
            }
        }
    }

    .wrapped {
        border-radius: 7.5px;
        padding: 10px 0 30px 0;
        margin: 5px 0;

        display: flex;
        flex-direction: column;
        align-items: center;

        box-shadow: ${props => props.isActive ? '0 0 15rem 4rem #6b1723' : 'none'};

        @media (max-width: 767px) {
            margin: 60rem 250rem 10rem;
            box-shadow: 0 0 5px 5px #6b1723;
            padding: 10px 0 60rem 0;
        }

        @media (min-width: 768px) and (max-width: 1023px) {
            margin: 10px 120rem 10px;
            box-shadow: 0 0 5px 5px #6b1723;
        }

        @media (min-width: 1024px) and (max-width: 1279px) {
            margin: 10rem 190rem 10rem;
            box-shadow: 0 0 5px 5px #6b1723;
        }

        .heading {
            font-size: ${props => props.isActive ? '22.5rem' : '18rem'};
            font-weight: bold;
            text-align: center;
            color: #df1e33;
            margin-bottom: 10rem;

            @media (max-width: 767px) {
                font-size: 52rem;
            }
        }

        .content {
            display: flex;
            justify-content: center;
            display: relative;

            .img-default {
                width: 300rem;
                height: 280rem;

                @media (max-width: 767px) {
                    width: 75%;
                    height: 570rem;
                    margin-right: ${props => props.type === 2 || props.type === 1 ? '45rem' : '0rem'};
                }
    
                @media (min-width: 768px) and (max-width: 1023px) {
                    width: 100%;
                }
    
                @media (min-width: 1024px) and (max-width: 1279px) {
                    width: 400rem;
                    height: 380rem;
                }
            }

            @media (max-width: 767px) {
                width: 100%;
            }

            @media (min-width: 768px) and (max-width: 1023px) {
                height: 280rem;
            }

            @media (min-width: 1024px) and (max-width: 1279px) {
                height: 380rem;
            }

            @media (min-width: 1280px) and (max-width: 1599px) {
                height: ${props => props.isActive ? '214rem' : '165rem'};
            }
        }

        .bottom {
            margin: 0 0 0 0;
            padding: 0 30px;

            @media (max-width: 767px) {
                padding: 0 10px;
                margin: 0;
            }

            .text-gradient {
                font-size: ${props => props.isActive ? '32.5rem' : '25rem'};
                font-weight: bold;
                text-align: center;
                color: #fff;
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;

                margin-bottom: 5rem;

                @media (max-width: 767px) {
                    font-size: 73rem;
                }
            }

            p {
                font-size: ${props => props.isActive ? '12rem' : '11rem'};
                font-weight: normal;
                line-height: 1.15;
                text-align: center;
                color: #fff;

                span {
                    font-weight: 600;
                }

                @media (max-width: 767px) {
                    font-size: 30rem;
                    line-height: 1.2;
                }
            }
        }

        .sold-out {
            display: flex;
            justify-content: center;
            width: 112.5rem;
            height: 31.5rem;
            margin: 0 auto 0 auto;

            cursor: not-allowed;
            opacity: 0.7;
            position: relative;

            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */

            @media screen and (max-width: 767px) {
                height: 21px;
                width: 257rem;
            }

            .bottom-mint-btn {
                position: absolute;
                width: 113%;
                transform: translateY(-50%);
                top: 50%;

            }

            .top-mint-btn {
                padding: 0px 0px;
                cursor: not-allowed;

                width: 100%;
                position: absolute;
            }

            span {
                top: 50%;
                transform: translateY(-50%);
                cursor: not-allowed;
                position: absolute;
                font-family: Poppins;
                font-size: 17rem;
                font-weight: 600;
                line-height: 1;
                text-align: center;
                color: #000;

                @media screen and (max-width: 767px) {
                    top: 56.5%;
                    font-size: 40rem;
                }
            }
        }

        .parent {
            display: flex;
            justify-content: center;
            width: 112.5rem;
            height: 31.5rem;
            margin: 0 auto 0 auto;

            position: relative;

            &:hover {
                transform: scale(1.010);
                transition: all 1s;

                span {
                    top: 50%;
                }
            }

            @media screen and (max-width: 767px) {
                height: 21px;
                width: 257rem;
            }

            &:active {
                transform: scale(0.9);
                transition: all 0.2s;
            }

            .bottom-mint-btn {
                position: absolute;
                width: 113%;
                transform: translateY(-50%);
                top: 50%;

            }

            .top-mint-btn {
                padding: 0px 0px;
                cursor: pointer;

                width: 100%;
                position: absolute;
            }

            span {
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                position: absolute;
                font-family: Poppins;
                font-size: 17rem;
                font-weight: 600;
                line-height: 1;
                text-align: center;
                color: #000;

                @media screen and (max-width: 767px) {
                    top: 56.5%;
                    font-size: 40rem;
                }
            }
        }
    }
`;

export default WithDirection(Wrapper);