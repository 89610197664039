import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import Lottie from 'react-lottie';

import Nav from '../Home/LandingPageHeader/Header';

import leftLayer from '../MintWeapons/img/left-test.png';
import rightLayer from '../MintWeapons/img/right.png';
import leftMan from '../MintWeapons/img/leftman.png';
import rightMan from '../MintWeapons/img/rightman.png';

import undeadToken from '../../lotties/undead_token.json';

import { fetchMeta1 } from '../../actions/user';

import Wrapper, {
    BubbleLayer,
    HeaderBanner,
    ContentWrapper,
    ButtonWrapper
} from './styles';

function PlayFrame() {
    const dispatch = useDispatch();

    const [isPlay, setPlay] = useState(false);

    const {
        isMobile,
    } = useSelector(state => state?.common ?? {});

    const meta1Url = useSelector(state => state?.user?.meta1Res?.result?.url ?? '');

    useEffect(() => {
        dispatch(fetchMeta1());
    }, [ dispatch]);

    const handleToggleWalletModal = () => {
        setPlay(true);
    }

    const renderBubbleLayer = () => (
        <BubbleLayer>
            <section className="bubble">
                <img src={leftLayer} alt="left" className="left" />
            </section>

            <section className="bubble">
                <img src={rightLayer} alt="right" className="right" />
            </section>
        </BubbleLayer>
    );

    const renderHeaderBanner = () => (
        <HeaderBanner>
            <div className="header-layout">
                <section className="left-layer">
                    <img src={leftMan} alt="left" className="left-header" />
                </section>

                <section className="right-layer">
                    <img src={rightMan} alt="right" className="right-header" style={{ paddingRight: '20rem' }} />
                </section>
            </div>
        </HeaderBanner>
    );

    return (
        <Wrapper>
            {isPlay && (
                <iframe
                    className={'playFrame'}
                    src={meta1Url}
                    title='UNDEADBLOCK'
                    allowFullScreen={true}
                    height={'100%'}
                    width={'100%'}
                ></iframe>
            )}

            <div
                className={cn('main', {
                    'isNone': isPlay
                })}
            >
                <Nav isStaking />

                {renderBubbleLayer()}
                {renderHeaderBanner()}

                <ContentWrapper>
                    <section className={'tokenAnimation'}>
                        <Lottie
                            width={isMobile ? '1250rem' : '500rem'}
                            height={isMobile ? '1100rem' : '400rem'}
                            options={{
                                loop: true,
                                autoplay: false,
                                animationData: undeadToken,
                                rendererSettings: {
                                    preserveAspectRatio: 'xMidYMid slice'
                                }
                            }}
                            style={{ pointerEvents: 'none' }}
                        />
                    </section>

                    <section className={'buttonContainer'}>
                        <ButtonWrapper onClick={() => handleToggleWalletModal()}>
                            PLAY
                        </ButtonWrapper>
                    </section>
                </ContentWrapper>
            </div>
        </Wrapper>
    );
}

export default PlayFrame;