import maskmanImg from '../img/maskman.png';
import hunterImg from '../img/hunter.png';
import demolitioninistImg from '../img/demolitioninist.png';


export const LIMIT_PACKAGES_LENGTH = 20;
export const SKIP_PACKAGES_LENGTH = 0; 

export const CARD_LIST = [
    {
        type: 9,
        img: demolitioninistImg,
        name: 'DEMOLITIONIST',
        nameLower: 'Demolitionist',
        price: '3',
        detail: '',
        // sparkleLottie: amateurSparkle,
        start: '#d48f56',
        end: '#915330',
        buttonLbl: 'MINT',
        fontSize: 25,
        fontSizeMobile: 15,
        minted: '0',
        totalMinted: '0',
        perk: null
    },
    {
        type: 7,
        img: maskmanImg,
        name: 'MARKSMAN',
        nameLower: 'Marksman',
        price: '1',
        detail: '',
        // sparkleLottie: assassinSparkle,
        start: '#e8c24a',
        end: '#ba9420',
        buttonLbl: 'MINT',
        fontSize: 15,
        fontSizeMobile: 8.9,
        minted: '0',
        totalMinted: '0',
        perk: 3
    },
    {
        type: 8,
        img: hunterImg,
        name: 'HUNTER',
        nameLower: 'Hunter',
        price: '2',
        detail: '',
        // sparkleLottie: killerSparkle,
        start: '#cdf1f1',
        end: '#7dafb1',
        buttonLbl: 'MINT',
        fontSize: 15,
        fontSizeMobile: 8.9,
        minted: '0',
        totalMinted: '0',
        perk: null
    },
];
