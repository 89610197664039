import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'

import button from '../../assets/img/button.png';
import './style.css';

const Wrapper = styled.div`
    padding-bottom: 30rem;

    @media only screen and (max-width: 767px) {
        padding-bottom: 0rem;
    }

    .header-nav {
        display: flex;
        justify-content: flex-end;
        position: relative;

        .layer {
            position: absolute;
            left: calc(50% - 50px);
            top: 0;
            width: 131rem;
            height: 94.5rem;
        }

        .close {
            position: absolute;
            width: 20px;
            height: 20px;
            margin: 10px;
            cursor: pointer;

            &:hover {
                transform: scale(1.005);
                transition: all 1s;
            }
        }
    }

    .transaction-info {
        text-align: center;

        h2 {
            font-family: Poppins;
            font-size: 22.5rem;
            font-weight: bold;
            line-height: 1.89;
            text-align: center;
            color: #fff;
            padding-top: 32rem;
            z-index: 1000;
            margin-bottom: 0;

            @media (max-width: 767px) {
                font-size: 76.8rem;
            }
        }

        p {
            font-size: 12rem;
            line-height: 1.67;
            text-align: center;
            color: #8e8e8e;

            margin-bottom: 30rem;

            @media only screen and (max-width: 767px) {
                font-size: 33rem;

                margin-bottom: 70rem;
            }
        }
    }

    .btn-container {}
`;

const ButtonStyle = styled.div`
    height: 40rem;
    width: 100% !important;

    cursor: pointer;

    background: url(${button});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    border: none; 

    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
        height: 120rem;
        font-size: 40rem;
        margin-bottom: 80rem;
    }

    &.ant-btn-loading:before {
        display: none;
    }

    .ant-btn-loading-icon {
        color: #ffffff;
    }
`

function TransactionLoading(props) {
    const { title, text, isVisible, isMobile } = props;

    const antIcon = <LoadingOutlined style={{ fontSize: isMobile ? '60rem' : '24rem', color: 'white', fontWeight: 'bold' }} spin />;

    return (
        <Modal
            centered
            visible={isVisible}
            title={title}
            onCancel={()=>{}}
            width={isMobile ? '1100rem' : '374rem'}
            wrapClassName={'transaction-loading'}
        >
            <Wrapper>
                <div className="transaction-info">
                    <p>{text}</p>
                </div>

                <div className="btn-container">
                    <ButtonStyle>
                        <Spin indicator={antIcon} />
                    </ButtonStyle>
                </div>
            </Wrapper>
        </Modal>
    );
}

export default TransactionLoading;