import React from 'react';

import Nav from '../Home/LandingPageHeader/Header';

import Wrapper from './styles';

function TermOfService() {
    return (
        <Wrapper>
            <Nav />
            <div className={'content'}>
                <h2 className={'title'}>
                    Wagyu Games Terms of Service & End User Agreement
                </h2>

                <span className={'paragraph'}>
                    Wagyu Games (“Provider”) will provide you access to the Web Services (defined below)and related website located at wagyu.io or
                    such other Web addresses or uniform resource locators as may be specified by the Provider (collectively, the "Site"), specifically
                    and solely for the purposes of requesting and receiving Data (defined below), and Third Party Data (defined below). Please read
                    carefully the following terms and conditions (this “Agreement”) and the Privacy Policy, which may be found at
                    http://www.wagyu.io/privacy. This Agreement governs your access to and use of the Site, Web Services, Data and Third Party Data,
                    and constitutes a binding legal agreement between you (referred to herein as “You” or “Customer”) and Provider.
                </span>

                <br />
                <br />

                <span className={'paragraph'}>
                    YOU ACKNOWLEDGE AND AGREE THAT, BY CLICKING THE CHECKBOX OR BY ACCESSING OR USING THE SITE, WEB SERVICES, DATA OR THIRD PARTY
                    DATA, YOU ARE INDICATING THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THIS AGREEMENT. IF YOU DO NOT AGREE TO THIS
                    AGREEMENT, THEN YOU HAVE NO RIGHT TO ACCESS OR USE THE SITE, WEB SERVICES, DATA OR THIRD PARTY DATA. If you accept or agree to
                    this Agreement on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind that
                    company or other legal entity to this Agreement and, in such event; “Customer”, “You” and “Your” will refer and apply to that
                    company or other legal entity.
                </span>

                <section className={'separate'}>
                    <p className={'heading'}>Definitions</p>

                    <span className={'paragraph'}>
                        “Web Services” consist of a set of software programs, interfaces, and webpages running on computers hosted at Provider sites
                        or at third-party hosting facilities accessible via the Internet as described in this Agreement and the Site. “Data” means
                        the prices and other market data that is (i) owned by Provider and (ii) obtained by Provider from exchanges and other sources
                        delivered to Customer via the Web Services and as described in this Agreement and on the Site. “Third Party Data” means the
                        prices and other data that is (i) not owned by Provider and (ii) obtained by Provider from exchanges and other sources
                        delivered to Customer via the Web Services and as described in this Agreement and on the Site. “Data Owner” means a legal
                        entity that holds ownership rights to some of the Data or Third Party Data (defined below) and is the original licensing
                        source of such portion of the Data or Third Party Data when such portion of the Data or Third Party Data is not in the public
                        domain. In cases of Third Party Data, Provider has secured redistribution agreements with the Data Owner. “Distinct Software
                        Applications” means software applications that automate substantially different business products, services, processes or
                        functions of Customer. Provider reserves the right, in its sole discretion, to make a reasonable determination as to whether
                        software applications that use the Web Services or the Data or Third Party Data constitute Distinct Software Applications.
                        “Data Exchange Format” means an electronic version of the Data or Third Party Data used for sharing the Data or Third Party
                        Data between software applications, including but not limited to any application programming interface (API), any database
                        access (e.g., ODBC, etc.), any network transmission format (e.g., EDI, SOAP, RSS, XML, etc.) and any data file format
                        (e.g., XLS, CSV, etc.). Provider reserves the right, in its sole discretion, to determine if a particular electronic
                        version of the Data or Third Party Data constitutes a Data Exchange Format. 
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Modification</p>

                    <span className={'paragraph'}>
                        Provider reserves the right to modify, discontinue or terminate the Site, Web Services, Data and Third Party Data or to
                        modify this Agreement, at any time and without prior notice. If Provider modifies this Agreement, Provider will post the
                        modification on the Site or provide you with notice of the modification. Provider will also update the “Last Updated Date” at
                        the top of this Agreement. By continuing to access or use the Site, Web Services, Data and Third Party Data after Provider
                        has posted a modification on the Site or has provided you with notice of a modification, you are indicating that you agree to
                        be bound by the modified Agreement. If the modified Agreement is not acceptable to You, Your only recourse is to cease using
                        the Site, Web Services, Data and Third Party Data.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Registration</p>

                    <span className={'paragraph'}>
                        In order to access the Site, Web Services, Data and Third Party Data, You must register to create an account (“Account”).
                        During the registration process, You will be required to provide certain information and You will establish a password. You
                        agree to provide accurate, current and complete information during the registration process and to update such information
                        to keep it accurate, current and complete. Provider reserves the right to suspend or terminate Your Account if any
                        information provided during the registration process or thereafter proves to be inaccurate, not current or incomplete. You
                        are responsible for safeguarding Your password. You agree not to disclose Your password to any third party and to take sole
                        responsibility for any activities or actions under Your Account, whether or not You have authorized such activities or actions.
                        You will immediately notify Provider of any unauthorized use of Your Account.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Licenses & Data</p>

                    <span className={'paragraph'}>
                        Web Services License. Subject to Customer’s compliance with the terms and conditions of this Agreement, Provider grants to
                        Customer a limited, non-exclusive, non-transferable, license to access and use the Web Services solely for its business
                        purposes. This Agreement governs Customer’s access to and use of the Web Services and the Site. Customer acknowledges and
                        agrees that but for this Agreement, Customer would have no rights or access to the Web Services and the Site.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Rights in Data License</p>

                    <span className={'paragraph'}>
                        Subject to Customer’s compliance with the terms and conditions of this Agreement, Provider grants to Customer a limited,
                        non-exclusive, non-transferable, license to access and use the Data and Third Party Data available via the Web Services
                        solely for its personal purposes. In addition, any Third Party Data may be subject to restrictions of use and require
                        additional license agreements with the Data Owner. While Provider may, in its sole discretion, offer administrative
                        assistance to Customer in obtaining such additional licenses, Customer is solely responsible for obtaining all required
                        license agreements with each Data Owner pursuant to Customer’s use of such Third Party Data. If Customer does not obtain a
                        required license agreement for any portion of the Third Party Data and as such is in violation of the Data Owner’s licensing
                        requirements, Provider may terminate access to the unlicensed portion of Third Party Data until Customer obtains such license
                        agreements with the Data Owner.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Proprietary Rights</p>

                    <span className={'paragraph'}>
                        Subject to the limited rights expressly granted hereunder, Customer acknowledges that the Data, Web Services, Site, and/or any
                        developments to the Data, Web Services, and Site that result from services provided to Customer hereunder are proprietary in
                        nature and owned exclusively by Provider. The Data, Third Party Data, the Web Services, as well as the Developments are to be
                        used exclusively as described herein. The services provided to the Customer and all materials therein or transferred thereby,
                        including, without limitation, software, images, text, graphics, illustrations, logos, patents, trademarks, service marks,
                        copyrights, photographs, audio, video, data, third party data, web services and all Intellectual Property Rights related
                        thereto, are the exclusive property of Wagyu Games and its licensors. Except as explicitly provided herein, nothing in this
                        Agreement shall be deemed to create a license in or under, any such Intellectual Property Rights, and you agree not to sell,
                        license, rent, modify, distribute, copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit or
                        create derivative works from any materials or content accessible from the services. Use of Wagyu Games’s Historical Data or
                        materials for any purpose not expressly permitted by this Agreement is strictly prohibited. Wagyu Games’s Historical Data
                        has been obtained and developed in a unique proprietary manner that cannot be redistributed without the exclusive written
                        permission of Wagyu Games.  Any unpermitted use or redistribution of Wagyu Games’s Historical Data will result in the
                        termination of the Customer’s account and legal action will be taken by Wagyu Games to the fullest extent of the law to
                        protect said proprietary product.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Restrictions on the Web Services</p>

                    <span className={'paragraph'}>
                        Customer may use the Web Services solely with any software application owned or licensed by Customer. Customer may not use,
                        adapt, modify, redistribute, sublicense, sell or otherwise make available any portion of the Web Services for use by software
                        applications not owned or licensed by Customer. Neither party will attempt to access, tamper with, or use non-public areas
                        of the other party’s website, computer systems, or the technical delivery systems of the other party’s providers. Neither
                        party will attempt to probe, scan, or test the vulnerability of any of the other party’s systems or networks or breach any
                        of the other party’s security or authentication measures.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Restrictions on the Data and Third Party Data</p>

                    <span className={'paragraph'}>
                        Customer may use the Data and Third Party Data solely with any software application owned or licensed by Customer. Customer
                        will not use, redistribute, sublicense, sell or otherwise make available any portion of the Data or Third Party Data in any
                        Data Exchange Format for use in software applications not owned or licensed by Customer. Customer may not display or make the
                        Data or Third Party Data available in any Data Exchange Format to non-employee users, including but not limited to, vendors,
                        contractors, partners and the general public (e.g., public websites, partner and vendor extranets, EDI applications, etc.).
                        Customer may cache and store the Data and Third Party Data for use within each Distinct Software Application provided such
                        use is in compliance with restrictions imposed by the Data Owners. The Data or Third Party Data may not be shared between
                        Distinct Software Applications or made available in any Data Exchange Format for the purpose of sharing between Distinct
                        Software Applications. Enforcement. Except as otherwise provided herein, Customer is responsible for all of Customer’s
                        activities occurring through its use of the Web Services. Telecommunications and Internet Services. Customer acknowledges
                        that the use of the Web Services by Customer is dependent upon access to telecommunications and Internet services. Customer
                        will be solely responsible for acquiring and maintaining all telecommunications and Internet services and other hardware and
                        software required to access and use the Web Services, including, without limitation, any and all costs, fees, expenses, and
                        taxes of any kind related to the foregoing.
 
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Survival</p>

                    <span className={'paragraph'}>
                        The provisions of the Definitions Section and Sections that by their nature should reasonably survive, and any amendments to
                        the provisions of the aforementioned will survive any termination or expiration of this Agreement.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Customer Responsibilities</p>

                    <span className={'paragraph'}>
                        Customer will promptly report any errors in the operation of the Web Services to Provider and will not take any actions that
                        would increase the severity of the error. Customer will use the Web Services solely as described herein. In the event that
                        Customer violates any of the requirements of this Section, Provider will have no responsibility to provide Support.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Warranties, Indemnity & Limitation of Liability</p>

                    <span className={'paragraph'}>
                        Indemnification by Provider. Provider agrees to defend (or settle), indemnify and hold Customer, its employees, directors and
                        officers harmless from and against any and all liabilities, losses, damages, or expenses (including court costs and reasonable
                        attorneys fees) in connection with any third party claim that the Web Services, Data or Third Party Data infringe or
                        misappropriate any Intellectual Property Rights (defined below) of any third party, only to the extent the liabilities,
                        damages, or expenses result from use of the Web Services that is within the scope of this Agreement, provided that Customer
                        does not make any admission of Provider guilt without Provider’s prior written approval and provided that Customer gives
                        Provider (i) prompt written notification of the claim or action, (ii) sole control and authority over the defense or
                        settlement thereof, and (iii) all reasonably available information, assistance and authority to settle and/or defend any such
                        claim or action. As used in this Section, “Intellectual Property Rights” specifically includes, without limitation, any patent,
                        copyright, trade mark, trade name, trade dress, trade secret, service mark, service name, title, slogan, proprietary process,
                        or any other intellectual property right.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Indemnification by Customer</p>

                    <span className={'paragraph'}>
                        Customer agrees to defend (or settle), indemnify and hold Provider, its employees, directors and officers harmless from and
                        against any and all liabilities, losses, damages, or expenses (including court costs and reasonable attorneys fees) in
                        connection with any third party claim that the Customer’s use of the Web Services or Data in violation of this Agreement
                        infringes or misappropriates any Intellectual Property Rights of any third party, provided that Provider does not make any
                        admission of Customer guilt without Customer’s prior written approval and provided that Provider gives Customer (i) prompt
                        written notification of the claim or action, (ii) sole control and authority over the defense or settlement thereof, and
                        (iii) all reasonably available information, assistance and authority to settle and/or defend any such claim or action.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Warranty Disclaimers</p>

                    <span className={'paragraph'}>
                        OTHER THAN SPECIFICALLY SET FORTH HEREIN, (i) THE WEB SERVICES, SITE, DATA, AND THIRD PARTY DATA ARE DELIVERED TO CUSTOMER
                        ON AN “AS IS” BASIS, WITHOUT ANY WARRANTIES OR REPRESENTATIONS, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES
                        OF MERCHANTABILITY, ACCURACY OF INFORMATION PROVIDED, NON-INFRINGEMENT, OR FITNESS FOR A PARTICULAR PURPOSE, QUALITY, OR
                        PERFORMANCE, (ii) PROVIDER MAKES NO WARRANTY THAT THE SITE, DATA, OR THIRD PARTY DATA WILL MEET CUSTOMER'S SPECIFIC
                        OBJECTIVES OR NEEDS; (iii) PROVIDER MAKES NO WARRANTY THAT THE WEB SERVICES, SITE, DATA, OR THIRD PARTY DATA WILL BE FREE
                        FROM ERRORS OR BUGS; and (iv) PROVIDER MAKES NO WARRANTY THAT THERE WILL BE UNINTERRUPTED OPERATION OF THE WEB SERVICES,
                        SITE, DATA, AND THIRD PARTY DATA. CUSTOMER ACKNOWLEDGES THAT (i) ANY DATA DOWNLOADED THROUGH THE USE OF THE WEB SERVICES AND
                        SITE IS DONE AT ITS OWN DISCRETION AND RISK, AND THAT CUSTOMER WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO ITS COMPUTER
                        SYSTEM OR LOSS OF APPLICATIONS OR DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH DATA and (ii) THE FOREGOING EXCLUSIONS AND
                        DISCLAIMERS OF WARRANTIES ARE AN ESSENTIAL PART OF THIS AGREEMENT AND FORMED THE BASIS FOR DETERMINING THE PRICE CHARGED
                        FOR THE PRODUCTS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM PROVIDER OR ELSEWHERE SHALL CREATE ANY
                        WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES IN
                        CERTAIN CIRCUMSTANCES. ACCORDINGLY, SOME OF THE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Accuracy of Data & Third Party Data</p>

                    <span className={'paragraph'}>
                        PROVIDER MAKES NO WARRANTY REGARDING THE DATA, THIRD PARTY DATA OR ANY OTHER INFORMATION PURCHASED OR OBTAINED THROUGH
                        PROVIDER’S SITE AND/OR THE WEB SERVICES, OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY DATA,
                        THIRD PARTY DATA OR OTHER INFORMATION OBTAINED THROUGH PROVIDER’S WEBSITE AND/OR THE WEB SERVICES.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Limitation of Liability</p>

                    <span className={'paragraph'}>
                        UNLESS OTHERWISE PROVIDED HEREIN, IN NO EVENT WILL PROVIDER’S AGGREGATE LIABILITY TO CUSTOMER AND ANY THIRD PARTY IN
                        CONNECTION WITH THIS AGREEMENT OR CUSTOMER’S ACCESS TO OR USE OF THE WEB SERVICES, REGARDLESS OF THE FORM OR THEORY OF THE
                        CLAIM OR ACTION. PROVIDER WILL NOT BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, SPECIAL, PUNITIVE, EXEMPLARY OR RELIANCE
                        DAMAGES ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, OR ANY DAMAGES RESULTING FROM ANY INTERRUPTION OR DISRUPTION IN
                        COMMUNICATIONS OR SERVICES, UNAVAILABILITY OR INOPERABILITY OF SERVICES, TECHNICAL MALFUNCTION, LOST DATA, OR LOST PROFITS,
                        EVEN IF PROVIDER KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILTY OF OR COULD HAVE REASONABLY PREVENTED SUCH DAMAGES, AND
                        NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Miscellaneous Provisions</p>

                    <span className={'paragraph'}>
                        Excusable Delays. Any delay in performance of any provision of this Agreement caused by conditions beyond the reasonable
                        control of either party will not constitute a breach of this Agreement, provided that the delaying party has taken reasonable
                        measures to notify the affected party of the delay in writing and uses reasonable efforts to perform in accordance with this
                        Agreement notwithstanding such conditions. The delayed party’s time for performance will be deemed extended for a period
                        equal to the duration of the conditions beyond its control. Conditions beyond a party’s reasonable control include, but are
                        not limited to, natural disasters, acts of government, acts of terrorism, power failures, major network failures, fires,
                        riots, and acts of war (collectively, “Excusable Delays”).
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Compliance with Laws & Policies</p>

                    <span className={'paragraph'}>
                        The parties hereby agree to abide by and comply with all applicable local, state, national, and international laws and
                        regulations (including applicable laws that pertain to the transmission of technical data, privacy, the encryption of
                        software, the export of technology, the transmission of obscenity, or the permissible uses of intellectual property).
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Entire Agreement and Severability</p>

                    <span className={'paragraph'}>
                        This Agreement, as amended from time to time according to its terms, shall constitute the entire agreement between Customer
                        and the Provider respecting the Site, the Web Services, the Support, the Data, and the Third Party Data described herein,
                        and shall supersede all prior agreements, arrangements, representations or promises, whether oral or written, as to its
                        subject matter. This Agreement may be amended only in a written agreement that is duly executed by authorized representatives
                        of the parties.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Force Majeure</p>

                    <span className={'paragraph'}>
                        The Provider and their respective affiliates shall not be deemed to be in default of any provision hereof or be liable for
                        any delay, failure in performance, or interruption of service resulting directly or indirectly from acts of God, civil or
                        military authority, civil disturbance, war, terrorism, strikes, fires, other catastrophes, power or telecommunications failure
                        or any other cause beyond its reasonable control.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Waiver</p>

                    <span className={'paragraph'}>
                        No waiver by either party of any default by the other in the performance of any provisions of this Agreement shall operate
                        as a waiver of any continuing or future default, whether of a like or different character.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Assignment</p>

                    <span className={'paragraph'}>
                        Neither party may assign this Agreement without prior written consent unless in connection with a merger or acquisition of
                        either party.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Severability</p>

                    <span className={'paragraph'}>
                        If any provision of this Agreement (or any portion thereof) shall be invalid, illegal or unenforceable, the validity,
                        legality or enforceability of the remainder of this Agreement shall not in any way be affected or impaired thereby.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Relationship Between the Parties</p>

                    <span className={'paragraph'}>
                        Nothing in this Agreement shall be construed to create a partnership, joint venture or agency relationship between the
                        parties. Neither party will have the power to bind the other or to incur obligations on the other’s behalf without such
                        other party’s prior written consent.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>No Third-Party Beneficiaries</p>

                    <span className={'paragraph'}>
                        This Agreement is intended for the sole and exclusive benefit of the signatories and is not intended to benefit any third
                        party. Only the parties to this Agreement may enforce it.
                    </span>
                </section>

                <section className={'separate'}>
                    <p className={'heading'}>Notice</p>

                    <span className={'paragraph'}>
                        The parties may give notice to each other via email, fax or certified mail. Notices sent to Provider should be directed to
                        support@wagyu.io. Notices sent to Customer will be sent to Customer at the email address provided during registration to use
                        the Web Services. Should you have any further questions with regards to Wagyu Games's terms of service, please feel free to
                        email us at admin@wagyu.io. We're happy to hear from you.
                    </span>
                </section>
            </div>
        </Wrapper>
    );
}

export default TermOfService;