import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const Wrapper = styled.div`
    background: #0f1014;
    position: relative;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;

    .playFrame {
        width: 100%;
        height: 100vh;
    }

    .main {
        &.isNone {
            display: none;
        }
    }
`;

export const BubbleLayer = styled.div`
    opacity: 0.7;
    pointer-events: none;

    .bubble {
    }

    @media screen and (max-width: 767px) {
        display: none;
    }

    .left {
        position: absolute;
        left: -6rem;
        top: -8rem;
        width: 31.25vw;
        min-height: 720rem;

        @media screen and (max-width: 767px) {
            width: 65vw;
            left: -50rem;
            top: 300rem;
            min-height: 400rem;
        }
    }

    .right {
        position: absolute;
        right: 0;
        top: 24rem;
        width: 44.5vw;
        min-height: 1033.5rem;

        @media screen and (max-width: 767px) {
            width: 90vw;
            min-height: 500rem;
        }
    }
`;

export const HeaderBanner = styled.div`
    position: relative;
    top: 155.5rem;
    z-index: 100;
    height: 100%;
    pointer-events: none;

    @media screen and (max-width: 767px) {
        top: 650rem;
    }

    @media screen and (min-width: 768px) and (max-width: 1279px) {
        z-index: 50;
        top: 300rem;
    }

    .header-layout {
        position: relative;

        @media screen and (max-width: 767px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            height: 400px;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }

        .left-layer {
            position: absolute;
            left: -3rem;
            top: -7rem;

            .left-header {
                width: 29.33vw;
                min-height: 564.5rem;

                @media screen and (max-width: 767px) {
                    position: absolute;
                    left: -280rem;
                    width: 70vw;
                    top: 13rem;
                }
            }
        }

        .right-layer {
            position: absolute;
            right: 0;
            top: -13rem;

            @media screen and (max-width: 767px) {
                top: 0;
                right: -25%;
            }

            @media screen and (min-width: 1024px) and (max-width: 1279px) {
                width: 35%;
            }

            .right-header {
                width: 27.14vw;
                height: 575.5rem;

                @media screen and (max-width: 767px) {
                    position: absolute;
                    width: 70vw;
                    right: 0;
                    height: auto;
                }
            }
        }
    }
`;

export const ContentWrapper = styled.div`
    position: relative;
    z-index: 100;
    padding: 120rem 100rem 50rem;

    @media screen and (max-width: 767px) {
        padding: 342rem 90rem 0;
    }

    .heading {
        width: 557rem;
        margin-bottom: 32.5rem;
        pointer-events: none;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    .tokenAnimation {
        position: relative;
        top: 50rem;

        @media screen and (max-width: 767px) {
            left: 45%;
            transform: translate(-50%);
            top: 70rem;
        }
    }

    .buttonContainer {
        margin-top: 40rem;
        display: flex;
        justify-content: center;
    }
`;

export const ButtonWrapper = styled.div`
    width: 120rem;
    height: 40rem;

    border-radius: 2.5rem;
    border: solid 0.5rem #f00;
    background-image: linear-gradient(to top, #be0000, #700000);

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14rem;
    font-weight: bold;
    text-align: center;
    color: #fff;

    cursor: pointer;

    transition: 0.2s;

    &:hover {
        transform: scale(1.05);
    }

    @media screen and (max-width: 767px) {
        width: 280rem;
        height: 100rem;

        font-size: 34rem;
    }
`;

export default WithDirection(Wrapper);