import { handleActions } from "redux-actions";
import { STAKING_CATEGORY_TYPES } from '../utils/common';
import { POOL_DATA } from '../constants/poolData';

const initialState = {
  poolData: {
    items: POOL_DATA,
    limit: 0,
    skip: 0,
    isEnd: true,
    requesting: false,
    error: null
  },
  requestingContract: false,
  requestingLoadMore: false,
  requestingAll: false,
  requestingSearch: false,
  selectedCategory: STAKING_CATEGORY_TYPES.ALL,
  query: '',
  resultStakeMint: {
    data: [],
    requesting: false,
    error: null
  }
};

export const pools = handleActions({

  INIT_POOLS_STATE: (state, { payload }) => ({
    ...initialState
  }),

  /** FETCH API REST **/
  FETCH_INIT_STAKING_API_SUCCESS: (state, { payload }) => ({
    ...state,
    poolData: {
      ...state.poolData,
      ...payload
    }
  }),
  FETCH_POOL_BY_ID_WITHOUT_CONNECT_REQUEST: (state,  { payload }) => {
    let listItem = (state.poolData.items || []);

    const resultItems = listItem.map(element => {
      if (element.id === payload?.data?.id) {
        element["requestingContractWithoutConnect"] = true;
      }
      return element
    });

    return {
      ...state,
      requestingLoadMore: false,
      poolData: {
        ...state.poolData,
        items: resultItems
      }
    };
  },
  /** FETCH ALL POOL **/
  FETCH_POOL_DATA_REQUEST: (state) => ({
    ...state,
    poolData: {
      ...state.poolData,
      requesting: true
    }
  }),
  FETCH_POOL_DATA_SUCCESS: (state, { payload }) => ({
    ...state,
    requestingLoadMore: false,
    poolData: {
      ...state.poolData,
      ...payload.data,
      requesting: false
    },
  }),
  FETCH_POOL_DATA_FAIL: (state, { payload }) => ({
    ...state,
    poolData: {
      ...state.poolData,
      requesting: false,
      error: payload.error
    }
  }),
  FETCH_POOL_DATA_CLEAN: (state) => ({
    ...state,
    poolData: {
      items: POOL_DATA,
      limit: 0,
      skip: 0,
      isEnd: true,
      requesting: false,
      error: null
    },
    requestingLoadMore: false,
  }),

  /** FETCH MORE POOL **/
  FETCH_POOL_BY_ID_SUCCESS: (state, { payload }) => {
    let listItem = (state.poolData.items || []);

    const resultItems = listItem.map(element => {
      if (element.id === payload?.data?.id) {
        element = payload.data;
      }
      return element
    });

    return {
      ...state,
      requestingLoadMore: false,
      poolData: {
        ...state.poolData,
        items: resultItems
      }
    };
  },

  /** FETCH MORE POOL **/
  FETCH_MORE_POOL_DATA_REQUEST: (state) => ({
    ...state,
    requestingLoadMore: true,
  }),
  FETCH_MORE_POOL_DATA_SUCCESS: (state, { payload }) => ({
    ...state,
    requestingLoadMore: false,
    poolData: {
      ...state.poolData,
      items: payload?.data?.items,
      limit: payload?.data?.limit,
      skip: payload?.data?.skip,
      isEnd: payload?.data?.isEnd,
    }
  }),
  FETCH_MORE_POOL_DATA_FAIL: (state) => ({
    ...state,
    requestingLoadMore: false,
    poolData: {
      ...state.poolData,
    }
  }),

  // ------------------------------------------------------
  SET_POOLS_DATA_REQUEST: (state) => ({
    ...state,
    requesting: true,
    data: null,
    error: null,
  }),
  SET_POOLS_DATA_SUCCESS: (state, { payload }) => ({
    ...state,
    requesting: false,
    data: payload.data,
    error: null,
  }),
  SET_POOLS_DATA_FAIL: (state, { payload }) => ({
    ...state,
    requesting: false,
    data: null,
    error: payload.error,
  }),


  SET_POOLS_USER_DATA: (state, { payload }) => ({
    ...state,
    data: state.data.map((pool) => {
      const userPoolData = payload.find((entry) => entry.id === pool.id);
      if (userPoolData) {
        return {
          ...pool,
          userData: userPoolData
        }
      } else {
        return {
          ...pool,
          userData: {}
        }
      }
    })
  }),

  SET_POOLS_PUBLIC_DATA: (state, { payload }) => ({
    ...state,
    data: state.data.map((pool) => {
      const livePoolData = payload.find((entry) => entry.id === pool.id);
      if (livePoolData) {
        return {
          ...pool,
          ...livePoolData
        }
      }
      return {
        ...pool
      }
    })

  }),

  /** UPDATE POOLS USER DATA **/
  UPDATE_POOLS_USER_DATA: (state, { payload }) => {
    const { field, value, id } = payload;
    const newState = (state?.poolData?.items || []).map((pool) => {
      if (pool.id === id) {
        return {
          ...pool,
          [field]: value
        }

      } else {
        return { ...pool }
      }
    });
    // return {
    //   data: newState
    // };

    return {
      ...state,
      poolData: {
        ...state.poolData,
        items: newState
      }
    };
  },
  UPDATE_BALANCE_POOL: (state, { payload }) => {
    const poolDataEx = state.poolData;
    const result = ({
      ...state,
      requestingAll: false,
      poolData: {
        ...poolDataEx,
        items: poolDataEx.items.map(pool => {
          if (pool.id === payload.id) {
            return {
              ...pool,
              totalStaked: payload.totalStaked,
              pendingReward: payload.pendingReward,
              stakedBalance: payload.stakedBalance,
              stakingTokenBalance: payload.stakingTokenBalance
            }
          }
          return {
            ...pool,
          }
        }),
      }
    })
    return result;
  },
  UPDATE_BALANCE_ALL_POOL: (state, { payload }) => {
    const poolDataEx = state.poolData;
    const result = ({
      ...state,
      requestingAll: false,
      poolData: {
        ...poolDataEx,
        items: poolDataEx.items.map(pool => {
          let poolTemp = { ...pool }
          // UPDATE DATA STAKE BY ID
          if (pool.id === payload.id) {
            poolTemp = {
              ...poolTemp,
              totalStaked: payload.totalStaked,
              // pendingReward: payload.pendingReward,
              stakedBalance: payload.stakedBalance,
              // stakingTokenBalance: payload.stakingTokenBalance,
              // total: payload?.total,
              progress: payload?.progress,
              listDeposit: [...payload?.listDeposit],
              myEarnings: payload?.myEarnings
            }
          }

          // UPDATE DATA WITH STAKE TOKEN ADDRESS BALANCE
          if (pool.stakeTokenAddress === payload.stakeTokenAddress) {
            poolTemp = {
              ...poolTemp,
              stakingTokenBalance: payload.stakingTokenBalance
            }
          }


          return poolTemp;
        }),
      }
    })
    return result;
  },

  /** FETCH RESULT STAKE+MINT **/
  FETCH_RESULT_STAKE_MINT_REQUEST: (state) => ({
    ...state,
    resultStakeMint: {
      ...state.resultStakeMint,
      requesting: true
    }
  }),
  FETCH_RESULT_STAKE_MINT_SUCCESS: (state, { payload }) => {
    return ({
      ...state,
      resultStakeMint: {
        ...state.resultStakeMint,
        ...payload,
        requesting: false
      }
    })
  },
  FETCH_RESULT_STAKE_MINT_FAIL: (state, { payload }) => ({
    ...state,
    resultStakeMint: {
      ...state.resultStakeMint,
      requesting: false,
      error: payload.error
    }
  }),
  FETCH_RESULT_STAKE_MINT_CLEAN: (state) => ({
    ...state,
    resultStakeMint: {
      data: [],
      requesting: false,
      error: null
    }
  }),
}, initialState);

export default pools;
