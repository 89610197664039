import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const Wrapper = styled.div`
    background: #0f1014;

    position: relative;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 60px

    @media screen and (max-width: 767px) {
    }
`;

export const BubbleLayer = styled.div`
    position: relative;
    opacity: 0.6;
    pointer-events: none;

    .bubble {
        position: relative;
    }

    @media screen and (max-width: 767px) {
    }

    .left {
        position: absolute;
        left: -6rem;
        top: -8rem;
        width: 31.25vw;
        min-height: 850rem;

        @media screen and (max-width: 767px) {
            width: 65vw;
            left: -50rem;
            top: 300rem;
            min-height: 400px;
        }
    }

    .left-bubble {
        position: absolute;
        width: 31.25vw;
        left: -6rem;
        top: 550rem;
        min-height: 300rem;

        // background-image: linear-gradient(to bottom, rgba(6,7,12,0), rgba(6,7,12,0.9));

        @media screen and (max-width: 767px) {
            // background-image: linear-gradient(to right, rgba(6,7,12,0.8), rgba(6,7,12,0.9));
            width: 65vw;
            left: -50rem;
            top: 1600rem;
            height: 400rem;
        }
    }

    .right-bubble {
        position: absolute;
        width: 44.5vw;
        right: 0;
        top: 550rem;
        min-height: 400rem;

        @media screen and (max-width: 767px) {
            // background-image: linear-gradient(to left, rgba(6,7,12,0.8), rgba(6,7,12,0.9));
            width: 90vw;
            right: 0;
            top: 1600rem;
            height: 500rem;
        }
    }

    .right {
        position: absolute;
        right: 0;
        top: 24rem;
        width: 44.5vw;
        min-height: 1033.5rem;

        @media screen and (max-width: 767px) {
            width: 90vw;
            min-height: 500rem;
        }
    }
`;

export const MyWeaponsWrapper = styled.div`
    padding: 0 95rem 0 95rem;
    position: relative;
    z-index: 10;

    @media screen and (max-width: 767px) {
        margin-top: 120rem;
    }
 
    .title {
        font-family: Poppins;
        font-size: 32.5rem;
        font-weight: bold;
        line-height: 1.31;
        text-align: left;
        color: #fff;

        margin-bottom: 17rem;

        @media screen and (max-width: 767px) {
            text-align: center;
            font-size: 75rem;
        }
    }

    .description {
        font-family: Poppins;
        font-size: 17.5rem;
        line-height: 1.71;
        text-align: left;
        color: #fff;

        margin-bottom: 17rem;

        @media screen and (max-width: 767px) {
            text-align: center;
            font-size: 40rem;
        }
    }

    .loading-more {
        width: 118rem;
        height: 118rem;

        @media screen and (max-width: 767px) {
            width: 220rem;
            height: 220rem;
        }
    }

    .mint-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 15rem 14.5rem;
        padding-top: 10rem;
        padding-bottom: 80rem;

        @media screen and (max-width: 767px) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 26rem;
        }

        @media screen and (min-width: 768px) and (max-width: 1023px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        .loading {
            width: 350rem;
            min-height: 330rem;
            border: solid 2rem #202429;
            border-radius: 7.5rem;
            padding: 25rem 19rem 31rem 19rem;

            display: flex;
            flex-direction: column;
            align-items: center;

            animation: opacityPulse 2s ease-in-out infinite;

            @media screen and (max-width: 767px) {
                width: 100%;
                height: 100%;
                border-radius: 20rem;
            }

            .heading-loading {
                width: 120rem;
                height: 18rem;
                background-color: #505050;
                border-radius: 5rem;
                margin-bottom: 31.5rem;

                @media screen and (max-width: 767px) {
                    width: 150rem;
                    height: 32rem;
                }
            }

            .content-loading {
                width: 179rem;
                height: 179rem;
                border-radius: 50%;
                background-color: #505050;

                margin-bottom: 25rem;

                @media screen and (max-width: 767px) {
                    width: 300rem;
                    height: 300rem;
                    border-radius: 50%;
                }
            }

            .footer-loading {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .left-loading {
                    width: 156rem;
                    height: 20rem;
                    border-radius: 5rem;

                    background-color: #505050;

                    @media screen and (max-width: 767px) {
                        width: 200rem;
                        height: 32rem;
                    }
                }

                .right-loading {
                    width: 75.5rem;
                    height: 28.5rem;
                    border-radius: 5rem;

                    background-color: #505050;

                    @media screen and (max-width: 767px) {
                        width: 170rem;
                        height: 55rem;
                    }
                }
            }
        }

        .mint-item {
            // padding: 25rem 17.5rem 25rem 19rem;
            border-radius: 7.5px;
            display: flex;
            z-index: 1000;
            background-color: #0d0d0f;

            &.nope {
                padding: 0;

                &:hover {
                    transition: 0.4s;
                    transform: scale(1.01);
                    box-shadow: 0 0 20rem 2rem rgba(32,36,41,0.8);
                }
            }

            &.border { 
                border: solid 2rem #202429;

                &:hover {
                    transition: 0.4s;
                    transform: scale(1.01);
                    box-shadow: 0 0 20rem 2rem rgba(144,0,0,0.8);
                    border: solid 2rem #900000;
                }
            }

            @media (max-width: 767px) {
                // padding: 49rem 28rem 40rem 28rem;
            }

            .no-content {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;

                @media (max-width: 767px) {
                    height: 100%;
                    width: 100%;
                    text-align: center;
                }

                .none {
                    width: 76rem;
                    cursor: pointer;
                    position: absolute;

                    @media (max-width: 767px) {
                        width: 120rem;
                    }
                }
            }
        }
    }
`;

export const WeaponContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 350rem;
    cursor: pointer;
    border-radius: 7.5rem;

    @media (max-width: 767px) {
        height: 550rem;
    }

    .top {
        display: flex;
        flex-direction: column;

        .heading {
            font-family: Poppins;
            font-size: 24.8rem;
            font-weight: bold;
            line-height: 1.19;
            text-align: center;
            color: #df1e33;
            margin-bottom: 0;

            @media (max-width: 767px) {
                font-size: 40rem;
            }
        }
    }

    .info {
        display: flex;
        justify-content: center;
        position: relative;
        padding: 22rem 0 9.5rem;

        .mintBg {
            width: 179rem;
            height: 205.5rem;

            @media (max-width: 767px) {
                width: 278rem;
                height: 315.5rem;
            }

            @media (min-width: 1024px) and (max-width: 1279px) {
            }

            @media (min-width: 1280px) {
            }
        }

        .knife {
            position: absolute;
            z-index: 40;

            &.amateurKnife {
                width: 179rem;

                @media (max-width: 767px) {
                    width: 275rem;
                }
            }

            &.survivorKnife {
                width: 199rem;
                height: 215rem;
                bottom: 10rem;
                left: 42%;
                transform: translateX(-42%);

                @media (max-width: 767px) {
                    width: 288rem;
                    height: 98%;

                    bottom: 5rem;
                }
            }

            &.assassinKnife {
                width: 199rem;
                bottom: 12rem;
                right: 60rem;

                @media (max-width: 767px) {
                    width: 300rem;
                    right: 100rem;
                }
            }

            &.killerKnife {
                bottom: 7rem;
                width: 219rem;
                right: 52rem;

                @media (max-width: 767px) {
                    width: 315rem;
                    right: 90rem;
                    bottom: 10rem;
                }
            }

            &.rookieKnife {
                width: 199rem;
                height: 215rem;
                bottom: 10rem;
                left: 42%;
                transform: translateX(-42%);

                @media (max-width: 767px) {
                    width: 288rem;
                    height: 98%;

                    bottom: 5rem;
                }
            }

            &.commanderKnife {
                width: 195rem;
                bottom: 5rem;
                right: 70rem;

                @media (max-width: 767px) {
                    width: 280rem;
                    bottom: 8rem;
                    right: 110rem;
                }
            }
        }

        .pistol {
            position: absolute;
            z-index: 30;

            &.amateurPistol {
                width: 179rem;

                @media (max-width: 767px) {
                    width: 275rem;
                }
            }

            &.survivorPistol {
                width: 200rem;
                bottom: 11rem;
                left: 40rem;

                @media (max-width: 767px) {
                    width: 310rem;
                    left: 60rem;
                }
            }

            &.assassinPistol {
                bottom: 4rem;
                width: 200rem;

                @media (max-width: 767px) {
                    width: 300rem;
                }
            }

            &.killerPistol {
                bottom: 22rem;
                width: 200rem;

                @media (max-width: 767px) {
                    width: 310rem;
                    bottom: 10rem;
                }
            }

            &.rookiePistol {
                width: 212rem;
                bottom: 11rem;
                left: 50rem;

                @media (max-width: 767px) {
                    width: 320rem;
                    left: 80rem;
                    bottom: 10rem;
                }
            }

            &.commanderPistol {
                width: 190rem;
                bottom: 9rem;
                left: 55rem;

                @media (max-width: 767px) {
                    width: 280rem;
                    left: 85rem;
                    bottom: 20rem;
                }
            }

            &.veteranPistol {
                width: 210rem;
                bottom: 9rem;
                left: 41rem;

                @media (max-width: 767px) {
                    width: 280rem;
                    left: 80rem;
                    bottom: 16rem;
                }
            }
        }

        .shortGuns {
            position: absolute;
            top: 0;
            width: 179rem;
            z-index: 20;

            &.amateurShort {

            }

            &.survivorShort {
                width: 179rem;

                @media (max-width: 767px) {
                    width: 300rem;
                }
            }

            &.assassinShort {
                top: 15rem;
                right: 68rem;
                width: 195rem;

                @media (max-width: 767px) {
                    width: 290rem;
                    right: 120rem;
                    top: 10rem;
                }
            }

            &.killerShort {
                top: 10rem;
                width: 210rem;
                right: 55rem;

                @media (max-width: 767px) {
                    width: 280rem;
                    right: 125rem;
                    top: 35rem;
                }
            }

            &.rookieShort {
                width: 212rem;
                bottom: 17rem;
                left: 50rem;
                z-index: 30;

                @media (max-width: 767px) {
                    width: 320rem;
                    left: 78rem;
                    bottom: 20rem;
                }
            }

            &.commanderShort {
                width: 180rem;
                top: 7rem;
                right: 68rem;

                @media (max-width: 767px) {
                    width: 270rem;
                    left: 100rem;
                    top: -2rem;
                }
            }

            &.veteranShort {
                position: absolute;
                top: 10rem;
                width: 200rem;
                left: 42rem;
                z-index: 20;

                @media (max-width: 767px) {
                    width: 300rem;
                    left: 62rem;
                    top: 15rem;
                }
            }
        }

        .longGuns {
            position: absolute;
            z-index: 10;

            &.amateurLong {
                @media (max-width: 767px) {
                    width: 300rem;
                    right: 107rem;
                }
            }

            &.survivorLong {
                width: 199rem;
                right: 67rem;
                top: 14rem;

                @media (max-width: 767px) {
                    width: 300rem;
                    right: 107rem;
                }
            }

            &.assassinLong {
                top: 20rem;
                right: 75rem;
                width: 179rem;

                @media (max-width: 767px) {
                    width: 290rem;
                    right: 110rem;
                    top: 10rem;
                }
            }

            &.killerLong {
                width: 209rem;
                top: 16rem;
                right: 56rem;

                @media (max-width: 767px) {
                    width: 335rem;
                    right: 85rem;
                    top: 14rem;
                }
            }

            &.rookieLong {
                width: 212rem;
                bottom: 17rem;
                left: 50rem;
                z-index: 30;

                @media (max-width: 767px) {
                    width: 320rem;
                    left: 78rem;
                    bottom: 20rem;
                }
            }

            &.commanderLong {
                width: 180rem;
                right: 69rem;
                top: 5rem;

                @media (max-width: 767px) {
                    width: 280rem;
                    left: 88rem;
                    top: -5rem;
                }
            }

            &.veteranLong {
                position: absolute;
                z-index: 10;
                width: 200rem;
                left: 40rem;
                top: 10rem;

                @media (max-width: 767px) {
                    width: 280rem;
                    left: 85rem;

                    position: absolute;
                    z-index: 10;
                    width: 320rem;
                    left: 46rem;
                    top: 5rem;
                }
            }
        }

        .perks {
            position: absolute;
            width: 230rem;
            height: 260rem;
            bottom: 0rem;
            right: 55rem;

            z-index: 30;

            @media (max-width: 767px) {
                width: 290rem;
                height: 360rem;
                right: 110rem;
            }

            &.veteranPerks {
                width: 275rem;
                bottom: -1rem;
                right: 55rem;
            
                @media (max-width: 767px) {
                    width: 380rem;
                    height: 380rem;
                    right: 85rem;
                    bottom: -3.5rem;
                }
            }
        }

        .axe {
            position: absolute;
            width: 240rem;
            bottom: 3.5rem;
            left: 20rem;
            z-index: 50;

            @media (max-width: 767px) {
                position: absolute;
                width: 350rem;
                bottom: 2rem;
                left: 32rem;
                z-index: 50;
            }
        }
    }

    .bottom {
        display: flex;
        align-items: center;
        flex: 1;
        width: 100%;
        z-index: 71;

        .detail {
            font-family: Poppins;
            font-size: ${props => props.fontSizeWeb && props.fontSizeWeb}rem;
            font-weight: 500;
            line-height: 1.18;
            text-align: left;
            color: #fff;
            flex-grow: 1;
            width: 73%;
            margin-bottom: 0;
            margin-right: 2%;

            @media (max-width: 767px) {
                margin-right: 5%;
                font-weight: 500;
                font-size: ${props => props.fontSizeMobile && props.fontSizeMobile}rem;
            }
        }

        .view-detail-btn {
            height: 28rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2.5px;
            width: 75.5rem;
            padding: 0 5px;
            text-align: right;
            width: 30%;
            cursor: pointer;

            &:hover {
                transition: 0.4s;
                transform: scale(1.05);
            }

            @media (max-width: 767px) {
                height: 28rem;
                padding: 0rem 7rem;
                height: 43rem;
            }

            .text-btn {
                font-family: Poppins;
                font-size: 10.8rem;
                font-weight: 600;
                line-height: 2.33;
                text-align: center;
                color: #010101;

                @media (max-width: 767px) {
                    font-size: 15rem;
                }
            }
        }

            // @media (max-width: 767px) {
            //     height: 15px;
            // }


            // span {
            //     font-family: Poppins;
            //     font-size: 10rem;
            //     font-weight: 600;
            //     font-stretch: normal;
            //     font-style: normal;
            //     line-height: 2;
            //     letter-spacing: normal;
            //     text-align: center;
            //     color: #010101;
            //     padding: 10px 5px;
            //     cursor: pointer;

            //     @media (max-width: 767px) {
            //         font-size: 8.9rem;
            //     }
            // }
        }
    }
`;

export const NftDetailStyle = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 17rem;

    margin: 0;
    z-index: 50;
    padding: 149rem 160rem 60rem 160rem;

    @media (max-width: 767px) {
        grid-template-columns: 100%;
        padding: 349rem 85rem 0rem;
        grid-gap: 17px;
    }

    // @media (min-width: 768px) and (max-width: 1023px) {
    //     padding: 179rem 160rem 60rem 160rem;
    // }
`

export const NftInfoStyle = styled.div`
    border-radius: 7.5rem;
    border: solid 1.5rem #202429;
    background-color: #0d0d0f;
    width: 351rem;
    height: 351rem;
    // padding: 25rem 20rem 27rem;
    z-index: 1000;

    pointer-events: none;

    @media (max-width: 767px) {
        width: 100%;
        height: 1100rem;
        border-radius: 7.5px;
        border: solid 1.5px #202429;
        // padding: 70rem 0 80rem 0;
    }

    .top {
        display: flex;
        flex-direction: column;

        .heading {
            font-family: Poppins;
            font-size: 24.8rem;
            font-weight: bold;
            line-height: 1.19;
            text-align: center;
            color: #df1e33;
            margin-bottom: 0;

            @media (max-width: 767px) {
                font-size: 80rem;
            }
        }
    }

    .info {
        display: flex;
        justify-content: center;
        position: relative;
        margin-bottom: 11.5rem;
        pointer-events: none;

        .mintBg {
            width: 230rem;
            height: 260rem;
            pointer-events: none;

            @media (max-width: 767px) {
                width: 750rem;
                height: 857rem;
            }
        }

        .knife {
            position: absolute;
            z-index: 40;

            &.amateurKnife {
                width: 228rem;

                @media (max-width: 767px) {
                    width: 750rem;
                }
            }

            &.survivorKnife {
                width: 228rem;
                height: 275rem;
                bottom: 0rem;
                left: 41%;
                transform: translateX(-41%);

                @media (max-width: 767px) {
                    width: 750rem;
                    height: 880rem;
                    bottom: 5rem;
                }
            }

            &.assassinKnife {
                width: 250rem;
                bottom: 7rem;
                right: 30rem;

                @media (max-width: 767px) {
                    width: 800rem;
                    right: 170rem;
                    bottom: 16rem;
                }
            }

            &.killerKnife {
                bottom: -3rem;
                width: 275rem;
                right: 17rem;

                @media (max-width: 767px) {
                    width: 860rem;
                    right: 133rem;
                    bottom: 5rem;
                }
            }

            &.rookieKnife {
                width: 228rem;
                height: 275rem;
                bottom: 0;
                left: 39%;
                transform: translateX(-39%);

                @media (max-width: 767px) {
                    width: 750rem;
                    height: 880rem;
                    bottom: 5rem;
                }
            }

            &.commanderKnife {
                width: 250rem;
                bottom: -5.5rem;
                left: 15rem;

                @media (max-width: 767px) {
                    width: 770rem;
                    left: 135rem;
                    bottom: 0rem;
                }
            }
        }

        .pistol {
            position: absolute;
            z-index: 30;

            &.amateurPistol {
                width: 228rem;

                @media (max-width: 767px) {
                    width: 750rem;
                }
            }

            &.survivorPistol {
                width: 245rem;
                bottom: 0rem;
                left: 26rem;

                @media (max-width: 767px) {
                    width: 800rem;
                    left: 115rem;
                    bottom: 10rem;
                }
            }

            &.assassinPistol {
                bottom: -7rem;
                width: 260rem;

                @media (max-width: 767px) {
                    width: 850rem;
                    bottom: -15rem;
                }
            }

            &.killerPistol {
                bottom: -3rem;
                width: 290rem;

                @media (max-width: 767px) {
                    width: 830rem;
                    right: 150rem;
                    bottom: 15rem;
                }
            }

            &.rookiePistol {
                width: 267rem;
                bottom: 1rem;
                left: 26rem;

                @media (max-width: 767px) {
                    width: 875rem;
                    left: 115rem;
                    bottom: 10rem;
                }
            }


            &.commanderPistol {
                width: 240rem;
                bottom: -1rem;
                left: 27rem;

                @media (max-width: 767px) {
                    width: 800rem;
                    left: 120rem;
                    bottom: -3rem;
                }
            }

            &.veteranPistol {
                width: 240rem;
                right: 52rem;

                @media (max-width: 767px) {
                    width: 850rem;
                    left: 80rem;
                    bottom: 0rem;
                }
            }
        }

        .shortGuns {
            position: absolute;
            top: 0;
            width: 179rem;
            z-index: 20;

            &.amateurShort {
                width: 750rem;
            }

            &.survivorShort {
                width: 179rem;

                @media (max-width: 767px) {
                    width: 750rem;
                }
            }

            &.assassinShort {
                top: -10rem;
                right: 48rem;
                width: 240rem;

                @media (max-width: 767px) {
                    width: 780rem;
                    right: 210rem;
                    top: -5rem;
                }
            }

            &.killerShort {
                top: -5rem;
                width: 240rem;
                right: 55rem;

                @media (max-width: 767px) {
                    width: 830rem;
                    right: 145rem;
                    top: 10rem;
                }
            }

            &.rookieShort {
                width: 179rem;

                @media (max-width: 767px) {
                    width: 750rem;
                }
            }

            &.commanderShort {
                width: 225rem;
                top: -17rem;
                right: 44rem;

                @media (max-width: 767px) {
                    width: 730rem;
                    right: 190rem;
                    top: -60rem;
                }
            }

            &.veteranShort {
                width: 260rem;
                right: 43rem;
                top: -20rem;

                @media (max-width: 767px) {
                    width: 800rem;
                    top: -30rem;
                    right: 190rem;
                }
            }
        }

        .longGuns {
            position: absolute;
            z-index: 10;

            &.amateurLong {
                @media (max-width: 767px) {
                    width: 750rem;
                    right: 107rem;
                }
            }

            &.survivorLong {
                width: 238rem;
                right: 44rem;
                top: 0rem;

                @media (max-width: 767px) {
                    width: 780rem;
                    right: 190rem;
                    top: 27rem;
                    right: 200rem;
                }
            }

            &.assassinLong {
                top: -10rem;
                right: 50rem;
                width: 229rem;

                @media (max-width: 767px) {
                    width: 800rem;
                    right: 188rem;
                    top: -10rem;
                }
            }

            &.killerLong {
                width: 269rem;
                top: -10rem;
                right: 26rem;

                @media (max-width: 767px) {
                    width: 870rem;
                    right: 135rem;
                    top: -15rem;
                }
            }

            &.rookieLong {
                width: 280rem;
                right: 18rem;
                top: -15rem;
                z-index: 30;

                @media (max-width: 767px) {
                    width: 880rem;
                    top: -25rem;
                    right: 110rem;
                }
            }

            &.commanderLong {
                width: 220rem;
                right: 43.5rem;
                top: -20rem;

                @media (max-width: 767px) {
                    width: 750rem;
                    top: -65rem;
                    right: 191rem;
                }
            }

            &.veteranLong {
                width: 250rem;
                left: 11rem;
                top: -20rem;

                @media (max-width: 767px) {
                    width: 800rem;
                    top: -50rem;
                    left: 99rem;
                }
            }
        }

        .perks {
            position: absolute;
            width: 275rem;
            height: 285rem;
            bottom: -2.5rem;
            right: 25rem;

            z-index: 30;

            @media (max-width: 767px) {
                width: 750rem;
                height: 800rem;
                right: 210rem;
                bottom: 16rem;
            }

            &.veteranPerks {
                width: 275rem;
                height: 285rem;
                bottom: -2.5rem;
                right: 40rem;

                @media (max-width: 767px) {
                    width: 850rem;
                    height: auto;
                    right: 150rem;
                    bottom: 17rem;
                }
            }
        }

        .axe {
            position: absolute;
            width: 312rem;
            bottom: -10rem;
            left: -20rem;
            z-index: 50;

            @media (max-width: 767px) {
                width: 980rem;
                left: 0rem;
                bottom: -20rem;
            }
        }
    }

    .bottom {
        align-items: center;
        width: 100%;

        .heading {
            font-family: Poppins;
            font-size: 25rem;
            font-weight: bold;
            line-height: 1;
            text-align: center;
            color: #df1e33;
            margin-bottom: 0;

            @media (max-width: 767px) {
                font-size: 80rem;
            }
        }
    }

    /* LOADING */
    .loading {
        border-radius: 7.5rem;
        padding: 22rem 19rem 10rem 19rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        animation: opacityPulse 2s ease-in-out infinite;

        @media screen and (max-width: 767px) {
            width: 100% ;
            max-width: unset;
        }

        .heading-loading {
            background-color: #505050;
            border-radius: 5rem;

            @media screen and (max-width: 767px) {
            }
        }

        .content-loading {
            width: 179rem;
            height: 179rem;
            border-radius: 50%;
            background-color: #505050;

            margin-bottom: 25rem;

            @media screen and (max-width: 767px) {
                width: 700rem;
                height: 700rem;
                border-radius: 50%;
            }
        }

        .footer-loading {
            width: 200rem;
            height: 25rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #505050;

            @media (max-width: 767px) {
                width: 500rem;
                height: 70rem;
            }
        }
    }
`;

export const NftListItem = styled.div`
    width: 595rem;
    padding: 17.5rem 33.5rem 19.5rem;
    border-radius: 7.5rem;
    border: solid 1.5rem #202429;
    background-color: #0d0d0f;
    z-index: 1000;

    display: flex;

    @media (max-width: 767px) {
        width: 100%;
        border-radius: 7.5px;
        border: solid 1.5px #202429;
        padding: 100rem 73rem 102rem;
    }

    .nft-detail {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #fff;

        @media (max-width: 767px) {
            align-items: center;
            padding: 0rem 0rem 0rem 0rem;
        }

        .top {
            flex: 1;
            margin-bottom: 11.5rem;

            @media (max-width: 767px) {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin-bottom: 60rem;
            }
        }

        .info{
            margin-bottom: 19.5rem;
            @media (max-width: 767px) {
                display: flex;
                align-items: center;
                flex-direction: column;
                padding: 0rem 0rem 0rem 0rem;
            }
        }
        
        .bottom {
            margin-bottom: 21rem;
            display: flex;
            justify-content: center;
        }

        .heading {
            font-family: Poppins;
            font-size: 32.5rem;
            font-weight: bold;
            line-height: 0.92;
            text-align: left;
            color: #fff;

            margin-bottom: 12rem;

            @media (max-width: 767px) {
                font-size: 78rem;
                margin-bottom: 55rem;
            }
        }

        .primary {
            font-family: Poppins;
            font-size: 15rem;
            font-weight: 600;
            line-height: 1;
            text-align: left;
            color: #fff;

            margin-bottom: 7rem;

            @media (max-width: 767px) {
                font-size: 40rem;
                margin-bottom: 20rem;
            }
        }

        .primary-bottom {
            font-family: Poppins;
            font-size: 15rem;
            font-weight: 600;
            line-height: 1.07;
            text-align: left;
            color: #fff;

            margin-bottom: 0rem;

            @media (max-width: 767px) {
                font-size: 40rem;
                margin-bottom: 37rem;
            }
        }


        .transaction-hash-group {
            display: flex;
            flex-direction: row;
            column-gap: 7rem;
            align-items: align-items;
        }

        .transaction-hash {
            font-family: Poppins;
            font-size: 12.5rem;
            line-height: 1.28;
            text-align: left;
            color: #8e8e8e;

            @media (max-width: 767px) {
                font-size: 35rem;
            }
        }

        .list-item-skin {
            display: grid;
            grid-template-columns: repeat(5, minmax(100rem, 1fr));
            grid-gap: 12rem;

            @media (max-width: 767px) {
                grid-template-columns: repeat(3, minmax(99rem, 1fr));
                grid-gap: 30rem;
            }
        }
    }

    /* LOADING */
    .loading {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16rem 24.6rem;

        animation: opacityPulse 2s ease-in-out infinite;

        @media screen and (max-width: 767px) {
            align-items: center;
            padding: 0rem 0rem 0rem 0rem;
        }

        .heading-loading {
            margin-bottom: 15rem;

            .heading{
                width: 250rem;
                height: 30rem;
                background-color: #505050;
                @media screen and (max-width: 767px) {
                    width: 500rem;
                    height: 70rem;
                }
            }

            @media screen and (max-width: 767px) {
                margin-bottom: 50rem;
            }
        }

        .content-loading {
            margin-bottom: 15rem;
            
            .primary{
                width: 200rem;
                height: 30rem;
                background-color: #505050;
                margin-bottom: 30rem;

                @media screen and (max-width: 767px) {
                    width: 700rem;
                    height: 82rem;
                    margin-bottom: 60rem;
                }
            }

            .item {
                width: 80rem;
                height: 80rem;
                border-radius:50%;
                background-color: #505050;
                @media screen and (max-width: 767px) {
                    width: 250rem;
                    height: 250rem;
                }
            }
            
            @media screen and (max-width: 767px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 50rem;
            }
        }

        .footer-loading {

            .list-button{
                display: flex;
                flex-direction: row;
                margin-bottom: 12rem;
                @media screen and (max-width: 767px) {
                    margin-bottom: 50rem;
                }
            }

            .parent{
                width: 150rem;
                height: 30rem;
                background-color: #505050;
                margin-right: 20rem;
                
                @media screen and (max-width: 767px) {
                    width: 350rem;
                    height: 70rem;
                    margin-right: 40rem;
                }
            }

            .address {
                width: 200rem;
                height: 15rem;
                background-color: #505050;
                @media screen and (max-width: 767px) {
                    width: 300rem;
                    height: 50rem;
                }
            }

            @media screen and (max-width: 767px) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
`

export const ViewAddress = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    color: #adafae;
    font-size: 12.5rem;
    cursor: pointer;

    svg {
        width: 12rem;
        height: 12.5rem;

        @media (max-width: 767px) {
            height: 28rem;
            width: 28rem;
        }
    }
`

export const ButtonRedStyle = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 16rem;

    @media screen and (max-width: 767px) {
        align-items: center;
        /* column-gap: 46rem; */
    }

    .parent {
        display: flex;
        justify-content: center;
        cursor: pointer;
    
        width: 122.5rem;
        height: 32.5rem;

        box-shadow: 3rem 0px 7rem 2rem #5e0000;
        /* background-image: radial-gradient(#5e0000, #5e0000); */

        &:hover {
            transform: scale(1.05);
            transition: all 1s;
        }

        @media screen and (max-width: 767px) {
            margin: 71rem 46rem 50rem;
            width: 390rem;
            height: 103rem;
        }

        &.open-btn {
            display: flex;
            align-items: center;

            .opensea {
                width: 19rem;
                height: 19rem;
                margin-right: 5rem;

                @media (max-width: 767px) {
                    width: 55rem;
                    height: 55rem;
                    margin-right: 20rem;
                }
            }

            span {
                font-family: Poppins;
                font-size: 15rem;
                font-weight: 600;
                line-height: 2;
                text-align: center;
                color: #000;

                @media (max-width: 767px) {
                    font-size: 45rem;
                }
            }
        }

        &.rent-btn {
            display: flex;
            align-items: center;

            .guns {
                width: 20rem;
                height: 21rem;
                margin-right: 5rem;

                @media (max-width: 767px) {
                    width: 60rem;
                    height: 60rem;
                    margin-right: 20rem;
                }
            }

            span {
                font-family: Poppins;
                font-size: 15rem;
                font-weight: 600;
                line-height: 2;
                text-align: center;
                color: #000;

                @media (max-width: 767px) {
                    font-size: 45rem;
                }
            }
        }
    }
`

export const AddessOwnershipStyle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5rem;

    .address-icon {
        width: 17.5rem;
        height: 17.5rem;
        display: flex;
        column-gap: 5rem;
        align-items: center;
        margin-right: 6.5rem;

        @media (max-width: 767px) {
            width: 50rem;
            height: 50rem;
            margin-right: 10rem;
        }
    }

    .address-image {
        width: 17.5rem;
        height: 17.5rem;
        max-height: 10px;
        max-width: 10px;
        display: flex;
        align-items: center;

        @media (max-width: 767px) {
            width: 50rem;
        }
    }

    .label-address {
        font-family: Poppins;
        font-size: 12.5rem;
        font-weight: normal;
        line-height: 1.28;
        text-align: left;
        color: #fff;

        @media (max-width: 767px) {
            font-size: 35rem;
        }
    }

    .address{
        font-family: Poppins;
        font-size: 12.5rem;
        font-weight: bold;
        line-height: 1.28;
        text-align: left;
        color: #fff;

        @media (max-width: 767px) {
            font-size: 35rem;
        }
    }
`

export default WithDirection(Wrapper);