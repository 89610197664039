import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import { getBalanceAmount } from '../utils/formatBalance';
import Logger from 'js-logger';
import {
    contractCall,
    getAllowance, getBalanceOf, getICOTickets, getPendingReward, getPoolContract,
    getTokenContract, getTotalStakingTokens, getUserInfo
} from '../utils/callHelpers';

export const getChainIdByConnectType = async () => {
    return await window?.web3?.eth?.getChainId();
}

export const web3Instance = async () => {
    if (!window.ethereum) return {};
    if (window.web3) return window.web3;
    const provider = await detectEthereumProvider();

    if (provider !== window.ethereum) {
        window.web3 = new Web3(provider);
    } else {
        window.web3 = new Web3(window.ethereum);
    }
    return window.web3;
}

export const web3InstanceEx = async () => {
    if (!window.ethereum) return {};
    // if (window.web3) return window.web3;
    const provider = await detectEthereumProvider();

    if (provider !== window.ethereum) {
        window.web3 = new Web3(provider);
    } else {
        window.web3 = new Web3(window.ethereum);
    }
    return window.web3;
}


export const getAccountSymbol = chainId => {
    switch (chainId) {
        case '0x1':
            return 'ETH';
        case '0x3':
            return 'ETH';
        case '0x38':
            return 'BNB';
        case '0x61':
            return 'BNB';
        default:
            return '';
    }
};


export const extractProjectWhitelist = async (contractInstance, walletAddress) => {
    try {
        return Promise.all([
            contractCall(contractInstance, 'getWhitelist', {}, walletAddress),
            contractCall(contractInstance, 'refundedList', false, walletAddress)
        ]).then(async res => {
            const whitelistRes = res[0];
            const whitelist = {
                amount: whitelistRes?._amount,
                maxPayableAmount: whitelistRes?._maxPayableAmount,
                redeemed: whitelistRes?._redeemed,
                rewardedAmount: whitelistRes?._rewardedAmount,
                wallet: whitelistRes?._wallet,
                whitelist: whitelistRes?._whitelist
            };
            const isRefund = res[1];
            return {
                whitelist,
                isRefund
            }
        })
    } catch (e) {
        return {}
    }
}


// Extract sale token info
// export const extractTokenContract = tokenContract => {
//     return new Promise(async resolve => {
//         try {
//             const data = {
//                 tokenName: await contractCall(tokenContract, 'name', undefined),
//                 tokenSymbol: await contractCall(tokenContract, 'symbol', undefined),
//                 tokenDecimals: Number(
//                     await contractCall(tokenContract, 'decimals', 18)
//                 ),
//                 tokenTotalSupply: Number(
//                     Web3.utils.fromWei(
//                         await contractCall(tokenContract, 'totalSupply', 0)
//                     )
//                 )
//             };
//             for (const key in data) {
//                 if (data[key] === undefined) {
//                     delete data[key];
//                 }
//             }
//             resolve(data);
//         } catch (e) {
//             resolve({});
//         }
//     });
// };


export const fetchPoolsAllowance = async (account, pool) => {
    let allowanceDatas = {};
    const tokenAContract = getTokenContract(pool.stakeTokenAddress);
    try {
        allowanceDatas = await getAllowance(tokenAContract, account, pool.stakeContractAddress);
    } catch (ex) {
        Logger.info(ex);
        allowanceDatas = 0;
    }
    return allowanceDatas;
};

export const fetchPoolsTotalStaking = async (pool) => {
    let totalStaked = {};
    const stakeContract = getPoolContract(pool.stakeContractAddress);
    try {
        const returnData = await getTotalStakingTokens(stakeContract);
        totalStaked = getBalanceAmount(returnData);
    } catch (ex) {
        Logger.info('fetchPoolsTotalStakingById', ex);
        totalStaked = 0;
    }
    return totalStaked;
};

export const fetchUserPendingRewards = async (account, pool) => {
    let pendingRewards = {};
    const stakeContract = getPoolContract(pool.stakeContractAddress);
    try {
        const returnData = await getPendingReward(stakeContract, account);
        pendingRewards = getBalanceAmount(returnData);

    } catch (ex) {
        Logger.info('fetchUserPendingRewards', ex);
        pendingRewards = 0;
    }
    return pendingRewards;
};

export const fetchUserStakeBalances = async (account, pool) => {
    let userInfos = {};
    const stakeContract = getPoolContract(pool.stakeContractAddress);
    try {
        const returnData = await getUserInfo(stakeContract, account);
        userInfos = getBalanceAmount(returnData.amount);
    } catch (ex) {
        Logger.info('getUserInfo', ex);
        userInfos = 0;
    }
    return userInfos;
};


export const fetchUserBalances = async (account, pool) => {
    let tokenBalances = {};
    const tokenAContract = getTokenContract(pool.stakeTokenAddress);
    try {
        const returnData = await getBalanceOf(tokenAContract, account);
        tokenBalances = getBalanceAmount(returnData);
    } catch (ex) {
        Logger.info(ex);
        tokenBalances = 0;
    }
    return tokenBalances;
};
export const fetchICOTickets = async (account, pool) => {
    if (account === undefined || account === null || account === '') {
        return [0, 0, 0];
    }
    let tickets = [];
    const stakeContract = getPoolContract(pool?.stakeContractAddress);
    try {
        tickets = await getICOTickets(stakeContract, account);
    } catch (ex) {
        Logger.info(ex);
        return [0, 0, 0];
    }
    return reverseArr(tickets);
};

const reverseArr = (input) => {
    var ret = [];
    for (var i = input.length - 1; i >= 0; i--) {
        ret.push(input[i]);
    }
    return ret;
};
