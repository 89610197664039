import React from 'react'
import glow from '../../assets/img/buttonBg/orange-small-glow.png';
import inner from '../../assets/img/buttonBg/orange-small-inner.png';
import soldGlow from '../../assets/img/buttonBg/gray-small-glow.png';
import soldInner from '../../assets/img/buttonBg/gray-small-inner.png';
import Wrapper from './styles';

const ButtonSmall = ({ title, content, event, loading, disabled, icon = null, isOrange = false }) => {
  return <Wrapper>

    {disabled ? (
      <div className="sold-out">
        <img src={soldGlow} alt="sold" className="bottom-mint-btn" />
        <img src={soldInner} alt="inner" className="top-mint-btn" />
        <span>{title}</span>
      </div>
    ) : (
      <div className="parent">
        <img src={glow} alt="mint" className="bottom-mint-btn" />
        <img src={inner} alt="mint" className="top-mint-btn" onClick={event} />
        <span onClick={event}>{title}</span>
      </div>
    )}

  </Wrapper>
}

export default ButtonSmall
