import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { EarnAPYWeapons } from './EarnAPYWeapons'
import {
  useFetchLockEvent,
  useFetchPriceList,
  useFetchPublicData,
  useFetchStakingPoolList,
} from '../../state/hooks'

import { useResize } from '../../hooks/useResize';


export function Staking() {
  const dispatch = useDispatch();

  const [showPopup, setShowPopup] = useState(false);

  const account = useSelector(state => (state.user.userAccount.accounts ? state.user.userAccount.accounts[0] : ''))
  const chainId = useSelector(state => state.user.chainId)
  const isConnected = useSelector(state => state.user.connectWallet.isConnect);
  const {
    isMobile, isTablet, isDesktop
  } = useSelector(state => state.common);

  useResize(dispatch, isMobile, isTablet, isDesktop);
  useFetchStakingPoolList(account)
  useFetchPriceList()
  useFetchPublicData()
  useFetchLockEvent()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [dispatch]);

  useEffect(() => {
    setShowPopup(true);
  }, [])

  return (
    <>
      <EarnAPYWeapons
        chainId={chainId}
        account={account}
        isConnected={isConnected}
        isTablet={isTablet}
        isMobile={isMobile}
        isShowPopup={showPopup}
        onClosePopup={() => setShowPopup(false)}
      />
    </>
  )
}