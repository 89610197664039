import React from 'react'
import { Slider } from 'antd'
import styled from 'styled-components'

const Container = styled.div`

`
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Lable = styled.div`
  font-size: 15rem;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
`
const Value = styled.div`
  font-size: 15rem;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #fff;
`

const SliderBar = styled(Slider)`
  margin: 15rem 0 0;

  .ant-slider {
    height: 12rem;

    @media (max-width: 767px) {
      height: 12rem;
    }
  }

  .ant-slider-rail {
    height: 4rem;
    background-color: #202429;

    @media (max-width: 767px) {
      height: 15rem;
    }
  }

  .ant-slider-handle {
    width: 13.5rem;
    height: 13.5rem;
    margin-top: -5.25rem;
    border: solid 5rem #ff001c;
    transform: translateY(-50%);

    @media screen and (max-width: 767px) {
      width: 55rem;
      height: 55rem;
      border: solid 20rem #ff001c;
      margin-top: -18rem;
    }
  }

  .ant-slider-track {
    height: 4rem;
    border-radius: 2rem;

    @media (max-width: 767px) {
      height: 15rem;
    }
  }
`
export default function RangeInput(props) {
  const { id, value, unit, rangeLabel, min, max, step, disabled, placeholder, onChange, onFocus, isShowValue = true } = props
  return (
    <Container>
      <Top>
        <Lable style={{ fontWeight: 500 }}>{rangeLabel}</Lable>
        {isShowValue && <Value style={{ fontWeight: 'normal' }}>
          {value ? value : 0} {unit}
        </Value>}
      </Top>
      <SliderBar
        {...{ id, value, min, max, step, disabled, placeholder, onFocus }}
        onChange={value => onChange(value)}
      />
    </Container>
  )
}
