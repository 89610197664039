// // KNIFE Maskman
// import MaskmanKnife_2 from './img/Items/maskman/Knife_2.png';
// import MaskmanKnife_3 from './img/Items/maskman/Knife_3.png';
// import MaskmanKnife_4 from './img/Items/maskman/Knife_4.png';
// import MaskmanKnife_5 from './img/Items/maskman/Knife_5.png';
// import MaskmanKnife_6 from './img/Items/maskman/Knife_6.png';
// import MaskmanKnife_7 from './img/Items/maskman/Knife_7.png';
// import MaskmanKnife_8 from './img/Items/maskman/Knife_8.png';
// import MaskmanKnife_9 from './img/Items/maskman/Knife_9.png';
// import MaskmanKnife_10 from './img/Items/maskman/Knife_10.png';
// import MaskmanKnife_11 from './img/Items/maskman/Knife_11.png';
// import MaskmanKnife_12 from './img/Items/maskman/Knife_12.png';
// import MaskmanKnife_13 from './img/Items/maskman/Knife_13.png';
// import MaskmanKnife_14 from './img/Items/maskman/Knife_14.png';
// import MaskmanKnife_15 from './img/Items/maskman/Knife_15.png';
// import MaskmanKnife_16 from './img/Items/maskman/Knife_16.png';
// import MaskmanKnife_17 from './img/Items/maskman/Knife_17.png';
// import MaskmanKnife_18 from './img/Items/maskman/Knife_18.png';
// import MaskmanKnife_19 from './img/Items/maskman/Knife_19.png';
// import MaskmanKnife_20 from './img/Items/maskman/Knife_20.png';
// import MaskmanKnife_21 from './img/Items/maskman/Knife_21.png';

// // PISTOL Maskman
// import MaskmanPistol_2 from './img/Items/maskman/Pistol_2.png';
// import MaskmanPistol_3 from './img/Items/maskman/Pistol_3.png';
// import MaskmanPistol_4 from './img/Items/maskman/Pistol_4.png';
// import MaskmanPistol_5 from './img/Items/maskman/Pistol_5.png';
// import MaskmanPistol_6 from './img/Items/maskman/Pistol_6.png';
// import MaskmanPistol_7 from './img/Items/maskman/Pistol_7.png';
// import MaskmanPistol_8 from './img/Items/maskman/Pistol_8.png';
// import MaskmanPistol_9 from './img/Items/maskman/Pistol_9.png';

// // PERK Maskman
// import PerkMaskman_1 from './img/Items/maskman/Perk_1.png';
// import PerkMaskman_1_2 from './img/Items/maskman/Perk_1_2.png';
// import PerkMaskman_2 from './img/Items/maskman/Perk_2.png';
// import PerkMaskman_2_2 from './img/Items/maskman/Perk_2_2.png';
// import PerkMaskman_3 from './img/Items/maskman/Perk_3.png';
// import PerkMaskman_3_2 from './img/Items/maskman/Perk_3_2.png';
// import PerkMaskman_4 from './img/Items/maskman/Perk_4.png';
// import PerkMaskman_4_2 from './img/Items/maskman/Perk_4_2.png';

// // REVOLVER Maskman
// import RevolverMaskman_13 from './img/Items/maskman/Revolver_13.png';
// import RevolverMaskman_14 from './img/Items/maskman/Revolver_14.png';
// import RevolverMaskman_15 from './img/Items/maskman/Revolver_15.png';
// import RevolverMaskman_16 from './img/Items/maskman/Revolver_16.png';
// import RevolverMaskman_17 from './img/Items/maskman/Revolver_17.png';
// import RevolverMaskman_18 from './img/Items/maskman/Revolver_18.png';
// import RevolverMaskman_19 from './img/Items/maskman/Revolver_19.png';
// import RevolverMaskman_20 from './img/Items/maskman/Revolver_20.png';

// // HuntingRifle Maskman
// import HuntingRifleMaskman_1 from './img/Items/maskman/HuntingRifle_1.png';
// import HuntingRifleMaskman_2 from './img/Items/maskman/HuntingRifle_2.png';
// import HuntingRifleMaskman_3 from './img/Items/maskman/HuntingRifle_3.png';
// import HuntingRifleMaskman_4 from './img/Items/maskman/HuntingRifle_4.png';
// import HuntingRifleMaskman_5 from './img/Items/maskman/HuntingRifle_5.png';
// import HuntingRifleMaskman_6 from './img/Items/maskman/HuntingRifle_6.png';
// import HuntingRifleMaskman_7 from './img/Items/maskman/HuntingRifle_7.png';
// import HuntingRifleMaskman_8 from './img/Items/maskman/HuntingRifle_8.png';
// import HuntingRifleMaskman_9 from './img/Items/maskman/HuntingRifle_9.png';
// import HuntingRifleMaskman_10 from './img/Items/maskman/HuntingRifle_10.png';
// import HuntingRifleMaskman_11 from './img/Items/maskman/HuntingRifle_11.png';
// import HuntingRifleMaskman_12 from './img/Items/maskman/HuntingRifle_12.png';
// import HuntingRifleMaskman_13 from './img/Items/maskman/HuntingRifle_13.png';
// import HuntingRifleMaskman_14 from './img/Items/maskman/HuntingRifle_14.png';
// import HuntingRifleMaskman_15 from './img/Items/maskman/HuntingRifle_15.png';
// import HuntingRifleMaskman_16 from './img/Items/maskman/HuntingRifle_16.png';
// import HuntingRifleMaskman_17 from './img/Items/maskman/HuntingRifle_17.png';
// import HuntingRifleMaskman_18 from './img/Items/maskman/HuntingRifle_18.png';
// import HuntingRifleMaskman_19 from './img/Items/maskman/HuntingRifle_19.png';
// import HuntingRifleMaskman_20 from './img/Items/maskman/HuntingRifle_20.png';

// // KNIFE Hunter
// import HunterKnife_2 from './img/Items/hunter/Knife_2.png';
// import HunterKnife_3 from './img/Items/hunter/Knife_3.png';
// import HunterKnife_4 from './img/Items/hunter/Knife_4.png';
// import HunterKnife_5 from './img/Items/hunter/Knife_5.png';
// import HunterKnife_6 from './img/Items/hunter/Knife_6.png';
// import HunterKnife_7 from './img/Items/hunter/Knife_7.png';
// import HunterKnife_8 from './img/Items/hunter/Knife_8.png';
// import HunterKnife_9 from './img/Items/hunter/Knife_9.png';
// import HunterKnife_10 from './img/Items/hunter/Knife_10.png';
// import HunterKnife_11 from './img/Items/hunter/Knife_11.png';
// import HunterKnife_12 from './img/Items/hunter/Knife_12.png';
// import HunterKnife_13 from './img/Items/hunter/Knife_13.png';
// import HunterKnife_14 from './img/Items/hunter/Knife_14.png';
// import HunterKnife_15 from './img/Items/hunter/Knife_15.png';
// import HunterKnife_16 from './img/Items/hunter/Knife_16.png';
// import HunterKnife_17 from './img/Items/hunter/Knife_17.png';
// import HunterKnife_18 from './img/Items/hunter/Knife_18.png';
// import HunterKnife_19 from './img/Items/hunter/Knife_19.png';
// import HunterKnife_20 from './img/Items/hunter/Knife_20.png';
// import HunterKnife_21 from './img/Items/hunter/Knife_21.png';

// // PISTOL Hunter
// import Hunter_M1911_Camoflage_Rare from './img/Items/hunter/M1911_Camoflage_Rare.png';
// import Hunter_M1911_DIY_Epic from './img/Items/hunter/M1911_DIY_Epic.png';
// import Hunter_M1911_Gold_Legendary from './img/Items/hunter/M1911_Gold_Legendary.png';
// import Hunter_M1911_Iridescent_Legendary from './img/Items/hunter/M1911_Iridescent_Legendary.png';
// import Hunter_M1911_Neon_Rare from './img/Items/hunter/M1911_Neon_Rare.png';
// import Hunter_M1911_Safari_Epic from './img/Items/hunter/M1911_Safari_Epic.png';
// import Hunter_M1911_Strike_Rare from './img/Items/hunter/M1911_Strike_Rare.png';
// import Hunter_M1911_Trace_Rare from './img/Items/hunter/M1911_Trace_Rare.png';

// // PERK Hunter
// import PerkHunter_1 from './img/Items/hunter/Perk_1.png';
// import PerkHunter_1_2 from './img/Items/hunter/Perk_1_2.png';
// import PerkHunter_2 from './img/Items/hunter/Perk_2.png';
// import PerkHunter_2_2 from './img/Items/hunter/Perk_2_2.png';
// import PerkHunter_3 from './img/Items/hunter/Perk_3.png';
// import PerkHunter_3_2 from './img/Items/hunter/Perk_3_2.png';
// import PerkHunter_4 from './img/Items/hunter/Perk_4.png';
// import PerkHunter_4_2 from './img/Items/hunter/Perk_4_2.png';

// // DB Hunter
// import DBHunter_2 from './img/Items/hunter/DB_2.png';
// import DBHunter_3 from './img/Items/hunter/DB_3.png';
// import DBHunter_4 from './img/Items/hunter/DB_4.png';
// import DBHunter_5 from './img/Items/hunter/DB_5.png';
// import DBHunter_6 from './img/Items/hunter/DB_6.png';
// import DBHunter_7 from './img/Items/hunter/DB_7.png';
// import DBHunter_8 from './img/Items/hunter/DB_8.png';
// import DBHunter_9 from './img/Items/hunter/DB_9.png';

// // CROSSBOW Hunter
// import CrossBow_2 from './img/Items/hunter/Crossbow_2.png';
// import CrossBow_3 from './img/Items/hunter/Crossbow_3.png';
// import CrossBow_4 from './img/Items/hunter/Crossbow_4.png';
// import CrossBow_5 from './img/Items/hunter/Crossbow_5.png';
// import CrossBow_6 from './img/Items/hunter/Crossbow_6.png';
// import CrossBow_7 from './img/Items/hunter/Crossbow_7.png';
// import CrossBow_8 from './img/Items/hunter/Crossbow_8.png';
// import CrossBow_9 from './img/Items/hunter/Crossbow_9.png';

// // KNIFE Demolitionist
// import DemolitionistKnife_2 from './img/Items/demolitionist/Knife_2.png';
// import DemolitionistKnife_3 from './img/Items/demolitionist/Knife_3.png';
// import DemolitionistKnife_4 from './img/Items/demolitionist/Knife_4.png';
// import DemolitionistKnife_5 from './img/Items/demolitionist/Knife_5.png';
// import DemolitionistKnife_6 from './img/Items/demolitionist/Knife_6.png';
// import DemolitionistKnife_7 from './img/Items/demolitionist/Knife_7.png';
// import DemolitionistKnife_8 from './img/Items/demolitionist/Knife_8.png';
// import DemolitionistKnife_9 from './img/Items/demolitionist/Knife_9.png';
// import DemolitionistKnife_10 from './img/Items/demolitionist/Knife_10.png';
// import DemolitionistKnife_11 from './img/Items/demolitionist/Knife_11.png';
// import DemolitionistKnife_12 from './img/Items/demolitionist/Knife_12.png';
// import DemolitionistKnife_13 from './img/Items/demolitionist/Knife_13.png';
// import DemolitionistKnife_14 from './img/Items/demolitionist/Knife_14.png';
// import DemolitionistKnife_15 from './img/Items/demolitionist/Knife_15.png';
// import DemolitionistKnife_16 from './img/Items/demolitionist/Knife_16.png';
// import DemolitionistKnife_17 from './img/Items/demolitionist/Knife_17.png';
// import DemolitionistKnife_18 from './img/Items/demolitionist/Knife_18.png';
// import DemolitionistKnife_19 from './img/Items/demolitionist/Knife_19.png';
// import DemolitionistKnife_20 from './img/Items/demolitionist/Knife_20.png';
// import DemolitionistKnife_21 from './img/Items/demolitionist/Knife_21.png';

// // PISTOL Demolitionist
// import DemolitionistPistol_2 from './img/Items/demolitionist/Pistol_2.png';
// import DemolitionistPistol_3 from './img/Items/demolitionist/Pistol_3.png';
// import DemolitionistPistol_4 from './img/Items/demolitionist/Pistol_4.png';
// import DemolitionistPistol_5 from './img/Items/demolitionist/Pistol_5.png';
// import DemolitionistPistol_6 from './img/Items/demolitionist/Pistol_6.png';
// import DemolitionistPistol_7 from './img/Items/demolitionist/Pistol_7.png';
// import DemolitionistPistol_8 from './img/Items/demolitionist/Pistol_8.png';
// import DemolitionistPistol_9 from './img/Items/demolitionist/Pistol_9.png';

// // PERK Demolitionist
// import PerkDemolitionist_1 from './img/Items/demolitionist/Perk_1.png';
// import PerkDemolitionist_1_2 from './img/Items/demolitionist/Perk_1_2.png';
// import PerkDemolitionist_2 from './img/Items/demolitionist/Perk_2.png';
// import PerkDemolitionist_2_2 from './img/Items/demolitionist/Perk_2_2.png';
// import PerkDemolitionist_3 from './img/Items/demolitionist/Perk_3.png';
// import PerkDemolitionist_3_2 from './img/Items/demolitionist/Perk_3_2.png';
// import PerkDemolitionist_4 from './img/Items/demolitionist/Perk_4.png';
// import PerkDemolitionist_4_2 from './img/Items/demolitionist/Perk_4_2.png';

// // GRENADE Demolitionist
// import GrenadeDemolitionist_2 from './img/Items/demolitionist/Grenade_2.png';
// import GrenadeDemolitionist_3 from './img/Items/demolitionist/Grenade_3.png';
// import GrenadeDemolitionist_4 from './img/Items/demolitionist/Grenade_4.png';

// // MOLOTOV Demolitionist
// import MolotovDemolitionist_1 from './img/Items/demolitionist/Molotov_1.png';
// import MolotovDemolitionist_2 from './img/Items/demolitionist/Molotov_2.png';
// import MolotovDemolitionist_3 from './img/Items/demolitionist/Molotov_3.png';

// // RPG Demolitionist
// import RpgDemolitionist_2 from './img/Items/demolitionist/RPG_2.png';
// import RpgDemolitionist_3 from './img/Items/demolitionist/RPG_3.png';
// import RpgDemolitionist_4 from './img/Items/demolitionist/RPG_4.png';
// import RpgDemolitionist_5 from './img/Items/demolitionist/RPG_5.png';
// import RpgDemolitionist_6 from './img/Items/demolitionist/RPG_6.png';
// import RpgDemolitionist_7 from './img/Items/demolitionist/RPG_7.png';
// import RpgDemolitionist_8 from './img/Items/demolitionist/RPG_8.png';
// import RpgDemolitionist_9 from './img/Items/demolitionist/RPG_9.png';

// export const SINGLE_WEAPON = {
//     Knife: [AmateurKnife],
//     Pistol: [Pistol_1, Pistol_2, Pistol_3, Pistol_4, Pistol_5, Pistol_6, Pistol_7, Pistol_8, Pistol_9],
//     AKM: [AKM_1, AKM_2, AKM_3, AKM_4, AKM_5, AKM_6, AKM_7, AKM_8, AKM_9],
//     DB: [Db_1, Db_2, Db_3, Db_4_1, Db_5, Db_6, Db_7, Db_8, Db_9],
//     MP5: [MP5_1, MP5_2, MP5_3, MP5_4_1, MP5_5, MP5_6, MP5_7, MP5_8, MP5_9],
//     Perk: [],
//     F1: [F1_1, F1_2, F1_3, F1_4, F1_5, F1_6, F1_7, F1_8, F1_9],
//     M1A: [
//         M1A_1,
//         M1A_2,
//         M1A_3,
//         M1A_4,
//         M1A_5,
//         M1A_6,
//         M1A_7,
//         M1A_8,
//         M1A_9
//     ],
//     BaseballBat: [
//         BaseballBat_1,
//         BaseballBat_2,
//         BaseballBat_3,
//         BaseballBat_4,
//         BaseballBat_5,
//         BaseballBat_6,
//         BaseballBat_7,
//         BaseballBat_8,
//         BaseballBat_9
//     ],
//     Axe: [
//         Axe_1,
//         Axe_2,
//         Axe_3,
//         Axe_4,
//         Axe_5,
//         Axe_6,
//         Axe_7,
//         Axe_8,
//         Axe_9
//     ],
//     RPG: [
//         RPG_1,
//         RPG_2,
//         RPG_3,
//         RPG_4,
//         RPG_5,
//         RPG_6,
//         RPG_7,
//         RPG_8,
//         RPG_9
//     ],
//     R870: [
//         R870_1,
//         R870_2,
//         R870_3,
//         R870_4,
//         R870_5,
//         R870_6,
//         R870_7,
//         R870_8,
//         R870_9
//     ]
// }

// export const AMATEUR_ASSETS = {
//     Knife: [AmateurKnife],
//     Pistol: [
//         AmateurPistol_1,
//         AmateurPistol_2,
//         AmateurPistol_3,
//         AmateurPistol_4,
//         AmateurPistol_5,
//         AmateurPistol_6,
//         AmateurPistol_7,
//         AmateurPistol_8,
//         AmateurPistol_9
//     ],
//     AKM: [],
//     DB: [],
//     Perk: [],
//     MP5: [],
//     F1: [],
//     R870: [],
//     Axe: [],
//     M1A: [],
//     BaseballBat: [],
//     RPG: []
// }

// export const ASSASSIN_ASSETS = {
//     Knife: [AssassinKnife],
//     Pistol: [
//         AssassinPistol_1,
//         AssassinPistol_2,
//         AssassinPistol_3,
//         AssassinPistol_4,
//         AssassinPistol_5,
//         AssassinPistol_6,
//         AssassinPistol_7,
//         AssassinPistol_8,
//         AssassinPistol_9
//     ],
//     AKM: [
//         AssassinLongGun_1,
//         AssassinLongGun_2,
//         AssassinLongGun_3,
//         AssassinLongGun_4,
//         AssassinLongGun_5,
//         AssassinLongGun_6,
//         AssassinLongGun_7,
//         AssassinLongGun_8,
//         AssassinLongGun_9
//     ],
//     DB: [
//         AssassinShortGun_1,
//         AssassinShortGun_2,
//         AssassinShortGun_3,
//         AssassinShortGun_4,
//         AssassinShortGun_5,
//         AssassinShortGun_6,
//         AssassinShortGun_7,
//         AssassinShortGun_8,
//         AssassinShortGun_9
//     ],
//     Perk: [
//         AssassinPerk_1,
//         AssassinPerk_2,
//         AssassinPerk_3,
//         AssassinPerk_4
//     ],
//     MP5: [],
//     F1: [],
//     R870: [],
//     Axe: [],
//     M1A: [],
//     BaseballBat: [],
//     RPG: []
// }

// export const SURVIVOR_ASSETS = {
//     Knife: [SurvivorKnife],
//     Pistol: [
//         SurvivorPistol_1,
//         SurvivorPistol_2,
//         SurvivorPistol_3,
//         SurvivorPistol_4,
//         SurvivorPistol_5,
//         SurvivorPistol_6,
//         SurvivorPistol_7,
//         SurvivorPistol_8,
//         SurvivorPistol_9
//     ],
//     R870: [],
//     AKM: [],
//     DB: [],
//     Perk: [],
//     MP5: [
//         SurvivorLongGun_1,
//         SurvivorLongGun_2,
//         SurvivorLongGun_3,
//         SurvivorLongGun_4,
//         SurvivorLongGun_5,
//         SurvivorLongGun_6,
//         SurvivorLongGun_7,
//         SurvivorLongGun_8,
//         SurvivorLongGun_9
//     ],
//     F1: [],
//     Axe: [],
//     M1A: [],
//     BaseballBat: [],
//     RPG: []
// }

// export const KILLER_ASSETS = {
//     Knife: [KillerKnife],
//     Pistol: [
//         KillerPistol_1,
//         KillerPistol_2,
//         KillerPistol_3,
//         KillerPistol_4,
//         KillerPistol_5,
//         KillerPistol_6,
//         KillerPistol_7,
//         KillerPistol_8,
//         KillerPistol_9
//     ],
//     AKM: [],
//     DB: [
//         KillerShortGun_1,
//         KillerShortGun_2,
//         KillerShortGun_3,
//         KillerShortGun_4,
//         KillerShortGun_5,
//         KillerShortGun_6,
//         KillerShortGun_7,
//         KillerShortGun_8,
//         KillerShortGun_9
//     ],
//     Perk: [],
//     MP5: [],
//     F1: [
//         KillerLongGun_1,
//         KillerLongGun_2,
//         KillerLongGun_3,
//         KillerLongGun_4,
//         KillerLongGun_5,
//         KillerLongGun_6,
//         KillerLongGun_7,
//         KillerLongGun_8,
//         KillerLongGun_9
//     ],
//     R870: [],
//     Axe: [],
//     M1A: [],
//     BaseballBat: [],
//     RPG: []
// }

// export const ROOKIE_ASSETS = {
//     Knife: [SurvivorKnife],
//     Pistol: [
//         RookiePistol_1,
//         RookiePistol_2,
//         RookiePistol_3,
//         RookiePistol_4,
//         RookiePistol_5,
//         RookiePistol_6,
//         RookiePistol_7,
//         RookiePistol_8,
//         RookiePistol_9
//     ],
//     R870: [
//         RookieShortGun_1,
//         RookieShortGun_2,
//         RookieShortGun_3,
//         RookieShortGun_4,
//         RookieShortGun_5,
//         RookieShortGun_6,
//         RookieShortGun_7,
//         RookieShortGun_8,
//         RookieShortGun_9
//     ],
//     AKM: [],
//     DB: [],
//     Perk: [],
//     MP5: [],
//     F1: [],
//     Axe: [],
//     M1A: [],
//     BaseballBat: [],
//     RPG: []
// }

// export const COMMANDER_ASSETS = {
//     Knife: [CommanderKnife],
//     Pistol: [
//         CommanderPistol_1,
//         CommanderPistol_2,
//         CommanderPistol_3,
//         CommanderPistol_4,
//         CommanderPistol_5,
//         CommanderPistol_6,
//         CommanderPistol_7,
//         CommanderPistol_8,
//         CommanderPistol_9
//     ],
//     R870: [],
//     AKM: [],
//     DB: [],
//     Perk: [],
//     MP5: [],
//     F1: [],
//     Axe: [],
//     M1A: [
//         CommanderLongGun_1,
//         CommanderLongGun_2,
//         CommanderLongGun_3,
//         CommanderLongGun_4,
//         CommanderLongGun_5,
//         CommanderLongGun_6,
//         CommanderLongGun_7,
//         CommanderLongGun_8,
//         CommanderLongGun_9
//     ],
//     BaseballBat: [
//         CommanderShortGun_1,
//         CommanderShortGun_2,
//         CommanderShortGun_3,
//         CommanderShortGun_4,
//         CommanderShortGun_5,
//         CommanderShortGun_6,
//         CommanderShortGun_7,
//         CommanderShortGun_8,
//         CommanderShortGun_9
//     ],
//     RPG: []
// }

// export const VETERAN_ASSETS = {
//     Knife: [],
//     Pistol: [
//         VeteranPistol_1,
//         VeteranPistol_2,
//         VeteranPistol_3,
//         VeteranPistol_4,
//         VeteranPistol_5,
//         VeteranPistol_6,
//         VeteranPistol_7,
//         VeteranPistol_8,
//         VeteranPistol_9
//     ],
//     R870: [],
//     AKM: [
//         VeteranLongGun_1,
//         VeteranLongGun_2,
//         VeteranLongGun_3,
//         VeteranLongGun_4,
//         VeteranLongGun_5,
//         VeteranLongGun_6,
//         VeteranLongGun_7,
//         VeteranLongGun_8,
//         VeteranLongGun_9
//     ],
//     DB: [],
//     Perk: [
//         VeteranPerk_1,
//         VeteranPerk_2,
//         VeteranPerk_3,
//         VeteranPerk_4
//     ],
//     MP5: [],
//     F1: [],
//     Axe: [
//         VeteranAxe_1,
//         VeteranAxe_2,
//         VeteranAxe_3,
//         VeteranAxe_4,
//         VeteranAxe_5,
//         VeteranAxe_6,
//         VeteranAxe_7,
//         VeteranAxe_8,
//         VeteranAxe_9
//     ],
//     M1A: [],
//     BaseballBat: [],
//     RPG: [
//         VeteranShortGun_1,
//         VeteranShortGun_2,
//         VeteranShortGun_3,
//         VeteranShortGun_4,
//         VeteranShortGun_5,
//         VeteranShortGun_6,
//         VeteranShortGun_7,
//         VeteranShortGun_8,
//         VeteranShortGun_9
//     ]
// }

// export const AMATEUR_ITEM = {
//     title: "AMATEUR",
//     description: "Knife + Pistol",
//     assets: AMATEUR_ASSETS,
//     fontSize: 25,
//     fontSizeMobile: 39
// }

// export const ASSASSIN_ITEM = {
//     title: "ASSASSIN",
//     description: "Knife + AK47 + Pistol + Shotgun + 1 random perk",
//     assets: ASSASSIN_ASSETS,
//     fontSize: 15,
//     fontSizeMobile: 23
// }

// export const SURVIVOR_ITEM = {
//     title: "SURVIVOR",
//     description: "Knife + MP5 + Pistol",
//     assets: SURVIVOR_ASSETS,
//     fontSize: 20,
//     fontSizeMobile: 31
// }

// export const KILLER_ITEM = {
//     title: "ZOMBIE KILLER",
//     description: "Knife + F1 + Pistol + Shotgun + 4 perks + Grenade",
//     assets: KILLER_ASSETS,
//     fontSize: 15,
//     fontSizeMobile: 23
// }

// export const ROOKIE_ITEM = {
//     title: "ROOKIE",
//     description: "Knife + R870 + Pistol",
//     assets: ROOKIE_ASSETS,
//     fontSize: 20,
//     fontSizeMobile: 31
// }

// export const COMMANDER_ITEM = {
//     title: "COMMANDER",
//     description: "Knife + M1A + Pistol + BaseballBat",
//     assets: COMMANDER_ASSETS,
//     fontSize: 15,
//     fontSizeMobile: 23
// }

// export const VETERAN_ITEM = {
//     title: "VETERAN",
//     description: "AKM + RPG + Pistol + Axe + 1 random perk",
//     assets: VETERAN_ASSETS,
//     fontSize: 15,
//     fontSizeMobile: 23
// }

// export const INSTANCE_ITEMS = [AMATEUR_ITEM, SURVIVOR_ITEM, ASSASSIN_ITEM, KILLER_ITEM, ROOKIE_ITEM, COMMANDER_ITEM, VETERAN_ITEM];

export const INSTANCE_ITEMS = []

