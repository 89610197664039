import { useMemo } from 'react'
import moment from 'moment'
import { BigNumber } from 'bignumber.js'
import { isEmpty } from 'lodash';

import { STAKING_TYPES } from '../../../../utils/constants';
import {
    getEnvDisableButton,
    compareTime,
    isSecond,
    getDescription
} from '../../../../utils/common'

/*-- STAKE BUTTON -- */
export const isAllowStakeCheck = (pool) => {
    if (isCommonDisable(pool))
        return false;

    if (pool?.status === STAKING_TYPES.COMPLETED || pool?.status === STAKING_TYPES.UPCOMING) {
        return false;
    }

    const now = moment().unix();
    const startTime = isSecond(pool?.startTime) ? pool?.startTime : pool?.startTime / 1000
    const endTime = isSecond(pool?.endTime) ? pool?.endTime : pool?.endTime / 1000
    if (now < startTime || now > endTime) {
        return false;
    }

    return true;
}

/*-- STAKE BUTTON -- */
export const isAllowEnableCheck = (pool) => {
    if (isCommonDisable(pool))
        return false;

    if (pool?.status === STAKING_TYPES.COMPLETED || pool?.status === STAKING_TYPES.UPCOMING) {
        return false;
    }

    const now = moment().unix();
    const startTime = isSecond(pool?.startTime) ? pool?.startTime : pool?.startTime / 1000
    const endTime = isSecond(pool?.endTime) ? pool?.endTime : pool?.endTime / 1000
    if (now < startTime || now > endTime) {
        return false;
    }

    return true;
}


/*-- UNSTAKE BUTTON -- */
export const isAllowUnStakeCheck = (pool) => {
    if (isCommonDisable(pool))
        return false;

    const stakedBlance = BigNumber.isBigNumber(pool?.stakedBalance) ? pool?.stakedBalance.toNumber() : pool?.stakedBalance;
    if (stakedBlance === 0)
        return false;

    if (pool?.status === STAKING_TYPES.COMPLETED) {
        return true;
    }

    // if (!pool?.pendingReward || pool?.pendingReward.toNumber() <= 0 || pool?.pendingReward === 'NaN')
    //     return false

    const now = moment().unix();
    const isLockPerUser = pool?.enableLockToUser ? pool?.userDepositTime + pool?.lockDuration > now : false;
    const isLockGlobal = compareTime(pool?.withdrawTime, now); // pool?.withdrawTime > now
    if (pool?.withdrawMode === 0 || pool?.withdrawMode === 1) {
        if (isLockPerUser || isLockGlobal)
            return false;
    }


    return true;
}

/*-- WITHDRAW BUTTON -- */
export const isAllowWithdrawCheck = (pool) => {
    if (isCommonDisable(pool))
        return false;

    if (!pool?.pendingReward || pool?.pendingReward.toNumber() <= 0 || pool?.pendingReward === 'NaN')
        return false;

    const now = moment().unix();
    const isLockPerUser = pool?.enableLockToUser ? pool?.userDepositTime + pool?.lockDuration > now : false;
    const isLockGlobal = compareTime(pool?.withdrawTime, now); // pool?.withdrawTime > now
    if (pool?.withdrawMode === 0 || pool?.withdrawMode === 2) {
        if (isLockPerUser || isLockGlobal)
            return false;
    }


    return true;
}

/*-- EXIT BUTTON -- */
export const isAllowExitCheck = (pool) => {
    if (isCommonDisable(pool))
        return false;

    const stakedBlance = BigNumber.isBigNumber(pool?.stakedBalance) ? pool?.stakedBalance.toNumber() : pool?.stakedBalance;
    if (stakedBlance === 0)
        return false;

    if (pool?.status === STAKING_TYPES.UPCOMING) {
        return false;
    }

    if (pool?.status === STAKING_TYPES.COMPLETED) {
        return false;
    }

    const now = moment().unix();
    const isLockPerUser = pool?.enableLockToUser ? pool?.userDepositTime + pool?.lockDuration > now : false;
    const isLockGlobal = compareTime(pool?.withdrawTime, now); // pool?.withdrawTime > now
    if (pool?.withdrawMode === 0 || pool?.withdrawMode === 1) {
        if (isLockPerUser || isLockGlobal)
            return false;
    }
    return true;
}


/*-- EXIT BUTTON  - COMPOUND -- */
export const isAllowExitCompoundCheck = (pool) => {
    if (isCommonDisable(pool))
        return false;

    const stakedBlance = BigNumber.isBigNumber(pool?.stakedBalance) ? pool?.stakedBalance.toNumber() : pool?.stakedBalance;
    if (stakedBlance === 0)
        return false;

    if (pool?.status === STAKING_TYPES.LIVE)
        return false;

    if (pool?.status === STAKING_TYPES.COMPLETED && stakedBlance !== 0)
        return true;


    const now = moment().unix() * 1000;
    const isLockPerUser = pool?.enableLockToUser ? pool?.userDepositTime + pool?.lockDuration > now : false;
    const isLockGlobal = pool?.withdrawTime > now;
    if (pool?.withdrawMode === 0 || pool?.withdrawMode === 1) {
        if (isLockPerUser || isLockGlobal)
            return false;
    }
    return true;
}

/*-- WITHDRAW & CLAIM BUTTON -- */
export const isAllowWithdrawClaimCheck = (pool, depositObject) => {
    if (isCommonDisable(pool)) return false;

    const amount = BigNumber.isBigNumber(depositObject?.amount)
        ? depositObject?.amount
        : new BigNumber(depositObject?.amount || 0);

    if (!amount.gt(0)) return false;

    if (pool?.status === STAKING_TYPES.UPCOMING) return false;
    // if (pool?.status === STAKING_TYPES.COMPLETED) return true;

    const now = moment().unix();
    // const startLock = isSecond(depositObject?.lockedFrom) ? depositObject?.lockedFrom : depositObject?.lockedFrom / 1000
    const endLock = isSecond(depositObject?.lockedTo) ? depositObject?.lockedTo : depositObject?.lockedTo / 1000

    if (now < Number(endLock)) {
        return false;
    }

    return true;
}

const isCommonDisable = (pool) => {
    const isDisableAllButton = getEnvDisableButton();
    
    if (isEmpty(pool)) return true;

    if (pool.isFrozen) return true;

    if (isDisableAllButton) return true;

    return false;
}

export const useButtonStake = (pool) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => isAllowStakeCheck(pool), [pool?.pendingReward, pool?.isFrozen])
}
export const useButtonUnStake = (pool) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => isAllowUnStakeCheck(pool), [pool?.pendingReward, pool?.isFrozen])
}
export const useButtonWithdraw = (pool) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => isAllowWithdrawCheck(pool), [pool?.pendingReward, pool?.isFrozen])
}
export const useButtonExit = (pool) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => isAllowExitCheck(pool), [pool?.pendingReward, pool?.isFrozen])
}


export const useDescription = (description, total, progress) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(
        () => getDescription(description, total, progress),
        [description, total, progress]
    )
}