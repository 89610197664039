import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from 'antd'
// import redwavegun from '../assets/video/red-wavegun-2.mp4'
import telegram from '../assets/img/button_telegram.png'
// import VideoAnimation from './Video'
import stakeBanner from '../assets/stake-banner.png';

const Wrapper = styled.div`
  background: #111114;
  width: 100%;
  padding: 0 30px;
  @media screen and (min-width: 1920px) {
    padding: 0 70px;
  }
  @media screen and (min-width: 2500px) {
    padding: 0 110px;
  }
  @media screen and (max-width: 900px) {
    padding-bottom: 50px;
  }
`

const MainContent = styled.div`
  display: flex;
  padding: 60px 0 0;
  align-items: center;
  @media screen and (max-width: 768px) {
    display: block;
    padding: 30px 0;
  }
`

const LeftBlock = styled.div`
  width: 50%;
  position: relative;

  margin-bottom: 50rem;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  img {
    width: 450rem;

    @media screen and (max-width: 767px) {
      width: 900rem;
    }
  }

  // &:before {
  //   position: absolute;
  //   right: 15%;
  //   content: '';
  //   display: block;
  //   background-color: #111114;
  //   width: 2px;
  //   height: 100%;
  // }
  // video {
  //   width: 70%;
  // }

  @media screen and (max-width: 900px) {
    // width: 80%;
    // margin: 0 auto;
    // &:before {
    //   right: 0;
    // }
    // video {
    //   width: 100%;
    // }
  }
`
const TextBlock = styled.div`
  margin-bottom: 36px;
`

const TextTitle = styled.div`
  font-size: 29px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  line-height: 1.5;

  @media screen and (min-width: 769px) {
    font-size: 30px;
    text-align: left;
    line-height: unset;
  }
  @media screen and (min-width: 1280px) {
    font-size: 32.5px;
    text-align: left;
    line-height: 2.2;
  }
  @media screen and (min-width: 1440px) {
    font-size: 35px;
    text-align: left;
    line-height: unset;
  }
  @media screen and (min-width: 1920px) {
    font-size: 40px;
    text-align: left;
    line-height: unset;
  }
`

const TextDescription = styled.div`
  font-size: 13px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  @media screen and (min-width: 769px) {
    font-size: 15px;
    text-align: left;
  }
  @media screen and (min-width: 1280px) {
    font-size: 15px;
    text-align: left;
  }
  @media screen and (min-width: 1440px) {
    font-size: 18px;
    text-align: left;
  }
  @media screen and (min-width: 1920px) {
    font-size: 20px;
    text-align: left;
  }
`

const ButtonStake = styled(Button)`
  padding: 0px 40px;
  background: url(${telegram});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: unset;
  height: 44px;
  > a:only-child {
    color: #fff;
  }
  a {
    color: #fff;
    font-weight: 700;
    line-height: 1.7;
    font-size: 18px;
  }
  span {
    color: #fff;
    font-weight: 700;
    line-height: 1.7;
    font-size: 18px;
  }
  :hover,
  :active,
  :focus {
    background: url(${telegram});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  @media screen and (min-width: 1920px) {
    width: 22%;
    span {
      font-size: 22px;
    }
  }
  @media screen and (min-width: 2500px) {
    width: 25%;
    span {
      font-size: 26px;
    }
  }
  @media screen and (max-width: 900px) {
    display: flex;
    margin: 0 auto;
    align-items: center;
  }
`
const RightBlock = styled.div`
  width: 50%;
  text-align: left;
  padding-left: 20px;
  @media screen and (max-width: 768px) {
    padding-left: 0;
  }
  @media screen and (max-width: 900px) {
    width: auto;
    margin: 0 auto;
  }
`
const ComponentFive = () => {
  return (
    <Wrapper>
      <MainContent>
        <LeftBlock>
          <img src={stakeBanner} alt="" />
          {/* <VideoAnimation video={redwavegun} /> */}
        </LeftBlock>

        <RightBlock>
          <TextBlock>
            <TextTitle>Play to Earn is Undead</TextTitle>
            <TextDescription>Use Genesis and Apocalypse Weapon NFTs to earn Gold ZBUX, which can be used to swap for your choice of crypto!</TextDescription>
          </TextBlock>

          <ButtonStake size={'large'}>
            <Link to="/staking">Stake now</Link>
          </ButtonStake>
        </RightBlock>
      </MainContent>
    </Wrapper>
  )
}

export default ComponentFive
