import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { useHistory } from 'react-router'
import UndeadArmory from '../../assets/img/undead_armory.png'
import aninationDesktop from '../../lotties/Desktop.json'
import aninationMobile from '../../lotties/Mobile.json'
import Anmations from '../../components/Animations'
import Internet from '../../assets/img/internet_about_us.png'
import Skullcap from '../../assets/img/skullcap.png'
import BGButton from '../../assets/img/button_about_us.png'
const Wrapper = styled.div`
  background: #111114;
  width: 100%;
  height: auto;
  position: relative;
  /* @media screen and (min-width: 1920px) {
    padding: 0 135px;
  }
  @media screen and (max-width: 768px) {
    display: block;
    padding: 0 30px;
  } */
`
const AnmationsDesktop = styled.div`
  svg {
    width: 100vw !important;
    height: 100vh !important;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`
const AnmationsMobile = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: unset;
  }
`
const Img = styled.img`
  width: 60%;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`

// const Des = styled.p`
//   font-size: 40px;
//   color: #ffff;
//   font-weight: bold;
//   line-height: 1.2;
//   text-align: center;
//   margin-bottom: 0;

//   @media screen and (min-width: 769px) {
//     font-size: 32px;
//   }
//   @media screen and (min-width: 1280px) {
//     font-size: 36px;
//   }
//   @media screen and (min-width: 1440px) {
//     font-size: 40px;
//   }
//   @media screen and (min-width: 1920px) {
//     font-size: 42px;
//   }
//   @media screen and (min-width: 2560px) {
//     font-size: 44px;
//   }
//   @media screen and (max-width: 768px) {
//     font-size: 30px;
//   }
//   @media screen and (max-width: 560px) {
//     font-size: 28px;
//   }
// `

const Content = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: 15%;
  @media screen and (max-width: 768px) {
    top: 40%;
    padding-top: unset;
  }
`
const ButtonControl = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
  width: 100%;
  @media screen and (max-width: 2560px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
    padding: 10px 20px;
    background: url(${BGButton}) no-repeat;
    background-size: 100% 100%;
    border: unset;
    height: unset;
    @media screen and (max-width: 1920px) {
      padding: 12px 25px;
    }
    @media screen and (max-width: 1440px) {
      padding: 10px 20px;
    }
    @media screen and (max-width: 768px) {
      background-color: rgba(134, 0, 0, 0.349);
      padding: 5px;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
    svg {
      width: 25px;
      height: auto;
      margin-right: 5px;
      @media screen and (max-width: 2500px) {
        width: 40px;
      }
      @media screen and (max-width: 1920px) {
        width: 35px;
      }
      @media screen and (max-width: 768px) {
        width: 20px;
      }
      @media screen and (max-width: 360px) {
        width: 17px;
      }
    }
    span {
      color: #fff;
      line-height: 1.7;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      @media screen and (max-width: 2500px) {
        font-size: 27px;
      }
      @media screen and (max-width: 1920px) {
        font-size: 25px;
      }
      @media screen and (max-width: 1440px) {
        font-size: 22px;
      }
      @media screen and (max-width: 1280px) {
        font-size: 20px;
      }
      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
      @media screen and (max-width: 480px) {
        font-size: 16px;
      }
    }
    :hover,
    :active,
    :focus {
      background: url(${BGButton}) no-repeat;
      background-size: 100% 100%;
    }
  }
`

export default function Armory() {
  const history = useHistory()
  return (
    <Wrapper>
      <AnmationsDesktop>
        <Anmations animationData={aninationDesktop} />
      </AnmationsDesktop>
      <AnmationsMobile>
        <Anmations animationData={aninationMobile} />
      </AnmationsMobile>
      <Content>
        <Img src={UndeadArmory} alt="" />
        {/* <Des>Coming March 2022</Des> */}
        <ButtonControl>
          <Button size={'middle'} onClick={() => history.push('./')}>
            <img src={Internet} alt={''} width={'15%'} style={{ paddingRight: '10px' }} />
            Landing Page
          </Button>
          <Button size={'middle'} onClick={() => history.push('./staking')}>
            <img src={Skullcap} alt={''} width={'30%'} style={{ paddingRight: '10px' }} />
            Stake
          </Button>
        </ButtonControl>
      </Content>
    </Wrapper>
  )
}
