import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'antd'
import styled from 'styled-components'

import backIcon from '../../assets/img/rightArrow.png';

import ButtonSmall from '../ButtonSmall';
import { formatNumber, convertDisplayTimeByFormat, handleResult } from '../../utils/common';
import { PREFIX_NOT_EQUAL_BN } from '../../utils/constants';
import { isAllowWithdrawClaimCheck } from '../CommonStaking/StakingList/StakingItem/itemHelper';
import { onWithdrawByDepositId } from '../../hooks/onWithdrawByDepositId';
import { setToast } from '../../actions/common';

import './style.css';

const WithdrawClaimScheduleModal = ({
  account,
  isVisible,
  onClose,
  onClosePopup,
  pool,
  pools,
  isMobile,
  setTransaction,
  loadingFunc,
  onCloseLoading,
  setHash
}) => {
  const dispatch = useDispatch();
  const { listDeposit, rewardTokenSymbol, stakeTokenSymbol, id } = pool

  const [, setPendingTx] = useState(false)

  const handleResultExit = (receipt, error = null) => {
    handleResult(() => {
      dispatch(setToast({ title: 'Timeout', detail: `Timeout ${error?.message}` }));
      setPendingTx(false)
      onCloseLoading()
    }, () => {
      dispatch(setToast({ title: 'Canceled', detail: 'Please try again and confirm the transaction.' }));
      setPendingTx(false);
      onClose()
    }, () => {
      // dispatch(setToast({ title: 'Withdraw', detail: `Your ${rewardTokenSymbol} earnings have been sent to your wallet!` }));
      dispatch(setToast(null));
      setPendingTx(false);
      onCloseLoading()
      setTransaction(receipt)
    }, receipt, error)
  }


  const onWithdraw = async (depositId, amount) => {
    setPendingTx(true);
    loadingFunc(true)
    try {
      await onWithdrawByDepositId(dispatch, id, account, pools, depositId, amount, handleResultExit, (hashParam) => setHash(hashParam));
    } catch (e) {
      setPendingTx(false)
    }
  }

  return <Modal
    centered
    width={isMobile ? '1150rem' : '450rem'}
    visible={isVisible}
    onCancel={onClosePopup}
    wrapClassName={'withdraw-claim-schedule-modal'}
  >
    <BodyContent>
      <BackRow>
        <BackImage src={backIcon} onClick={onClosePopup} />
        Back
      </BackRow>

      <TitleMint> Withdraw & Claim </TitleMint>
      <BodyMint>
        Below is the unlock schedule for all individual staked <br /> amounts in the current pool.
      </BodyMint>

      <WithdrawSchedule>
        {/* <ListWrapper>
          <section className={'title-list'}>
              <p className={'title-text'}>Stake amount</p>
              <p className={'title-text'}>Unlock Date</p>
              <p className={'title-text'}>Reward</p>
          </section>

          <section className={'withdraw-list'}>
            {(listDeposit || [])?.map((item, index) => (
              <div key={index} className={'withdraw-item'}>
                <p className={'value-text'}>{formatNumber(item?.amount || 0, PREFIX_NOT_EQUAL_BN, 2)} {stakeTokenSymbol}</p>
                <p className={'value-text'}>{convertDisplayTimeByFormat(item?.lockedTo)}</p>
                <p className={'value-text'}>{formatNumber(item?.pendingReward || 0, PREFIX_NOT_EQUAL_BN, 2)} {rewardTokenSymbol}</p>

                <ButtonWrapper>
                  <ButtonSmall
                    disabled={!isAllowWithdrawClaimCheck(pool, item)}
                    title={'Withdraw & Claim'}
                    event={() => onWithdraw(item?.id, item?.amount.plus(item?.pendingReward))}
                  />
                </ButtonWrapper>
              </div>
            ))}
          </section>
        </ListWrapper> */}

        <WithdrawScheduleTable>
          <thead style={{ verticalAlign: 'text-top' }}>
            <tr>
              <th>Stake amount</th>
              <th>Unlock Date</th>
              <th>Reward</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {(listDeposit || [])?.map((item, index) =>
              <tr key={index}>
                <td>
                  {formatNumber(item?.amount || 0, PREFIX_NOT_EQUAL_BN, 2)}
                  {' '}
                  {stakeTokenSymbol}
                </td>

                <td>
                  {convertDisplayTimeByFormat(item?.lockedTo)}
                </td>

                <td>
                  {formatNumber(item?.pendingReward || 0, PREFIX_NOT_EQUAL_BN, 2)}
                  {' '}
                  {rewardTokenSymbol}
                </td>

                <td>
                  <ButtonWrapper>
                    <ButtonSmall
                      disabled={!isAllowWithdrawClaimCheck(pool, item)}
                      title={'Withdraw & Claim'}
                      event={() => onWithdraw(item?.id, item?.amount.plus(item?.pendingReward))} />
                  </ButtonWrapper>
                </td>
              </tr>
            )}
          </tbody>
        </WithdrawScheduleTable>
      </WithdrawSchedule>

    </BodyContent>
  </Modal >
}

export default WithdrawClaimScheduleModal

const BodyContent = styled.div`
  padding: 25.5rem 26.5rem 16rem 23rem;
  position: relative;

  @media (max-width: 767px) {
    padding: 55rem 50rem 50rem 50rem;
  }
`

const BackRow = styled.div`
  font-family: Poppins; 
  font-size: 15rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #fff;

  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    font-size: 43rem;
  }
`

const BackImage = styled.img`
  width: 14rem;
  margin-right: 7rem;

  @media (max-width: 767px) {
    width: 45rem;
    margin-right: 15rem;
  }
`

const TitleMint = styled.div`
  font-size: 22.5rem;
  font-weight: 600;
  line-height: 0.8;
  text-align: left;
  color: #fff;

  margin-top: 15rem;

  @media (max-width: 767px) {
    font-size: 61rem;
    margin-top: 15px;
  }
`

const BodyMint = styled.p`
  font-size: 12rem;
  line-height: 1.33;
  text-align: left;
  color: #8e8e8e;

  padding-top: 10rem;
  margin-bottom: 17rem;

  @media (max-width: 767px) {
    font-size: 33rem;
    padding: 20rem 0 20rem 0;
  }
`

const WithdrawSchedule = styled.div`
`

const WithdrawScheduleTable = styled.table`
    font-size: 11rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    color: #fff;
    width: 100%;

    td:last-child {
      text-align: right;
    }

    @media (max-width: 767px) {
      font-size: 30rem;
    }
`;

// eslint-disable-next-line
const ListWrapper = styled.div`
    width: 100%;

    .title-list {
      display: grid;
      grid-template-columns: calc(27% - 10rem) calc(19% - 10rem) calc(27% - 10rem) calc(27% - 10rem);
      grid-gap: 10rem;

      .title-text {
        font-size: 11rem;
        font-weight: 600;
        line-height: 1.45;
        text-align: left;
        color: #fff;

        margin-bottom: 0;

        @media (max-width: 767px) {
          font-size: 30rem;
        }
      }
    }

    .withdraw-list {
      width: 100%;

      .withdraw-item {
        display: grid;
        grid-template-columns: calc(27% - 10rem) calc(19% - 10rem) calc(27% - 10rem) calc(27% - 10rem);
        grid-gap: 10rem;
        align-items: center;

        .value-text {
          font-size: 11rem;
          line-height: 2.95;
          letter-spacing: -0.11rem;
          text-align: left;
          color: #fff;

          margin-bottom: 0;

          @media (max-width: 767px) {
            font-size: 30rem;
          }
        }
      }
    }
`;

const ButtonWrapper = styled.div`
  margin-bottom: 3rem;
`