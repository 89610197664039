import onceWayIcon from './img/1.png';
import secondWayIcon from './img/2.png';
import thirdWayIcon from './img/3.png';

export const WHITELIST = [
    {
        name: 'ART & MEMES',
        value: '500 Spots Available',
        description: 'Creativity is key. Create Undead Blocks themed art, memes, music, you name it.',
        icon: onceWayIcon,
        link: 'https://docs.google.com/forms/d/e/1FAIpQLSeS9Q7Yv2Df82ZoIZUlRxO08CDiZGlbSmpPf4HWEY_nUvJX2A/viewform'
    },
    {
        name: 'Community Engagement',
        value: '1000 Spots Available',
        description: `Be active in our Discord & Twitter, strike up meaningful conversations. Don't just be active, be a true member!`,
        icon: secondWayIcon,
        link: 'https://docs.google.com/forms/d/e/1FAIpQLScy7Gmw0YbhLMLIDjnlU4fvnybKzgLODHYjWi6qSo7nCoMz9Q/viewform'
    },
    {
        name: 'Content Creator',
        value: '500 Spots Available',
        description: 'Create content for Youtube, Twitter, etc. Help the game grow.',
        icon: thirdWayIcon,
        link: 'https://docs.google.com/forms/d/e/1FAIpQLSc6688O1CFV3w6_LQjp82eK7TdK1cMimTXtXZDRUVmiADFHMg/viewform'
    }
];