// Approve a Pool
import moment from 'moment';
import Logger from 'js-logger';
import { updateUserAllowanceMulti } from '../actions/pools'
import { storeTransactionLog } from '../actions/user';
import { approve } from '../utils/callHelpers'

export const onApprove = async (dispatch, stakeTokenAddress, id, account, stakingPoolsData, cb, cbProcessing) => {
    try {
        if (id) {
            const pool = stakingPoolsData.find(pool => pool.id === id)

            const callbackLog = (tx) => {
                let log = {
                    chainId: pool?.chainId,
                    account,
                    eventName: `Approved ${pool?.stakeTokenSymbol} to "${pool?.poolTitle}" pool.`,
                    date: moment().valueOf(),
                };
                log.transactionHash = tx?.transactionHash;
                dispatch(storeTransactionLog(log))

                dispatch(updateUserAllowanceMulti(id, account));
            }

            const tx = await approve(stakeTokenAddress, pool.stakeContractAddress, account, cb, callbackLog, cbProcessing)

            return tx
        }
        return false
    } catch (e) {
        Logger.error(`useApprove ${e}`);
        return false
    }
}
