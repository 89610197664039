import React from 'react'
import { Image } from 'antd'
import styled from 'styled-components'
// import openbeta from '../lotties/Open_Beta.json'
import softlaunch from '../lotties/Soft_Launch.json'
import handset from './../assets/img/handset.png'
import dark_layout from './../assets/img/dark_layout.jpg'
import dark_layout_mobile from './../assets/img/dark_bg_mobile.jpg'
import apple from './../assets/img/apple.png'
import window from './../assets/img/microsoft.png'
import Anmations from './Animations'

const WrapperLayout = styled.div`
  background-image: url(${dark_layout});
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @media screen and (max-width: 768px) {
    background-color: #111114;
    overflow: hidden;
    background-image: url(${dark_layout_mobile});
  }
`

const BackgroundFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`

const BackgroundImg = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
  @media screen and (max-width: 1440px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    margin: 30px 0 0 0;
  }
`

const Img = styled(Image)`
  width: 60%;
  margin-right: 94px;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  @media screen and (min-width: 1920px) {
    margin-right: 286px;
  }
  @media screen and (min-width: 2500px) {
    margin-right: 673px;
  }
`

const Title = styled.div`
  color: rgb(255, 255, 255);
  width: 36%;
  position: absolute;
  text-align: initial;
  z-index: 1;
  right: 0;
  @media screen and (max-width: 1440px) {
    width: 37%;
  }
  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    position: unset;
    width: auto;
    margin-top: 0;
    text-align: center;
    padding: 0 30px 30px;
  }
`

const TitleHead = styled.p`
  font-size: 36px;
  padding-right: 200px;
  font-weight: bold;
  @media screen and (min-width: 1920px) {
    font-size: 50px;
    line-height: 1.3;
  }
  @media screen and (min-width: 2500px) {
    font-size: 65px;
  }
  @media screen and (max-width: 1440px) {
    font-size: 32px;
    line-height: 1.2;
    padding-right: 90px;
  }
  @media screen and (max-width: 1180px) {
    padding-right: 50px;
    font-size: 30px;
  }
  @media screen and (max-width: 980px) {
    padding-right: 30px;
    font-size: 24px;
  }
  @media screen and (max-width: 768px) {
    padding: 0;
    font-size: 29px;
  }
  @media screen and (max-width: 480px) {
    font-size: 25px;
  }
`

const TitleContainer = styled.p`
  font-size: 13px;
  color: rgb(211, 211, 211);
  margin-top: -22px;
  /* padding: 0 50px; */
  padding-left: 0px;
  @media screen and (min-width: 769px) {
    font-size: 15px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 15px;
    padding-right: 125px;
    padding-left: 0px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 17px;
    padding-left: 0px;
  }
  @media screen and (min-width: 1920px) {
    font-size: 20px;
    padding-left: 0px;
  }
`
const Group = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  @media screen and (max-width: 1000px) {
    padding-right: 20px;
    font-size: 13px;
  }
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`

const Tag = styled.a``

const Apple = styled(Image)`
  height: 47px;
  width: 40px;
  margin-right: 25px;
  @media screen and (min-width: 1920px) {
    height: 90px;
    width: 75px;
  }
  @media screen and (min-width: 2500px) {
    height: 120px;
    width: 105px;
  }
`

const Window = styled(Image)`
  height: 50px;
  width: 50px;
  margin-right: 25px;
  @media screen and (min-width: 1920px) {
    height: 80px;
    width: 60px;
  }
  @media screen and (min-width: 2500px) {
    height: 110px;
    width: 95px;
  }
`
const Download = styled.button`
  text-decoration: none;
  font-size: 15px;
  max-width: max-content;
  padding: 0 22px;
  height: 52px;
  border-radius: 5px;
  background: #fff;
  color: black;
  font-weight: 750;
  border: none;
  cursor: pointer;
  @media screen and (min-width: 1920px) {
    height: 70px;
    font-size: 18px;
  }
  @media screen and (min-width: 2560px) {
    height: 100px;
    font-size: 25px;
  }
  @media screen and (max-width: 1000px) {
    padding: 0 10px;
    font-size: 13px;
  }
`
const Animaton = styled.div`
  position: absolute;
  top: 64%;
  left: 11%;
  width: 19%;
  @media screen and (max-width: 1920px) {
    position: absolute;
    top: 64%;
    left: 15%;
    width: 12%;
  }
  @media screen and (max-width: 765px) {
    position: absolute;
    top: 64%;
    left: 11%;
    width: 19%;
  }
`
const ComponentEleven = () => {
  return (
    <WrapperLayout>
      <BackgroundFlex>
        <BackgroundImg>
          <Img src={handset} preview={false} alt={''} />
          <Animaton>
            <Anmations animationData={softlaunch} />
          </Animaton>
        </BackgroundImg>
        <Title>
          <TitleHead>Soft Launch Now Live</TitleHead>
          <TitleContainer>
          Undead Blocks soft launch is officially live! Stay tuned for more updates as we continue enhancing our multiplayer zombie slaying experience!
          </TitleContainer>
          <Group>
            <Tag href="#">
              <Apple preview={false} src={apple} alt={''} />
            </Tag>
            <Tag href="#">
              <Window preview={false} src={window} alt={''} />
            </Tag>
            <Tag
              href={'https://www.wagyu.io/'}
              target={'_blank'}
            >
              <Download>Download Now</Download>
            </Tag>
          </Group>
        </Title>
      </BackgroundFlex>
    </WrapperLayout>
  )
}

export default ComponentEleven
