import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Tooltip } from 'antd';
import cn from 'classnames';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { PROVIDER_TYPES } from '../../containers/ConnectToWalletModal/constants';
import { setUserAccounts, disconnect, clearTransactionLogs } from '../../actions/user';
import { toggleAccountModal } from '../../actions/common';
import { verifyChainId, isSameChainId, isSameAddress } from '../../utils/web3';
import { getExplorerUrl } from '../../utils/common';

// import { ReactComponent as AddressImage } from '../../assets/svg/address.svg';
import { ReactComponent as CopyImage } from '../../assets/svg/copy.svg';
import { ReactComponent as ViewImage } from '../../assets/svg/view.svg';
import checkIcon from '../../assets/svg/check.svg';
import viewIcon from '../../assets/view.png';

import styled from 'styled-components';

const AccountBox = styled.div`

`;

const Logout = styled(Button)`
  background-color: #df1e33;
  color: #ffffff;
  border-color: transparent;
  height: 30rem;

  font-family: Poppins;
  font-size: 13rem;
  font-weight: bold;
  line-height: 1.31;
  text-align: left;
  color: #fff;
  margin-bottom: 10rem;

  &:hover,
  &:active,
  &:focus {
    opacity: 0.75;
    background: #df1e33;
    color: #ffffff;
    border-color: transparent;
  }

  @media screen and (max-width: 767px) {
    height: 85rem;
    font-size: 38rem;
  }
`

const AddressInfo = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`

const CopyAddress = styled(Tooltip)`
  display: flex;
  justify-content: start;
  align-items: center;
  color: #adafae;
  margin-right: 10px;
  font-size: 11rem;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    font-size: 38rem;
  }
`

const CopyIcon = styled.div`
  margin-right: 6px;
  display: flex;

  svg {
    width: 15rem;

    @media screen and (max-width: 767px) {
      width: 30rem;
    }
  }
`

const ViewIcon = styled.div`
  margin-right: 6px;
  display: flex;

  svg {
    width: 15rem;

    @media screen and (max-width: 767px) {
      width: 30rem;
    }
  }
`

const ViewAddress = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  color: #adafae;
  font-size: 12rem;
  cursor: pointer;
  line-height: 1.5;

  @media screen and (max-width: 767px) {
    font-size: 38rem;
    line-height: 1.5;
  }
`

const Account = styled.div`
  padding: 15px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  margin-bottom: 12px;

  @media screen and (max-width: 767px) {
    margin-bottom: 70rem;
  }
`

const ConnectWith = styled.div`
  font-family: Poppins;
  font-size: 15rem;
  font-weight: bold;
  line-height: 1.31;
  text-align: left;
  color: #fff;
  margin-bottom: 10rem;

  @media screen and (max-width: 767px) {
    font-size: 45rem;
    margin-bottom: 25rem;
  }
`

const Address = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  font-family: Poppins;
  font-size: 13rem;
  line-height: 1.31;
  text-align: left;
  color: #fff;
  margin-bottom: 10rem;

  @media screen and (max-width: 767px) {
    font-size: 35rem;
    margin-bottom: 25rem;
  }
`

const AddressIcon = styled.span`
  margin-right: 10px;
  border-radius: 50px;
  overflow: hidden;
  padding: 0px;
  width: 17.5rem;
  height: 17.5rem;

  display: inline-block;
  @media (max-width: 767px) {
    width: 47.5rem;
    height: 47.5rem;
  }
`

// const AddressIcon = styled.span`
//   margin-left: 10rem;
//   width: 17.5rem;
//   height: 15rem;

//   border-radius: 50%;
//   display: flex;
//   overflow: hidden;

//   @media (max-width: 767px) {
//     width: 47.5rem;
//     height: 47.5rem;
//   }
// `

const NoRecent = styled.p`
  font-size: 12rem;
  font-weight: 400;
  line-height: 1;
  margin-top: 20px;
  color: #ffffff;

  @media screen and (max-width: 767px) {
    font-size: 38rem;
  }
`

const Transaction = styled.div`
  margin-top: 15rem;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5rem;

    .title {
      font-family: Poppins;
      font-size: 18rem;
      font-weight: bold;
      line-height: 1.31;
      text-align: left;
      color: #fff;
  
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        font-size: 55rem;
      }
    }
  
    .clear {
      font-family: Poppins;
      font-size: 12.5rem;
      font-weight: bold;
      text-align: left;
      color: #1dc489;
      line-height: 1;

      margin-bottom: 0;
      cursor: pointer;

      &:hover {
        transform: scale(1.010);
        transition: all 0.2s;
      }

      @media screen and (max-width: 767px) {
        font-size: 45rem;
      }
    }
  }
`

const TransactionList = styled.ul`{
  list-style: none;
  padding: 0;
  margin: 0rem 0 14rem;
  max-height: 100rem;
  overflow-y: scroll;

  &.hasScroll {
    padding-right: 20rem;

    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: gray;
      border-radius: 2.5px;
      :hover {
      }
    }

    @media screen and (max-width: 767px) {
      padding-right: 50rem;
    }
  }

  @media screen and (max-width: 767px) {
    margin: 50rem 0 90rem;

    max-height: 300rem;
  }
}`

const TransactionItem = styled.li`{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5rem 0;

  @media screen and (max-width: 767px) {
    padding: 20rem 0;
  }

  &:not(:first-child) {
    margin-top: 5rem;
  }

  .ant-btn {
    background: transparent;
    border: none;
  }
}`

const TransactionItemValue = styled.div`{
  font-family: Poppins;
  font-size: 13rem;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  color: #adafae;

  display: flex;
  align-items: center;

  .view {
    width: 10rem;
    margin: 0 10rem;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      margin-left: 24rem;
      width: 30rem;
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 38rem;
  }
}`

const CheckIcon = styled.img`
  width: 15rem;

  @media screen and (max-width: 767px) {
    width: 50rem;
    height: 50rem;
  }
`

const ALLOW_NETWORK = [1, 4];

function AccountModal() {
  const dispatch = useDispatch();

  const [copied, setCopied] = useState(false);

  const { isMobile } = useSelector(state => state.common);
  const account = useSelector(state => (state.user.userAccount.accounts ? state.user.userAccount.accounts[0] : ''));
  const chainId = useSelector(state => state.user.chainId);
  const isAccountModalVisible = useSelector(state => state.common.isAccountModalVisible);
  const transactionLogs = useSelector(state => state.user?.transactionLogs?.result);
  const transactionList = (transactionLogs || [])
    .filter(item => isSameAddress(item?.account, account) || isSameAddress(item?.walletAddress, account))
    .filter(item => isSameChainId(item?.chainId, chainId));
  const transactionListSort = (transactionList || []).sort((a, b) => b?.date - a?.date);

  const providerType = PROVIDER_TYPES.METAMASK;

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000)
    }
  }, [copied])

  const copyClipboard = () => {
    setCopied(true)
    navigator.clipboard.writeText(account)
  }

  const viewEthererumScan = () => {
    const url = chainId && ALLOW_NETWORK.includes(chainId) && (
      chainId === 1 
        ? `${process.env.REACT_APP_BLOCK_EXPLORER_URL_ETH_MAINNET}/address/${account}`
        : `${process.env.REACT_APP_BLOCK_EXPLORER_URL_ETH}/address/${account}`
    )
    
    window.open(url, '_blank')
  }

  const logout = () => {
    dispatch(disconnect());
    dispatch(setUserAccounts({ accounts: [] }))
    window.localStorage.removeItem('connectorId')
    window.localStorage.removeItem('walletconnect')
    dispatch(toggleAccountModal(false))
  }

  const onViewLinkClick = (transaction) => {
    const link = `${getExplorerUrl(verifyChainId(chainId))}/tx/${transaction}`;
    window.open(link, '_blank');
  };


  return (
    <Modal
      title="Account"
      visible={isAccountModalVisible}
      onCancel={() => dispatch(toggleAccountModal(false))}
      centered
      width={isMobile ? '1200rem' : '380rem'}
      zIndex={2000}
    >
      <AccountBox>
        <Account>
          <ConnectWith>Connected with {providerType}</ConnectWith>

          <Address>
            <AddressIcon>
              {/* <AddressImage /> */}
              <Jazzicon paperStyles={{ maxWidth: '100%', maxHeight: '100%' }} d
                                    iameter={isMobile ? 11 : 14} seed={jsNumberForAddress(account || '')} />
            </AddressIcon>
            <span>
              {account && account.substring(0, 14)}...{account && account.substring(account.length - 4)}
            </span>
          </Address>

          <AddressInfo>
            <CopyAddress title="Copy to clipboard" color="#111111" onClick={copyClipboard}>
              <CopyIcon>
                {copied ? <CheckIcon src={checkIcon} alt="" /> : <CopyImage />}
              </CopyIcon>

              <span>{copied ? 'Copied' : 'Copy Address'}</span>
            </CopyAddress>

            <ViewAddress onClick={viewEthererumScan}>
              <ViewIcon>
                <ViewImage />
              </ViewIcon>

              <span>View on Etherscan {chainId === 1 ? 'Mainnet' : 'Testnet'}</span>
            </ViewAddress>
          </AddressInfo>
        </Account>

        {transactionListSort && transactionListSort.length > 0 ? (
          <Transaction>
            <section className="header">
              <p className="title">Recent Transactions</p>
              <p
                className="clear"
                onClick={() => dispatch(clearTransactionLogs())}
              >
                Clear All
              </p>
            </section>
            <TransactionList
              className={cn('', {
                'hasScroll': transactionListSort.length > 3
              })}
            >
              {transactionListSort.map((item, i) => (
                <TransactionItem key={i}>
                  <TransactionItemValue>
                    {item.eventName} {item.display}

                    <img
                      className="view"
                      src={viewIcon}
                      alt="view"
                      onClick={() => onViewLinkClick(item.transactionHash)}
                    />
                    {/* <Button
                      wrapperclass={'transactionLink'}
                      icon={<ViewImage />}
                      onClick={() => onViewLinkClick(item.transactionHash)}
                    /> */}
                  </TransactionItemValue>

                  <CheckIcon src={checkIcon} alt="" />
                </TransactionItem>
              ))}
            </TransactionList>
          </Transaction>
        ) : (
          <NoRecent>Have No Recent Transactions</NoRecent>
        )}

        <Logout onClick={logout}>Disconnect</Logout>
      </AccountBox>
    </Modal>
  );
}

export default AccountModal;