import React from 'react'
import Lottie from 'react-lottie'
import { Modal } from 'antd'
import styled from 'styled-components'
import InfoLockPeriod from '../CommonStaking/StakingList/StakingItem/InfoLockPeriod'
import InfoStakingRight from '../CommonStaking/StakingList/StakingItem/InfoStakingRight'
import { getMonthsShort } from '../../utils/common'

import './style.css';

const StakingInfomationModal = ({
  stakingTokenPrice,
  isVisible,
  onClose,
  pool,
  isMobile,
  isTablet,
  isLoading
}) => {
  const {
    apy,
    background,
    lottie,
    // titleMint,
    // bodyMint,
    // footerMint,
    infoHeader,
    infoBody,
    infoFooter,
    isLotte,
    lockDuration
  } = pool

  return (
    <Modal
      width={isMobile ? '1000rem' : '374rem'}
      visible={isVisible}
      onCancel={onClose}
      wrapClassName={'staking-infomation-modal'}
      centered
    >
      <BannerHeader>
        <InfoLockPeriod
          title={`Lock`}
          content={getMonthsShort(lockDuration) === 'N/A' ? '0 Mo' : getMonthsShort(lockDuration)}
          isMobile={isMobile}
          isTablet={isTablet}
          isLoading={isLoading}
        />

        <InfoStakingRight
          title={'APY'}
          pool={pool}
          stakingTokenPrice={stakingTokenPrice}
          content={apy}
          isMobile={isMobile}
          isTablet={isTablet}
          isLoading={isLoading}
        />
      </BannerHeader>

      <Background>
        <ImageBackground src={background} alt='' style={{ width: '100%' }} />

        {isLotte ? <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: lottie,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            }
          }}
          style={isMobile ? styleImageLottieMobile : styleImageLottie}
        /> : <ImgItem src={lottie} />}

        <Lottie
          options={{
            loop: true,
            autoplay: false,
            animationData: pool.lottieSparkle,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            }
          }}
          style={styleImageLottieSparkle}
        />
      </Background>

      <div className='body-description'>
        <TitleMint> {infoHeader} </TitleMint>
        <BodyMint> {infoBody} </BodyMint>
        <FooterMint> {infoFooter} </FooterMint>
      </div>
    </Modal>
  );
}

export default StakingInfomationModal

const Background = styled.div`
}
`
const styleImageLottie = {
  transition: `height 0.05s`,
  width: '150rem',
  height: '170rem',
  position: 'absolute',
  zIndex: 2,
  top: 35,
  right: 0,
  left: 0,
}

const styleImageLottieMobile = {
  transition: `height 0.05s`,
  width: '50%',
  height: '550rem',
  position: 'absolute',
  zIndex: 2,
  top: 35,
  right: 0,
  left: 0,
}

const BannerHeader = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15rem 29rem;
  @media (max-width: 768px) {
    padding: 51.2rem;
  }
`

const TitleMint = styled.span`
  font-size: 22.5rem;
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 0.8;
  text-align: left;
  margin-top: 5px;
  @media (max-width: 768px) {
    font-size: 76.8rem;
  }
`

const BodyMint = styled.p`
  font-size: 12rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.68;
  letter-spacing: normal;
  text-align: left;
  color: #8e8e8e;
  padding: 14.5rem 0 8rem 0;
  @media (max-width: 768px) {
    font-size: 39.4rem;
  }
`

const FooterMint = styled.p`
  font-size: 12rem;
  color: #cacaca;
  font-weight: bold;
  text-align: left;
  margin-bottom:0;
  @media (max-width: 768px) {
    font-size: 39.4rem;
  }
`

const ImageBackground = styled.img`
  width: 100%;
  border-radius: 7.5px 0 0 0;
  height: 217rem;

  @media (max-width: 1280px) {
    height: 217rem;
  }

  @media screen and (max-width: 767px) {
    height: 712rem;
  }
`

const ImgItem = styled.img`
  height: 170rem;
  position: absolute;
  margin: 0 auto;
  top: 30rem;
  z-index: 2;
  right: 0;
  left: 0;

  @media (max-width: 1280px) {
    height: 170rem;
    top: 30rem;
  }

  @media (max-width: 768px) {
    height: 570rem;
    top: 85rem;
  }
`

const styleImageLottieSparkle = {
  transition: `height 0.05s`,
  height: 'auto',
  width: '35%',
  position: 'absolute',
  zIndex: 2,
  top: 35,
  right: 0,
  left: 0,
}