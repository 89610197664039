import { createActions } from "redux-actions";
import PackageApi from '../services/api/package';

const { fetchAllPackagesRequest, fetchAllPackagesSuccess, fetchAllPackagesFail } = createActions({
    FETCH_ALL_PACKAGES_REQUEST: () => {},
    FETCH_ALL_PACKAGES_SUCCESS: data => ({ data }),
    FETCH_ALL_PACKAGES_FAIL: error => ({ error }),
});

export const fetchAllPackages = (limit = 20, skip = 0) => (dispatch) => {
    dispatch(fetchAllPackagesRequest());

    return PackageApi.fetchAllPackages({ limit, skip })
        .then(({ data }) => {
            dispatch(fetchAllPackagesSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(fetchAllPackagesFail(error));
            return error;
        });
}