import http from '../http';

export default class PackageApi {
    static fetchAllPackages({
      limit = 20,
      skip = 0
    }) {
        return http.get('/packages', {
            params: {
                limit,
                skip
            }
        });
    }
}
