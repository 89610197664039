// import { Skeleton } from 'antd'
import React from 'react'
import styled from 'styled-components'

const Title = styled.div`
  margin: 20rem 29rem 3rem;

  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    margin: 70rem 100rem 3rem;
  }

  p {
    font-size: 22.5rem;
    font-weight: bold;
    line-height: 0.8;
    text-align: left;
    color: #fff;

    margin-bottom: 0;

    @media (max-width: 767px) {
      font-size: 72rem;
    }
  }
`

const Information = styled.img`
  width: 16.5rem;
  cursor: pointer;
  margin-left: 7.5rem;

  @media (max-width: 767px) {
    width: 52rem;
    margin-left: 20rem;
  }
}
`

// const WrapperSkeleton = styled.div`
//   span {
//     width: 286rem !important; // 429
//     height: 25rem !important; // 37.5
//     border-radius: 5rem;
//     @media (max-width: 1280px) {
//       width: 289rem !important;
//       height: 25rem !important;
//     }
//     @media only screen and (max-width: 768px) {
//       width: 1033rem!important;
//       height: 64rem!important;
//       border-radius: 22rem;
//     }
//   }
// `

const TitleStaking = ({ title, imgInfomation, setIsVisibleInfoModal, isLoading = false }) => {
  return (
    <Title>
      <p>{title}</p>
      <Information src={imgInfomation} onClick={() => setIsVisibleInfoModal(true)} />
    </Title>
  )
}

export default TitleStaking
