import { handleActions } from "redux-actions";

const initialState = {
    allSettings: {
        result: null,
        requesting: false,
        error: null
    }
};

let settingReducer = handleActions({
    /** FETCH ALL SETTINGS **/
    FETCH_ALL_SETTINGS_REQUEST: (state) => ({
        ...state,
        allSettings: {
            ...state.allSettings,
            requesting: true
        }
    }),
    FETCH_ALL_SETTINGS_SUCCESS: (state, { payload }) => ({
        ...state,
        allSettings: {
            ...state.allSettings,
            requesting: false,
            result: payload.data,
            error: null
        }
    }),
    FETCH_ALL_SETTINGS_FAIL: (state, { payload }) => ({
        ...state,
        allSettings: {
            ...state.allSettings,
            requesting: false,
            error: payload.error
        }
    })
}, initialState);

export default settingReducer;