import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';

import close from '../TournamentPopup/img/close.png';
import closeHover from '../TournamentPopup/img/close-hover.png';

import bg from './img/bg.png';
import undeadLogo from './img/undeadtext.png';
import downloadBtn from './img/download-button.png';
import mainTitle from './img/main-title.png';

import {
    setIsMobile,
    setIsTablet,
    setIsDesktop,
} from '../../actions/common';

import styled from 'styled-components';
import './style.css';

const BREAKPOINTS = {
    SM_MAX: 767,
    MD_MIN: 768,
    MD_MAX: 1023,
    LG_MIN: 1024,
    LG_MIN_CUSTOM: 1279
};

const Wrapper = styled.div`
    position: relative;

    background: url(${bg});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    width: 100%;
    height: 540rem;

    .banner {
        width: 100%;
        height: 100%;
    }

    .close {
        background: url(${close});
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        width: 50rem;
        height: 50rem;

        position: absolute;
        z-index: 50;
        top: -25rem;
        right: -25rem;

        cursor: pointer;
        transition: all 0.5s;

        @media screen and (max-width: 767px) {
            width: 100rem;
            height: 100rem;

            top: -50rem;
            right: -50rem;
        }

        :hover {
            background: url(${closeHover});
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
    
            width: 50rem;
            height: 50rem;

            @media screen and (max-width: 767px) {
                width: 100rem;
                height: 100rem;
            }
        }
    }

    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        padding-top: 43rem;

        .logo {
            width: 472rem;
            height: 113rem;

            margin-bottom: 20rem;
        }

        .main-title {
            width: 587rem;
            height: 95.5rem;

            margin-bottom: 19rem;
        }

        .description {
            margin-bottom: 0;

            font-family: Rajdhani;
            font-size: 22.8rem;
            font-weight: 600;
            line-height: 1.42;
            letter-spacing: -0.57rem;
            text-align: center;
            color: #fff;
          
            padding: 0 190rem;
        }
    }

    .btn-container {
        display: flex;
        justify-content: center;

        margin-top: 36rem;

        @media screen and (max-width: 767px) {

        }
    }

    .close-button {
        background: url(${downloadBtn});
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        width: 267rem;
        height: 53.5rem;

        cursor: pointer;
        transition: all 0.5s;

        @media screen and (max-width: 767px) {
            width: 350rem;
            height: 76rem;
        }

        :hover {
            transform: scale(1.02);
        }
    }
`;

function DailyRewardsPopup() {
    const dispatch = useDispatch();

    const [visible, setVisible] = useState(false);

    const {
        isMobile,
        isTablet,
        isDesktop
    } = useSelector(state => state.common);

    const onResize = useCallback(() => {
        if ((window.innerWidth < BREAKPOINTS.MD_MIN) && !isMobile) {
            dispatch(setIsMobile())
        } else if ((window.innerWidth > BREAKPOINTS.SM_MAX) && (window.innerWidth < BREAKPOINTS.LG_MIN_CUSTOM) && !isTablet) {
            dispatch(setIsTablet())
        } else if ((window.innerWidth > BREAKPOINTS.LG_MIN_CUSTOM) && !isDesktop) {
            dispatch(setIsDesktop())
        }
    }, [dispatch, isMobile, isTablet, isDesktop]);

    useEffect(() => {
        onResize();
        window.addEventListener('onload', onResize);
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
            window.removeEventListener('onload', onResize);
        }
    }, [onResize, dispatch]);

    // handle show popup
    useEffect(() => {

    }, [])

    const handleOnClose = () => {
        setVisible(false);
    };

    return (
        <Modal
            visible={visible}
            closable={false}
            centered
            width={'960rem'}
            height={isMobile ? 'auto' : '540rem'}
            wrapClassName={'daily-rewards-popup'}
        >
            <Wrapper>
                {/* <img src={bg} alt="" className={'banner'} /> */}
                <div className={'close'} onClick={handleOnClose} />

                <div className={'content-box'}>
                    <img src={undeadLogo} alt="" className={'logo'} />

                    <img src={mainTitle} alt="" className={'main-title'} />

                    <p className={'description'}>
                        Play for free with friends and earn Standard ZBUX and use your
                        Weapon NFTs to compete in VIP Solo mode! Download <br />
                        the Undead Blocks game at Wagyu.io
                    </p>
                </div>

                <div className={'btn-container'}>
                    <div className={'close-button'} onClick={handleOnClose} />
                </div>
            </Wrapper>
        </Modal>
    )
}

export default React.memo(DailyRewardsPopup);
