import { isEmpty, get } from 'lodash';

import { INSTANCE_ITEMS } from './constants';

export const exportItems = (list) => {
    if (isEmpty(list)) return [];

    let newData = [];

    for (let i = 0; i < list.length; i++) {
        const initItem = INSTANCE_ITEMS[list[i]?.type];

        const variants = list[i].variants;
        let variant = {};

        for (let j = 0; j < variants.length; j++) {
            const key = get(initItem, `assets.${variants[j].itemSku}`, []);

            variant = {
                ...variant,
                [variants[j].itemSku]: isEmpty(key) ? [] : key[variants[j]?.level - 1]
            }
        }

        newData.push({
            ...initItem,
            ...list[i],
            assets: {
                ...initItem?.assets,
                ...variant
            },
            tokenId: get(list[i], 'tokenId', 0)
        })
    }

    return [...newData];
}

export const exportMintItem = (mintItem = {}) => {
    if (!mintItem) return {};

    const initItem = INSTANCE_ITEMS[mintItem?.type];
    const variants = mintItem?.variants || [];

    if (!variants) return;

    let variant = {};

    for (let i = 0; i < variants.length; i++) {
        const element = variants[i];
        const key = get(initItem, `assets.${element.itemSku}`, []);

        variant = {
            ...variant,
            [element.itemSku]: isEmpty(key) ? [] : key[element?.level - 1]
        }
    }

    return {
        ...initItem,
        assets: {
            ...initItem?.assets,
            ...variant
        }
    }
}

export const exportItemsNFT = (dataNFT) => {
    if (isEmpty(dataNFT)) return {};

    let newData = {};

    const initItem = INSTANCE_ITEMS[dataNFT?.type];

    const variants = dataNFT?.variants;
    let variant = {};

    for (let j = 0; j < variants.length; j++) {
        const key = get(initItem, `assets.${variants[j].itemSku}`, []);

        variant = {
            ...variant,
            [variants[j].itemSku]: isEmpty(key) ? [] : key[variants[j]?.level - 1]
        }
    }

    newData = {
        ...initItem,
        assets: {
            ...initItem?.assets,
            ...variant
        },
        tokenId: get(dataNFT, 'tokenId', 0)
    }

    return newData;
}

export const testMint = (type, a, b, c, d = 0, e, f, g) => {
    const item = INSTANCE_ITEMS[type];

    const { AKM = [], DB = [], F1 = [], Knife = [], MP5 = [], Perk = [], Pistol = [] } = item.assets;

    return {
        ...item,
        assets: {
            ...item.assets,
            AKM: a ? AKM[a] : [],
            DB: b ? DB[b] : [],
            F1: c ? F1[c] : [],
            Knife:  Knife[d],
            MP5: e ? MP5[e] : [],
            Perk: f ? Perk[f] : [],
            Pistol: g ? Pistol[g] : []
        }
    }
}