import { createActions, createAction } from "redux-actions";
import { get } from 'lodash';

import OwnershipApi from '../services/api/ownership';
import { exportItems, exportItemsNFT } from '../pages/MintWeapons/utils';

export const getAfterMinted = createAction('GET_AFTER_MINTED');


// MINT
const { signatureRequest, signatureSuccess, signatureFail } = createActions({
    SIGNATURE_REQUEST: () => {},
    SIGNATURE_SUCCESS: data => ({ data }),
    SIGNATURE_FAIL: error => ({ error }),
});

export const signature = (body) => (dispatch) => {
    dispatch(signatureRequest());

    return OwnershipApi.signature(body)
        .then(({ data }) => {
            dispatch(signatureSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(signatureFail(error));
            return error;
        });
}

const { mintRequest, mintSuccess, mintFail } = createActions({
    MINT_REQUEST: () => {},
    MINT_SUCCESS: data => ({ data }),
    MINT_FAIL: error => ({ error }),
});

export const mint = (body) => (dispatch) => {
    dispatch(mintRequest());

    return OwnershipApi.minted(body)
        .then(({ data }) => {
            dispatch(mintSuccess(data));
            dispatch(getAfterMinted(new Date().getTime()));
            return data;
        })
        .catch(error => {
            dispatch(mintFail(error));
            return error;
        });
}

// REBOOT MINT
const { rebootSignatureRequest, rebootSignatureSuccess, rebootSignatureFail } = createActions({
    REBOOT_SIGNATURE_REQUEST: () => {},
    REBOOT_SIGNATURE_SUCCESS: data => ({ data }),
    REBOOT_SIGNATURE_FAIL: error => ({ error }),
});

export const rebootSignature = (body) => (dispatch) => {
    dispatch(rebootSignatureRequest());

    return OwnershipApi.rebootSignature(body)
        .then(({ data }) => {
            dispatch(rebootSignatureSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(rebootSignatureFail(error));
            return error;
        });
}

const { rebootMintRequest, rebootMintSuccess, rebootMintFail } = createActions({
    REBOOT_MINT_REQUEST: () => {},
    REBOOT_MINT_SUCCESS: data => ({ data }),
    REBOOT_MINT_FAIL: error => ({ error }),
});

export const rebootMint = (body) => (dispatch) => {
    dispatch(rebootMintRequest());

    return OwnershipApi.rebootMinted(body)
        .then(({ data }) => {
            dispatch(rebootMintSuccess(data));
            dispatch(getAfterMinted(new Date().getTime()));
            return data;
        })
        .catch(error => {
            dispatch(rebootMintFail(error));
            return error;
        });
}

// -----
const { fetchOwnershipsRequest, fetchOwnershipsSuccess, fetchOwnershipsFail } = createActions({
    FETCH_OWNERSHIPS_REQUEST: () => {},
    FETCH_OWNERSHIPS_SUCCESS: data => ({ data }),
    FETCH_OWNERSHIPS_FAIL: error => ({ error }),
});

export const fetchOwnerships = (weaponType, address, limit = 20, skip = 0) => (dispatch) => {
    dispatch(fetchOwnershipsRequest());

    return OwnershipApi.ownerships({ weaponType, address, limit, skip })
        .then(({ data }) => {
            dispatch(fetchOwnershipsSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(fetchOwnershipsFail(error));
            return error;
        });
}


export const {
    fetchNftDetailRequest,
    fetchNftDetailSuccess,
    fetchNftDetailFail,
    fetchNftDetailClean
} = createActions({
    FETCH_NFT_DETAIL_REQUEST: () => { },
    FETCH_NFT_DETAIL_SUCCESS: data => ({ data }),
    FETCH_NFT_DETAIL_FAIL: error => ({ error }),
    FETCH_NFT_DETAIL_CLEAN: () => { }
});
export const fetchNFTDetail = (contractAddress, tokenId) => async (dispatch, getState) => {
    const { nftDetail } = getState().ownership;

    // Prevent double call while requesting
    if (nftDetail.requesting) {
        return Promise.resolve()
    }
    dispatch(fetchNftDetailRequest())

    return OwnershipApi.getNFTDeail(contractAddress, tokenId)
        .then(({ data }) => {
            dispatch(fetchNftDetailClean())

            const resultFinal = {
                ...data?.data,
                itemSku: exportItemsNFT(data?.data),
                title: `${get(data, 'data.package.name', '')}`
                // title: `${get(data, 'data.package.name', '')} #${get(data, 'data.tokenId', '')}`
            }
            dispatch(fetchNftDetailSuccess({ data: resultFinal }));
            return data;
        })
        .catch(error => {
            dispatch(fetchNftDetailFail(error))
        })
}

export const {
    fetchMyWeaponsRequest,
    fetchMyWeaponsSuccess,
    fetchMyWeaponsFail,
    fetchMyWeaponsClean
} = createActions({
    FETCH_MY_WEAPONS_REQUEST: () => { },
    FETCH_MY_WEAPONS_SUCCESS: data => ({ data }),
    FETCH_MY_WEAPONS_FAIL: error => ({ error }),
    FETCH_MY_WEAPONS_CLEAN: () => { }
});
export const fetchMyWeapons = (account) => async (dispatch, getState) => {
    const { myWeapons } = getState().ownership;

    // Prevent double call while requesting
    if (myWeapons.requesting) {
        return Promise.resolve()
    }
    dispatch(fetchMyWeaponsRequest())
    return OwnershipApi.getMyListWeapons(account)
        .then(({ data }) => {
            dispatch(fetchMyWeaponsClean())

            const resultFinal = {
                ...data,
                result: exportItems(data.data),
            }
            dispatch(fetchMyWeaponsSuccess(resultFinal));
            return data;
        })
        .catch(error => {
            dispatch(fetchMyWeaponsFail(error))
        })
}