import { useMemo } from 'react'
import { getStakingContract, getTokenContract } from '../utils/contractHelpers'
import useWeb3 from './useWeb3'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useStakingContract = (id, stakingPoolsData) => {
  const web3 = useWeb3()
  return useMemo(() => getStakingContract(id, stakingPoolsData, web3), [id, stakingPoolsData, web3])
}

export const useTokenContract = (address) => {
  const web3 = useWeb3()
  return useMemo(() => getTokenContract(address, web3), [address, web3])
}
