import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'

// import close from '../img/close.png';
import left from '../img/left-step3.png';
import bottomRight from '../img/bottom-right.png';
import button from '../img/button.png';
import view from '../img/view.png';
import loadingData from '../../../lotties/loading.json'

import Animations from '../../../components/Animations';

const antIcon = <LoadingOutlined style={{ fontSize: 17, color: 'white', fontWeight: 'bold' }} spin />;

const Wrapper = styled.div`
    padding-bottom: 30rem;

    @media (max-width: 767px) {
        padding-bottom: 60rem;
    }

    .layer-bottom {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 104rem;
        height: 125.5rem;
        border-top-left-radius: 12rem;

        @media (max-width: 767px) {
            width: 210rem;
            height: 250rem;
            border-top-left-radius: 24rem;
        }
    }

    .header-nav {
        display: flex;
        justify-content: flex-end;
        position: relative;

        padding-bottom: 33rem;

        @media (max-width: 767px) {
            padding-bottom: 79rem;
        }

        .layer {
            position: absolute;
            left: 0;
            top: 0;
            width: 108.5rem;
            height: 124.5rem;
            border-top-left-radius: 12rem;

            @media (max-width: 767px) {
                border-top-left-radius: 24rem;
                width: 220rem;
                height: 250rem;
            }
        }

        .close {
            position: absolute;
            width: 22.5rem;
            height: 22.5rem;
            margin: 15rem;
            cursor: pointer;

            &:hover {
                transform: scale(1.005);
                transition: all 1s;
            }

            @media (max-width: 767px) {
                top: 15rem;
                right: 20rem;
                width: 55rem;
                height: 55rem;
            }
        }
    }

    .mint-image {
        display: flex;
        justify-content: center;
        margin-bottom: 8rem;

        .loading {
            width: 118rem;
            height: 118rem;

            @media screen and (max-width: 767px) {
                width: 268rem;
                height: 268rem;
            }
        }

        @media (max-width: 767px) {
            margin-bottom: 25rem;
        }
    }

    .mint-info {
        position: relative;
        z-index: 50;
        padding: 0 33rem;
        padding-bottom: 15rem;

        h2 {
            font-family: Poppins;
            font-size: 22.5rem;
            font-weight: bold;
            line-height: 1.89;
            text-align: center;
            color: #fff;
            margin-bottom: 0;

            @media (max-width: 767px) {
                font-size: 52.5rem;
                margin: 0 0 20rem 0;
            }
        }

        p {
            font-family: Poppins;
            font-size: 12.5rem;
            line-height: 1.6;
            text-align: center;
            color: #fff;

            @media (max-width: 767px) {
                font-size: 30.5rem;
            }
        }
    }

    .btn-container {
        margin: 0 30rem;

        &:active {
            transform: scale(0.9);
            transition: all 0.2s;
        }

        @media (max-width: 767px) {
            margin: 0 60rem;
        }
        
        .mint-btn {
            height: 40rem;
            border-radius: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            @media (max-width: 767px) {
                border-radius: 5rem;
                height: 90rem;
            }

            img {
                margin-left: 5rem;
                width: 12rem;

                @media (max-width: 767px) {
                    margin-left: 10rem;
                    width: 24rem;
                }
            }

            span {
                font-family: Poppins;
                font-size: 15rem;
                font-weight: 600;
                line-height: 1.33;
                text-align: center;
                color: #fff;

                @media (max-width: 767px) {
                    font-size: 36rem;
                }
            }
        }
    }
`;

function Step3(props) {
    const { transactionHash, viewTransaction } = props;

    return (
        <Wrapper>
            <div className="header-nav">
                <img src={left} alt="left" className="layer" />
                {/* <img src={close} alt="close" onClick={() => {}} className="close" /> */}
            </div>

            <div className="mint-image">
                <div className="loading">
                    <Animations animationData={loadingData} />
                </div>
            </div>

            <div className="mint-info">
                <h2>Minting Weapon Loadout</h2>
                <p>Please wait while we mint your weapon NFTs. This process can take a couple minutes.</p>
            </div>

            <div className="btn-container">
                <div
                    onClick={() => viewTransaction()}
                    className="mint-btn"
                    style={{
                        background: `url(${button}) no-repeat center center / cover`
                    }}
                >
                    {transactionHash ? (
                        <>
                            <span>View Transaction</span>
                            <img src={view} alt="view" />
                        </>
                    ) : (
                        <Spin indicator={antIcon} />
                    )}
                </div>
            </div>
            <img src={bottomRight} alt="left" className="layer-bottom" />
        </Wrapper>
    );
}

export default Step3;
