import poolABI from '../config/abi/pool.json'
import erc20ABI from '../config/abi/erc20.json'

const getContract = (abi, address, web3) => {
  if (web3 && web3.eth) {
    return new web3.eth.Contract(abi, address)
  }
  return null
};

export const getStakingContract = (id, stakingPoolsData, web3) => {
  const config = stakingPoolsData && stakingPoolsData.find((pool) => pool.id === id);
  if (!config) {
    return null;
  }
  return getContract(poolABI, config.stakeContractAddress, web3)
};

export const getTokenContract = (coinAddress, web3) => {
  return getContract(erc20ABI, coinAddress, web3)
};
