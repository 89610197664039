import { useState, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setEndCountDown } from '../../actions/common';

function useCountDown(props) {
    const dispatch = useDispatch();

    const { timeStamp } = props;

    const [timer, setTimer] = useState({});

    const countDownDate = new Date(timeStamp).getTime();

    useLayoutEffect(() => {
        if (!timeStamp || timeStamp < Date.now()) {
            return;
        }

        const interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countDownDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            setTimer({ days, hours, minutes, seconds });

            if (distance < 0) {
                clearInterval(interval);
                return dispatch(setEndCountDown(true));
            }

            return dispatch(setEndCountDown(false));
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, [dispatch, countDownDate, timeStamp]);

    return { timer }
}

export default useCountDown;
