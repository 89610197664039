import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import leftLayer from './img/left-test.png';
import rightLayer from './img/right.png';
import mintNone from './img/plus.png';
// import minted from './img/mintedBg.png';
import noneBg from './img/none-bg.svg';
// import redSea from './img/red-sea.png';
// import whiteSea from './img/white-sea.png';

// import openSeaLogo from '../../assets/opensea.png';
import rentLogo from '../../assets/rent.png';
import aquaRedIcon from '../../assets/banner-header/aqua_red.png';
import aquaBlackIcon from '../../assets/banner-header/aqua_black.png';
import aquaWhiteIcon from '../../assets/banner-header/aqua_white.png';
import { ReactComponent as ViewImage } from '../../assets/svg/view.svg';
import buttonBg from '../WhiteList/img/button-bg.png';
import loadingData from '../../lotties/loading.json';

import Variant from './Variant';
import ItemSkin from './ItemSkin';
import RenInfoModal from './RenInfoModal';
import { WEAPON_BENEFITS } from './constants';
import Nav from '../Home/LandingPageHeader/Header';
import Animations from '../../components/Animations';

import useMyWeapon from './useMyWeapon';
import { WEAPON_SKINS } from '../../utils/constants';

import {
    getExplorerUrl,
    getApologyContractUrl,
    // getOpenSeaUrlByAddress,
    getApologyContractAddress,
    // getContractUndeadNFT,
    splitAddress
} from '../../utils/common';
import {
    setIsMobile,
    setIsTablet,
    setIsDesktop,
} from '../../actions/common';

import Wrapper, {
    BubbleLayer,
    WeaponContent,
    MyWeaponsWrapper,
    NftDetailStyle,
    NftInfoStyle,
    NftListItem,
    ViewAddress,
    ButtonRedStyle,
    AddessOwnershipStyle,
    BenefitItem
} from './styles';
import { isEmpty } from 'lodash';

const BREAKPOINTS = {
    SM_MAX: 767,
    MD_MIN: 768,
    MD_MAX: 1023,
    LG_MIN: 1024,
    LG_MIN_CUSTOM: 1279
};

function MyWeapons(props) {
    const dispatch = useDispatch();

    const [visibleRentInfo, setVisibleRentInfo] = useState(false); // eslint-disable-next-line
    const [offset, setOffset] = useState(0);

    const {
        isMobile,
        isTablet,
        isDesktop,
        isEndCountdown
    } = useSelector(state => state.common);

    const isConnected = useSelector(state => state.user.connectWallet.isConnect);
    const account = useSelector(state => (state.user.userAccount.accounts ? state.user.userAccount.accounts[0] : ''))
    const chainId = useSelector(state => state.user.chainId);
    const [weaponItem, setWeaponItem] = useState({});

    const listLoading = useSelector(state => state.ownership?.ownershipsRes?.requesting);
    const { loadingConnectAccount } = useSelector(state => state.user ?? false);

    const { weaponList, hasMore, firstLoad } = useMyWeapon({ offset, isConnected });

    // LOAD MORE
    const observer = useRef();
    const lastElement = useCallback(node => {
        if (listLoading) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && hasMore) {
                    setOffset(prevState => prevState + 10);
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        });

        if (node) observer.current.observe(node);
    }, [listLoading, hasMore]);

    const onResize = useCallback(() => {
        if ((window.innerWidth < BREAKPOINTS.MD_MIN) && !isMobile) {
            dispatch(setIsMobile())
        } else if ((window.innerWidth > BREAKPOINTS.SM_MAX) && (window.innerWidth < BREAKPOINTS.LG_MIN_CUSTOM) && !isTablet) {
            dispatch(setIsTablet())
        } else if ((window.innerWidth > BREAKPOINTS.LG_MIN_CUSTOM) && !isDesktop) {
            dispatch(setIsDesktop())
        }
    }, [dispatch, isMobile, isTablet, isDesktop]);


    useEffect(() => {
        onResize();
        window.addEventListener('onload', onResize);
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
            window.removeEventListener('onload', onResize);
        }
    }, [onResize, dispatch]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        loadingConnectAccount && setOffset(0);
    }, [loadingConnectAccount]);

    useEffect(() => {
        if (!weaponList) return;

        weaponList && setWeaponItem(weaponList[0])
    }, [firstLoad, weaponList, account]);

    const renderBubbleLayer = () => (
        <BubbleLayer>
            <section className="bubble">
                <img src={leftLayer} alt="left" className="left" />
                <div className="left-bubble" />
            </section>
            <section className="bubble">
                <img src={rightLayer} alt="right" className="right" />
                <div className="right-bubble" />
            </section>
        </BubbleLayer>
    );

    const renderMyWeapons = () => {
        return (
            <MyWeaponsWrapper>
                <h2 className="title">My Weapons</h2>
                <p className="description">Weapon Loadouts found in your connected wallet will appear here</p>

                <div className="mint-list">
                    {firstLoad ? (
                        new Array(isMobile ? 2 : 3).fill({}).map((e, i) => (
                            <div
                                key={i}
                                className="loading"
                            >
                                <div className="heading-loading" />

                                <div className="content-loading" />

                                <div className="footer-loading">
                                    <section className="left-loading" />
                                    <section className="right-loading" />
                                </div>
                            </div>
                        ))
                    ) : (
                        !isEmpty(weaponList) && [...weaponList, {}, {}].map((e, i) => (
                            <div
                                key={i}
                                className={cn('mint-item', {
                                    'nope': !e.id,
                                    'border': e.id
                                })}
                                // style={{ border: 'solid 2rem #202429' }}
                                ref={weaponList.length === i + 1 ? lastElement : null}
                            >
                                {!e.id ? (
                                    <div className="no-content">
                                        <img
                                            src={noneBg}
                                            style={{ width: '100%' }}
                                            alt="border"
                                        />
                                        <img
                                            src={mintNone}
                                            alt="none"
                                            className="none"
                                            onClick={() => handleOnTop()}
                                        />
                                    </div>
                                ) : (
                                    <WeaponContent
                                        style={{ background: `url(${e.thumbnail}) no-repeat center center / cover` }}
                                        title={e?.package?.description}
                                        fontSizeWeb={e.fontSize}
                                        fontSizeMobile={e.fontSizeMobile}
                                        onClick={() => onViewDetail(e)}
                                    >
                                        {/* <div className="top">
                                            <h2 className="heading">{e?.title}</h2>
                                        </div> */}
        
                                        {/* <div className="info">
                                            <Variant item={e} />
                                        </div> */}
        
                                        {/* <div className="bottom">
                                            <p className="detail">
                                                {e.description}
                                            </p>
                                            <div style={{ background: `url(${minted}) no-repeat center center / cover` }}
                                                className="view-detail-btn"
                                                onClick={() => onViewDetail(e)}
                                            >
                                                <span className="text-btn">View Detail</span>
                                            </div>
                                        </div> */}
                                    </WeaponContent>
                                )}
                            </div>
                        ))
                    )}
                </div>

                {!firstLoad && listLoading && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '80rem'
                        }}
                    >
                        <div className="loading-more">
                            <Animations animationData={loadingData} />
                        </div>
                    </div>
                )}
            </MyWeaponsWrapper>
        )
    }

    const handleOnTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    const onViewDetail = (e) => {
        setWeaponItem(e);
        handleOnTop();
    }

    return (
        <Wrapper>
            <Nav isStaking={isEndCountdown} />
            {renderBubbleLayer()}

            {/* NFT Detail */}
            <NFTDetail
                {...props}
                {...{ nftDetail: weaponItem, chainId, setVisibleRentInfo, isMobile, account, loading: firstLoad, isConnected }}
            />

            {/* My weapons */}
            {renderMyWeapons()}

            <RenInfoModal
                isVisible={visibleRentInfo}
                onClose={() => setVisibleRentInfo(false)}
                nftDetail={weaponItem}
                isMobile={isMobile}
            />
        </Wrapper>
    );
}

const NFTDetail = (props) => {
    const {
        nftDetail = {},
        // setVisibleRentInfo,
        isMobile,
        account,
        loading,
        isConnected
    } = props;

    const viewBscscan = (contractNFT) => {
        let url;

        if (isConnected) {
            url = `${getExplorerUrl(process.env.REACT_APP_CHAIN_ID)}/token/${contractNFT}`;
        } else {
            url = getApologyContractUrl();
        }

        window.open(url, '_blank')
    }

    // const viewOpenSea = (add, tokenId) => {
    //     const url = `${getOpenSeaUrlByAddress(process.env.REACT_APP_CHAIN_ID, add)}/${tokenId}`

    //     window.open(url, '_blank')
    // }

    return (
        loading ? (
            <NftDetailStyle>
                <NftInfoStyle>
                    <div className="loading">
                        <div className="heading-loading" />

                        <div className="content-loading" />

                        <div className="footer-loading" />
                    </div>
                </NftInfoStyle>

                <NftListItem>
                    <div className="loading">
                        <div className="heading-loading">
                            <div className="heading" />
                        </div>

                        <div className="content-loading">
                            <div className="primary" />
                            <div className="item" />
                        </div>

                        <div className="footer-loading">
                            <div className="list-button">
                                <div className="parent" />
                                <div className="parent" />
                            </div>
                            <div className="address" />
                        </div>
                    </div>
                </NftListItem>
            </NftDetailStyle>
        )  : (
            nftDetail?.title ? (
                <NftDetailStyle>
                    <NftInfoStyle>
                        <div className="top"></div>

                        <div className="info">
                            <Variant item={nftDetail} />
                        </div>

                        <div className="bottom">
                            <h2 className="heading">{nftDetail?.title}</h2>
                        </div>
                    </NftInfoStyle>

                    <NftListItem>
                        <div className="nft-detail">
                            <div className="top">
                                <h2 className="heading">{nftDetail?.package?.name} Loadout</h2>
                                <div className="primary">Contract Address</div>
                                <div className="transaction-hash-group">
                                    <div className="transaction-hash">{nftDetail?.contractAddress}</div>
                                    <ViewAddress onClick={() => viewBscscan(nftDetail?.contractAddress)}>
                                        <ViewImage />
                                    </ViewAddress>
                                </div>
                            </div>

                            <div className="info">
                                <div className="primary-bottom">Skin Attributes</div>

                                <div className="list-item-skin">
                                    {(nftDetail?.variants || []).filter(item => WEAPON_SKINS.includes((item?.itemSku || '').toLowerCase()))
                                        .map((item, index) =>
                                            <ItemSkin key={index} item={item} />
                                        )}
                                </div>
                            </div>

                            <div className="bottom">
                                <ButtonRedStyle>
                                    <div
                                        className="parent rent-btn"
                                        onClick={() => {
                                            // setVisibleRentInfo(true)
                                            window.open(`https://www.blumint.com/item/eth/${nftDetail?.contractAddress}/${nftDetail?.tokenId}`)
                                        }}
                                        style={{
                                            background: `url(${buttonBg}) no-repeat center / 100% 100%`
                                        }}
                                    >
                                        <img src={rentLogo} alt="guns" className="guns" />
                                        <span>Rent</span>
                                    </div>
                                    <div
                                        className="parent open-btn"
                                        style={{
                                            background: `url(${buttonBg}) no-repeat center / 100% 100%`
                                        }}
                                        // onClick={() => viewOpenSea(nftDetail?.contractAddress, nftDetail?.tokenId)}
                                        onClick={() => window.open('https://aqua.xyz/ub/search/', '_blank')}
                                    >
                                        <img src={aquaBlackIcon} alt="opensea" className="opensea" />
                                        <span>AQUA</span>
                                    </div>
                                </ButtonRedStyle>
                            </div>

                            <AddessOwnershipStyle>
                                <span className='address-icon'>
                                    {/* <img className='addess-image' src={WalletIcon} alt='' /> */}
                                    {/* <div ref={iconContainerRef} className={'address-image'} /> */}
                                    <Jazzicon paperStyles={{ maxWidth: '100%', maxHeight: '100%' }} d
                                        iameter={isMobile ? 11 : 14} seed={jsNumberForAddress(account || '')} />
                                </span>
                                <div className='label-address'>Owner:</div>
                                <div className='address'>{splitAddress(nftDetail?.address)}</div>
                            </AddessOwnershipStyle>
                        </div>
                    </NftListItem>
                </NftDetailStyle>
            ) : (
                <NftDetailStyle>
                    <NftInfoStyle>
                        <div className="top"></div>

                        <div className="info">
                            <Variant item={nftDetail} />
                        </div>

                        <div
                            className={cn('bottom', {
                                'no-item': !nftDetail.title
                            })}
                        >
                            <span className="no-heading-item">Buy on</span>
                            <img src={aquaRedIcon} alt="" className="red-sea" />
                            <span className="no-heading-item">AQUA</span>
                        </div>
                    </NftInfoStyle>

                    <NftListItem className={cn('', {
                        'no-item': !nftDetail?.title
                    })}>
                        <div className="nft-detail">
                            <div className="top">
                                <div className="heading-top">
                                    <span className="no-heading-item">Available on</span>
                                    <img src={aquaWhiteIcon} alt="" className="white-sea" />
                                    <span className="no-heading-item">AQUA</span>
                                </div>

                                <div className="primary">Contract Address</div>

                                <div className="transaction-hash-group">
                                    <div className="transaction-hash">{getApologyContractAddress()}</div>
                                    <ViewAddress onClick={() => viewBscscan(nftDetail?.contractAddress)}>
                                        <ViewImage />
                                    </ViewAddress>
                                </div>
                            </div>

                            <div className={cn('info', {
                                'no-item': !nftDetail?.title
                            })}>
                                <div className="primary-bottom">Weapon Benefits</div>

                                <div className="list-benefit">
                                    {WEAPON_BENEFITS.map((e, i) => (
                                        <BenefitItem id={i} key={i}>
                                            <img src={e.icon} alt="icon" />

                                            <section className="benefit-info">{e.label}</section>
                                        </BenefitItem>
                                    ))}
                                </div>
                            </div>

                            <div className="bottom">
                                <ButtonRedStyle>
                                    <div
                                        className="parent open-btn"
                                        style={{
                                            background: `url(${buttonBg}) no-repeat center / 100% 100%`
                                        }}
                                        onClick={() => {
                                            // window.open(process.env.REACT_APP_UNDEAD_BLOCK_OPEASEA, '_blank')
                                            window.open('https://aqua.xyz/ub/search/', '_blank')
                                        }}
                                    >
                                        <img src={aquaBlackIcon} alt="opensea" className="opensea" />
                                        <span>AQUA</span>
                                    </div>

                                    <div
                                        className="parent rent-btn"
                                        onClick={() => {
                                            // setVisibleRentInfo(true)
                                            window.open(`https://www.blumint.com`)
                                        }}
                                        style={{
                                            background: `url(${buttonBg}) no-repeat center / 100% 100%`
                                        }}
                                    >
                                        <img src={rentLogo} alt="guns" className="guns" />
                                        <span>Rent</span>
                                    </div>
                                </ButtonRedStyle>
                            </div>
                        </div>
                    </NftListItem>
                </NftDetailStyle>
            )
        )
    )
}

export default MyWeapons;
