import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';
import BGSection from './img/wlbg.png';

const Wrapper = styled.div`
    background: url(${BGSection}), #000000;
    background-size: contain;
    background-repeat: no-repeat;
    @media screen and (max-width: 767px) {
      background: #000000;
    }
    height: 100%;
    width: 100%;
`

export const HeaderBanner = styled.div`
    padding: 200px 30px 50px 30px;

    position: relative;

    @media screen and (max-width: 767px) {
        padding: 120px 0 0 0;
        position: static;
    }

    .heading {
        position: absolute;
        left: 50%;
        top: 150px;
        transform: translate(-50%);

        @media screen and (max-width: 849px) {
            position: static;
            transform: unset;

            padding: 0 20px;
        }

        h2 {
            font-family: Poppins;
            font-size: 32.5px;
            font-weight: bold;
            line-height: 1.31;
            text-align: center;
            color: #fff;

            @media screen and (max-width: 767px) {
                font-size: 21px;
            }
        }
    }

    .image-layout {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        @media screen and (min-width: 476px) and (max-width: 767px) {
            display: grid;
            position: relative;
            height: 600px;
            overflow: hidden;
        }

        @media screen and (max-width: 475px) {
            display: grid;
            position: relative;
            height: 400px;
            overflow: hidden;
        }

        .overlay {
            position: absolute;
            right: 0;
            bottom: 0;

            background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.9));
            height: 50%;
            width: 67%;

            @media screen and (max-width: 767px) {
                height: 60%;
                width: 200%;
            }
        }

        .with-overlay {
            position: relative;

            .left-header {
                width: 100%;
                height: 100%;
            }

            @media screen and (max-width: 767px) {
                .left-header {
                    position: absolute;
                    left: -110%;
                    width: 250%;
                    height: auto;
                }
            }
        }

        .without-overlay {
            display: flex;
            align-items: center;
            justify-content: center;

            .center-header {
                width: 88%;

                @media screen and (max-width: 767px) {
                    position: absolute;
                    z-index: 10;
                    width: 58%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        .with-overlay {
            position: relative;

            .right-header {
                width: 100%;
                height: auto;
            }

            @media screen and (max-width: 767px) {
                .right-header {
                    position: absolute;
                    right: -65%;
                    width: 250%;
                    height: auto;
                }
            }
        }
    }

    .bottom {
        position: absolute;
        bottom: 0;
        padding: 0 17%;

        @media screen and (max-width: 767px) {
            position: static;
            transform: unset;

            padding: 0 33px;
        }

        h2 {
            font-family: Poppins;
            font-size: 18px;
            line-height: 1.67;
            text-align: center;
            color: #fff;

            @media screen and (max-width: 849px) {
                font-size: 12px;
            }

            @media screen and (min-width: 1675px) {
                font-size: 28px;
            }
        }
    }
`;

export const Document = styled.div`
    background-image: radial-gradient(circle at 0 0, #d20000, #af0000);
    padding: 10px 26.5px 10px 10px;
    width: 75%;
    margin: 50px auto 0px auto;

    border-radius: 7.5px;
    box-shadow: 0px 3.5px 50px 0 rgba(0, 0, 0, 0.5);


    @media screen and (max-width: 767px) {
        padding: 20px 10px;
        width: 90%;
    }

    .box {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 8fr 1fr;
        grid-gap: 20px;

        @media screen and (max-width: 767px) {
            grid-template-columns: 30% 70%;

            grid-gap: 0px;
        }

        .left {
            width: 100%;

            @media screen and (max-width: 767px) {
                width: 85px;
                grid-row: 1 / 3;
            }
        }

        .center {
            h2 {
                font-family: Poppins;
                font-size: 17.5px;
                font-weight: bold;
                line-height: 1;
                text-align: left;
                color: #fff;
                text-transform: uppercase;

                @media screen and (max-width: 767px) {
                    font-size: 13px;
                    line-height: 1.5;
                }
            }

            p {
                font-family: Poppins;
                font-size: 15px;
                line-height: 1.35;
                text-align: left;
                color: #fff;
                margin-bottom: 0;

                @media screen and (max-width: 767px) {
                    font-size: 13px;
                    line-height: 1.2;
                }
            }
        }

        .read-btn {
            background-color: #5e0000;
            padding: 0px 22px;
            width: 150px;
            height: 40px;

            display: flex;
            justify-content: center;
            align-items: center;

            cursor: pointer;

            &:hover {
                transform: scale(1.005);
                transition: all 1s;
            }

            @media screen and (max-width: 767px) {
                width: 130px;
                height: 28px;

                margin-top: 10px;
                grid-column-start: 2;
                grid-row-end: 3;
            }

            p {
                font-family: Poppins;
                font-size: 15px;
                font-weight: 600;
                line-height: 1.73;
                text-align: center;
                color: #fff;

                margin: 0 5px 0 0;

                @media screen and (max-width: 767px) {
                    font-size: 11px;
                }
            }

            img {
                width: 8px;

                @media screen and (max-width: 767px) {
                    width: 5px;
                }
            }
        }
    }
`;

export const WhiteListWay = styled.div`
    width: 75%;
    margin: 75px auto;

    @media screen and (max-width: 767px) {
        width: 100%;
    }

    .box {
        h2 {
            font-family: Poppins;
            font-size: 32.5px;
            font-weight: bold;
            line-height: 1.2;
            text-align: center;
            color: #fff;

            margin-bottom: 0;

            @media screen and (max-width: 767px) {
                font-size: 22px;
                margin-bottom: 0;
            }
        }

        .list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 30px;

            margin-top: 32px;

            @media screen and (max-width: 767px) {
                grid-template-columns: 1fr;
                padding: 0 50px;
            }

            @media screen and (min-width: 768px) and (max-width: 1023px) {
                grid-template-columns: 1fr;
            }

            @media screen and (min-width: 1600px) {
                grid-gap: 50px;
            }

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;

                padding: 24px 10px 21px 10px;
                border-radius: 10px;

                .top-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .title {
                        object-fit: contain;
                        background-image: linear-gradient(to top, #f00, #af0000), linear-gradient(to bottom, #df1e33, #df1e33);
                        font-family: Poppins;
                        font-size: 20px;
                        font-weight: bold;
                        line-height: 1.2;
                        text-align: center;
                        color: #df1e33;
                        -webkit-background-clip: text;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                        text-transform: uppercase;

                        margin-bottom: 10px;
                    }

                    img {
                        // width: 50%;
                        height: 150px;

                        @media screen and (min-width: 768px) and (max-width: 1023px) {
                            width: 80%;
                            height: 300px;
                            object-fit: contain;
                        }

                        // @media screen and (min-width: 1600px) {
                        //     width: 80%;
                        //     height: 300px;
                        //     object-fit: contain;
                        // }
                    }
                }

                .content {
                    margin-top: 10px;
                    flex: 1;

                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 0 20px;

                    .value {
                        font-family: Poppins;
                        font-size: 20px;
                        font-weight: bold;
                        line-height: 1.5;
                        text-align: center;
                        color: #fff;
                        margin-bottom: 5px;

                        text-shadow: 1px 0px 1.5px #fff;
                        text-transform: uppercase;
                        object-fit: contain;
                    }

                    .description {
                        font-family: Poppins;
                        font-size: 13px;
                        font-style: normal;
                        line-height: 1.62;
                        text-align: center;
                        color: #fff;

                        @media screen and (min-width: 1600px) {
                            font-size: 15px;
                        }
                    }

                    .parent {
                        display: flex;
                        justify-content: center;
                        width: 135px;
                        margin: 10px auto 0 auto;

                        box-shadow: 3px 0px 7px 2px #5e0000;
                        background-image: radial-gradient(#5e0000, #5e0000);

                        @media screen and (max-width: 767px) {
                            box-shadow: 3px 0px 10px 2px #5e0000;
                        }

                        .apply-btn {
                            padding: 0px 0px;
                            cursor: pointer;

                            width: 100%;
                            height: 37px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &:hover {
                                transform: scale(1.005);
                                transition: all 1s;
                            }

                            span {
                                font-family: Poppins;
                                font-size: 20px;
                                font-weight: 600;
                                line-height: 1;
                                text-align: center;
                                color: #000;
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const YoutubeWrapper = styled.div`
    width: 60%;
    height: calc(100% / 2);

    margin: 0 auto 0px auto;

    @media screen and (max-width: 767px) {
        padding: 0 30px;

        width: 100%;
        height: 100%;
    }

    iframe {
        width: 100%;
        height: 500px;

        @media screen and (max-width: 767px) {
            width: 100%;
            height: 200px;
        }

        @media screen and (min-width: 1600px) {
            height: 600px;
        }
    }
`

export default WithDirection(Wrapper)