import Web3 from 'web3';
import { get } from 'lodash';
import Logger from 'js-logger';

import { CONTRACT_TIMEOUT, ERROR_CODE_TIME_OUT, MESSAGE_TIMEOUT_MINT } from './constants';

const methods = ['buyAmateur', 'buySurvivor', 'buyAssassin', 'buyZombieKiller'];

export async function buy(instancePresale, type, params, signature, price, walletAddress, callbacks) {
    const method = methods[type];

    return instancePresale
      .methods[method](params.from, params.to, params.max, params.wl, params.timestamp, signature)
      .send({ from: walletAddress, value: Web3.utils.toWei(price + '', 'ether')})
      .on('sent', (sent) => {
        Logger.log(`sent ${sent}`);
        // callbacks.onSending();
      })
      .on('error', (error) => {
        Logger.log(`minted error ${error}`);
        callbacks.onError({...error, description: error.message });

        return error;
      })
      .on('transactionHash', async (hash) => {
        await callbacks.onTransactionHash(hash);

        waitForReceipt(hash, callbacks.onReceipt, 0);
      })
      .on('receipt', (receipt) => {
        return receipt;
      })
    ;
}

export async function rebootBuy(instancePresale, type, params, signature, walletAddress, callbacks) {
  return instancePresale.methods
    .buy(
      type,
      params.from,
      params.to,
      params.max,
      params.isFree,
      params.timestamp,
      signature
    )
    .send({ from: walletAddress })
    .on('sent', (sent) => {
      Logger.log(`sent ${sent}`);
    })
    .on('error', (error) => {
      Logger.log(`minted error ${error}`);
      callbacks.onError({...error, description: error.message });

      return error;
    })
    .on('transactionHash', async (hash) => {
      await callbacks.onTransactionHash(hash);

      waitForReceipt(hash, callbacks.onReceipt, 0);
    })
    .on('receipt', (receipt) => {
      return receipt;
    })
  ;
}

// export const approveToken = (instanceContract, senderAddress, account, cb = () => { }, cbLog = () => { }, cbProcessing = () => { }, amount = ethers.constants.MaxUint256.toString()) => {
//   return instanceContract.approve(senderAddress, amount).send({ from: account })
//     .on('error', function (error) {
//       Logger.log(`approve error ${error}`);
//       cb(null, { ...error, description: error.message });
//     })
//     .on('transactionHash', (tx) => {
//       waitForReceipt(tx, cb, 0, cbLog, cbProcessing);
//       return tx.transactionHash;
//     })
// }

export const approve = (contract, spenderAddress, address, amount, cb = () => {}) => {
  return contract.methods.approve(spenderAddress, amount).send({ from: address })
      .on('error', function (error) {
          Logger.log(`approve error ${error}`);
          return error;
      })
      .on('transactionHash', (tx) => {
          waitForReceipt(tx, cb, 0);
          return tx.transactionHash;
      })
}

export const waitForReceipt = (hash, cb, timeout, cbLog = () => { }) => {
  return window.web3 ? window.web3.eth.getTransactionReceipt(hash, function (error, receipt) {
    if (timeout >= (CONTRACT_TIMEOUT * 60 * 1000)) {
      return cb({ code: ERROR_CODE_TIME_OUT, description: MESSAGE_TIMEOUT_MINT });
    }
    if (error) {
      Logger.log(`waitForReceipt ${error}`);
      cb({ ...error, description: error.message });
    }
    if (receipt !== null) {
      Logger.log(`waitForReceipt receipt ${receipt} ${error}`);
      const status = get(receipt, 'status', false);
      // Transaction went through
      if (cb) {
        if (status) {
          cb(receipt);
          cbLog(receipt)
        } else {
          cb(null, null);
        }
      }
    } else {
      // Try again in 1 second
      window.setTimeout(function () {
        waitForReceipt(hash, cb, (timeout + 1000), cbLog);
      }, 1000);
    }
  }) : '';
}
