import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import item0 from '../assets/img/animoca.png';
import item1 from '../assets/img/item1.png'
import item2 from '../assets/img/item2.png'
import item3 from '../assets/img/item3.png'
import phone from '../assets/img/phone.png'

const ContentSeven = styled.div`
  background-color: #111114;
  color: #fff;
  width: 100%;
  height: 100%;
  padding: 80px 60px 0 60px;
  @media screen and (min-width: 1920px) {
    padding: 80px;
  }
  @media screen and (min-width: 2560px) {
    padding: 80px 110px;
  }
  @media screen and (max-width: 768px) {
    padding: 50px 30px 15px 30px;
  }
`
const Content = styled.div`
  padding-bottom: 90px;
`
const TitleOne = styled.div`
  color: white;
  font-size: 29px;
  text-align: center;
  font-weight: bold;

  @media screen and (min-width: 769px) {
    text-align: center;
    font-size: 30px;
  }
  @media screen and (min-width: 1280px) {
    text-align: center;
    font-size: 32.5px;
  }
  @media screen and (min-width: 1440px) {
    text-align: center;
    font-size: 35px;
  }
  @media screen and (min-width: 1920px) {
    text-align: center;
    font-size: 40px;
  }
`
const TitleTwo = styled.div`
  font-size: 13px;
  color: #ffffff;
  text-align: center;
  @media screen and (min-width: 769px) {
    font-size: 15px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 15px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 18px;
  }
  @media screen and (min-width: 1920px) {
    font-size: 20px;
  }
`
const ImageContent = styled.div`
  position: relative;
  padding-bottom: 60px;

  @media screen and (max-width: 768px) {
    padding-bottom: 0;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 0;
  }

  @media screen and (min-width: 1280px) {
    padding: 0 60px 127rem;
  }

  @media screen and (min-width: 2560px) {
    padding-top: 70px;
  }
`

const ItemTopContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: #888888;
  border-radius: 50%;
  border: 10px solid #111114;

  padding: 0rem;
`;

const ItemTop = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media screen and (min-width: 1920px) {
    // width: 120px;
    // height: 120px;
    // border: 15px solid #111114;
  }
  @media screen and (min-width: 2560px) {
    // width: 180px;
    // height: 180px;
    // border: 20px solid #111114;
  }
`
const ItemBottom = styled.img`
  width: 65px;
  height: 65px;
  position: absolute;
  background-color: #111114;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  border: 10px solid #111114;
`
const RowContainer = styled.div`
  justify-content: space-between;
  display: flex;
  width: '100%';
  justify-content: center;

  @media screen and (max-width: 1200px) {
    width: '50%';
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 30%);
    padding-left: 15%;
    grid-template-areas: 'aa aa bb bb' 'cc cc dd dd';
  }
  @media screen and (max-width: 1000px) {
    width: '50%';
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 30%);
    padding-left: 30%;
    grid-template-areas: 'aa aa bb bb' 'dd cc cc dd';
  }
  @media screen and (max-width: 800px) {
    width: '50%';
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 30%);
    padding-left: 30%;
    grid-template-areas: 'aa aa bb bb' '. cc cc .';
  }
  @media screen and (max-width: 768px) {
    width: '50%';
    display: grid;
    grid-template-columns: repeat(1, 30%);
    padding-left: 20%;
    grid-template-areas: 'aa' 'bb' 'cc' 'dd';
    justify-content: center;
  }

  .ant-col {
    margin: 0 50px;
    @media screen and (max-width: 1000px) {
      margin: 0;
    }
  }
`
const Title = styled.p`
  position: absolute;
  transform: translate-y(0px, -50%);
  color: #888888;
  font-size: 27px;
  padding: 30% 25px;
  line-height: 1.4;

  @media screen and (max-width: 2560px) {
    font-size: 21px;
    padding: 0 20px;
    top: 53%;
    left: 10%;
    transform: translate(-5%, -50%);
  }
  @media screen and (max-width: 1920px) {
    font-size: 17px;
    padding: 0 20px;
    top: 53%;
    left: 10%;
    transform: translate(-5%, -50%);
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
    padding: 0 20px;
    top: 53%;
    left: 10%;
    transform: translate(-5%, -50%);
  }
`
const NameItem = styled.div`
  font-size: 18px;
  color: #888888;
  font-weight: bold;
  padding-top: 25px;
  width: max-content;

  @media screen and (min-width: 769px) {
    font-size: 18px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 20px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 22px;
  }
  @media screen and (min-width: 1920px) {
    font-size: 25px;
  }
`
const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 270px;
  height: 270px;
  border: 10px solid #888888;
  border-radius: 50%;
  position: relative;
  @media screen and (min-width: 1920px) {
    width: 350px;
    height: 350px;
    border: 15px solid #888888;
  }
  @media screen and (min-width: 2560px) {
    width: 450px;
    height: 450px;
    border: 20px solid #888888;
  }
`

const ComponentSeven = () => {
  return (
    <ContentSeven>
      <Content>
        <TitleOne>Our Backers</TitleOne>
        <TitleTwo>Meet the partners backing Undead Blocks</TitleTwo>
      </Content>
      <ImageContent>
        <RowContainer>
          <Col
            span={5}
            style={{
              display: 'flex',
              gridArea: 'aa',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '60px',
            }}>
            <Box>
              <ItemTopContainer style={{ padding: 7 }}>
                <ItemTop src={item0} />
              </ItemTopContainer>
              <Title>
                Animoca Brands is excited to see Undead Blocks develop into the P2E zombie shooter of choice for FPS gamers.
              </Title>
              <ItemBottom preview={false} src={phone} />
            </Box>
            <NameItem>Animoca Brands</NameItem>
          </Col>
          <Col
            span={5}
            style={{
              display: 'flex',
              gridArea: 'bb',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '60px',
            }}>
            <Box>
              <ItemTopContainer>
                <ItemTop src={item1} />
              </ItemTopContainer>
              <Title>
                Undead Blocks is an exciting next gen play-to-earn game that combines the highly popular FPS genre into
                the world of blockchain.
              </Title>
              <ItemBottom preview={false} src={phone} />
            </Box>
            <NameItem>Double Peak</NameItem>
          </Col>
          <Col
            span={5}
            style={{
              display: 'flex',
              gridArea: 'cc',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '60px',
            }}>
            <Box>
              <ItemTopContainer>
                <ItemTop src={item2} />
              </ItemTopContainer>
              <Title>
                This game has a clear path towards large-scale adoption from crypto and non-crypto players around the
                world.
              </Title>
              <ItemBottom preview={false} src={phone} />
            </Box>
            <NameItem>Genblock Capital</NameItem>
          </Col>
          <Col
            span={5}
            style={{
              display: 'flex',
              gridArea: 'dd',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '60px',
            }}>
            <Box>
              <ItemTopContainer>
                <ItemTop src={item3} />
              </ItemTopContainer>
              <Title>
                Undead Blocks may be the Axie Infinity of First Person Shooters with its top notch design and fun
                gameplay.
              </Title>
              <ItemBottom preview={false} src={phone} />
            </Box>
            <NameItem>Exnetwork Capital</NameItem>
          </Col>
        </RowContainer>
      </ImageContent>
    </ContentSeven>
  )
}

export default ComponentSeven
