import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import Lottie from 'react-lottie'
import SwiperCore, {
    Parallax,
    Pagination,
    Navigation,
    EffectFade
  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

import bg from '../img/bg.png';
import titleImg from '../img/title.png';
import glow from '../img/buttonBg/glow.png';
import inner from '../img/buttonBg/inner.png';
import soldGlow from '../img/buttonBg/sold-glow.png';
import soldInner from '../img/buttonBg/sold-inner.png';

import {
} from './constants';
import usePackages from './usePackages';

import Wrapper, {
    TitlePage,
    SwiperList,
    SwiperItem
} from './styles';

import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

SwiperCore.use([Parallax,Pagination,Navigation]);

function SwiperCustom(props) {
    const [activeItem, setActiveItem] = useState('');

    const {
        isConnected,
        setMintStarted,
        setCurrentItem,
        toggleWalletModal,
        pathname,
        bloodBg
    } = props;

    const {
        isMobile,
        isTablet,
        isDesktop
    } = useSelector(state => state.common);
    const { data = [] } = useSelector(state => state.setting?.allSettings?.result ?? []);

    // const getTotalMinted = data.find(e => e.key === 'totalMinted');
    const getSoldOut = data.find(e => e.key === 'soldOut');

    const { allPackages, requesting } = usePackages({ pathname });

    useEffect(() => {
        if (isTablet || isMobile) {
            return setActiveItem('HUNTER');
        }
    }, [isTablet, isMobile, isDesktop]);

    const handleOnSlideChange =  (e) => {
        if (isTablet || isMobile) {
            switch (e.realIndex) {
                case 0:
                    setActiveItem('MARKSMAN');
                    break;

                case 1:
                    setActiveItem('HUNTER');
                    break;

                case 2:
                    setActiveItem('DEMOLITIONIST');
                    break;

                default:
                    break;
            }
        } else {
            switch (e.realIndex) {
                case 0:
                    setActiveItem('MARKSMAN');
                    break;

                case 1:
                    setActiveItem('HUNTER');
                    break;

                case 2:
                    setActiveItem('DEMOLITIONIST');
                    break;

                default:
                    break;
            }
        }
    }

    const handleOnClick = (item) => {
        if (isConnected) {
            setMintStarted(true);
            setCurrentItem(item);
        } else {
            toggleWalletModal();
        }
    }

    return (
        <Wrapper>
            <TitlePage>
                <img
                    src={titleImg}
                    alt="title"
                />
            </TitlePage>

            <SwiperList>
                 <Swiper
                    spaceBetween={isDesktop ? 70 : 50}
                    slidesPerView={(isTablet || isMobile) ? 1 : 3}
                    onSlideChange={handleOnSlideChange}
                    onSwiper={handleOnSlideChange}
                    modules={[EffectFade]}
                    effect={'coverflow'}
                    loop={true}
                    navigation={true}
                    allowTouchMove={false}
                >
                    {allPackages && allPackages.map((item, index) => (
                        <SwiperSlide
                            key={index}
                        >
                            {({ isActive }) => {
                                return (
                                    <SwiperItem isActive={item.name === activeItem} type={item.type}>
                                        {requesting ? (
                                            <div
                                                className="wrapped-null"
                                                style={{ background: `url(${bg}) no-repeat bottom / cover` }}
                                            >
                                                <div className="content-null">
                                                    <img
                                                        className='img-null'
                                                        src={bloodBg}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                className="wrapped"
                                                style={{ background: `url(${bg}) no-repeat bottom / cover` }}
                                            >
                                                <h1 className="heading">{item.name}</h1>

                                                <div className="content">
                                                    <img
                                                        className="img-default"
                                                        src={item.img}
                                                        alt=""
                                                        style={{
                                                            // display: item.name === activeItem ? 'none' : 'block',
                                                        }}
                                                    />
                                                </div>

                                                <div className="bottom">
                                                    <p
                                                        style={{
                                                            backgroundImage: `linear-gradient(to bottom, ${item.start}, ${item.end}), linear-gradient(to bottom, #fff, #fff)`
                                                        }}
                                                        className="text-gradient"
                                                    >
                                                        {item.price} {item.symbol}
                                                    </p>
                                                    <p>{item.description} <br /> <span>Playable in game</span></p>
                                                </div>
                                                
                                                {getSoldOut?.value ? (
                                                    <div className="sold-out">
                                                        <img src={soldGlow} alt="sold" className="bottom-mint-btn" />
                                                        <img src={soldInner} alt="inner" className="top-mint-btn" />
                                                        <span>SOLD OUT</span>
                                                    </div>
                                                ) : (
                                                    <div className="parent">
                                                        <img src={glow} alt="mint" className="bottom-mint-btn" />
                                                        <img src={inner} alt="mint" className="top-mint-btn" onClick={() => handleOnClick(item)} />
                                                        <span onClick={() => handleOnClick(item)}>{!isConnected ? 'CONNECT' : item.buttonLbl}</span>
                                                    </div>
                                                )}
                                            </div>
                                        )}
 
                                        {item.name !== activeItem && <div className="overlay" />}
                                    </SwiperItem>
                                )
                            }}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </SwiperList>
        </Wrapper>
    );
}

export default SwiperCustom;
