import React from 'react'
import styled from 'styled-components'
import icon from '../assets/img/icon.png'
import anination from '../lotties/data.json'
import Anmations from './Animations/index'

const Wrapper = styled.div`
  background: #111114;
  width: 100%;
  padding: 0 100px;
  @media screen and (min-width: 1920px) {
    padding: 0 135px;
  }
  @media screen and (max-width: 768px) {
    display: block;
    padding: 0 30px;
  }
`

const MainContent = styled.div`
  display: flex;
  padding: 100px 0 0;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: block;
    padding: 60px 0 60px;
    div {
      display: table-footer-group;
    }
  }
`

const LeftBlock = styled.div`
  width: 50%;
  @media screen and (max-width: 768px) {
    padding-left: 0px;
    width: 100%;
  }
`
const TextBlock = styled.div`
  margin-bottom: 30px;
  margin-right: -20px;
  @media screen and (max-width: 768px) {
    margin-right: 0;
    direction: ltr;
    div {
      display: grid;
    }
  }
`

const TextTitle = styled.div`
  font-size: 29px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  padding: 15px 90px;
  line-height: 1.5;
  @media screen and (min-width: 769px) {
    font-size: 30px;
    text-align: left;
    padding: 0;
    line-height: unset;
  }
  @media screen and (min-width: 1280px) {
    font-size: 32.5px;
    text-align: left;
    line-height: 2.2;
    padding: 0;
  }
  @media screen and (min-width: 1440px) {
    font-size: 35px;
    text-align: left;
    padding: 0;
    line-height: unset;
  }
  @media screen and (min-width: 1920px) {
    font-size: 40px;
    text-align: left;
    padding: 0;
    line-height: unset;
  }
  @media screen and (max-width: 520px) {
    font-size: 29px;
    padding: 15px 0px;
    line-height: 1.5;
  }
`

const TextDescription = styled.div`
  font-size: 13px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  padding: 0;

  @media screen and (min-width: 769px) {
    font-size: 15px;
    text-align: left;
    padding-right: 5%;
    padding: 0;
  }
  @media screen and (min-width: 1440px) {
    font-size: 18px;
    text-align: left;
    padding: 0;
  }
  @media screen and (min-width: 1920px) {
    font-size: 20px;
    text-align: left;
    padding: 0;
  }
`

const ImgOne = styled.div`
  max-width: 450px;
  text-align: left;
  img {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: auto;
    margin: 0 auto 30px;
    text-align: center;
    img {
      width: 85%;
    }
  }
  @media screen and (max-width: 520px) {
    img {
      width: 100%;
    }
  }
`

const RightBlock = styled.div`
  width: 50%;
  @media screen and (max-width: 900px) {
    padding-right: 0px;
    width: 100%;
    margin: 0 auto;
  }
`

const ComponentFour = () => {
  return (
    <Wrapper>
      <MainContent className="box">
        <LeftBlock>
          <TextBlock>
            <TextTitle>Play With Your Favorite Controllers</TextTitle>
            <TextDescription>
              Choose to play Undead Blocks using your favorite gaming controllers or your mouse & keyboard.
            </TextDescription>
          </TextBlock>
          <ImgOne>
            <img src={icon} alt={''} />
          </ImgOne>
        </LeftBlock>
        <RightBlock>
          <Anmations animationData={anination} />
        </RightBlock>
      </MainContent>
    </Wrapper>
  )
}

export default ComponentFour
