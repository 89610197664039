import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import ComponentOne from '../../components/ComponentOne'
import ComponentTwo from '../../components/ComponentTwo'
import ComponentThree from '../../components/ComponentThree'
import ComponentFour from '../../components/ComponentFour'
import ComponentFive from '../../components/ComponentFive'
import ComponentSix from '../../components/ComponentSix'
import ComponentSeven from '../../components/ComponentSeven'
// import ComponentEight from '../../components/ComponentEight'
// import ComponentNine from '../../components/ComponentNine'
// import ComponentTen from '../../components/ComponentTen'
import ComponentEleven from '../../components/ComponentEleven'
import ComponentTwelve from '../../components/ComponentTwelve'
import LandingPageHeader from './LandingPageHeader'
// import MintInformation from '../../components/MintInformation'

const Wrapper = styled.div`
  background: rgb(0, 0, 0);

  overflow-x: hidden !important;
`
export function Home() {
  const location = useLocation()
  window.addEventListener('load', () => {
    setTimeout(() => {
      if (location.pathname === '/' && location.hash) {
        const element = document.getElementById(location.hash.replace('#', ''))
        element.scrollIntoView({ behavior: 'smooth' })
      }
    })
  })
  return (
    <>
      <Wrapper>
        <LandingPageHeader />
        <div id="features">
          <ComponentOne />
        </div>
        <ComponentTwo />
        <ComponentThree />
        {/* <MintInformation /> */}
        <ComponentFour />
        <ComponentFive />
        <div id="about-us">
          <ComponentSix />
        </div>
        <ComponentSeven />
        {/* <ComponentEight /> */}
        {/* <ComponentNine /> */}
        {/* <ComponentTen /> */}
        <ComponentEleven />
        <ComponentTwelve />
      </Wrapper>
    </>
  )
}
