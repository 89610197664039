import React, {useCallback, useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router'
import Lottie from 'react-lottie';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { ethers } from 'ethers'

import leftLayer from './img/left-test.png';
import rightLayer from './img/right.png';
import leftMan from './img/leftman.png';
import rightMan from './img/rightman.png';
import weaponsIcon from './img/weapons.png';
import whitelistIcon from './img/whitelist-icon.png';
import rightArrow from './img/right-arrow.png';
// import minted from './img/mintedBg.png';
import mintNone from './img/plus.png';
import noneBg from './img/none-bg.svg';
import bloodBg from './img/Circle_Blood.png';

import amateurSparkle from '../../lotties/amateur_sparkle.json';
import assassinSparkle from '../../lotties/assassin_sparkle.json';
import survivorSparkle from '../../lotties/survivor_sparkle.json';
import killerSparkle from '../../lotties/killer_sparkle.json';
import loadingData from '../../lotties/loading.json';
import { setToast } from "../../actions/common";
import { onApprove, getAllowanceUndead } from "../../actions/user";
import undeadTokenAbi from "../../services/abis/undeadToken.abi.json";

import Swiper from './Swiper';
import MintStepModal from './MintSteps';
import useCountDown from './useCountdown';
import useMintedList from './useMintedList';

import Nav from '../Home/LandingPageHeader/Header';
import CountDown from '../../components/CountDown';

import Animations from '../../components/Animations';

import {
    setIsMobile,
    setIsTablet,
    setIsDesktop,
    toggleWalletModal
} from '../../actions/common';

import
    Wrapper,
    {
        BubbleLayer,
        HeaderBanner,
        Document,
        MyArsenal,
        MintContent
    }
from './styles';

const BREAKPOINTS = {
    SM_MAX: 767,
    MD_MIN: 768,
    MD_MAX: 1023,
    LG_MIN: 1024,
    LG_MIN_CUSTOM: 1279
};

function MintWeapons() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const { pathname = '' } = location;

    const [mintStarted, setMintStarted] = useState(false);
    const [currentItem, setCurrentItem] = useState({});
    const [offset, setOffset] = useState(0);
    const [isRefetchAllowance, setRefetchAllowance] = useState(null);

    const {
        isMobile,
        isTablet,
        isDesktop,
        isEndCountdown
    } = useSelector(state => state.common);

    const isConnected = useSelector(state => state.user.connectWallet.isConnect);
    const mintListLoading = useSelector(state => state.ownership?.ownershipsRes?.requesting);
    const { data = [] } = useSelector(state => state.setting?.allSettings?.result ?? []);
    const { loadingConnectAccount } = useSelector(state => state.user ?? false);
    const chainId = useSelector(state => state.user?.chainId);
    const address = useSelector(state => state.user?.userAccount?.accounts[0] ?? '');

    const getTimeStamp = data.find(e => e.key === 'startTime');

    const undeadTokenAddress = chainId === 1
        ? process.env.REACT_APP_UNDEAD_TOKEN_MAINNET_ADDRESS
        : process.env.REACT_APP_UNDEAD_TESTNET;
        
    const rebootEventAddress = chainId === 1
        ? process.env.REACT_APP_UNDEAD_NFT_REBOOT_EVENT_MAINNET
        : process.env.REACT_APP_UNDEAD_NFT_REBOOT_EVENT_TESTNET


    // USE HOOKS
    const { mintedList = [], hasMore, firstLoad } = useMintedList({ offset, isConnected, isEndCountdown });
    const { timer } = useCountDown({ timeStamp: getTimeStamp?.value });

    const onResize = useCallback(() => {
        if ((window.innerWidth < BREAKPOINTS.MD_MIN) && !isMobile) {
            dispatch(setIsMobile())
        } else if ((window.innerWidth > BREAKPOINTS.SM_MAX) && (window.innerWidth < BREAKPOINTS.LG_MIN_CUSTOM) && !isTablet) {
            dispatch(setIsTablet())
        } else if ((window.innerWidth > BREAKPOINTS.LG_MIN_CUSTOM) && !isDesktop) {
            dispatch(setIsDesktop())
        }
    }, [dispatch, isMobile, isTablet, isDesktop]);

    // LISTEN LOAD MORE
    const observer = useRef();
    const lastElement = useCallback(node => {
        if (mintListLoading) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && hasMore) {
                    setOffset(prevState => prevState + 10);
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        });

        if (node) observer.current.observe(node);
    }, [mintListLoading, hasMore]);

    useEffect(() => {
        onResize();
        window.addEventListener('onload', onResize);
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
            window.removeEventListener('onload', onResize);
        }
    }, [onResize, dispatch]);

    useEffect(() => {
        loadingConnectAccount && setOffset(0);
    }, [loadingConnectAccount]);

    // useEffect(() => {
    //     dispatch(fetchAllSettings());
    // }, [dispatch]);

    useEffect(() => {
        if (!isConnected) return;

        fetchAllowanceUndead();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConnected, isRefetchAllowance]);

    const renderLottieSparkle = (name) => {
        if (!name) return amateurSparkle;
        if (name === 'AMATEUR') return amateurSparkle;
        if (name === 'ASSASSIN') return assassinSparkle;
        if (name === 'SURVIVOR') return survivorSparkle;
        if (name === 'ZOMBIE KILLER') return killerSparkle;

        return amateurSparkle;
    };

    const renderHeaderBanner = () => (
        <HeaderBanner>
            <div className="image-layout">
                <section className="left-layer">
                    <img src={leftMan} alt="left" className="left-header" />
                    <div className="left-overlay" />
                </section>

                <section className="right-layer">
                    <img src={rightMan} alt="right" className="right-header" style={{ paddingRight: 20 }} />
                    <div className="right-overlay" />
                </section>

                <div className="overlay" />
            </div>
        </HeaderBanner>
    );

    const renderBubbleLayer = () => (
        <BubbleLayer>
            <section className="bubble">
                <img src={leftLayer} alt="left" className="left" />
                <div className="left-bubble" />
            </section>
            <section className="bubble">
                <img src={rightLayer} alt="right" className="right" />
                <div className="right-bubble" />
            </section>
        </BubbleLayer>
    );

    const renderDocument = () => (
        <Document>
            <div className="box">
                <img src={isEndCountdown ? weaponsIcon : whitelistIcon} alt="icon" className="left" />

                <section className="center">
                    <h2>INTRODUCING THE WEAPONS MINTING GUIDE</h2>
                    <p>Read our Medium guide and learn how minting works.</p>
                </section>

                <a
                    href="https://medium.com/@UndeadBlocks/undead-blocks-call-to-arms-weapons-minting-guide-f7b48c72ef23"
                    target="_blank"
                    rel="noreferrer"
                    className="read-btn"
                >
                    <p>Read Article</p>
                    <img src={rightArrow} alt="arrow" />
                </a>
            </div>
        </Document>
    );

    const renderMyArsenal = () => {
        return (
            <MyArsenal>
                <h2 className="title">My Weapons</h2>
                <p className="description">Weapon Loadouts found in your connected wallet will appear here</p>

                <div className="mint-list">
                    {firstLoad ? (
                        new Array(isMobile ? 2 : 3).fill({}).map((e, i) => (
                            <div
                                key={i}
                                className="loading"
                            >
                                <div className="heading-loading" />

                                <div className="content-loading" />

                                <div className="footer-loading">
                                    <section className="left-loading" />
                                    <section className="right-loading" />
                                </div>
                            </div>
                        ))
                    ) : (
                        !isEmpty(mintedList) && [...mintedList, {}, {}].map((e, i) => (
                            <div
                                key={i}
                                className={cn('mint-item', {
                                    'nope': !e.id,
                                    'border': e.id
                                })}
                                ref={mintedList.length === i + 1 ? lastElement : null}
                            >
                                {!e?.id ? (
                                    <div className="no-content">
                                        <img
                                            src={noneBg}
                                            style={{ width: '100%' }}
                                            alt="border"
                                        />
                                        <img
                                            src={mintNone}
                                            alt="none"
                                            className="none"
                                            onClick={() => handleOnTop()}
                                        />
                                    </div>
                                ) : (
                                    <MintContent
                                        style={{ background: `url(${e.thumbnail}) no-repeat center center / cover` }}
                                        onClick={() => {
                                            history.push(`/nft/${e?.contractAddress}/${e?.tokenId}`)
                                        }}
                                    >
                                        <div className="info">
                                            <Lottie
                                                options={{
                                                    loop: true,
                                                    autoplay: true,
                                                    animationData: renderLottieSparkle(e.title)
                                                }}
                                                style={{
                                                    transition: `height 0.05s`,
                                                    position: 'absolute',
                                                    zIndex: 70
                                                }}
                                                height={isMobile ? '315.5rem' : '205.5rem'}
                                                isClickToPauseDisabled={true}
                                            />
                                        </div>

                                        {/* <div className="bottom">
                                            <p className="detail">
                                                {e.description}
                                            </p>
                                            <div
                                                style={{
                                                    background: `url(${minted}) no-repeat center center / cover`
                                                }}
                                                className="mint-btn"
                                            >
                                                <Link to={`/nft/${e.tokenId}`} className="view-lbl">
                                                    View Details
                                                </Link>
                                            </div>
                                        </div> */}
                                    </MintContent>
                                )}
                            </div>
                        ))
                    )}
                </div>

                {!firstLoad && mintListLoading && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '80rem'
                        }}
                    >
                        <div className="loading-more">
                            <Animations animationData={loadingData} />
                        </div>
                    </div>
                )}
            </MyArsenal>
        )
    }

    const handleOnTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    const handleOnApproveUndead = () => {
        try {
            const contract = new window.web3.eth.Contract(undeadTokenAbi, undeadTokenAddress);

            dispatch(onApprove(contract, rebootEventAddress, address, ethers.constants.MaxUint256.toString(), () => {}))
                .then((res) => {
                    if (res?.code === 4001) {
                        return dispatch(setToast({ title: 'APPROVE FAILED', detail: res?.message }))
                    }

                    setRefetchAllowance(new Date().getTime());
                })
                .catch(err => {
                    dispatch(setToast({ title: 'APPROVE FAILED', detail: 'User rejected the request.' }))
                    return err;
                })
        } catch (error) {
            dispatch(setToast({ title: 'APPROVE FAILED', detail: 'Something went wrong' }))
            return error;
        }
    }

    const fetchAllowanceUndead = () => {
        try {
            const contract = new window.web3.eth.Contract(undeadTokenAbi, undeadTokenAddress);

            dispatch(getAllowanceUndead(contract, address, rebootEventAddress))
                .then()
                .catch(err => {
                    dispatch(setToast({ title: 'FAILED', detail: 'Something went wrong' }))
                    return err;
                })
        } catch (error) {
            dispatch(setToast({ title: 'FAILED', detail: 'Something went wrong' }))
            return error;
        }
    }

    return (
        <Wrapper>
            <Nav isStaking />
            {renderBubbleLayer()}
            {renderHeaderBanner()}

            {!isEndCountdown ? (
                <CountDown timer={timer} />
            ) : (
                <div
                    className={cn('', {
                        'notShow': !isEndCountdown
                    })}
                >
                    <Swiper
                        bloodBg={bloodBg}
                        pathname={pathname}
                        isConnected={isConnected}
                        toggleWalletModal={() => dispatch(toggleWalletModal(true))}
                        setMintStarted={setMintStarted}
                        setCurrentItem={setCurrentItem}
                    />
                </div>
            )}

            {renderDocument()}
            {renderMyArsenal()}

            <MintStepModal
                item={currentItem}
                isVisible={mintStarted}
                onCancel={() => setMintStarted(false)}
                isMobile={isMobile}
                isTablet={isTablet}
                isDesktop={isDesktop}
                onApproveUndead={handleOnApproveUndead}
            />
        </Wrapper>
    );
}

export default MintWeapons;