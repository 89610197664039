import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import moment from 'moment';

import close from './img/close.png';
import closeHover from './img/close-hover.png';

import signUpButton from './img/signup-button.png';
import signUpButtonHover from './img/signup-button-hover.png';

import closeButton from './img/close-button.png';
import closeButtonHover from './img/close-button-hover.png';

import {
    setIsMobile,
    setIsTablet,
    setIsDesktop,
} from '../../actions/common';

import styled from 'styled-components';
import './style.css';

const BREAKPOINTS = {
    SM_MAX: 767,
    MD_MIN: 768,
    MD_MAX: 1023,
    LG_MIN: 1024,
    LG_MIN_CUSTOM: 1279
};

const Wrapper = styled.div`
    width: 100%;
    position: relative;

    .banner {
        width: 100%;
        height: 100%;
    }

    .close {
        background: url(${close});
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        width: 50rem;
        height: 50rem;

        position: absolute;
        z-index: 50;
        top: -25rem;
        right: -25rem;

        cursor: pointer;
        transition: all 0.5s;

        @media screen and (max-width: 767px) {
            width: 100rem;
            height: 100rem;

            top: -50rem;
            right: -50rem;
        }

        :hover {
            background: url(${closeHover});
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
    
            width: 50rem;
            height: 50rem;

            @media screen and (max-width: 767px) {
                width: 100rem;
                height: 100rem;
            }
        }
    }

    .btn-container {
        display: flex;
        gap: 8rem;

        position: absolute;
        bottom: 35rem;
        left: 50%;
        transform: translateX(-50%);

        @media screen and (max-width: 767px) {
            flex-direction: column;
            bottom: 650rem;
        }
    }

    .sign-up-button {
        background: url(${signUpButton});
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        width: 191.5rem;
        height: 38rem;

        cursor: pointer;
        transition: all 0.5s;

        @media screen and (max-width: 767px) {
            width: 350rem;
            height: 76rem;
        }

        :hover {
            background: url(${signUpButtonHover});
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
    
            width: 191.5rem;
            height: 38rem;

            @media screen and (max-width: 767px) {
                width: 350rem;
                height: 76rem;
            }
        }
    }

    .close-button {
        background: url(${closeButton});
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        width: 191.5rem;
        height: 38rem;

        cursor: pointer;
        transition: all 0.5s;

        @media screen and (max-width: 767px) {
            width: 350rem;
            height: 76rem;
        }

        :hover {
            background: url(${closeButtonHover});
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
    
            width: 191.5rem;
            height: 38rem;

            @media screen and (max-width: 767px) {
                width: 350rem;
                height: 76rem;
            }
        }
    }
`;

function TournamentPopup() {
    const dispatch = useDispatch();

    const [visible, setVisible] = useState(false);

    const {
        isMobile,
        isTablet,
        isDesktop
    } = useSelector(state => state.common);
    const { data = [] } = useSelector(state => state.setting?.allSettings?.result ?? []);

    const getStartDate = data && data.find(e => e.key === 'startDate');

    const getEndDate = data && data.find(e => e.key === 'endDate');

    const getBannerDesktop = useMemo(() => {
        return data.find(e => e.key === 'bannerUrlDesktop');
    }, [data]);

    const getBannerMobile = useMemo(() => {
        return data.find(e => e.key === 'bannerUrlMobile');
    }, [data]);

    const onResize = useCallback(() => {
        if ((window.innerWidth < BREAKPOINTS.MD_MIN) && !isMobile) {
            dispatch(setIsMobile())
        } else if ((window.innerWidth > BREAKPOINTS.SM_MAX) && (window.innerWidth < BREAKPOINTS.LG_MIN_CUSTOM) && !isTablet) {
            dispatch(setIsTablet())
        } else if ((window.innerWidth > BREAKPOINTS.LG_MIN_CUSTOM) && !isDesktop) {
            dispatch(setIsDesktop())
        }
    }, [dispatch, isMobile, isTablet, isDesktop]);

    useEffect(() => {
        onResize();
        window.addEventListener('onload', onResize);
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
            window.removeEventListener('onload', onResize);
        }
    }, [onResize, dispatch]);

    // HANDLE SHOW TOURNAMENT POPUP
    useEffect(() => {
        if (!getStartDate || !getEndDate) return;

        const dateNow = Date.now();
        const tomorrow = window.localStorage.getItem('tomorrow');
        const isReadTournament = window.localStorage.getItem('readTournament');

        if (!tomorrow) {
            window.localStorage.setItem('tomorrow', moment(dateNow).add(1,'days').endOf().valueOf());
        }

        if (isReadTournament === 'true') {
            if (dateNow < Number(tomorrow)) return setVisible(false);

            window.localStorage.removeItem('readTournament');
            window.localStorage.removeItem('tomorrow');

            return;
        }

        // ZO DAY ROI
        if (dateNow > Number(`${getStartDate?.value}000`) && dateNow < Number(`${getEndDate?.value}000`)) {
            return setVisible(true);
        }

        return setVisible(false);
    }, [getStartDate, getEndDate]);

    const handleOnClose = () => {
        setVisible(false);
        window.localStorage.setItem('readTournament', 'true');
    };

    const handleOnSignUp = () => {
        window.open(process.env.REACT_APP_WAGYU_REGISTER_URL, '_blank');
    };
 
    return (
        <Modal
            visible={visible}
            closable={false}
            centered
            width={'960rem'}
            height={isMobile ? 'auto' : '540rem'}
            wrapClassName={'tournament-popup'}
        >
            <Wrapper>
                <img src={isMobile ? getBannerMobile?.value : getBannerDesktop?.value} alt="" className={'banner'} />
                <div className={'close'} onClick={handleOnClose} />

                <div className={'btn-container'}>
                    <div className={'sign-up-button'} onClick={handleOnSignUp} />
                    <div className={'close-button'} onClick={handleOnClose} />
                </div>
            </Wrapper>
        </Modal>
    );
}

export default React.memo(TournamentPopup);