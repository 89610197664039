import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import titleDesktop from './img/titleDesktop3x.png';
import titleMobile from './img/titleMobile3x.png';
import content from './img/content.png';
import contentMobile from './img/contentMobile.png';

import { exportTimeValue } from './utils'; 

import TextCustom from '../TextCustom';

import Wrapper, {
    TitleWrapper,
    CountDownWrapper,
    ContentWrapper
} from './styles.js';

function CountDown(props) {
    const { timer = {} } = props;

    const { isDesktop } = useSelector(state => state.common);

    const days = exportTimeValue(timer?.days) || '00';
    const hours = exportTimeValue(timer?.hours) || '00';
    const mins = exportTimeValue(timer?.minutes) || '00';
    const secs = exportTimeValue(timer?.seconds) || '00';

    return (
        <Wrapper>
            <div
                className={cn('box')}
            >
                <TitleWrapper>
                    <img
                        src={isDesktop ? titleDesktop : titleMobile}
                        alt="title"
                        className="title-img"
                    />
                </TitleWrapper>

                <CountDownWrapper>
                    <div className="container">
                        <section className="item">
                            <div className="timer-countdown">
                                <TextCustom
                                    content={{
                                        days,
                                        hours,
                                        mins,
                                        secs
                                    }}
                                    fontSize={112.5}
                                    lineHeight={0.78}
                                    letterSpacing={2}
                                    textStroke={13}
                                    isDesktop={isDesktop}
                                />
                            </div>
                        </section>
                    </div>
                </CountDownWrapper>

                <ContentWrapper>
                    <img src={isDesktop ? content : contentMobile} alt="content" />
                </ContentWrapper>
            </div>
        </Wrapper>
    );
}

export default CountDown;