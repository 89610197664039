import React from 'react';
import TransactionProcessModal from './SuccessModal';

export default function SuccessModalContainer(props) {
    const { link } = props
    const onViewTransaction = () => window.open(link, '_blank');

    return (
        <TransactionProcessModal
            {...props}
            {...{
                onViewTransaction,
            }}

        />
    )
}
