import React, { useState } from 'react'
import styled from 'styled-components'
// import { Button } from 'antd'
// import { getPublicUrl } from '../../../../utils/common';
import Header from '../Header'
import UndeadBlocks from '../../../../assets/img/undead_block_logo.png'
import copyIcon from '../../../../assets/img/copy.png'
import IconDirec from '../../../../assets/img/uniswap_logo.png'
import GameControl from '../../../../assets/img/download-game.png'
import desktop from '../../../../assets/video/banner-desktop.mp4'
import mobile from '../../../../assets/video/banner-mobile.mp4'
import aquaRedIcon from '../../../../assets/banner-header/aqua_red.png'
// import VideoAnimation from '../../../../components/Video'

const Wrapper = styled.div`
  background: #08080a;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    background: #000;
  }
`
const Logo = styled.div`
  img {
    width: 467.5rem;
    height: 326rem;

    @media screen and (max-width: 767px) {
      width: 830rem;
      height: 545rem;
      margin-bottom: 40rem;
    }
  }
`
const Content = styled.div`
  font-family: Poppins;
  font-size: 18rem;
  line-height: 1.67;
  text-align: center;
  color: #fff;
  margin-bottom: 12rem;

  @media screen and (max-width: 767px) {
    font-size: 45rem;
  }
`
const BannerWrapper = styled.div`
  display: flex;
  align-items: center;

  position: absolute;
  top: 127.5rem;
  padding-left: 88.5rem;
  z-index: 40;

  @media screen and (max-width: 767px) {
    top: 1300rem;
    padding: 0 50rem;
  }
`
const BannerContent = styled.div`
  width: 508rem;
  text-align: left;

  @media screen and (max-width: 767px) {
    width: 100%;
    text-align: center;
  }
`

const TradingButton = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 10rem;

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0 0rem;
    margin-top: 20rem;
  }

  .btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.45);
    height: 44rem;

    cursor: pointer;
    padding: 0 18rem 0 16rem;

    &:hover {
      transform: scale(1.05);
      transition: all 0.5s;
    }

    @media screen and (max-width: 767px) {
      background-color: rgba(134, 0, 0, 0.349);
      width: auto;
      min-height: 120rem;
    }
  }

  p {
    font-family: Poppins;
    font-size: 12rem;
    // line-height: 2;
    text-align: left;
    color: #fff;
    margin-bottom: 0;

    @media screen and (max-width: 767px) {
      font-size: 31rem;
    }
  }

  .trading {
    width: 28rem;
    height: 33rem;

    @media screen and (max-width: 767px) {
      width: 70rem;
      height: 70rem;
    }
  }
`

const ButtonControl = styled.div`
  display: flex;
  // padding: 0 20rem 0;
  position: relative;

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0 0rem;
  }

  .copyText {
    position: absolute;
    top: -13rem;
    right: 20rem;

    background-color: #5e0000;
    color: #fff;
    box-shadow: 0 0 2rem 2rem rgba(0,0,0,0.45);

    padding: 2rem 8rem;
    border-radius: 3rem;
    transition: .2s ease-out;

    font-size: 12rem;
    font-weight: 600;
    line-height: 1.4;
    text-align: center;

    @media screen and (max-width: 767px) {
      top: -35rem;
      border-radius: 3px;
      padding: 2px 8px;
      font-size: 10px;
      line-height: 1.4;
    }
  }

  .btn-contract {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.45);
    min-height: 44rem;
    width: 100%;
    padding: 0 14rem 0 11rem;
    margin-top: 10rem;
    cursor: pointer;
    position: relative;

    &:hover {
      transform: scale(1.05);
      transition: all 0.5s;
    }

    :before {
      content: 'Contract Address:';
      font-family: Poppins;
      font-size: 12rem;
      font-weight: bold;
      text-align: left;

      color: #fff;
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        content: 'Contract:';
        font-size: 35rem;
      }
    }

    @media screen and (max-width: 767px) {
      background-color: rgba(134, 0, 0, 0.349);
      width: 100%;
      min-height: 120rem;
      padding: 0 0rem;
      margin-top: 20rem;

      &:hover {
        transform: scale(1);
      }
    }

    .copy {
      width: 12.5rem;
      height: 15.5rem;

      @media screen and (max-width: 767px) {
        width: 45rem;
        height: 55rem;
      }
    }

    p {
      font-family: Poppins;
      font-size: 12rem;
      text-align: left;
      color: #fff;
      margin-bottom: 0;
      margin-right: 6.5rem;

      @media screen and (max-width: 767px) {
        font-size: 35.5rem;
      }
    }
  }

  .btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.45);
    height: 44rem;

    cursor: pointer;
    padding: 0 18rem 0 16rem;

    &:hover {
      transform: scale(1.05);
      transition: all 0.5s;
    }

    &.left {
      margin-right: 15rem;
      flex: 1;

      @media screen and (max-width: 767px) {
        margin-right: 40rem;
      }
    }

    &.right {
      // flex: 1;
    }

    @media screen and (max-width: 767px) {
      background-color: rgba(134, 0, 0, 0.349);
      width: auto;
      min-height: 120rem;

      &.right {
        flex: 1;
      }
    }

    p {
      font-family: Poppins;
      font-size: 12rem;
      // line-height: 2;
      text-align: left;
      color: #fff;
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        font-size: 31rem;
      }
    }

    .direct {
      width: 45rem;
      height: 18rem;
      margin-right: 8.5rem;

      @media screen and (max-width: 767px) {
        width: 70rem;
        height: 40rem;
        // margin-right: 8.5rem;
      }
    }

    .discord {
      width: 25rem;
      height: 19.5rem;
      margin-right: 8.5rem;

      @media screen and (max-width: 767px) {
        width: 55rem;
        height: 40rem;
        margin-right: 15rem;
      }
    }
  }
`
const WrapperVideoDesktop = styled.div`
  position: relative;
  width: 100%;
  height: 720rem;
  z-index: 0;

  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    outline: none;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`
const WrapperVideoMobile = styled.div`
  display: none;

  @media screen and (max-width: 767px) {
    position: relative;
    width: 100%;
    height: 1800rem;
    z-index: 0;

    display: block;

    video {
      position: static;
      width: 100%;
      height: 100%;
      object-fit: cover;
      outline: none;
    }
  }
`

export default function Banner() {
  const [copied, setCopied] = useState(false);

  const copyClipboard = (value) => {
    setCopied(true);
    navigator.clipboard.writeText(value);

    setTimeout(() => {
        setCopied(false);
    }, 2000)
  };

  return (
    <Wrapper>
      <Header />

      <WrapperVideoDesktop>
        <video autoPlay playsInline muted loop>
          <source src={desktop} type="video/mp4"></source>
        </video>
        {/* <VideoAnimation video={desktop} /> */}
      </WrapperVideoDesktop>

      <WrapperVideoMobile>
        <video autoPlay playsInline muted loop>
          <source src={mobile} type="video/mp4"></source>
        </video>
        {/* <VideoAnimation video={mobile} /> */}
      </WrapperVideoMobile>

      <BannerWrapper>
        <BannerContent>
          <Logo>
            <img src={UndeadBlocks} alt={''} />
          </Logo>

          <Content>
            Undead Blocks, a multiplayer zombie survival game. Collect weapons and slay endless waves of zombies in an immersive FPS experience.
          </Content>

          <ButtonControl>
            <div
              className="btn-container left"
              // onClick={() => window.open(`${getPublicUrl()}gamedeck.pdf`, '_blank').focus()}
              onClick={() => window.open(process.env.REACT_APP_WAGYU_URL, '_blank')}
            >
              <img src={GameControl} alt={'game'} className="discord" />
              <p>Download Undead Blocks</p>
            </div>

            <div
              className="btn-container right"
              onClick={() => window.open('https://aqua.xyz/ub/search/', '_blank')}
            >
              <img src={aquaRedIcon} alt={'list'} className="direct" />
              <p>Undead Blocks Marketplace</p>
            </div>
          </ButtonControl>

          <TradingButton>
            <div
              className="btn-container"
              onClick={() => window.open('https://app.uniswap.org/#/swap?outputCurrency=0x310C8F00b9dE3c31Ab95ea68feb6C877538f7947&chain=mainnet', '_blank')}
            >
              <img src={IconDirec} alt={'list'} className="trading" />
              <p>Trading on Uniswap</p>
            </div>
          </TradingButton>

          <ButtonControl onClick={() => copyClipboard('0x310C8F00b9dE3c31Ab95ea68feb6C877538f7947')}>
            <div className="btn-contract">
              <p>&nbsp;0x310C8F00b9dE3c31Ab95ea68feb6C877538f7947</p>
              <img src={copyIcon} alt={'list'} className="copy" />
            </div>

            {copied && (
              <section className={'copyText'}>
                  Copied
              </section>
            )}
          </ButtonControl>
        </BannerContent>
      </BannerWrapper>
    </Wrapper>
  )
}
