import StakingRed from '../assets/video/staking-red.mp4'
import StakingGreen from '../assets/video/staking-green.mp4'
import StakingBlue from '../assets/video/staking-blue.mp4'

import StakingBackground1 from '../assets/img/staking-background-1.png'
import StakingBackground2 from '../assets/img/staking-background-2.png'
import StakingBackground3 from '../assets/img/staking-background-3.png'

import amateur from '../lotties/amateur.json';
import survivor from '../lotties/survivor.json';
import killer from '../lotties/killer.json';

import rookiePoolImg from '../assets/img/RookiePool.png';
import commanderPoolImg from '../assets/img/CommanderPool.png';
import veteranPoolImg from '../assets/img/VeteranPool.png';

import sparkle1 from '../lotties/sparkle1.json';
import sparkle2 from '../lotties/sparkle2.json';
import sparkle3 from '../lotties/sparkle3.json';
import BigNumber from 'bignumber.js'

import { getBalanceAmount } from '../services/multicall';

export const LOTTIE_CONST = {
  AMATEUR: 'AMATEUR',
  SURVIVOR: 'SURVIVOR',
  KILLER: 'KILLER',
  ROOKIE: 'ROOKIE',
  COMMANDER: 'COMMANDER',
  VETERAN: 'VETERAN'
}

export const BACKGROUND_CONST = {
  BACKGROUND1: 'BACKGROUND-1',
  BACKGROUND2: 'BACKGROUND-2',
  BACKGROUND3: 'BACKGROUND-3'
}

export const LIST_WITHDRAW_CLAIM = [
  {
    stakeAmount: new BigNumber(500),
    unlockDate: '2022-04-09T02:25:48.583Z',
    rewardAmount: new BigNumber(350),
    unit: 'UNDEAD',
    disabled: false
  }, {
    stakeAmount: new BigNumber(500),
    unlockDate: '2022-04-10T02:25:48.583Z',
    rewardAmount: new BigNumber(350),
    unit: 'UNDEAD',
    disabled: true
  }, {
    stakeAmount: new BigNumber(500),
    unlockDate: '2022-04-11T02:25:48.583Z',
    rewardAmount: new BigNumber(350),
    unit: 'UNDEAD',
    disabled: true
  }, {
    stakeAmount: new BigNumber(500),
    unlockDate: '2022-04-12T02:25:48.583Z',
    rewardAmount: new BigNumber(350),
    unit: 'UNDEAD',
    disabled: true
  }, {
    stakeAmount: new BigNumber(500),
    unlockDate: '2022-04-13T02:25:48.583Z',
    rewardAmount: new BigNumber(350),
    unit: 'UNDEAD',
    disabled: true
  }, {
    stakeAmount: new BigNumber(500),
    unlockDate: '2022-04-14T02:25:48.583Z',
    rewardAmount: new BigNumber(350),
    unit: 'UNDEAD',
    disabled: true
  }, {
    stakeAmount: new BigNumber(500),
    unlockDate: '2022-04-15T02:25:48.583Z',
    rewardAmount: new BigNumber(350),
    unit: 'UNDEAD',
    disabled: true
  }, {
    stakeAmount: new BigNumber(500),
    unlockDate: '2022-04-16T02:25:48.583Z',
    rewardAmount: new BigNumber(350),
    unit: 'UNDEAD',
    disabled: true
  }, {
    stakeAmount: new BigNumber(500),
    unlockDate: '2022-04-17T02:25:48.583Z',
    rewardAmount: new BigNumber(350),
    unit: 'UNDEAD',
    disabled: true
  }
]

export const POOL_DATA = [
  {
    poolTitle: 'ROOKIE POOL',
    description: 'Must Stake minimum 500 UNDEAD to mint weapon loadout*',
    network: 'bsc',
    chainId: process.env.REACT_APP_CHAIN_ID_HEX,
    coinSymbol: 'UNDEAD',
    mainCoinImage: 'https://d1g9mb5fixv36m.cloudfront.net/nafter/1633966480950_unlock-2.png',
    smallerCoinImage: 'https://d1g9mb5fixv36m.cloudfront.net/nafter/1622600747836_naft.0568be00.png',
    stakeTokenAddress: process.env.REACT_APP_STAKE_TOKEN_ADDRESS,
    stakeTokenSymbol: 'UNDEAD',
    stakeTokenDecimals: 18,
    rewardTokenAddress: process.env.REACT_APP_STAKE_TOKEN_ADDRESS,
    rewardTokenSymbol: 'UNDEAD',
    rewardTokenDecimals: 18,
    stakeContractAddress: process.env.REACT_APP_POOL_1_ADDRESS,
    status: 'LIVE',
    apy: 0,
    order: 1,
    isMaster: true,
    id: '1',
    createdAt: '2021-06-02T02:25:48.583Z',
    updatedAt: '2021-10-12T03:46:56.693Z',
    unlockAt: 'Dec, 09 2021 - 12:00 EST',
    isFrozen: false,
    disable: process.env.REACT_APP_POOL_DISABLE === "true",
    videoUrl: StakingGreen,
    background: StakingBackground1,
    lottie: amateur,
    lottieSparkle: sparkle1,
    titleMint: 'Mint A Free Loadout',
    bodyMint: 'Staking a minimum amount of UNDEAD into the staking pool will allow you to mint a free weapon loadout instantly. Only one weapon loadout mint per pool, per wallet, is permitted.',
    footerMint: 'Note: You will only receive a weapon loadout if you stake the minimum UNDEAD amount.',
    isLoading: true
  },
  {
    poolTitle: 'SURVIVOR POOL',
    description: 'Must Stake minimum 500 UNDEAD to mint weapon loadout*',
    network: 'bsc',
    chainId: process.env.REACT_APP_CHAIN_ID_HEX,
    coinSymbol: 'UNDEAD',
    mainCoinImage: 'https://d1g9mb5fixv36m.cloudfront.net/nafter/1633966480950_unlock-2.png',
    smallerCoinImage: 'https://d1g9mb5fixv36m.cloudfront.net/nafter/1622600747836_naft.0568be00.png',
    stakeTokenAddress: process.env.REACT_APP_STAKE_TOKEN_ADDRESS,
    stakeTokenSymbol: 'UNDEAD',
    stakeTokenDecimals: 18,
    rewardTokenAddress: process.env.REACT_APP_STAKE_TOKEN_ADDRESS,
    rewardTokenSymbol: 'UNDEAD',
    rewardTokenDecimals: 18,
    stakeContractAddress: process.env.REACT_APP_POOL_2_ADDRESS,
    status: 'LIVE',
    apy: 0,
    order: 1,
    isMaster: true,
    id: '2',
    createdAt: '2021-06-02T02:25:48.583Z',
    updatedAt: '2021-10-12T03:46:56.693Z',
    unlockAt: 'Dec, 09 2021 - 12:00 EST',
    isFrozen: false,
    disable: process.env.REACT_APP_POOL_DISABLE === "true",
    videoUrl: StakingRed,
    background: StakingBackground2,
    lottie: survivor,
    lottieSparkle: sparkle2,
    titleMint: 'Mint A Free Loadout',
    bodyMint: 'Staking a minimum amount of UNDEAD into the staking pool will allow you to mint a free weapon loadout instantly. Only one weapon loadout mint per pool, per wallet, is permitted.',
    footerMint: 'Note: You will only receive a weapon loadout if you stake the minimum UNDEAD amount.',
    isLoading: true
  },
  {
    poolTitle: 'ZOMBIE KILLER POOL',
    description: 'Must Stake minimum 500 UNDEAD to mint weapon loadout*',
    network: 'bsc',
    chainId: process.env.REACT_APP_CHAIN_ID_HEX,
    coinSymbol: 'UNDEAD',
    mainCoinImage: 'https://d1g9mb5fixv36m.cloudfront.net/nafter/1633966480950_unlock-2.png',
    smallerCoinImage: 'https://d1g9mb5fixv36m.cloudfront.net/nafter/1622600747836_naft.0568be00.png',
    stakeTokenAddress: process.env.REACT_APP_STAKE_TOKEN_ADDRESS,
    stakeTokenSymbol: 'UNDEAD',
    stakeTokenDecimals: 18,
    rewardTokenAddress: process.env.REACT_APP_STAKE_TOKEN_ADDRESS,
    rewardTokenSymbol: 'UNDEAD',
    rewardTokenDecimals: 18,
    stakeContractAddress: process.env.REACT_APP_POOL_3_ADDRESS,
    status: 'LIVE',
    apy: 0,
    order: 1,
    isMaster: true,
    id: '3',
    createdAt: '2021-06-02T02:25:48.583Z',
    updatedAt: '2021-10-12T03:46:56.693Z',
    unlockAt: 'Dec, 09 2021 - 12:00 EST',
    isFrozen: false,
    disable: process.env.REACT_APP_POOL_DISABLE === "true",
    videoUrl: StakingBlue,
    background: StakingBackground3,
    lottie: killer,
    lottieSparkle: sparkle3,
    titleMint: 'Mint A Free Loadout',
    bodyMint: 'Staking a minimum amount of UNDEAD into the staking pool will allow you to mint a free weapon loadout instantly. Only one weapon loadout mint per pool, per wallet, is permitted.',
    footerMint: 'Note: You will only receive a weapon loadout if you stake the minimum UNDEAD amount.',
    isLoading: true
  },
]

const getLottie = (lottie) => {
  switch (lottie) {
    case LOTTIE_CONST.AMATEUR:
      return amateur;
    case LOTTIE_CONST.SURVIVOR:
      return survivor;
    case LOTTIE_CONST.KILLER:
      return killer;
    case LOTTIE_CONST.ROOKIE:
      return rookiePoolImg;
    case LOTTIE_CONST.COMMANDER:
      return commanderPoolImg;
    case LOTTIE_CONST.VETERAN:
      return veteranPoolImg;
    default:
      return rookiePoolImg;
  }
}

const getIsLottie = (lottie) => {
  switch (lottie) {
    case LOTTIE_CONST.AMATEUR:
      return true;
    case LOTTIE_CONST.SURVIVOR:
      return true;
    case LOTTIE_CONST.KILLER:
      return true;
    case LOTTIE_CONST.ROOKIE:
      return false;
    case LOTTIE_CONST.COMMANDER:
      return false;
    case LOTTIE_CONST.VETERAN:
      return false;
    default:
      return false;
  }
}

const getSparkle = (lottie) => {
  switch (lottie) {
    case LOTTIE_CONST.AMATEUR:
      return sparkle1;
    case LOTTIE_CONST.SURVIVOR:
      return sparkle2;
    case LOTTIE_CONST.KILLER:
      return sparkle3;
    case LOTTIE_CONST.ROOKIE:
      return sparkle1;
    case LOTTIE_CONST.COMMANDER:
      return sparkle2;
    case LOTTIE_CONST.VETERAN:
      return sparkle3;
    default:
      return sparkle1;
  }
}

const getBackground = (bg) => {
  switch (bg) {
    case BACKGROUND_CONST.BACKGROUND1:
      return StakingBackground1;
    case BACKGROUND_CONST.BACKGROUND2:
      return StakingBackground2;
    case BACKGROUND_CONST.BACKGROUND3:
      return StakingBackground3;
    default:
      return StakingBackground1;
  }
}

export const converListItem = (list) => {
  return (list || []).map((item, index) => {
    return {
      ...item,
      descriptionRoot: item?.description,
      lottie: getLottie(item?.lottie),
      isLotte: getIsLottie(item?.lottie),
      lottieSparkle: getSparkle(item?.lottie),
      background: getBackground(item?.background),
      disable: process.env.REACT_APP_POOL_DISABLE === "true",
      apy: item?.fixedAPY || 0,
      lockDuration: item?.lockPeriod,
      total: new BigNumber(item?.nftStakedRequired || 0),
      totalStaked: getBalanceAmount(item?.totalStaked || 0),


      isLoading: false,
      titleMint: 'Mint A Free Loadout',
      bodyMint: 'Staking a minimum amount of UNDEAD into the staking pool will allow you to mint a free weapon loadout instantly. Only one weapon loadout mint per pool, per wallet, is permitted.',
      footerMint: 'Note: You will only receive a weapon loadout if you stake the minimum UNDEAD amount.',
      isMintStake: true
    }
  }).sort((a, b) => a.order - b.order)
    // .filter((item, id) => id === 0)
}

const FIELD_DEPOSIT_LIST = {
  ID: 'id',
  AMOUNT: 'amount',
  REWARD: 'reward',
  LOCKED_FROM: 'lockedFrom',
  LOCKED_TO: 'lockedTo',
  FIXED_APY: 'fixedAPY',
  LAST_REWARD_TIME: 'lastRewardTime',
  DEPOSIT_TIME: 'depositTime',
  PENDING_REWARD: 'pendingReward'
}

export const convertListDeposit = (objectListDeposit, depositLength) => {
  if (!objectListDeposit) {
    return [];
  }
  const listDeposit = [];
  let myEarnings = new BigNumber(0)

  for (let i = 0; i < depositLength; i++) {
    const amount = objectListDeposit[`${FIELD_DEPOSIT_LIST.AMOUNT}-${i}`] || new BigNumber(0);

    if (!amount.gt(0)) {
      continue;
    }

    const depositById = {
      'id': objectListDeposit[`${FIELD_DEPOSIT_LIST.ID}-${i}`],
      'amount': objectListDeposit[`${FIELD_DEPOSIT_LIST.AMOUNT}-${i}`],
      'reward': objectListDeposit[`${FIELD_DEPOSIT_LIST.REWARD}-${i}`],
      'lockedFrom': objectListDeposit[`${FIELD_DEPOSIT_LIST.LOCKED_FROM}-${i}`],
      'lockedTo': objectListDeposit[`${FIELD_DEPOSIT_LIST.LOCKED_TO}-${i}`],
      'fixedAPY': objectListDeposit[`${FIELD_DEPOSIT_LIST.FIXED_APY}-${i}`],
      'lastRewardTime': objectListDeposit[`${FIELD_DEPOSIT_LIST.LAST_REWARD_TIME}-${i}`],
      'depositTime': objectListDeposit[`${FIELD_DEPOSIT_LIST.DEPOSIT_TIME}-${i}`],
      'pendingReward': objectListDeposit[`${FIELD_DEPOSIT_LIST.PENDING_REWARD}-${i}`]
    }

    myEarnings = myEarnings.plus(objectListDeposit[`${FIELD_DEPOSIT_LIST.PENDING_REWARD}-${i}`]);
    listDeposit.push(depositById);
  }

  return { listDeposit, myEarnings };

}