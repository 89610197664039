import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const Wrapper = styled.div`
    background: #0f1014;

    position: relative;
    min-height: 100vh;
    width: 100%;

    &.notShow {
        opacity: 0;
    }

    .modal-boxed {
        min-width: 389rem;

        // @media screen and (max-width: 767px) {
        //     width: 1050rem;
        // }
    }

    @media screen and (max-width: 767px) {
    }
`;

export const BubbleLayer = styled.div`
    position: relative;
    opacity: 0.7;

    .bubble {
        position: relative;
    }

    @media screen and (max-width: 767px) {
    }

    .left {
        position: absolute;
        left: -6rem;
        top: -8rem;
        width: 31.25vw;
        min-height: 850rem;

        @media screen and (max-width: 767px) {
            width: 65vw;
            left: -50rem;
            top: 300rem;
            min-height: 400px;
        }
    }

    .left-bubble {
        position: absolute;
        width: 31.25vw;
        left: -6rem;
        top: 550rem;
        min-height: 300rem;

        // background-image: linear-gradient(to bottom, rgba(6,7,12,0), rgba(6,7,12,0.9));

        @media screen and (max-width: 767px) {
            // background-image: linear-gradient(to right, rgba(6,7,12,0.8), rgba(6,7,12,0.9));
            width: 65vw;
            left: -50rem;
            top: 1600rem;
            height: 400rem;
        }
    }

    .right-bubble {
        position: absolute;
        width: 44.5vw;
        right: 0;
        top: 550rem;
        min-height: 400rem;

        @media screen and (max-width: 767px) {
            // background-image: linear-gradient(to left, rgba(6,7,12,0.8), rgba(6,7,12,0.9));
            width: 90vw;
            right: 0;
            top: 1600rem;
            height: 500rem;
        }
    }

    .right {
        position: absolute;
        right: 0;
        top: 24rem;
        width: 44.5vw;
        min-height: 1033.5rem;

        @media screen and (max-width: 767px) {
            width: 90vw;
            min-height: 500rem;
        }
    }
`;

export const HeaderBanner = styled.div`
    position: relative;
    top: 155.5rem;
    z-index: 100;

    @media screen and (max-width: 767px) {
        top: 550rem;
    }

    @media screen and (min-width: 768px) and (max-width: 1279px) {
        z-index: 50;
        top: 300rem;
    }

    .image-layout {
        position: relative;

        @media screen and (max-width: 767px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            height: 400px;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }

        .left-overlay {
            position: absolute;
            z-index: 100;
            left: 0;
            bottom: 0;

            // background-image: linear-gradient(to bottom, rgba(6,7,12,0), rgba(6,7,12,0.8)), linear-gradient(to bottom, rgba(6,7,12,0), rgba(6,7,12,0.8));
            height: 400px;
            width: 76%;

            @media screen and (max-width: 767px) {
                display: none;
            }

            @media (min-width: 768px) and (max-width: 1279px) {
                // display: none;
            }
        }

        .left-layer {
            position: absolute;
            left: 0;

            @media screen and (min-width: 1024px) and (max-width: 1279px) {
                width: 35%;
            }

            .left-header {
                width: 29.33vw;
                min-height: 564.5rem;

                @media screen and (max-width: 767px) {
                    position: absolute;
                    left: -100px;
                    width: 70vw;
                    height: auto;
                }

                @media screen and (min-width: 768px) and (max-width: 1279px) {
                }
            }
        }

        .overlay {
            display: none;
            // background-image: linear-gradient(to bottom, rgba(6,7,12,0), rgba(6,7,12,0.9)), linear-gradient(to bottom, rgba(6,7,12,0), rgba(6,7,12,0.9));
            width: 100%;
            height: 300px;

            position: absolute;
            z-index: 100;
            right: 0;
            top: 100px;

            @media screen and (max-width: 767px) {
                display: flex;
            }
        }

        .right-overlay {
            position: absolute;
            z-index: 100;
            right: 0;
            bottom: 0;

            // background-image: linear-gradient(to bottom, rgba(6,7,12,0), rgba(6,7,12,0.9));
            height: 500px;
            width: 75%;

            @media screen and (max-width: 767px) {
                display: none;
            }

            @media (min-width: 768px) and (max-width: 1279px) {
            }
        }

        .right-layer {
            position: absolute;
            right: 0;
            top: -13rem;

            @media screen and (max-width: 767px) {
                top: 0;
                right: -25%;
            }

            @media screen and (min-width: 1024px) and (max-width: 1279px) {
                width: 35%;
            }

            .right-header {
                width: 27.14vw;
                height: 575.5rem;

                @media screen and (max-width: 767px) {
                    position: absolute;
                    width: 70vw;
                    right: 0;
                    height: auto;
                }
            }
        }
    }
`;

export const Document = styled.div`
    background-image: radial-gradient(circle at 0 0, #d20000, #af0000);
    padding: 10px 39.5rem 10px 29rem;
    margin: 10rem 95rem 50rem 95rem;

    border-radius: 7.5rem;
    box-shadow: 0px 3.5rem 50rem 0 rgba(0, 0, 0, 0.5);
    position: relative;
    z-index: 200;

    @media screen and (max-width: 767px) {
        border-radius: 7.5px;
        margin-top: 50px;
        margin-bottom: 40px;
        padding: 52rem 20rem 52rem 70rem;
    }

    .box {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 8fr 1fr;
        grid-gap: 10px;

        @media screen and (max-width: 767px) {
            grid-template-columns: 35% 63%;

            grid-gap: 2%;
        }

        .left {
            width: 100%;

            @media screen and (max-width: 767px) {
                grid-row: 1 / 3;
            }
        }

        .center {
            h2 {
                font-family: Poppins;
                font-size: 17.5rem;
                font-weight: bold;
                line-height: 0.77;
                text-align: left;
                color: #fff;
                text-transform: uppercase;

                @media screen and (max-width: 767px) {
                    font-size: 38rem;
                    line-height: 1.5;
                }
            }

            p {
                font-family: Poppins;
                font-size: 15rem;
                line-height: 1.35;
                text-align: left;
                color: #fff;
                margin-bottom: 0;

                @media screen and (max-width: 767px) {
                    font-size: 34rem;
                    line-height: 1.2;
                }
            }
        }

        .read-btn {
            background-color: #5e0000;
            padding: 0px 22rem;
            width: 150rem;
            height: 40rem;

            display: flex;
            justify-content: center;
            align-items: center;

            cursor: pointer;

            &:hover {
                transform: scale(1.005);
                transition: all 1s;
            }

            @media screen and (max-width: 767px) {
                width: 100px;
                height: 28px;

                margin-top: 10px;
                grid-column-start: 2;
                grid-row-end: 3;
            }

            p {
                font-family: Poppins;
                font-size: 15rem;
                font-weight: 600;
                line-height: 1.73;
                text-align: center;
                color: #fff;

                margin: 0 5rem 0 0;

                @media screen and (max-width: 767px) {
                    font-size: 11px;
                }
            }

            img {
                width: 7rem;
                height: 11rem;

                @media screen and (max-width: 767px) {
                    width: 5px;
                    height: 7.5px;
                }
            }
        }
    }
`;

export const MyArsenal = styled.div`
    padding: 0 95rem 0 95rem;

    @media screen and (max-width: 767px) {
        padding: 0 85rem 0 85rem;
    }

    .loading-more {
        width: 118rem;
        height: 118rem;

        @media screen and (max-width: 767px) {
            width: 220rem;
            height: 220rem;
        }
    }
 
    .title {
        font-family: Poppins;
        font-size: 32.5rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: normal;
        text-align: left;
        color: #fff;

        @media screen and (max-width: 767px) {
            text-align: center;
            font-size: 75rem;
        }
    }

    .description {
        font-family: Poppins;
        font-size: 17.5rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #fff;

        @media screen and (max-width: 767px) {
            text-align: center;
            font-size: 40rem;
        }
    }

    .mint-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 15rem 14.5rem;
        padding-top: 10rem;
        padding-bottom: 80rem;

        @media screen and (max-width: 767px) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 26rem;
        }

        @media screen and (min-width: 768px) and (max-width: 1023px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        .loading {
            width: 350rem;
            min-height: 330rem;
            border: solid 2rem #202429;
            border-radius: 7.5rem;
            padding: 25rem 19rem 31rem 19rem;

            display: flex;
            flex-direction: column;
            align-items: center;

            animation: opacityPulse 2s ease-in-out infinite;

            @media screen and (max-width: 767px) {
                width: 100%;
                height: 100%;
                border-radius: 20rem;
            }

            .heading-loading {
                width: 120rem;
                height: 18rem;
                background-color: #505050;
                border-radius: 5rem;
                margin-bottom: 31.5rem;

                @media screen and (max-width: 767px) {
                    width: 150rem;
                    height: 32rem;
                }
            }

            .content-loading {
                width: 179rem;
                height: 179rem;
                border-radius: 50%;
                background-color: #505050;

                margin-bottom: 25rem;

                @media screen and (max-width: 767px) {
                    width: 300rem;
                    height: 300rem;
                    border-radius: 50%;
                }
            }

            .footer-loading {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .left-loading {
                    width: 156rem;
                    height: 20rem;
                    border-radius: 5rem;

                    background-color: #505050;

                    @media screen and (max-width: 767px) {
                        width: 200rem;
                        height: 32rem;
                    }
                }

                .right-loading {
                    width: 75.5rem;
                    height: 28.5rem;
                    border-radius: 5rem;

                    background-color: #505050;

                    @media screen and (max-width: 767px) {
                        width: 170rem;
                        height: 55rem;
                    }
                }
            }
        }

        .mint-item {
            // padding: 25rem 17.5rem 25rem 19rem;
            border-radius: 7.5rem;
            display: flex;

            &.nope {
                padding: 0;

                &:hover {
                    transition: 0.4s;
                    transform: scale(1.01);
                    box-shadow: 0 0 20rem 2rem rgba(32,36,41,0.8);
                }
            }

            &.border { 
                border: solid 2rem #202429;

                &:hover {
                    transition: 0.4s;
                    transform: scale(1.01);
                    box-shadow: 0 0 20rem 2rem rgba(144,0,0,0.8);
                    border: solid 2rem #900000;
                }
            }

            @media (max-width: 767px) {
                // padding: 49rem 28rem 40rem 28rem;
            }

            .no-content {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;

                @media (max-width: 767px) {
                    height: 100%;
                    width: 100%;
                    text-align: center;
                }

                .none {
                    width: 76rem;
                    cursor: pointer;
                    position: absolute;

                    @media (max-width: 767px) {
                        width: 120rem;
                    }
                }
            }
        }
    }
`;

export const MintContent = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 350rem;
    cursor: pointer;
    border-radius: 7.5rem;
    cursor: pointer;

    @media (max-width: 767px) {
        height: 550rem;
    }

    .top {
        display: flex;
        flex-direction: column;

        .heading {
            font-family: Poppins;
            font-size: 24.8rem;
            font-weight: bold;
            line-height: 1.19;
            text-align: center;
            color: #df1e33;
            margin-bottom: 0;

            @media (max-width: 767px) {
                font-size: 38.5rem;
            }
        }
    }

    .info {
        display: flex;
        justify-content: center;
        position: relative;
        padding: 22rem 0 9.5rem;

        .mintBg {
            width: 179rem;
            height: 205.5rem;

            @media (max-width: 767px) {
                width: 278rem;
                height: 315.5rem;
            }

            @media (min-width: 1024px) and (max-width: 1279px) {
            }

            @media (min-width: 1280px) {
            }
        }

        .knife {
            position: absolute;
            z-index: 40;

            &.amateurKnife {
                width: 179rem;

                @media (max-width: 767px) {
                    width: 275rem;
                }
            }

            &.survivorKnife {
                width: 199rem;
                height: 215rem;
                bottom: 10rem;
                left: 42%;
                transform: translateX(-42%);

                @media (max-width: 767px) {
                    width: 288rem;
                    height: 98%;

                    bottom: 5rem;
                }
            }

            &.assassinKnife {
                width: 199rem;
                bottom: 12rem;
                right: 60rem;

                @media (max-width: 767px) {
                    width: 300rem;
                    right: 100rem;
                }
            }

            &.killerKnife {
                bottom: 7rem;
                width: 219rem;
                right: 52rem;

                @media (max-width: 767px) {
                    width: 315rem;
                    right: 90rem;
                    bottom: 10rem;
                }
            }

            &.rookieKnife {
                width: 199rem;
                height: 215rem;
                bottom: 10rem;
                left: 42%;
                transform: translateX(-42%);

                @media (max-width: 767px) {
                    width: 288rem;
                    height: 98%;

                    bottom: 5rem;
                }
            }

            &.commanderKnife {
                width: 195rem;
                bottom: 5rem;
                right: 70rem;

                @media (max-width: 767px) {
                    width: 280rem;
                    bottom: 8rem;
                    right: 110rem;
                }
            }
        }

        .pistol {
            position: absolute;
            z-index: 30;

            &.amateurPistol {
                width: 179rem;

                @media (max-width: 767px) {
                    width: 275rem;
                }
            }

            &.survivorPistol {
                width: 200rem;
                bottom: 11rem;
                left: 40rem;

                @media (max-width: 767px) {
                    width: 310rem;
                    left: 60rem;
                }
            }

            &.assassinPistol {
                bottom: 4rem;
                width: 200rem;

                @media (max-width: 767px) {
                    width: 300rem;
                }
            }

            &.killerPistol {
                bottom: 22rem;
                width: 200rem;

                @media (max-width: 767px) {
                    width: 310rem;
                    bottom: 10rem;
                }
            }

            &.rookiePistol {
                width: 212rem;
                bottom: 11rem;
                left: 50rem;

                @media (max-width: 767px) {
                    width: 320rem;
                    left: 80rem;
                    bottom: 10rem;
                }
            }

            &.commanderPistol {
                width: 190rem;
                bottom: 9rem;
                left: 55rem;

                @media (max-width: 767px) {
                    width: 280rem;
                    left: 85rem;
                    bottom: 20rem;
                }
            }

            &.veteranPistol {
                width: 210rem;
                bottom: 9rem;
                left: 41rem;

                @media (max-width: 767px) {
                    width: 280rem;
                    left: 80rem;
                    bottom: 16rem;
                }
            }
        }

        .shortGuns {
            position: absolute;
            top: 0;
            width: 179rem;
            z-index: 20;

            &.amateurShort {

            }

            &.survivorShort {
                width: 179rem;

                @media (max-width: 767px) {
                    width: 300rem;
                }
            }

            &.assassinShort {
                top: 15rem;
                right: 68rem;
                width: 195rem;

                @media (max-width: 767px) {
                    width: 290rem;
                    right: 120rem;
                    top: 10rem;
                }
            }

            &.killerShort {
                top: 10rem;
                width: 210rem;
                right: 55rem;

                @media (max-width: 767px) {
                    width: 280rem;
                    right: 125rem;
                    top: 35rem;
                }
            }

            &.rookieShort {
                width: 212rem;
                bottom: 17rem;
                left: 50rem;
                z-index: 30;

                @media (max-width: 767px) {
                    width: 320rem;
                    left: 78rem;
                    bottom: 20rem;
                }
            }

            &.commanderShort {
                width: 180rem;
                top: 7rem;
                right: 68rem;

                @media (max-width: 767px) {
                    width: 270rem;
                    left: 100rem;
                    top: -2rem;
                }
            }

            &.veteranShort {
                position: absolute;
                top: 10rem;
                width: 200rem;
                left: 42rem;
                z-index: 20;

                @media (max-width: 767px) {
                    width: 300rem;
                    left: 62rem;
                    top: 15rem;
                }
            }
        }

        .longGuns {
            position: absolute;
            z-index: 10;

            &.amateurLong {
                @media (max-width: 767px) {
                    width: 300rem;
                    right: 107rem;
                }
            }

            &.survivorLong {
                width: 199rem;
                right: 67rem;
                top: 14rem;

                @media (max-width: 767px) {
                    width: 300rem;
                    right: 107rem;
                }
            }

            &.assassinLong {
                top: 20rem;
                right: 70.5rem;
                width: 179rem;

                @media (max-width: 767px) {
                    width: 290rem;
                    right: 110rem;
                    top: 10rem;
                }
            }

            &.killerLong {
                width: 209rem;
                top: 16rem;
                right: 56rem;

                @media (max-width: 767px) {
                    width: 335rem;
                    right: 85rem;
                    top: 14rem;
                }
            }

            &.rookieLong {
                width: 212rem;
                bottom: 17rem;
                left: 50rem;
                z-index: 30;

                @media (max-width: 767px) {
                    width: 320rem;
                    left: 78rem;
                    bottom: 20rem;
                }
            }

            &.commanderLong {
                width: 180rem;
                right: 69rem;
                top: 5rem;

                @media (max-width: 767px) {
                    width: 280rem;
                    left: 88rem;
                    top: -5rem;
                }
            }

            &.veteranLong {
                position: absolute;
                z-index: 10;
                width: 200rem;
                left: 40rem;
                top: 10rem;

                @media (max-width: 767px) {
                    width: 280rem;
                    left: 85rem;

                    position: absolute;
                    z-index: 10;
                    width: 320rem;
                    left: 46rem;
                    top: 5rem;
                }
            }
        }

        .perks {
            position: absolute;
            width: 230rem;
            height: 260rem;
            bottom: 0rem;
            right: 60rem;

            z-index: 30;

            @media (max-width: 767px) {
                width: 290rem;
                height: 360rem;
                right: 110rem;
            }

            &.veteranPerks {
                width: 275rem;
                bottom: -1rem;
                right: 53rem;
            
                @media (max-width: 767px) {
                    width: 380rem;
                    height: 380rem;
                    right: 85rem;
                    bottom: -3.5rem;
                }
            }
        }

        .axe {
            position: absolute;
            width: 240rem;
            bottom: 3.5rem;
            left: 20rem;
            z-index: 50;

            @media (max-width: 767px) {
                position: absolute;
                width: 350rem;
                bottom: 2rem;
                left: 32rem;
                z-index: 50;
            }
        }
    }

    .bottom {
        display: flex;
        align-items: center;
        flex: 1;
        width: 100%;

        .detail {
            font-family: Poppins;
            font-size: ${props => props.fontSizeWeb && props.fontSizeWeb}rem;
            font-weight: 500;
            line-height: 1.18;
            text-align: left;
            color: #fff;
            flex-grow: 1;
            width: 68%;
            margin-bottom: 0;
            margin-right: 2%;

            @media (max-width: 767px) {
                margin-right: 5%;
                font-weight: 500;
                font-size: ${props => props.fontSizeMobile && props.fontSizeMobile}rem;
            }
        }

        .mint-btn {
            height: 28rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2.5px;
            width: 75.5rem;
            padding: 0 5px;
            text-align: center;
            width: 30%;
            cursor: pointer;

            &:hover {
                transform: scale(1.010);
                transition: all 1s;
            }

            @media (max-width: 767px) {
                height: 45rem;
                width: 30%;
            }

            .view-lbl {
                font-family: Poppins;
                font-size: 10.8rem;
                font-weight: 600;
                text-align: center;
                color: #010101;

                @media (max-width: 767px) {
                    font-size: 15rem;
                }
            }
        }
    }
`;

export default WithDirection(Wrapper);