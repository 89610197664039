import React, { useState } from 'react'

const Image = ({ src }) => {
    const [didLoad, setLoad] = useState(false);

    const style = didLoad ? {} : { visibility: 'hidden' };

    return <img style={style} src={src} onLoad={() => setLoad(true)} alt='' />;
}

export default Image;