/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { EventEmitter } from 'fbemitter';
import { parseInt } from 'lodash';

const IntervalContext = React.createContext({
    emitter: new EventEmitter(),
    intervalAddOnceListener: () => {},
    intervalAddListener: () => {},
    intervalRemoveListener: () => {},
    intervalEmit: () => {},
});

// This context maintain 2 counters that can be used as a dependencies on other hooks to force a periodic Interval
const IntervalContextProvider = ({ children }) => {
    const [emitter, setEmitter] = useState(new EventEmitter());

    const timeout = {};

    const intervalAddOnceListener = useCallback((type, callback, seconds) => {
        emitter.once(type, callback);
        timeout[type] = seconds;
        setEmitter(emitter);
    }, [emitter, setEmitter]);

    const intervalAddListener = useCallback((type, callback, seconds) => {
        emitter.removeAllListeners(type);
        emitter.addListener(type, callback);
        timeout[type] = seconds;
        setEmitter(emitter);
    }, [emitter, setEmitter]);

    const intervalRemoveListener = useCallback(type => {
        emitter.removeAllListeners(type);
        setEmitter(emitter);
    }, [emitter, setEmitter]);

    const intervalEmit = useCallback((type, ...rest) => {
        emitter.emit(type, rest);
        setEmitter(emitter);
    }, [emitter, setEmitter]);

    useEffect(() => {
        const interval = setInterval(() => {
            const allEvents = emitter?._subscriber?._subscriptionsForType || {};
            for (const eventKey in allEvents) {
                const now = parseInt(Date.now() / 1000);
                if (now % timeout[eventKey] === 0) {
                    emitter.emit(eventKey);
                }
                // emitter.emit(eventKey);
                // const listeners = emitter.listeners(eventKey);
                // const now = parseInt(Date.now() / 1000);
                // if (now % timeout[eventKey] === 0 && typeof listeners?.[0] === 'function') {
                //     listeners?.[0]();
                // }
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        }
    }, [emitter, setEmitter])

    return <IntervalContext.Provider value={{
        intervalAddOnceListener,
        intervalAddListener,
        intervalRemoveListener,
        intervalEmit
    }}>{children}</IntervalContext.Provider>
};

export { IntervalContext, IntervalContextProvider }
