import React from 'react'
import styled from 'styled-components'
import { Button, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons'

import SilverButton from '../../../../assets/img/buttonBg/silverRoot.png'
import ButtonStakingOrange from '../../../../assets/img/buttonBg/orangeRoot.png';
import glow from '../../../../assets/img/buttonBg/orange-glow.png';
import glowDisable from '../../../../assets/img/buttonBg/gray-glow.png';

const antIcon = <LoadingOutlined style={{ fontSize: 20, color: '#000' }} spin />

const Title = styled.p`
  font-weight: bold;
  // line-height: 1.25;
  text-align: center;
  font-size: 15rem;
  margin-bottom: 0;
  width: 100%;

  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 767px) {
    font-size: 48rem;
  }
`
const Content = styled.p`
  line-height: 1.25;
  text-align: center;
  color : #000;
  font-size: 12px;
  margin-bottom: 5px;
  
  @media (max-width: 767px) {
    font-size: 12px;
  }
`
const ButtonStyle = styled(Button)`
  cursor: pointer;

  &.ant-btn-loading:before {
    display: none;
  }

  &[disabled] {
    color: unset;
    border-color: transparent;
    background: url(${SilverButton});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    height: auto;
    :hover,
    :active,
    :focus {
      background: url(${SilverButton});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .ant-btn-loading-icon {
    color: #ffffff;
  }
`
const ButtonSingleStyle = styled.div`
  cursor: pointer;
 
  position: absolute;
  left: 49.8%;
  top: -2rem;
  transform: translateX(-50%);

  border-radius: 0;
  border: none;

  background: url(${ButtonStakingOrange});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: contain;

  height: 35rem;
  width: 92.5%;

  @media (max-width: 767px) {
    top: -63rem;
    height: 160rem;
  }
  
  :hover,
  :active,
  :focus {
    background: url(${ButtonStakingOrange});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: contain;
  }

  &.ant-btn-loading:before {
    display: none;
  }


  &[disabled] {
    height: 35rem;
    width: 92.5%;

    left: 49.8%;
    top: -2rem;
    transform: translateX(-50%);

    border-color: transparent;
    border: none;

    background: url(${SilverButton});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    cursor: not-allowed;

    @media (max-width: 767px) {
      top: -63rem;
      height: 160rem;
    }

    :hover,
    :active,
    :focus {
      background: url(${SilverButton});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &.ant-btn-loading-icon {
    color: #ffffff;
  }
`

const ButtonStakingStyle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 32rem;

  :hover {
    transform: scale(1.02);
    transition: all .2s;
  }

  @media (max-width: 767px) {
    margin: 80rem 0 120rem;
  }

  .background{
    border-radius: 3px;
    box-shadow: 0 0px 8px 1px #f59600;
    background-image: radial-gradient(#f59600,#38260c);
  }

  .loadingContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &[disabled] {
    :hover {
      transform: scale(1);
    }

    @media (max-width: 767px) {
      margin: 80rem 0 120rem;
    }

    .background{
      border-radius: 3px;
      box-shadow: 0 0px 8px 1px #3d3d3f;
      background-image: radial-gradient(#1f1f21,#3d3d3f);
    }

    .bottom-mint-btn {
      width: 99%;

      @media (max-width: 767px) {

      }
    }
  }
  
  .bottom-mint-btn {
    width: 99%;

    @media (max-width: 767px) {

    }
  }
`

const ButtonStaking = ({ title, content, event, loading, disabled, icon = null, isOrange = false }) => {
  return (
    content ? (
      <ButtonStyle loading={loading} size={''} onClick={event} disabled={disabled}>
        <div>
          <Title>{title}</Title>
          <Content>{content}</Content>
        </div>
      </ButtonStyle>
    ) : (
      <ButtonStakingStyle
        disabled={disabled}
      >
        <img src={disabled ? glowDisable : glow} alt="mint" className="bottom-mint-btn" />
        <ButtonSingleStyle
          onClick={() => !disabled && event()}
          disabled={disabled}
        >
          {loading ? (
            <div className={'loadingContainer'}>
              <Spin indicator={antIcon} />
            </div>
          ) : (
            <Title>
              {icon}
              {title}
            </Title>
          )}
        </ButtonSingleStyle>
      </ButtonStakingStyle >
    )
  )
}

export default ButtonStaking
