import localForage from 'localforage';
import { persistReducer } from 'redux-persist';

import common from './common';
import user from './user';
import pools from './pools';
import prices from './prices';
import block from './block';
import projects from './projects';
import packages from './package';
import ownership from './ownership';
import setting from './setting';

const commonConfig = {
  key: 'common',
  storage: localForage,
  whitelist: []
};

const userConfig = {
  key: 'user',
  storage: localForage,
  whitelist: ['userAccount', 'transactionLogs', 'chainId']
};

const poolsConfig = {
  key: 'pools',
  storage: localForage,
  whitelist: []
};

const pricesConfig = {
  key: 'prices',
  storage: localForage,
  whitelist: []
};

const blockConfig = {
  key: 'block',
  storage: localForage,
  whitelist: []
};

const reducer = {
  common: persistReducer(commonConfig, common),
  user: persistReducer(userConfig, user),
  pools: persistReducer(poolsConfig, pools),
  prices: persistReducer(pricesConfig, prices),
  block: persistReducer(blockConfig, block),
  projects,
  packages,
  ownership,
  setting
};

export default reducer;
