import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function usePackages(props) {
    const dispatch = useDispatch();

    const { pathname, data } = props;

    const [allPackages, setAllPackages] = useState(Array(4).fill({}));

    const { requesting } = useSelector(state => state.pools?.resultStakeMint);

    useEffect(() => {
        handlePackages(data, setAllPackages)

        return () => {
            setAllPackages(Array(0).fill({}));
        }
    }, [dispatch, pathname, data]);

    return { allPackages, requesting };
}

export default usePackages;

const handlePackages = (items, setPackages) => {
    if (!items) return;

    setPackages(items);
}