export const exportTimeValue = (time) => {
    if (!time) return;

    let timeValue = '00';

    if (time < 10) {
        timeValue = `0${time}`;
    } else {
        timeValue = `${time}`;
    }

    return timeValue;
}
