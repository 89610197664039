import Web3 from "web3";
import Logger from 'js-logger';
import { createAction, createActions } from 'redux-actions';
import { isEmpty } from 'lodash';
import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
// import WalletConnectProvider from "@walletconnect/web3-provider";
import detectGamestopProvider from "@gamestopnft/detect-gamestop-provider";

import { checkWrongNetwork } from './common';
import { fetchPoolDataClean } from './pools';

import http from '../services/http';
import User from '../services/api/user';
import { getProvider } from '../utils/common';
import { getWalletConnectProvider } from '../utils/providers';
import { CONNECTION_TYPES } from '../containers/ConnectToWalletModal/constants';
import { getAccountSymbol, getChainName, getBlockExplorerUrls, verifyChainId } from '../utils/web3';
import { approve } from "../utils/presaleNft";
import { getAllowance } from "../utils/callHelpers";

export const clearTransactionLogs = createAction('CLEAR_TRANSACTION_LOGS');
export const storeTransactionLog = createAction('STORE_TRANSACTION_LOG');
export const clearUserDataOnDisconnectMetamask = createAction('CLEAR_USER_DATA_ON_DISCONNECT_METAMASK');
export const setUserAccounts = createAction('SET_USER_ACCOUNTS');

export const updateWalletBalance = createAction('UPDATE_WALLET_BALANCE');
export const loadingConnectAccount = createAction('LOADING_CONNECT_ACCOUNT');
export const setUserNonce = createAction('SET_USER_NONCE');
export const setUserSignKey = createAction('SET_USER_SIGNKEY');
export const setUserToken = createAction('SET_USER_TOKEN');

const NETWORKS_DEV = ['0x1', '0x5'];
const NETWORKS_PROD = ['0x1'];
const ALLOW_NETWORK = process.env.REACT_APP_DEV === '1' ? NETWORKS_DEV : NETWORKS_PROD;
// const PATHS_UN_CHECK_NETWORK = ['/my-weapons'];

/** FETCH METALS **/
const { fetchMeta1Request, fetchMeta1Success, fetchMeta1Fail } = createActions({
  FETCH_META1_REQUEST: () => {},
  FETCH_META1_SUCCESS: data => ({ data }),
  FETCH_META1_FAIL: error => ({ error }),
});

export const fetchMeta1 = () => (dispatch) => {
  dispatch(fetchMeta1Request());

  return User.getMeta1()
    .then(({ data }) => {
      dispatch(fetchMeta1Success(data));
      return data;
    })
    .catch(error => {
      dispatch(fetchMeta1Fail(error));
      return error;
    });
}

/** CONNECT TO METAMASK **/
const { connectWalletRequest, connectWalletSuccess, connectWalletFail } = createActions({
  CONNECT_WALLET_REQUEST: () => {},
  CONNECT_WALLET_SUCCESS: data => ({ data }),
  CONNECT_WALLET_FAIL: error => ({ error }),
});

export const switchNetwork = async (poolChainId, cb = () => { }, toastInfo) => {
  // const connectorId = window.localStorage.getItem('connectorId');
  // const chainId = parseInt(poolChainId);
  // if (connectorId === CONNECTION_TYPES.metamask) {
  //   if (window.ethereum) {
  //     try {
  //       window.ethereum.request({
  //         method: 'wallet_addEthereumChain',
  //         params: [
  //           {
  //             chainId: `0x${chainId.toString(16)}`,
  //             chainName: getChainName(chainId),
  //             nativeCurrency: {
  //               name: getAccountSymbol(chainId),
  //               symbol: getAccountSymbol(chainId),
  //               decimals: 18,
  //             },
  //             rpcUrls: [getProvider(`0x${chainId.toString(16)}`)],
  //             blockExplorerUrls: [getExplorerUrl(`0x${chainId.toString(16)}`)],
  //           },
  //         ],
  //       });
  //       return true;
  //     } catch (e) {
  //       return false;
  //     }
  //   }
  // } else if (connectorId === CONNECTION_TYPES.walletconnect) {
  //   toastInfo && toastInfo('Info', `Please change network to ${chainId === 56 ? 'BSC Mainnet' : 'BSC Testnet'} in WalletConnected App`);
  // }
  // return false;

  const chainId = verifyChainId(poolChainId);
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }],
    });
    cb();
  } catch (e) {
    if (e.code === 4902) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId,
              chainName: getChainName(chainId),
              nativeCurrency: {
                name: getAccountSymbol(chainId),
                symbol: getAccountSymbol(chainId),
                decimals: 18,
              },
              rpcUrls: [getProvider(chainId)],
              blockExplorerUrls: [getBlockExplorerUrls(chainId)],
            },
          ],
        });
      } catch (addError) {
        return false
      }
    }
  }
};

export const connectMetaMask = () => async (dispatch, getState) => {
  dispatch(connectWalletRequest());

  // Check metamask is install or not
  if (window.ethereum) {
    if (window.ethereum.isMetaMask) {
      window.ethereum.autoRefreshOnNetworkChange = false;
    }
    const provider = await detectEthereumProvider();
    // If the provider returned by detectEthereumProvider is not the same as
    // window.ethereum, something is overwriting it, perhaps another wallet.
    if (provider !== window.ethereum) {
      window.web3 = new Web3(provider);
    } else {
      window.web3 = new Web3(window.ethereum);
      window.ethersProvider = new ethers.providers.Web3Provider(window.ethereum, 'any');
    }

    window.ethereum && window.ethereum.on('accountsChanged', async (accounts) => {
      const isConnect = getState().user?.connectWallet?.isConnect;

      if (!isConnect) return;

      if (window.ethereum.selectedAddress) {
        const chainId = window.ethereum.chainId;
        const account = accounts[0]?.toLowerCase();
        const balance = await window.ethersProvider.getBalance(account);

        if (!ALLOW_NETWORK.includes(chainId)) {
          return dispatch(checkWrongNetwork(true));
        }

        dispatch(checkWrongNetwork(false));

        /* sign */
        const { data } = await User.getUserNonceKey(account, chainId);
        dispatch(setUserNonce(data));
        const result = await dispatch(web3Auth(account, chainId));

        if (result) {
          dispatch(setUserAccounts({ accounts: [account], balance }));
          dispatch(connectWalletSuccess());

          return true;
        } else {
          dispatch(connectWalletFail());

          return false;
        }
      } else {
        dispatch(loadingConnectAccount(false));
        dispatch(connectWalletFail());
        dispatch(disconnect());
      }
    });

    window.ethereum && window.ethereum.on('chainChanged', async (chainId) => {
      const token = getState().user?.userAccount?.token;
      const defaultAccount = await window.ethersProvider.getSigner().getAddress();
      const account = defaultAccount.toLowerCase();
      const balance = await window.ethersProvider.getBalance(account);

      if (account) {
        if (!ALLOW_NETWORK.includes(chainId)) {
          return dispatch(checkWrongNetwork(true))
        }

        dispatch(checkWrongNetwork(false))

        if (token) {
          dispatch(setUserAccounts( {accounts: [account], balance, chainId }));
          dispatch(connectWalletSuccess());

          return true;
        } else {
          const { data } = await User.getUserNonceKey(account, chainId);
          dispatch(setUserNonce(data));

          const result = await dispatch(web3Auth(account, chainId));
  
          if (result) {
            dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
            dispatch(connectWalletSuccess());

            return true;
          } else {
            dispatch(connectWalletFail());

            return false;
          }
        }
      }
    });

    return window.ethereum.request({ method: 'eth_requestAccounts' })
      .then(async () => {
        const chainId = window.ethereum.chainId;
        const defaultAccount = await window.ethersProvider.getSigner().getAddress();
        const account = defaultAccount.toLowerCase();
        const balance = await window.ethersProvider.getBalance(account);

        if (!ALLOW_NETWORK.includes(chainId)) {
          return dispatch(checkWrongNetwork(true));
        }

        dispatch(checkWrongNetwork(false));

        /** Sign **/ 
        const { data } = await User.getUserNonceKey(account, chainId);
        dispatch(setUserNonce(data));
        window.localStorage.setItem('nonce', data.nonce);

        const result = await dispatch(web3Auth(account, chainId));

        if (result) {
          dispatch(setUserAccounts({
            accounts: [account],
            balance,
            chainId,
          }));

          dispatch(connectWalletSuccess());

          return true;
        } else {
          dispatch(setUserAccounts({ accounts: [] }));
          dispatch(connectWalletFail());

          return false;
        }
      })
      .catch((error) => {
        dispatch(disconnect());
        dispatch(connectWalletFail(error));
        
        return false;
      });
  }

  return new Promise((resolve, reject) => {
    const err = 'Metamask not install.';

    resolve(err);
    dispatch(disconnect());
    
    return dispatch(connectWalletFail(err));
  });
};

export const connectGameStop = () => async (dispatch, getState) => {
  dispatch(connectWalletRequest());

  if (window.gamestop) {
    if (window?.gamestop?.isGamestop) {
      window.ethereum.autoRefreshOnNetworkChange = false;
    }

    const provider = await detectGamestopProvider();

    if (provider !== window.gamestop) {
      window.web3 = new Web3(provider);
    } else {
      window.web3 = new Web3(window.gamestop);
      window.ethersProvider = new ethers.providers.Web3Provider(window.gamestop, 'any');
    }

    provider && provider.on('accountsChanged', async (accounts) => {
      const isConnect = getState().user?.connectWallet?.isConnect;

      if (!isConnect) return;

      if (!accounts || !accounts[0]) {
        dispatch(setUserAccounts({ accounts }));
      } else {
        const chainId = await provider.chainId();
        const defaultAccount = await window.ethersProvider.getSigner().getAddress();
        const account = defaultAccount.toLowerCase();
        const balance = await window.ethersProvider.getBalance(account);

        if (!ALLOW_NETWORK.includes(chainId)) {
          return dispatch(checkWrongNetwork(true));
        }

        dispatch(checkWrongNetwork(false));

        /* sign */
        const { data } = await User.getUserNonceKey(account, chainId);
        dispatch(setUserNonce(data));
        const result = await dispatch(web3Auth(account, chainId));

        if (result) {
          dispatch(setUserAccounts({ accounts: [account], balance }));
          dispatch(connectWalletSuccess());

          return true;
        } else {
          dispatch(connectWalletFail());

          return false;
        }
      }
    });

    provider && provider.on('chainChanged', async (chainId) => {
      const defaultAccount = await window.ethersProvider.getSigner().getAddress();
      const account = defaultAccount.toLowerCase();
      const balance = await window.ethersProvider.getBalance(account);
      const token = getState().user?.userAccount?.token;

      if (account) {
        if (!ALLOW_NETWORK.includes(chainId)) {
          return dispatch(checkWrongNetwork(true))
        }

        dispatch(checkWrongNetwork(false))

        if (token) {
          dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
          dispatch(connectWalletSuccess());

          return true;
        } else {
          const { data } = await User.getUserNonceKey(account, chainId);
          dispatch(setUserNonce(data));

          const result = await dispatch(web3Auth(account, chainId));
  
          if (result) {
            dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
            dispatch(connectWalletSuccess());

            return true;
          } else {
            dispatch(connectWalletFail());

            return false;
          }
        }
      }
    });

    return provider.request({ method: 'eth_requestAccounts' })
      .then(async () => {
        const chainId = await provider.chainId();
        const defaultAccount = await window.ethersProvider.getSigner().getAddress();
        const account = defaultAccount.toLowerCase();
        const balance = await window.ethersProvider.getBalance(account);

        if (!ALLOW_NETWORK.includes(chainId)) {
          return dispatch(checkWrongNetwork(true));
        }

        dispatch(checkWrongNetwork(false));

        /** Sign **/ 
        const { data } = await User.getUserNonceKey(account, chainId);
        dispatch(setUserNonce(data));
        window.localStorage.setItem('nonce', data.nonce);

        const result = await dispatch(web3Auth(account, chainId));

        if (result) {
          dispatch(setUserAccounts({
            accounts: [account],
            balance,
            chainId,
          }));

          dispatch(connectWalletSuccess());

          return true;
        } else {
          dispatch(setUserAccounts({ accounts: [] }));
          dispatch(connectWalletFail());

          return false;
        }
      })
      .catch((error) => {
        dispatch(disconnect());
        dispatch(connectWalletFail(error));
        
        return false;
      });
  }

  return new Promise((resolve, reject) => {
    const err = 'Metamask not install.';

    resolve(err);
    dispatch(disconnect());
    
    return dispatch(connectWalletFail(err));
  });
}

/** CONNECT TO WALLET CONNECT **/
export const connectToWalletConnect = () => async (dispatch, getState) => {
  try {
    const provider = await getWalletConnectProvider();

    dispatch(connectWalletRequest());

    const defaultAccount = await window.ethersProvider.getSigner().getAddress();
    const account = defaultAccount.toLowerCase();
    const balance = await window.ethersProvider.getBalance(account);
    const chainId = `0x${Number(provider.chainId).toString(16)}`;

    const token = getState().user?.userAccount?.token;

    if (!ALLOW_NETWORK.includes(chainId)) {
      return dispatch(checkWrongNetwork(true));
    }

    dispatch(checkWrongNetwork(false));

    if (token) {
      dispatch(setUserAccounts({
        accounts: [account],
        balance,
        chainId,
      }));
      dispatch(connectWalletSuccess());

      return true;
    } else {
      const { data } = await User.getUserNonceKey(account, chainId);
      dispatch(setUserNonce(data));
      window.localStorage.setItem('nonce', data.nonce);

      const result = await dispatch(web3Auth(account, chainId));

      if (result) {
        dispatch(setUserAccounts({
          accounts: [account],
          balance,
          chainId,
        }));
        dispatch(connectWalletSuccess());
        
        return true;
      } else {
        dispatch(setUserAccounts({ accounts: [] }));
        dispatch(connectWalletFail('FAILED'));
      }
    }

    provider.on("accountsChanged", async (accounts) => {
      // const chainId = await window.web3.eth.getChainId();
      // const balance = await window.web3.eth.getBalance(accounts[0]);
      // const hexChainId = await window.web3.utils.toHex(chainId);

      // if (!ALLOW_NETWORK.includes(hexChainId)) {
      //   return dispatch(checkWrongNetwork(true))
      // }

      // dispatch(checkWrongNetwork(false))

      // const { data } = await User.getUserNonceKey(accounts[0], chainId);
      // dispatch(setUserNonce(data));

      // const result = await dispatch(web3Auth(accounts[0], chainId));

      // if (result) {
      //   dispatch(setUserAccounts({ accounts, balance, chainId }));
      //   dispatch(connectWalletSuccess());

      //   return true;
      // } else {
      //   dispatch(connectWalletFail());
      //   dispatch(disconnect());

      //   return false;
      // }
      dispatch(disconnect());
    });

    // Subscribe to chainId change
    provider.on("chainChanged", async (chainId) => {
      const hexChainId = `0x${Number(chainId).toString(16)}`;

      if (!ALLOW_NETWORK.includes(hexChainId)) {
        return dispatch(checkWrongNetwork(true));
      }

      dispatch(checkWrongNetwork(false));

      const defaultAccount = await window.ethersProvider.getSigner().getAddress();
      const account = defaultAccount.toLowerCase();
      const balance = await window.ethersProvider.getBalance(account);

      if (token) {
        dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
        dispatch(connectWalletSuccess());

        return true;
      } else {
        const { data } = await User.getUserNonceKey(account, chainId);
        dispatch(setUserNonce(data));

        const result = await dispatch(web3Auth(account, chainId));

        if (result) {
          dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
          dispatch(connectWalletSuccess());

          return true;
        } else {
          dispatch(connectWalletFail());

          return false;
        }
      }
    });

    provider.on('disconnect', () => {
      dispatch(disconnect());
      window.web3 = undefined;
    });

    return true;
  } catch (error) {
    dispatch(connectWalletFail(error));
    dispatch(disconnect());
    return false;
  }
};

const autoConnectMetamask = () => async (dispatch, getState) => {
  if (window.ethereum) {
    if (window.ethereum.isMetaMask) {
      window.ethereum.autoRefreshOnNetworkChange = false;
    }
    const provider = await detectEthereumProvider();
    // If the provider returned by detectEthereumProvider is not the same as
    // window.ethereum, something is overwriting it, perhaps another wallet.
    if (provider !== window.ethereum) {
      window.web3 = new Web3(provider);
    } else {
      window.web3 = new Web3(window.ethereum);
      window.ethersProvider = new ethers.providers.Web3Provider(window.ethereum, 'any');
    }

    window.ethereum.request({ method: 'eth_accounts' }).then(async (accounts) => {
      const chainId = window.ethereum.chainId;
      const account = accounts[0]?.toLowerCase();

      if (!ALLOW_NETWORK.includes(chainId)) {
        return dispatch(checkWrongNetwork(true))
      }

      dispatch(checkWrongNetwork(false))

      if (isEmpty(accounts)) {
        dispatch(connectWalletFail());
        dispatch(setUserAccounts({ accounts: [] }));
      } else {
        const balance = await window.ethersProvider.getBalance(account);
        const token = getState().user?.userAccount?.token;

        if (token) {
          dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
          dispatch(connectWalletSuccess());

          return true;
        } else {
          const { data } = await User.getUserNonceKey(account, chainId);
          dispatch(setUserNonce(data));
  
          const result = await dispatch(web3Auth(account, chainId));
  
          if (result) {
            dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
            dispatch(connectWalletSuccess());

            return true;
          } else {
            dispatch(setUserAccounts({ accounts: [] }));
            dispatch(connectWalletFail());

            return false;
          }
        }
      }
    });

    window.ethereum && window.ethereum.on('accountsChanged', async (accounts) => {
      const isConnect = getState().user?.connectWallet?.isConnect;

      if (!isConnect) return;

      if (window.ethereum.selectedAddress) {
        const chainId = window.ethereum.chainId;
        const account = accounts[0]?.toLowerCase();
        const balance = await window.ethersProvider.getBalance(account);

        if (!ALLOW_NETWORK.includes(chainId)) {
          return dispatch(checkWrongNetwork(true));
        }

        dispatch(checkWrongNetwork(false));

        /* sign */
        const { data } = await User.getUserNonceKey(account, chainId);
        dispatch(setUserNonce(data));
        const result = await dispatch(web3Auth(account, chainId));

        if (result) {
          dispatch(setUserAccounts({ accounts: [account], balance }));
          dispatch(connectWalletSuccess());

          return true;
        } else {
          dispatch(connectWalletFail());

          return false;
        }
      } else {
        dispatch(loadingConnectAccount(false));
        dispatch(connectWalletFail());
        dispatch(disconnect());
      }
    });

    window.ethereum && window.ethereum.on('chainChanged', async (chainId) => {
      const defaultAccount = await window.ethersProvider.getSigner().getAddress();
      const account = defaultAccount.toLowerCase();

      if (account) {
        const balance = await window.ethersProvider.getBalance(account);
        const token = getState().user?.userAccount?.token;

        if (!ALLOW_NETWORK.includes(chainId)) {
          return dispatch(checkWrongNetwork(true))
        }

        dispatch(checkWrongNetwork(false));

        if (token) {
          dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
          dispatch(connectWalletSuccess());

          return true;
        } else {
          const { data } = await User.getUserNonceKey(account, chainId);
          dispatch(setUserNonce(data));
  
          const result = await dispatch(web3Auth(account, chainId));
  
          if (result) {
            dispatch(setUserAccounts({ accounts: [account], balance }));
            dispatch(connectWalletSuccess());

            return true;
          } else {
            dispatch(setUserAccounts({ accounts: [] }));
            dispatch(connectWalletFail());

            return false;
          }
        }
      }
    });
  }
};

const autoConnectGameStop = () => async (dispatch, getState) => {
  if (window.gamestop) {
    if (window?.gamestop?.isGamestop) {
      window.ethereum.autoRefreshOnNetworkChange = true;
    }

    const provider = await detectGamestopProvider();

    if (provider !== window.gamestop) {
      window.web3 = new Web3(provider);
    } else {
      window.web3 = new Web3(window.gamestop);
      window.ethersProvider = new ethers.providers.Web3Provider(window.gamestop, 'any');
    }

    provider.request({ method: 'eth_accounts' }).then(async (accounts) => {
      const chainId = await provider.chainId();
      const account = accounts[0]?.toLowerCase();

      if (isEmpty(accounts)) {
        dispatch(setUserAccounts({ accounts: [] }));
      } else {
        const balance = await window.ethersProvider.getBalance(account);
        const token = getState().user?.userAccount?.token;

        if (!ALLOW_NETWORK.includes(chainId)) {
          return dispatch(checkWrongNetwork(true))
        }

        dispatch(checkWrongNetwork(false))

        if (token) {
          dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
          dispatch(connectWalletSuccess());

          return true;
        } else {
          const { data } = await User.getUserNonceKey(account, chainId);
          dispatch(setUserNonce(data));
  
          const result = await dispatch(web3Auth(account, chainId));
  
          if (result) {
            dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
            dispatch(connectWalletSuccess());

            return true;
          } else {
            dispatch(setUserAccounts({ accounts: [] }));
            dispatch(connectWalletFail());

            return false;
          }
        }
      }
    });

    provider && provider.on('accountsChanged', async (accounts) => {
      // if (!account) return;
      // if (account === accounts[0]) return;
 
      // if (!accounts || !accounts[0]) {
      //   dispatch(setUserAccounts({ accounts }));
      // } else {
      //   const balance = await window.web3.eth.getBalance(accounts[0]);
      //   const chainId = await provider.chainId();

      //   if (!ALLOW_NETWORK.includes(chainId)) {
      //     return dispatch(checkWrongNetwork(true));
      //   }

      //   dispatch(checkWrongNetwork(false));

      //   /* sign */
      //   const { data } = await User.getUserNonceKey(accounts[0], chainId);
      //   dispatch(setUserNonce(data));
      //   const result = await dispatch(web3Auth(accounts[0], chainId));

      //   if (result) {
      //     dispatch(setUserAccounts({ accounts, balance }));
      //     dispatch(connectWalletSuccess());

      //     return true;
      //   } else {
      //     dispatch(connectWalletFail());

      //     return false;
      //   }
      // }

      return accounts;
    });

    provider && provider.on('chainChanged', async (chainId) => {
      const defaultAccount = await window.ethersProvider.getSigner().getAddress();
      const account = defaultAccount.toLowerCase();
    
      if (account) {
        const balance = await window.ethersProvider.getBalance(account);
        const token = getState().user?.userAccount?.token;

        if (!ALLOW_NETWORK.includes(chainId)) {
          return dispatch(checkWrongNetwork(true))
        }

        dispatch(checkWrongNetwork(false));

        if (token) {
          dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
          dispatch(connectWalletSuccess());

          return true;
        } else {
          const { data } = await User.getUserNonceKey(account, chainId);
          dispatch(setUserNonce(data));
  
          const result = await dispatch(web3Auth(account, chainId));
  
          if (result) {
            dispatch(setUserAccounts({ accounts: [account], balance }));
            dispatch(connectWalletSuccess());

            return true;
          } else {
            dispatch(setUserAccounts({ accounts: [] }));
            dispatch(connectWalletFail());

            return false;
          }
        }
      }
    });
  }
}

/** CONNECT TO WALLET CONNECT **/
const autoConnectWallet = () => async (dispatch, getState) => {
  try {
    const provider = await getWalletConnectProvider();

    dispatch(connectWalletRequest());

    const defaultAccount = await window.ethersProvider.getSigner().getAddress();
    const account = defaultAccount.toLowerCase();
    const balance = await window.ethersProvider.getBalance(account);
    const chainId = `0x${Number(provider.chainId).toString(16)}`;

    const token = getState().user?.userAccount?.token;

    if (!ALLOW_NETWORK.includes(chainId)) {
      return dispatch(checkWrongNetwork(true));
    }

    dispatch(checkWrongNetwork(false));

    if (token) {
      dispatch(setUserAccounts({
        accounts: [account],
        balance,
        chainId,
      }));

      dispatch(connectWalletSuccess());

      return true;
    } else {
      const { data } = await User.getUserNonceKey(account, chainId);

      dispatch(setUserNonce(data));
      window.localStorage.setItem('nonce', data.nonce);
      const result = await dispatch(web3Auth(account, chainId));

      if (result) {
        dispatch(setUserAccounts({
          accounts: [account],
          balance,
          chainId,
        }));

        dispatch(connectWalletSuccess());

        return true;
      } else {
        dispatch(setUserAccounts({ accounts: [] }));
        dispatch(connectWalletFail());
        dispatch(disconnect());
      }
    }

    provider.on("accountsChanged", async (accounts) => {
      // const hexChainId = await window.web3.utils.toHex(chainId);

      // if (!ALLOW_NETWORK.includes(hexChainId)) {
      //   return dispatch(checkWrongNetwork(true))
      // }

      // dispatch(checkWrongNetwork(false))

      // const balance = await window.web3.eth.getBalance(accounts[0]);
      // const { data } = await User.getUserNonceKey(accounts[0], chainId);
      // dispatch(setUserNonce(data));

      // const result = await dispatch(web3Auth(accounts[0], chainId));

      // if (result) {
      //   dispatch(setUserAccounts({ accounts, balance, chainId }));
      //   dispatch(connectWalletSuccess());

      //   return true;
      // } else {
      //   dispatch(connectWalletFail());
      //   dispatch(disconnect());

      //   return false;
      // }

      dispatch(disconnect());
    });

    // Subscribe to chainId change
    provider.on("chainChanged", async (chainId) => {
      const hexChainId = `0x${Number(chainId).toString(16)}`;

      if (!ALLOW_NETWORK.includes(hexChainId)) {
        return dispatch(checkWrongNetwork(true));
      }

      dispatch(checkWrongNetwork(false));

      const defaultAccount = await window.ethersProvider.getSigner().getAddress();
      const account = defaultAccount.toLowerCase();
      const balance = await window.ethersProvider.getBalance(account);

      if (token) {
        dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
        dispatch(connectWalletSuccess());

        return true;
      } else {
        const { data } = await User.getUserNonceKey(account, chainId);
        dispatch(setUserNonce(data));

        const result = await dispatch(web3Auth(account, chainId));

        if (result) {
          dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
          dispatch(connectWalletSuccess());

          return true;
          
        } else {
          dispatch(connectWalletFail());
          return false;
        }
      }
    });

    provider.on('disconnect', () => {
      dispatch(disconnect());
      window.web3 = undefined;
    });

    return true;
  } catch (error) {
    dispatch(connectWalletFail(error));
    dispatch(disconnect());
    return false;
  }
};

export const autoConnect = () => dispatch => {
  const connectorId = window.localStorage.getItem('connectorId');
  if (connectorId === CONNECTION_TYPES.metamask) {
    dispatch(autoConnectMetamask());
  } else if (connectorId === CONNECTION_TYPES.walletconnect) {
    dispatch(autoConnectWallet());
  } else if (connectorId === CONNECTION_TYPES.gamestop) {
    dispatch(autoConnectGameStop(''));
  }
};

export const detectAccountLock = () => async dispatch => {
  const connectorId = window.localStorage.getItem('connectorId');
  if (connectorId === CONNECTION_TYPES.metamask) {
    if (window.ethereum) {

      const provider = await detectEthereumProvider();
      // If the provider returned by detectEthereumProvider is not the same as
      // window.ethereum, something is overwriting it, perhaps another wallet.
      if (provider !== window.ethereum) {
        window.web3 = new Web3(provider);
      } else {
        window.web3 = new Web3(window.ethereum);
      }

      const accounts = await window.web3.eth.getAccounts();
      if (accounts && accounts[0]) {

      } else {
        dispatch(connectWalletFail())
      }
    }
  }
};

export const updateWalletBalanceAction = address => async (dispatch) => {
  try {
    const balance = await window.ethersProvider.getBalance(address).catch(() => 0);

    dispatch(updateWalletBalance({ balance }))
  } catch (error) {
    Logger.error('updateWalletBalanceAction', error);
    return false
  }
}

export const web3Auth = (address) => async (dispatch, getState) => {
  const isLoading = getState().user?.loadingConnectAccount;

  if(!isLoading) {
      dispatch(loadingConnectAccount(true));

      const nonce = getState().user.userAccount?.nonce?.nonce;

      try {
          const signer = window?.ethersProvider?.getSigner();
          const message = ethers.utils.toUtf8String(nonce);
          const signKey = await signer.signMessage(message);

          // const signKey = await window.web3.eth.personal.sign(nonce, address);
          const { data } = await User.getToken(address, signKey);

          dispatch(setUserSignKey(signKey));
          dispatch(setUserToken(data.token));
          http.setAuthorizationHeader(data.token);
          Logger.log(`web3Auth : ${data.wallet}`);
          dispatch(loadingConnectAccount(false));

          return true;
      } catch (error) {
          dispatch(loadingConnectAccount(false));
          dispatch(connectWalletFail(error));
          dispatch(disconnect());
          dispatch(checkWrongNetwork(false));
          Logger.error(error);

          return false;
      }
  }
};

export const disconnect = () => async (dispatch) => {
  dispatch(setUserAccounts({ accounts: [] }));
  dispatch(setUserNonce({}));
  dispatch(setUserSignKey(''));
  dispatch(setUserToken(''));
  dispatch(fetchPoolDataClean());

  http.setAuthorizationHeader('');
  window.localStorage.removeItem('connectorId');
  window.localStorage.removeItem('walletconnect');
  window.localStorage.clear();
}

const { onApproveRequest, onApproveSuccess, onApproveFail } = createActions({
  ON_APPROVE_REQUEST: () => {},
  ON_APPROVE_SUCCESS: data => ({ data }),
  ON_APPROVE_FAIL: error => ({ error }),
});

export const onApprove = (contract, spenderAddress, address, amount) => (dispatch) => {
  dispatch(onApproveRequest());

  return approve(contract, spenderAddress, address, amount)
    .then(({ data }) => {
      dispatch(onApproveSuccess(data));
      return data;
    })
    .catch(error => {
        dispatch(onApproveFail(error));
        return error;
    });
}

const { getAllowanceUndeadRequest, getAllowanceUndeadSuccess, getAllowanceUndeadFail } = createActions({
  GET_ALLOWANCE_UNDEAD_REQUEST: () => {},
  GET_ALLOWANCE_UNDEAD_SUCCESS: data => (data),
  GET_ALLOWANCE_UNDEAD_FAIL: error => ({ error }),
});

export const getAllowanceUndead = (tokenContract, account, contractAddress) => (dispatch) => {
  dispatch(getAllowanceUndeadRequest());

  return getAllowance(tokenContract, account, contractAddress)
    .then((data) => {
      dispatch(getAllowanceUndeadSuccess(data));
      return data;
    })
    .catch(error => {
        dispatch(getAllowanceUndeadFail(error));
        return error;
    });
}


