import React, { useEffect } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router'
import { isEmpty } from 'lodash';

import { fetchResultStakeMintClean, fetchResultStakeMint } from '../../actions/pools';
import Swiper from '../CommonStaking/Swiper';
// import { toggleWalletModal } from '../../actions/common';

import style from './style.css';
// import success from '../../assets/img/successIcon.png';
import button from '../../assets/img/button.png';
import left from '../../assets/img/leftBackground.png';
import right from '../../assets/img/rightBackground.png';
import bloodBg from '../../assets/img/staking/Circle_Blood.png';

const Wrapper = styled.div`
    .left {
        position: absolute;
        right: 0;
        top: 0;
        width: 95rem;
        height: 98.5rem;

        @media (max-width: 767px) {
            width: 285rem;
            height: 300rem;
        }
    }

    .right {
        position: absolute;
        left: 0;
        top: 180rem;
        width: 80.5rem;
        height: 144.5rem;

        @media (max-width: 767px) {
            top: 600rem;
            width: 240rem;
            height: 440rem;
        }
    }

    .left-2 {
        position: absolute;
        left: 0;
        top: 0;
        width: 95px;
        border-top-left-radius: 12px;
    }

    .right-2 {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 95px;
        border-top-left-radius: 12px;
    }

    .image-success {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0rem 26rem 15rem;

        @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0rem 80rem 60rem;
        }

        .layer {
            width: 92px;
            height: 92px;
        }

        span svg {
            width: 100%;
            height: 100%;
            fill: #fff;
        }

        .loading-modal {
            background: transparent;
        }
    }   

    .text-success {
        font-size: 22.5rem;
        font-weight: bold;
        line-height: 1.22;
        text-align: center;

        color: #fff;

        margin: 0 25rem 10rem;

        @media (max-width: 767px) {
            font-size: 65rem;
            margin: 0 120rem 30rem;
        }
    }


    .description {
        font-size: 11.5rem;
        line-height: 1.6;
        text-align: center;
        color: #fff;

        margin-bottom: 0rem;

        @media only screen and (max-width: 767px) {
            font-size: 33rem;
        }
    }
`;


const ButtonStyle = styled.div`
    height: 40rem;
    width: 100% !important;
    margin-bottom: 30rem;

    background: url(${button});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    justify-content: center;
    align-items: center;

    border: none; 
    cursor: pointer;

    font-size: 15rem;
    font-weight: 600;
    line-height: 1.33;
    text-align: center;

    color: #fff;

    @media (max-width: 767px) {
        height: 120rem;
        font-size: 40rem;
        margin-bottom: 80rem;
    }

    &.ant-btn-loading:before {
        display: none;
    }

    .ant-btn-loading-icon {
        color: #ffffff;
    }

    &:active {
        transform: scale(0.9);
        transition: all 0.2s;
    }
    :hover,
    :active,
    :focus {
      background: url(${button});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      span{
        color: #ffffff;
      }
    }
`

export const ImgItem = styled.img`
    height: 120rem;

    @media (max-width: 767px) {
        height: 570rem;
    }
`

export default function SuccessMintStakeModal(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { pathname = '' } = location;

    const {
        visible,
        onCancel,
        link,
        transactionHash, 
        title,
        text,
        chainId,
        imagePool,
        isConnected,
        isMobile,
        ...restProps
    } = props;

    const resultStakeMint = useSelector(state => state.pools?.resultStakeMint?.data || []);
    const { requesting } = useSelector(state => state.pools?.resultStakeMint);
    const loading = requesting || isEmpty(resultStakeMint);

    useEffect(() => {
        if (!isEmpty(transactionHash) && isConnected) {
            dispatch(fetchResultStakeMint(transactionHash))
        }

        return dispatch(fetchResultStakeMintClean());
    }, [dispatch, transactionHash, isConnected])

    return (
        <Modal
            {...restProps}
            centered
            onCancel={onCancel}
            visible={visible}
            bodyClass={style.wrapper}
            wrapClassName={'success-mint-stake-modal'}
            width={isMobile ? '1100rem' : '374rem'}
        >
            <Wrapper>
                <>
                    <div className="header-nav">
                        <img src={right} alt="right" className="right" />
                        <img src={left} alt="left" className="left" />
                    </div>

                    <div className={'image-success'}>
                        <Swiper
                            bloodBg={bloodBg}
                            pathname={pathname}
                            data={resultStakeMint}
                            requesting={loading}
                        />

                        <p className={'text-success'}>
                            {title || 'You successfully minted & staked'}
                        </p>
                        <p className={'description'}>
                            {text || 'Congratulations, you have staked XXX UNDEAD & Minted XXX Weapon loadouts. You can now view your loadouts on the "My Weapons" screen.'}
                        </p>
                    </div>

                    <div className={'buttonsSuccess'}>
                        {/* <ButtonStyle
                            onClick={onViewTransaction}
                        >View on Etherscan {chainId === 1 ? 'Mainnet' : 'Testnet'}</ButtonStyle> */}
                        <ButtonStyle
                            onClick={() => history.push(`/my-weapons`)}
                        >
                            My Weapons
                        </ButtonStyle>
                    </div>
                </>
            </Wrapper>
        </Modal>
    )
}
