import React from 'react';

import dayMobile from './img/dayMobile.png';
import hourMobile from './img/hourMobile.png';
import minMobile from './img/minMobile.png';
import secMobile from './img/secMobile.png';
import daysImg from './img/days.png';
import hoursImg from './img/hours.png';
import minsImg from './img/min.png';
import secsImg from './img/secs.png';

import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;

    .layer-parent {
        position: relative;
        text-align: center;

        display: grid;
        grid-template-columns: repeat(7, 1fr);

        .footer {
            padding-top: 38rem;
            padding-right: 20rem;

            @media screen and (max-width: 767px) {
                padding-right: 40rem;
            }
        }

        img:first-child {
            width: 68.5rem;
            height: 32rem;

            @media screen and (max-width: 767px) {
                width: 36.7px;
                height: 17.3px;
            }
        }

        img:nth-child(2) {
            width: 91.5rem;
            height: 28rem;

            @media screen and (max-width: 767px) {
                width: 48.7px;
                height: 15.3px;
            }
        }

        img:nth-child(3) {
            width: 51rem;
            height: 28rem;

            @media screen and (max-width: 767px) {
                width: 29.7px;
                height: 19px;
            }
        }

        img:last-child {
            width: 78.5rem;
            height: 28rem;

            @media screen and (max-width: 767px) {
                width: 42px;
                height: 15.3px;
            }
        }

        .item {
            position: relative;

            .top {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 20;
                width: 100%;
        
                font-family: HalloweenNight;
                font-size: ${props => props.fontSize}rem;
                line-height: ${props => props.lineHeight};
                letter-spacing: ${props => props.letterSpacing}rem;
                text-align: center;


                background-image: linear-gradient(to top, #8c000c, #ff0007, #8c000c), linear-gradient(to bottom, #c70106, #c70106);
                -webkit-background-clip: text;
                background-clip: text;
        
                color: #c70106;
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke: 2rem #ff0007;

                @media screen and (max-width: 767px) {
                    font-size: 150rem;
                    line-height: 0.7;
                    letter-spacing: 12rem;
                }
            }

            .bottom {
                font-family: HalloweenNight;
                font-size: ${props => props.fontSize}rem;
                line-height: ${props => props.lineHeight};
                letter-spacing: ${props => props.letterSpacing}rem;
                text-align: center;
                width: 100%;
    
                color: #4d0004;
                -webkit-text-stroke: ${props => props.textStroke}rem #4d0004;
        
                @media screen and (max-width: 767px) {
                    font-size: 150rem;
                    line-height: 0.7;
                    -webkit-text-stroke: 7px #4d0004;
                    letter-spacing: 12rem;
                }
            }
        }
    }
`;

function TextCustom(props) {
    const {
        content = {},
        fontSize,
        textStroke,
        lineHeight,
        letterSpacing,
        isDesktop
    } = props;

    return (
        <Wrapper textStroke={textStroke} fontSize={fontSize} lineHeight={lineHeight} letterSpacing={letterSpacing}>
            <section className="layer-parent">
                <div className="item">
                    <span className="bottom" data={content?.days}>{content?.days}</span>
                    <span className="top" data={content?.days}>{content?.days}</span>

                    <section className="footer">
                        <img src={isDesktop ? daysImg : dayMobile} alt="icon" />
                    </section>
                </div>

                <div className="item">
                    <span className="bottom">:</span>
                    <span className="top">:</span>
                </div>

                <div className="item">
                    <span className="bottom">{content?.hours}</span>
                    <span className="top">{content?.hours}</span>

                    <section className="footer">
                        <img src={isDesktop ? hoursImg : hourMobile} alt="icon" />
                    </section>
                </div>

                <div className="item">
                    <span className="bottom">:</span>
                    <span className="top">:</span>
                </div>

                <div className="item">
                    <span className="bottom">{content?.mins}</span>
                    <span className="top">{content?.mins}</span>

                    <section className="footer">
                        <img src={isDesktop ? minsImg : minMobile} alt="icon" />
                    </section>
                </div>

                <div className="item">
                    <span className="bottom">:</span>
                    <span className="top">:</span>
                </div>

                <div className="item">
                    <span className="bottom">{content?.secs}</span>
                    <span className="top">{content?.secs}</span>

                    <section className="footer">
                        <img src={isDesktop ? secsImg : secMobile} alt="icon" />
                    </section>
                </div>
            </section>
        </Wrapper>
    );
}

export default TextCustom;