import { Web3ReactProvider } from '@web3-react/core'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import createStore from './configureStore'
import { getLibrary } from './utils/web3React'
import { withAppContext } from './context'
import { ThemeContextProvider } from './context/ThemeProvider'
import { RefreshContextProvider } from './context/RefreshContext'
import { ToastsProvider } from './context/ToastsContext'
import { IntervalContextProvider } from './context/IntervalContext';

const { store } = createStore()

const AppWithContext = withAppContext(App)

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Provider store={store}>
      <ToastsProvider>
        <ThemeContextProvider>
          <RefreshContextProvider>
            <IntervalContextProvider>
              <AppWithContext />
            </IntervalContextProvider>
          </RefreshContextProvider>
        </ThemeContextProvider>
      </ToastsProvider>
    </Provider>
  </Web3ReactProvider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
