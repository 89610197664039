import { createAction } from 'redux-actions';
import { getView } from '../utils/common';

export const checkServiceWorker = createAction('CHECK_SERVICE_WORKER');
export const toggleCollapsed = createAction('TOGGLE_COLLAPSED');
export const handleToggleAll = createAction('HANDLE_TOGGLE_ALL');
export const toggleOpenDrawer = createAction('TOGGLE_OPEN_DRAWER');
export const setShowSubscribeContainer = createAction('SET_SHOW_SUBSCRIBE_CONTAINER');
export const getOverScaleBanner = createAction('GET_OVER_SCALE_BANNER');
export const getTransparentNav = createAction('GET_TRANSPARENT_NAV');
export const checkWrongNetwork = createAction('CHECK_WRONG_NETWORK');
export const toggleWalletModal = createAction('TOGGLE_WALLET_MODAL');
export const toggleAccountModal = createAction('TOGGLE_ACCOUNT_MODAL');
export const setIsMobile = createAction('SET_IS_MOBILE');
export const setIsTablet = createAction('SET_IS_TABLET');
export const setIsDesktop = createAction('SET_IS_DESKTOP');
export const setEndCountDown = createAction('SET_END_COUNT_DOWN');
export const setToast = createAction('SET_TOAST');

export const toggleAll = (width, height) => (dispatch) => {
  const view = getView(width);
  const collapsed = view !== 'DesktopView';
  dispatch(handleToggleAll({ collapsed, view, height }));
};
