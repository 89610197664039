import styled from 'styled-components';
import WithDirection from '../../../settings/withDirection';

const Wrapper = styled.div`
    margin: 0;
    position: relative;
    z-index: 50;
    width: 100%;
    /* padding-top: 112.5rem; */
    /* min-height: 770rem; */

    .swiper-button-prev {
        top: 130rem;
        margin-left: -85rem;

        @media screen and (max-width: 767px) {
            top: 380rem;
            margin-left: -70rem;
        }
    }

    .swiper-button-next {
        top: 130rem;
        margin-right: -85rem;

        @media screen and (max-width: 767px) {
            top: 380rem;
            margin-right: -70rem;
        }
    }

    .mintProgress {
        display: flex;
        justify-content: center;
        position: relative;
        bottom: 60rem;

        .loadout-box {
            display: flex;

            .loadouts {
                width: 220rem;
                margin-right: 5rem;

                @media screen and (max-width: 767px) {
                    width: 400rem;
                }
            }
        }

        .mint-value {
            display: flex;
            position: relative;

            .top {
                z-index: 20;
                width: 100%;
    
                font-family: HalloweenNight;
                font-size: 30rem;
                line-height: 0.78;
                letter-spacing: 1.65rem;
                text-align: center;
    
                background-image: linear-gradient(to top, #8c000c, #ff0007, #8c000c), linear-gradient(to bottom, #c70106, #c70106);
                -webkit-background-clip: text;
                background-clip: text;
        
                color: #c70106;
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke: 2rem #c70106;
    
                @media screen and (max-width: 767px) {
                    font-size: 60rem;
                }
            }
    
            .bottom {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                
                font-family: HalloweenNight;
                font-size: 30rem;
                line-height: 0.78;
                letter-spacing: 1.65rem;
                text-align: center;
    
                color: #4d0004;
                -webkit-text-stroke: 6rem #4d0004;
    
                @media screen and (max-width: 767px) {
                    font-size: 60rem;
                }
            }
        }
    }

    .swiper {
        width: 1280rem;
        padding-top: 10rem;
        padding-bottom: 0;
    }

    @media screen and (max-width: 767px) {
        position: relative;
        /* padding-top: 100px; */
        z-index: 200;
    }

    @media screen and (min-width: 768px) and (max-width: 1279px) {
        min-height: 870rem;
    }
`;

export const TitlePage = styled.div`
    margin-bottom: 7.5rem;

    img {
        width: 615rem;

        @media screen and (max-width: 767px) {
            width: 85.8%;
        }
    }

    @media screen and (max-width: 767px) {
    }

    @media screen and (min-width: 768px) and (max-width: 1440px) {
    }
`;

export const SwiperList = styled.div`
    margin: 0;
    display: flex;
    align-items: center;

    padding: 0 12% 80px 12%;

    .swiper-wrapper {
        align-items: center;
    }

    @media (max-width: 767px) {
        padding: 0 0px;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
        padding: 0 200px;
    }

    @media (min-width: 1024px) and (max-width: 1279px) {
        padding: 0 15%;
    }

    @media (min-width: 1280px) {
        padding: 0 1%;
    }

    @media (min-width: 1600px) {
    }
`;

export const SwiperItem = styled.div`
    margin: 0;
    position: relative;

    .overlay {
        position: absolute;
        z-index: 100;
        left: 0;
        bottom: 0;
        background-color: ${props => !props.isActive && 'rgba(15, 16, 20, 0.75)'};
        height: 100%;
        width: 100%;

        @media screen and (max-width: 1279px) {
            display: none;
        }
    }

    .wrapped-null {
        padding: 10px 0 0 0;
        margin: 5px 0 10px 0;

        display: flex;
        flex-direction: column;
        align-items: center;


        @media (max-width: 767px) {
            margin: 60rem -60rem 10rem;
            padding: 10px 0 30rem 0;
        }

        @media (min-width: 768px) and (max-width: 1023px) {
            margin: 10px 30px 10px;
        }

        @media (min-width: 1024px) and (max-width: 1279px) {
            margin: 10rem 150rem 10rem;
        }

        .content-null {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            padding: 25rem 20rem 10rem;

            @media (max-width: 767px) {
                padding: 70rem 0 40rem 0;
                margin: 0;
            }


            .img-null {
                width: 179rem;
                height: 179rem;
                border-radius: 50%;
                background-color: #505050;
                animation: opacityPulse 2s ease-in-out infinite;

                
                @media (max-width: 767px) {
                    width: 540rem;
                    height: 540rem;
                }
    
                @media (min-width: 768px) and (max-width: 1023px) {
                    width: 60%;
                }
    
                @media (min-width: 1024px) and (max-width: 1279px) {
                    width: 60%;
                }
            }
        }

        .bottom-null {
                width: 120rem;
                height: 18rem;
                background-color: #505050;
                animation: opacityPulse 2s ease-in-out infinite;
                
                @media (max-width: 767px) {
                    width: 500rem;
                    height: 60rem;
                }
    
                @media (min-width: 768px) and (max-width: 1023px) {
                }
    
                @media (min-width: 1024px) and (max-width: 1279px) {
                }
        }
    }

    .wrapped {
        padding: 10px 0 0 0;
        margin: 5px 0;

        display: flex;
        flex-direction: column;
        align-items: center;


        @media (max-width: 767px) {
            margin: 60rem -60rem 10rem;
            padding: 0rem 0 20rem 0;
        }

        @media (min-width: 768px) and (max-width: 1023px) {
            margin: 10px 30px 10px;
        }

        @media (min-width: 1024px) and (max-width: 1279px) {
            margin: 10rem 150rem 10rem;
        }

        .heading {
            font-size: ${props => props.isActive ? '22.5rem' : '18rem'};
            font-weight: bold;
            text-align: center;
            color: #df1e33;
            margin-bottom: 10rem;

            @media (max-width: 767px) {
                font-size: 52rem;
            }
        }

        .content {
            display: flex;
            justify-content: center;
            display: relative;

            .img-default {
                width: 200rem;
                height: auto;

                @media (max-width: 767px) {
                    width: 75%;
                    margin-right: ${props => props.type === 2 || props.type === 1 ? '45rem' : '0rem'};
                }
    
                @media (min-width: 768px) and (max-width: 1023px) {
                    width: 80%;
                }
    
                @media (min-width: 1024px) and (max-width: 1279px) {
                    width: 100%;
                }
            }

            @media (max-width: 767px) {
                width: 100%;
            }

            @media (min-width: 768px) and (max-width: 1023px) {
                height: 300px;
            }

            @media (min-width: 1024px) and (max-width: 1279px) {
                height: 300px;
            }

            @media (min-width: 1280px) and (max-width: 1599px) {
                /* height: ${props => props.isActive ? '214rem' : '165rem'}; */
            }

            @media (min-width: 1600px) {
            }
        }

        .bottom {
            margin: 0;
            padding: 0;

            @media (max-width: 767px) {
                padding: 30rem 10rem 0rem;
                margin: 0;
            }

            .text-gradient {
                font-size: ${props => props.isActive ? '32.5rem' : '25rem'};
                font-weight: bold;
                text-align: center;
                color: #fff;
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;

                margin-bottom: 5rem;

                @media (max-width: 767px) {
                    font-size: 73rem;
                }
            }

            p {
                font-size: 13rem;
                font-weight: normal;
                line-height: 1.15;
                text-align: center;
                color: #fff;

                margin-bottom: 10rem;

                span {
                    font-weight: 600;
                }

                @media (max-width: 767px) {
                    font-size: 39.4rem;
                    line-height: 1.2;
                    margin-bottom: 0;
                }
            }
        }

        .sold-out {
            display: flex;
            justify-content: center;
            width: 112.5rem;
            height: 31.5rem;
            margin: 0 auto 0 auto;

            cursor: not-allowed;
            opacity: 0.7;
            position: relative;

            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */

            @media screen and (max-width: 767px) {
                height: 21px;
                width: 257rem;
            }

            .bottom-mint-btn {
                position: absolute;
                width: 113%;
                transform: translateY(-50%);
                top: 50%;

            }

            .top-mint-btn {
                padding: 0px 0px;
                cursor: not-allowed;

                width: 100%;
                position: absolute;
            }

            span {
                top: 50%;
                transform: translateY(-50%);
                cursor: not-allowed;
                position: absolute;
                font-family: Poppins;
                font-size: 17rem;
                font-weight: 600;
                line-height: 1;
                text-align: center;
                color: #000;

                @media screen and (max-width: 767px) {
                    top: 56.5%;
                    font-size: 40rem;
                }
            }
        }

        .parent {
            display: flex;
            justify-content: center;
            width: 112.5rem;
            height: 31.5rem;
            margin: 0 auto 0 auto;

            position: relative;

            &:hover {
                transform: scale(1.010);
                transition: all 1s;

                span {
                    top: 50%;
                }
            }

            @media screen and (max-width: 767px) {
                height: 21px;
                width: 257rem;
            }

            &:active {
                transform: scale(0.9);
                transition: all 0.2s;
            }

            .bottom-mint-btn {
                position: absolute;
                width: 113%;
                transform: translateY(-50%);
                top: 50%;

            }

            .top-mint-btn {
                padding: 0px 0px;
                cursor: pointer;

                width: 100%;
                position: absolute;
            }

            span {
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                position: absolute;
                font-family: Poppins;
                font-size: 17rem;
                font-weight: 600;
                line-height: 1;
                text-align: center;
                color: #000;

                @media screen and (max-width: 767px) {
                    top: 56.5%;
                    font-size: 40rem;
                }
            }
        }
    }
`;


export const NftInfoStyle = styled.div`
    border-radius: 7.5rem;
    width: 351rem;
    padding: 10rem 20rem 0rem;
    z-index: 1000;

    @media (max-width: 767px) {
        width: 100%;
        border-radius: 7.5rem;
        padding: 0;
    }


    .info {
        display: flex;
        justify-content: center;
        position: relative;
        margin-bottom: 11.5rem;

        .mintBg {
            width: 179rem;

            @media (max-width: 767px) {
                width: 540rem;
            }
        }

        .knife {
            position: absolute;
            z-index: 40;

            &.amateurKnife {
                width: 179rem;

                @media (max-width: 767px) {
                    width: 540rem;
                }
            }

            &.survivorKnife {
                width: 199rem;
                bottom: 0rem;
                left: 42%;
                transform: translateX(-42%);

                @media (max-width: 767px) {
                    width: 600rem;
                    bottom: 5rem;
                }
            }

            &.assassinKnife {
                width: 199rem;
                bottom: 2rem;
                right: 60rem;

                @media (max-width: 767px) {
                    width: 600rem;
                    right: 250rem;
                }
            }

            &.killerKnife {
                bottom: -2rem;
                width: 219rem;
                right: 52rem;

                @media (max-width: 767px) {
                    width: 660rem;
                    right: 220rem;
                    bottom: -2rem;
                }
            }

            &.rookieKnife {
                width: 199rem;
                bottom: 0rem;
                left: 42%;
                transform: translateX(-42%);

                @media (max-width: 767px) {
                    width: 600rem;
                    bottom: 5rem;
                }
            }

            &.commanderKnife {
                width: 190rem;
                bottom: -4rem;
                right: 73rem;

                @media (max-width: 767px) {
                    width: 570rem;
                    bottom: -5rem;
                    right: 270rem;
                }
            }
        }

        .pistol {
            position: absolute;
            z-index: 30;

            &.amateurPistol {
                width: 179rem;

                @media (max-width: 767px) {
                    width: 540rem;
                }
            }

            &.survivorPistol {
                width: 200rem;
                bottom: 1rem;
                left: 40rem;

                @media (max-width: 767px) {
                    width: 600rem;
                    left: 200rem;
                    bottom: 20rem;
                }
            }

            &.assassinPistol {
                bottom: -4rem;
                width: 200rem;

                @media (max-width: 767px) {
                    width: 600rem;
                }
            }

            &.killerPistol {
                bottom: 0rem;
                width: 200rem;

                @media (max-width: 767px) {
                    width: 600rem;
                    bottom: 10rem;
                }
            }

            &.rookiePistol {
                width: 212rem;
                bottom: 0rem;
                left: 50rem;

                @media (max-width: 767px) {
                    width: 636rem;
                    left: 215rem;
                    bottom: 0rem;
                }
            }

            &.commanderPistol {
                width: 190rem;
                bottom: -1rem;
                left: 55rem;

                @media (max-width: 767px) {
                    width: 570rem;
                    left: 235rem;
                    bottom: 0rem;
                }
            }

            &.veteranPistol {
                width: 210rem;
                bottom: -1rem;
                left: 42rem;

                @media (max-width: 767px) {
                    width: 630rem;
                    left: 180rem;
                    bottom: 0rem;
                }
            }
        }

        .shortGuns {
            position: absolute;
            top: 0;
            width: 179rem;
            z-index: 20;

            &.amateurShort {

            }

            &.survivorShort {
                width: 179rem;

                @media (max-width: 767px) {
                    width: 540rem;
                }
            }

            &.assassinShort {
                top: -15rem;
                right: 68rem;
                width: 195rem;

                @media (max-width: 767px) {
                    width: 585rem;
                    right: 275rem;
                    top: -45rem;
                }
            }

            &.killerShort {
                top: -10rem;
                width: 210rem;
                right: 55rem;

                @media (max-width: 767px) {
                    width: 630rem;
                    right: 225rem;
                    top: -13rem;
                }
            }

            &.rookieShort {
                width: 212rem;
                bottom: 17rem;
                left: 50rem;
                z-index: 30;

                @media (max-width: 767px) {
                    width: 636rem;
                    left: 78rem;
                    bottom: 20rem;
                }
            }

            &.commanderShort {
                width: 170rem;
                top: -10rem;
                left: 73rem;

                @media (max-width: 767px) {
                    width: 510rem;
                    left: 278rem;
                    top: -28rem;
                }
            }

            &.veteranShort {
                position: absolute;
                top: -15rem;
                width: 200rem;
                left: 43rem;
                z-index: 20;

                @media (max-width: 767px) {
                    width: 550rem;
                    left: 237rem;
                    top: -25rem;
                }
            }
        }

        .longGuns {
            position: absolute;
            z-index: 10;

            &.amateurLong {
                @media (max-width: 767px) {
                    width: 600rem;
                    right: 107rem;
                }
            }

            &.survivorLong {
                width: 199rem;
                right: 67rem;
                top: -5rem;

                @media (max-width: 767px) {
                    width: 600rem;
                    right: 285rem;
                }
            }

            &.assassinLong {
                top: -10rem;
                right: 75rem;
                width: 179rem;

                @media (max-width: 767px) {
                    width: 540rem;
                    right: 285rem;
                    top: -10rem;
                }
            }

            &.killerLong {
                width: 209rem;
                top: -10rem;
                right: 56rem;

                @media (max-width: 767px) {
                    width: 627rem;
                    right: 250rem;
                    top: -40rem;
                }
            }

            &.rookieLong {
                width: 212rem;
                bottom: 2rem;
                left: 50rem;
                z-index: 30;

                @media (max-width: 767px) {
                    width: 636rem;
                    left: 212rem;
                    bottom: 20rem;
                }
            }

            &.commanderLong {
                width: 190rem;
                right: 70rem;
                top: -25rem;

                @media (max-width: 767px) {
                    width: 510rem;
                    left: 270rem;
                    top: -55rem;
                }
            }

            &.veteranLong {
                position: absolute;
                z-index: 10;
                width: 190rem;
                left: 49rem;
                top: -12rem;

                @media (max-width: 767px) {
                    width: 540rem;
                    position: absolute;
                    z-index: 10;
                    left: 234rem;
                    top: -30rem
                }
            }
        }

        .perks {
            position: absolute;
            width: 230rem;
            bottom: -8rem;
            right: 55rem;

            z-index: 30;

            @media (max-width: 767px) {
                width: 690rem;
                right: 220rem;
                bottom: -18rem;
            }

            &.veteranPerks {
                width: 255rem;
                bottom: -15rem;
                right: 58rem;
            
                @media (max-width: 767px) {
                    width: 800rem;
                    height: 720rem;
                    right: 215rem;
                    bottom: -15rem;
                }
            }
        }

        .axe {
            position: absolute;
            width: 240rem;
            bottom: -6.5rem;
            left: 20rem;
            z-index: 50;

            @media (max-width: 767px) {
                position: absolute;
                width: 620rem;
                bottom: -2rem;
                left: 170rem;
                z-index: 50;
            }
        }
    }


    /* LOADING */
    .loading {
        border-radius: 7.5rem;
        padding: 22rem 19rem 10rem 19rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        animation: opacityPulse 2s ease-in-out infinite;

        @media screen and (max-width: 767px) {
            width: 100% ;
            max-width: unset;
        }

        .heading-loading {
            background-color: #505050;
            border-radius: 5rem;

            @media screen and (max-width: 767px) {
            }
        }

        .content-loading {
            /* width: 179rem; */
            width: 80rem; 
            height: 179rem;
            border-radius: 50%;
            background-color: #505050;

            margin-bottom: 25rem;

            @media screen and (max-width: 767px) {
                width: 700rem;
                height: 700rem;
                border-radius: 50%;
            }
        }

        .footer-loading {
            width: 200rem;
            height: 25rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #505050;

            @media (max-width: 767px) {
                width: 500rem;
                height: 70rem;
            }
        }
    }
`;

export default WithDirection(Wrapper);
