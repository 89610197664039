import React from 'react'
import NoItems from '../../NoItems';
import StakingList from './StakingList';

export default function StakingListContainer(props) {
    const {
        chainId,
        account,
        pools,
        requesting,
        limit,
        skip,
        isEnd,
        onLoadMore,
        requestingLoadMore,
        isMobile,
        isTablet
    } = props;

    return (
        requesting || (pools && pools.length) ? (
            <>
                <StakingList
                    {...props}
                    {...{
                        account,
                        chainId,
                        loading: requesting,
                        requestingLoadMore,
                        pools,
                        onLoadMore,
                        limit,
                        skip,
                        isEnd,
                        isMobile,
                        isTablet
                    }}
                />
            </>
        ) : (
            <NoItems />
        )
    )
}