import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const Wrapper = styled.div`
    background: #0f1014;
    position: relative;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
`;

export const BubbleLayer = styled.div`
    opacity: 0.7;
    pointer-events: none;

    .bubble {
    }

    @media screen and (max-width: 767px) {
        display: none;
    }

    .left {
        position: absolute;
        left: -6rem;
        top: -8rem;
        width: 31.25vw;
        min-height: 720rem;

        @media screen and (max-width: 767px) {
            width: 65vw;
            left: -50rem;
            top: 300rem;
            min-height: 400rem;
        }
    }

    .right {
        position: absolute;
        right: 0;
        top: 24rem;
        width: 44.5vw;
        min-height: 1033.5rem;

        @media screen and (max-width: 767px) {
            width: 90vw;
            min-height: 500rem;
        }
    }
`;

export const HeaderBanner = styled.div`
    position: relative;
    top: 155.5rem;
    z-index: 100;
    height: 100%;
    pointer-events: none;

    @media screen and (max-width: 767px) {
        top: 650rem;
    }

    @media screen and (min-width: 768px) and (max-width: 1279px) {
        z-index: 50;
        top: 300rem;
    }

    .header-layout {
        position: relative;

        @media screen and (max-width: 767px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            height: 400px;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }

        .left-layer {
            position: absolute;
            left: -3rem;
            top: -7rem;

            .left-header {
                width: 29.33vw;
                min-height: 564.5rem;

                @media screen and (max-width: 767px) {
                    position: absolute;
                    left: -280rem;
                    width: 70vw;
                    top: 13rem;
                }
            }
        }

        .right-layer {
            position: absolute;
            right: 0;
            top: -13rem;

            @media screen and (max-width: 767px) {
                top: 0;
                right: -25%;
            }

            @media screen and (min-width: 1024px) and (max-width: 1279px) {
                width: 35%;
            }

            .right-header {
                width: 27.14vw;
                height: 575.5rem;

                @media screen and (max-width: 767px) {
                    position: absolute;
                    width: 70vw;
                    right: 0;
                    height: auto;
                }
            }
        }
    }
`;

export const ContentWrapper = styled.div`
    position: relative;
    z-index: 100;
    padding: 120rem 100rem 50rem;

    @media screen and (max-width: 767px) {
        padding: 342rem 90rem 0;
    }

    .heading {
        width: 557rem;
        margin-bottom: 32.5rem;
        pointer-events: none;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    .tokenAnimation {
        position: relative;
        top: -75rem;

        @media screen and (max-width: 767px) {
            left: 45%;
            transform: translate(-50%);
            top: 40rem;
        }
    }

    .preparingAnimation {
        position: relative;
        top: -75rem;

        &.isTrading {
            padding: 0 0rem;
        }

        @media screen and (max-width: 767px) {
            // padding: 0 110rem;
            padding: 0 0rem;
            top: 100rem;
        }

        .nowTrading {
            width: 398.5rem;
            margin-bottom: 15rem;

            @media screen and (max-width: 767px) {
                width: 880rem;
                margin-bottom: 30rem;
            }
        }

        .copy {
            width: 12.5rem;
            height: 15.5rem;

            @media screen and (max-width: 767px) {
                width: 35rem;
                height: 45rem;
            }
        }

        .description {
            font-size: 17.5rem;
            line-height: 0.77;
            text-align: center;
            color: #fff;

            margin-bottom: 0;
            margin-right: 6.5rem;
            cursor: pointer;

            &:hover {
                transform: scale(1.010);
                transition: all .3s;
            }

            &.isCopy {
                cursor: pointer;
            }

            @media screen and (max-width: 767px) {
                margin-right: 10rem;
                font-size: 40rem;
            }
        }

        .copyText {
            position: absolute;
            top: 90rem;
            left: 50%;
            transform: translateX(-50%);

            background-color: #5e0000;
            padding: 2rem 8rem;
            border-radius: 3rem;
            transition: .2s ease-out;

            font-size: 13rem;
            font-weight: 600;
            line-height: 1.4;
            text-align: center;
            color: #fff;

            @media screen and (max-width: 767px) {
                top: 200rem;
                border-radius: 3px;
                padding: 2px 8px;
                font-size: 10px;
                line-height: 1.4;
            }
        }
    }
`;

export const DocumentWrapper = styled.div`
    background-image: radial-gradient(circle at 0 0, #d20000, #af0000);
    padding: 10px 39.5rem 10px 29rem;

    border-radius: 7.5rem;
    box-shadow: 0px 3.5rem 50rem 0 rgba(0, 0, 0, 0.5);
    position: relative;
    z-index: 200;

    @media screen and (max-width: 767px) {
        border-radius: 7px;
        margin-top: 300rem;
        margin-bottom: 250rem;
        padding: 52rem 20rem 52rem 70rem;
    }

    .box {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 8fr 1fr;
        grid-gap: 10px;

        @media screen and (max-width: 767px) {
            grid-template-columns: 35% 63%;

            grid-gap: 2%;
        }

        .left {
            width: 67.5rem;
            height: 77rem;

            @media screen and (max-width: 767px) {
                width: 90%;
                height: auto;
                grid-row: 1 / 3;
            }
        }

        .center {
            h2 {
                font-family: Poppins;
                font-size: 17.5rem;
                font-weight: bold;
                line-height: 0.77;
                text-align: left;
                color: #fff;
                text-transform: uppercase;

                @media screen and (max-width: 767px) {
                    font-size: 38rem;
                    line-height: 1.5;
                }
            }

            p {
                font-family: Poppins;
                font-size: 15rem;
                line-height: 1.35;
                text-align: left;
                color: #fff;
                margin-bottom: 0;

                @media screen and (max-width: 767px) {
                    font-size: 32rem;
                    line-height: 1.2;
                }
            }
        }

        .read-btn {
            background-color: #5e0000;
            padding: 0px 22rem;
            width: 150rem;
            height: 40rem;

            display: flex;
            justify-content: center;
            align-items: center;

            cursor: pointer;

            &:hover {
                transform: scale(1.005);
                transition: all 1s;
            }

            @media screen and (max-width: 767px) {
                width: 100px;
                height: 28px;

                margin-top: 10px;
                grid-column-start: 2;
                grid-row-end: 3;
            }

            p {
                font-family: Poppins;
                font-size: 15rem;
                font-weight: 600;
                line-height: 1.73;
                text-align: center;
                color: #fff;

                margin: 0 5rem 0 0;

                @media screen and (max-width: 767px) {
                    font-size: 11px;
                }
            }

            img {
                width: 7rem;
                height: 11rem;

                @media screen and (max-width: 767px) {
                    width: 5px;
                    height: 7.5px;
                }
            }
        }
    }
`;

export default WithDirection(Wrapper);