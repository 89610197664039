import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'

import close from '../img/close.png';
import center from '../img/center.png';
import button from '../img/button.png';

const antIcon = <LoadingOutlined style={{ fontSize: 17, color: 'white', fontWeight: 'bold' }} spin />;

const Wrapper = styled.div`
    padding-bottom: 30rem;

    @media (max-width: 767px) {
        padding-bottom: 60rem;
    }

    .header-nav {
        display: flex;
        justify-content: flex-end;
        position: relative;

        padding-bottom: 49rem;

        @media (max-width: 767px) {
            padding-bottom: 79rem;
        }

        .layer {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            width: 131rem;
            height: 94.5rem;
            border-top-left-radius: 12rem;
            z-index: 10;

            @media (max-width: 767px) {
                width: 298rem;
                height: 220rem;
            }
        }

        .close {
            position: absolute;
            width: 22.5rem;
            height: 22.5rem;
            margin: 15rem;
            cursor: pointer;
            z-index: 20;

            &:hover {
                transform: scale(1.005);
                transition: all 1s;
            }

            @media (max-width: 767px) {
                top: 15rem;
                right: 20rem;
                width: 55rem;
                height: 55rem;
            }
        }
    }

    .mint-info {
        position: relative;
        z-index: 50;
        padding: 0 33rem;
        text-align: center;

        @media (max-width: 767px) {
        }

        h2 {
            font-family: Poppins;
            font-size: 22.5rem;
            font-weight: bold;
            line-height: 1.89;
            text-align: center;
            color: #fff;
            margin-bottom: 0rem;

            @media (max-width: 767px) {
                font-size: 52.5rem;
                margin: 0;
            }
        }

        p {
            font-family: Poppins;
            font-size: 12rem;
            line-height: 1.67;
            text-align: center;
            color: #8e8e8e;
            margin-bottom: 29rem;

            @media (max-width: 767px) {
                font-size: 30.5rem;
            }
        }
    }

    .btn-container {
        margin: 0 30rem;

        &:active {
            transform: scale(0.9);
            transition: all 0.2s;
        }

        @media (max-width: 767px) {
            margin: 0 60rem;
        }
        
        .mint-btn {
            height: 40rem;
            border-radius: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            @media (max-width: 767px) {
                height: 90rem;
            }

            span {
                font-family: Poppins;
                font-size: 15rem;
                font-weight: 600;
                line-height: 1.33;
                text-align: center;
                color: #fff;

                @media (max-width: 767px) {
                    font-size: 36rem;
                }
            }
        }
    }
`;

function Step2(props) {
    const { onCancel } = props;

    return (
        <Wrapper>
            <div className="header-nav">
                <img src={center} alt="left" className="layer" />
                <img src={close} alt="close" onClick={() => onCancel()} className="close" />
            </div>

            <div className="mint-info">
                <h2>Receiving Transaction</h2>
                <p>Approve the transaction in your wallet to mint & receive your weapon NFTs.</p>
            </div>

            <div className="btn-container">
                <div
                    className="mint-btn"
                    style={{
                        background: `url(${button}) no-repeat center center / cover`
                    }}
                >
                    <Spin indicator={antIcon} />
                </div>
            </div>
        </Wrapper>
    );
}

export default Step2;