import React from 'react';
import styled from 'styled-components';
import { SINGLE_WEAPON } from '../../MintWeapons/constants';
import { F1, PISTOL, MP5, AKM, DB, M1A, BASEBALL_BAT, AXE, RPG, R870 } from '../../../utils/constants';

import bloodBg from '../../../assets/img/SingleWeapon/BG _Circle.png';

const ItemSkinStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    color: #adafae;
    position: relative;

    @media (max-width: 767px) {
    }

    .skin-bg {
        width: 90%;

        @media (max-width: 767px) {
            width: 205rem;
        }
    }

    .item-weapon {
        position: absolute;
        width: 90rem;
        left: 0;

        @media (max-width: 767px) {
            width: 170rem;
        }
    }

    .f1 {
        top: -5rem;
        right: 0rem;
        width: 96rem;

        @media (max-width: 767px) {
            width: 210rem;
            left: -5rem;
            top: -10rem;
        }
    }

    .pistol {
        width: 85rem;
        left: 8rem;
        bottom: 2rem;

        @media (max-width: 767px) {
            width: 200rem;
            left: 5rem;
            top: 0rem;
        }
    }

    .mp5 {
        left: 5rem;

        @media (max-width: 767px) {
            width: 200rem;
            left: 2rem;
            top: 0rem;
        }
    }

    .akm {
        left: 4rem;

        @media (max-width: 767px) {
            width: 200rem;
            left: 0rem;
            top: 0rem;
        }
    }

    .db {
        width: 90rem;
        left: 5rem;

        @media (max-width: 767px) {
            width: 200rem;
            left: 5rem;
            top: 0rem;
        }
    }

    .r870 {
        width: 90rem;
        left: 2rem;

        @media (max-width: 767px) {
            width: 200rem;
            left: 2rem;
            top: 0rem;
        }
    }

    .axe {
        width: 115rem;
        top: -45rem;
        left: -3rem;

        @media (max-width: 767px) {
            width: 250rem;
            left: -15rem;
            top: -80rem;
        }
    }

    .rpg {
        width: 85rem;
        left: -2rem;

        @media (max-width: 767px) {
            width: 200rem;
            left: -25rem;
            top: 0rem;
        }
    }

    .baseballbat {
        width: 75rem;
        top: -2rem;
        left: 9rem;

        @media (max-width: 767px) {
            width: 180rem;
            left: -2rem;
            top: -20rem;
        }
    }

    .m1a {
        width: 75rem;
        top: -3rem;
        left: 8rem;

        @media (max-width: 767px) {
            width: 180rem;
            left: -2rem;
            top: 10rem;
        }
    }
`

const ItemSkinImage = styled.div`
    margin: 0 auto;
    position: relative;
`

const ItemSkinInfo = styled.div`
    width: 100%;

    color: #fff;
    border-radius: 2.5rem;
    border: solid 1rem #900000;

    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 1.5rem 0;

    @media (max-width: 767px) {
        border-radius: 2.5px;
        border: solid 0.5px #900000;
        padding: 10.5rem 10rem 9rem;
    }

    span {
        font-family: Poppins;
        font-size: 12.5rem;
        text-align: center;

        @media (max-width: 767px) {
            font-size: 34rem;
        }
    }
`

const ItemSkin = ({ item }) => {
    const { itemSku, name } = item;

    const renderItem = (itemParam) => {
        const itemSku = (itemParam?.itemSku || '').toLowerCase();
        const { level } = itemParam;
        let elementReturn = null;

        switch (itemSku) {
            case F1:
                elementReturn = <img src={SINGLE_WEAPON.F1[level - 1]} alt='f1' className={`f1 item-weapon f1-${level}`} />
                break;
            case PISTOL:
                elementReturn = <img src={SINGLE_WEAPON.Pistol[level - 1]} alt='pistol' className={`pistol item-weapon pistol-${level}`} />
                break;
            case MP5:
                elementReturn = <img src={SINGLE_WEAPON.MP5[level - 1]} alt='mp5' className={`mp5 item-weapon mp5-${level}`} />
                break;
            case AKM:
                elementReturn = <img src={SINGLE_WEAPON.AKM[level - 1]} alt='akm' className={`akm item-weapon akm-${level}`} />
                break;
            case DB:
                elementReturn = <img src={SINGLE_WEAPON.DB[level - 1]} alt='db' className={`db item-weapon db-${level}`} />
                break;

            case M1A:
                elementReturn = <img src={SINGLE_WEAPON.M1A[level - 1]} alt='m1a' className={`m1a item-weapon m1a-${level}`} />
                break;

            case BASEBALL_BAT:
                elementReturn = <img src={SINGLE_WEAPON.BaseballBat[level - 1]} alt='baseballbat' className={`baseballbat item-weapon baseballbat-${level}`} />
                break;

            case AXE:
                elementReturn = <img src={SINGLE_WEAPON.Axe[level - 1]} alt='axe' className={`axe item-weapon axe-${level}`} />
                break;

            case RPG:
                elementReturn = <img src={SINGLE_WEAPON.RPG[level - 1]} alt='rpg' className={`rpg item-weapon rpg-${level}`} />
                break;

            case R870:
                elementReturn = <img src={SINGLE_WEAPON.R870[level - 1]} alt='r870' className={`r870 item-weapon r870-${level}`} />
                break;
            default:
                elementReturn = <></>
        }
        return elementReturn;
    }

    return <ItemSkinStyle>
        <ItemSkinImage>
            <img src={bloodBg} alt='bg' className='skin-bg' />
            {renderItem(item)}
        </ItemSkinImage>

        <ItemSkinInfo>
            <span>{`${itemSku} - ${name}`}</span>
        </ItemSkinInfo>
    </ItemSkinStyle>
}

export default ItemSkin;