import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import moment from 'moment';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

import { rebootBuy } from '../../../utils/presaleNft';
import rebootEventAbi from '../../../services/abis/rebootEventContract.abi.json';
import OwnershipApi from '../../../services/api/ownership';
import { rebootMint } from '../../../actions/ownership';

import { setToast } from '../../../actions/common';
import { handleResult } from '../../../utils/common';
import { storeTransactionLog } from '../../../actions/user';
import { fromWei } from '../../../utils/web3';

function MintSteps(props) {
    const dispatch = useDispatch();

    const {
        item,
        isVisible,
        onCancel,
        isMobile,
        // isTablet,
        // isDesktop,
        onApproveUndead
    } = props;

    const [step, setStep] = useState(1);
    const [transactionHash, setTransactionHash] = useState('');
    const [tokenCaptcha, getTokenCaptcha] = useState('');

    const chainId = useSelector(state => state.user?.chainId);
    const account = useSelector(state => state.user?.userAccount?.accounts[0] ?? '');
    const rebootMintedRes = useSelector(state => state.ownership?.rebootMintedRes?.result ?? {});
    const { data = [] } = useSelector(state => state.setting?.allSettings?.result ?? []);
    const alowanceUndead = useSelector(state => state.user?.alowanceUndead?.result);
    const hasCaptcha = data.find(e => e.key === 'captcha');

    const formatNumberAllowance = +fromWei(alowanceUndead);

    const lableButton = useMemo(() => {
        if (formatNumberAllowance >= item.price) {
            return "Mint"
        }

        return "Approve"
    }, [formatNumberAllowance, item.price]);

    const allowanceNeeded = useMemo(() => {
        if (formatNumberAllowance >= item.price) return 0;

        return item.price;
    }, [
        formatNumberAllowance,
        item.price
    ]);

    const isAllowedMint = useMemo(() => {
        return formatNumberAllowance >= item.price;
    }, [
        formatNumberAllowance,
        item.price
    ]);

    const viewTransaction = () => {
      const exploreUrl = chainId === 1 ? process.env.REACT_APP_BLOCK_EXPLORER_URL_ETH_MAINNET : process.env.REACT_APP_BLOCK_EXPLORER_URL_ETH;
      window.open(`${exploreUrl}/tx/${transactionHash}`, '_blank');
    }

    const onSending = async () => {
    }

    const onError = async (error) => {
        dispatch(setToast({ title: 'CANCELLED:', detail: error.description }));
        handleCancel();
    }

    const onTransactionHash = async (hash) => {
        await setTransactionHash(hash);
        setStep(3);
    }

    const onReceipt = async (receipt, error = null) => {
        const body = await { transactionHash: receipt?.transactionHash };

        dispatch(rebootMint(body))
            .then((res) => {
                if (res.status >= 400) {
                    dispatch(setToast({ title: 'MINTED FAILED', detail: "something went wrong" }));
                    handleCancel();
                    return;
                }

                handleResult(() => {
                    dispatch(setToast({ title: 'TIMEOUT:', detail: `${error?.message}` }));
                    handleCancel();
                }, () => {
                    dispatch(setToast({ title: 'CANCELLED:', detail: 'Please try again and confirm the transaction.' }));
                    handleCancel();
                }, () => {
                    let log = {
                        chainId,
                        walletAddress: account,
                        eventName: `MINTED ${item.nameLower}`,
                        display: `success`,
                        date: moment().valueOf(),
                    };
              
                    log.transactionHash = receipt?.transactionHash;
                    dispatch(storeTransactionLog(log));
                    setStep(4);
                }, receipt, error)
            })
            .catch(err => {
                let log = {
                    chainId,
                    walletAddress: account,
                    eventName: `MINTED ${item.nameLower}`,
                    display: `failed`,
                    date: moment().valueOf(),
                };
          
                log.transactionHash = receipt?.transactionHash;
                dispatch(storeTransactionLog(log));

                dispatch(setToast({ title: 'MINTED FAILED', detail: err?.message }));
                handleCancel();

                return err;
            })
    }

    const handleOnMint = async () => {
        if (!isAllowedMint) return onApproveUndead();

        setStep(2);

        const rebootEventAddress = chainId === 1
            ? process.env.REACT_APP_UNDEAD_NFT_REBOOT_EVENT_MAINNET
            : process.env.REACT_APP_UNDEAD_NFT_REBOOT_EVENT_TESTNET

        try {
            const contract = new window.web3.eth.Contract(rebootEventAbi, rebootEventAddress);
            const body = !hasCaptcha?.value
                ? await { address: account, type: item.type }
                : await { address: account, type: item.type, captcha: tokenCaptcha }

            const { data } = await OwnershipApi.rebootSignature(body);

            rebootBuy(contract, item.type, data.params, data.signature, account, {
                onSending,
                onError,
                onTransactionHash,
                onReceipt,
                handleCancel
            })
                .then()
                .catch((err) => {
                    dispatch(setToast({ title: 'Transaction Failed. ', detail: "Something went wrong" }));
                    handleCancel();
                    return err;
                })
        } catch (error) {
            dispatch(setToast({ title: 'Transaction Failed. ', detail: error.data?.message }));
            handleCancel();
            return error
        }
    }
 
    const renderSteps = () => {
        switch (step) {
        case 1:
            return (
                <Step1
                    onMint={handleOnMint}
                    item={item}
                    onCancel={handleCancel}
                    setStep={() => handleNextStep()}
                    hasCaptcha={hasCaptcha}
                    getTokenCaptcha={getTokenCaptcha}
                    tokenCaptcha={tokenCaptcha}
                    label={lableButton}
                    allowanceNeeded={allowanceNeeded}
                    isAllowedMint={isAllowedMint}
                />
            )

        case 2:
            return <Step2 item={item} onCancel={handleCancel} />

        case 3:
            return <Step3 item={item} onCancel={handleCancel} transactionHash={transactionHash} viewTransaction={viewTransaction} />

        case 4:
            return <Step4 item={item} onCancel={handleCancel} mintedRes={rebootMintedRes} />
        
        default:
            break;
        }
    };

    const handleNextStep = () => {
        setStep(step + 1)
    };

    const handleCancel = () => {
        onCancel();
        getTokenCaptcha('');
        setStep(1);
    };

    const widthModal = () => {
        if (isMobile) return '90%';
        return '389rem';
        // if (isTablet) return '550rem';

        // if (isMobile && hasCaptcha?.value && step === 1) {
        //     return '1100rem';
        // }
    }

    return (
        <Modal
            visible={isVisible}
            closable={false}
            centered
            onCancel={() => (step !== 2 && step !==3) && handleCancel()}
            bodyStyle={{ 
                padding: 0,
                backgroundColor: '#0f1014',
                borderRadius: 7.5,
                border: 'solid 0.5rem #202429'
            }}
            // wrapClassName={'modal-boxed'}
            width={widthModal()}
        >
            {renderSteps()}
        </Modal>
    );
}

export default MintSteps;

