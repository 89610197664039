import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const Wrapper = styled.div`
    background-color: #0f1014;

    .content {
        padding: 120rem 100rem 50rem;
        color: white;
        min-height: 100vh;
        text-align: left;

        @media screen and (max-width: 767px) {
            padding: 350rem 90rem 100rem;
        }
    }

    .title {
        font-size: 28rem;
        font-weight: bold;
        text-align: left;
        line-height: 1.2;
        margin-bottom: 25rem;
        color: white;
    
        @media screen and (max-width: 767px) {
            font-size: 58rem;
        }
    }
    
    .separate {
        margin: 27rem 0;
    }
    
    .heading {
        font-size: 12.5rem;
        font-weight: 900;
        line-height: 1.5;
        letter-spacing: 0.2rem;
    
        @media screen and (max-width: 767px) {
            font-size: 35rem;
        }
    }
    
    .paragraph {
        font-size: 11.5rem;
        font-weight: 300;
        line-height: 1.5;
        letter-spacing: 0.2rem;
    
        @media screen and (max-width: 767px) {
            font-size: 33rem;
        }
    }
`;

export default WithDirection(Wrapper);
