import React from 'react';

import { WHITELIST } from './constants';

import Nav from '../Home/LandingPageHeader/Header';
import Footer from '../../components/ComponentTwelve';

import leftHeader from './img/boy.png';
import centerHeader from './img/center-header.png';
import rightHeader from './img/girl-survival.png'
import whitelistIcon from './img/whitelist-icon.png';
import rightArrow from './img/right-arrow.png';
import itemBg from './img/bg.png';
import buttonBg from './img/button-bg.png';
import { useHistory } from 'react-router-dom';

import
    Wrapper,
    {
        HeaderBanner,
        Document,
        WhiteListWay,
        YoutubeWrapper
    }
from './styles';

function WhiteList() {
    const history = useHistory();
    history.push("/");

    const renderHeaderBanner = () => (
        <HeaderBanner>
            <div className="heading">
                <h2>Welcome to the Weapons <br/> Whitelist Program</h2>
            </div>

            <div className="image-layout">
                <>
                    <section className="with-overlay">
                        <img src={leftHeader} alt="left" className="left-header" />
                        <div className="overlay" />
                    </section>

                    <section className="without-overlay">
                        <img src={centerHeader} alt="center" className="center-header" />
                    </section>

                    <section className="with-overlay" style={{ paddingTop: 15 }}>
                        <img src={rightHeader} alt="right" className="right-header" />
                        <div className="overlay" />
                    </section>
                </>
            </div>

            <div className="bottom">
                <h2>
                    Weapon packages are your key to the Undead Blocks play-to-earn game ecosystem. Earn ZBUX as you fight through hordes of undead bastards.
                </h2>
            </div>
        </HeaderBanner>
    );

    const renderDocument = () => (
        <Document>
            <div className="box">
                <img src={whitelistIcon} alt="icon" className="left" />

                <section className="center">
                    <h2>Introducing the Undead Blocks Weapon Whitelist Program</h2>
                    <p>Read our Medium post to learn more about our whitelist.</p>
                </section>

                <a
                    href="https://medium.com/@UndeadBlocks/undead-blocks-call-to-arms-weapons-minting-guide-f7b48c72ef23"
                    target="_blank"
                    rel="noreferrer"
                    className="read-btn"
                >
                    <p>Read Article</p>
                    <img src={rightArrow} alt="arrow" />
                </a>
            </div>
        </Document>
    );

    const renderWhiteListItems = () => (
        <WhiteListWay>
            <div className="box">
                <h2>Three Ways to Get Whitelisted</h2>

                <div className="list">
                    {WHITELIST.map((item) => (
                        <div
                            key={item.name}
                            className="item"
                            style={{
                                background: `url(${itemBg}) no-repeat bottom / cover`
                            }}
                        >
                            <section className="top-item">
                                <h2 className="title">{item.name}</h2>

                                <div>
                                    <img src={item.icon} alt="icon" />
                                </div>
                            </section>

                            <section className="content">
                                <div>
                                    <p className="value">{item.value}</p>
                                    <span className="description">{item.description}</span>
                                </div>

                                <div className="parent">
                                    <a
                                        href={item.link}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="apply-btn"
                                        style={{
                                            background: `url(${buttonBg}) no-repeat center center / cover`
                                        }}
                                    >
                                        <span>APPLY</span>
                                    </a>
                                </div>
                            </section>
                        </div>
                    ))}
                </div>
            </div>
        </WhiteListWay>
    )

    const renderYoutube = () => (
        <YoutubeWrapper>
            <iframe
                title="yb"
                src="https://www.youtube.com/embed/_6T_MfmNcG0"
                style={{ border: '1px solid #ff0000', borderRadius: 5 }}
            >
            </iframe>
        </YoutubeWrapper>
    )

    return (
        <Wrapper>
            <Nav />
            {renderHeaderBanner()}
            {renderDocument()}
            {renderWhiteListItems()}
            {renderYoutube()}
            <Footer isCustom />
        </Wrapper>
    );
}

export default WhiteList;