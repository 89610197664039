import BigNumber from 'bignumber.js'
import Logger from 'js-logger';
import { get } from 'lodash';
import { ethers } from 'ethers'
import { BIG_TEN } from './bigNumber'
import { CONTRACT_TIMEOUT, ERROR_CODE_TIME_OUT, MESSAGE_TIMEOUT_MINT } from '../utils/constants';
import stakingAbi from '../config/abi/pool.json';
import erc20Abi from '../config/abi/erc20.json';



export const getContract = (abi, address, web3) => {
  if (web3 && web3.eth) {
    return new web3.eth.Contract(abi, address)
  }
  return null
};
export const getPoolContractEx = (stakeContractAddress, web3) => {
  if (!stakeContractAddress) {
    return null
  }
  return getContractCatch(stakingAbi, stakeContractAddress, web3)
}

export const getTokenContractEx = (contract, web3) => {
  return getContractCatch(erc20Abi, contract, web3)
}

export const getContractCatch = (abi, address) => {
  try {
    return new window.web3.eth.Contract(abi, address)
  } catch (ex) {
    return null
  }
}

export const getPoolContract = (stakeContractAddress) => {
  if (!stakeContractAddress) {
    return null
  }
  return getContractCatch(stakingAbi, stakeContractAddress)
}

export const getTokenContract = contract => {
  return getContractCatch(erc20Abi, contract)
}

// BACK-UP
// export const approve = async (lpContract, senderAddress, account) => {
//   return lpContract.methods
//     .approve(senderAddress, ethers.constants.MaxUint256)
//     .send({ from: account })
// };

export const approve = (lpContract, senderAddress, account, cb = () => { }, cbLog = () => { }, cbProcessing = () => { }, amount = ethers.constants.MaxUint256.toString()) => {
  return lpContract.methods.approve(senderAddress, amount).send({ from: account })
    .on('error', function (error) {
      Logger.log(`approve error ${error}`);
      cb(null, { ...error, description: error.message });
    })
    .on('transactionHash', (tx) => {
      waitForReceipt(tx, cb, 0, cbLog, cbProcessing);
      return tx.transactionHash;
    })
}

// BACK-UP
// export const stake = async (stakeContract, amount, decimals = 18, account) => {
//   return stakeContract.methods
//     .deposit(new BigNumber(amount).times(BIG_TEN.pow(decimals)).toFixed().toString())
//     .send({ from: account })
//     .on('transactionHash', (tx) => {
//       return tx.transactionHash
//     })
// };

export const stake = (stakeContract, amount, decimals = 18, account, cb = () => { }, cbLog = () => { }, cbProcessing = () => { }) => {
  const amountConvert = convertAmount(amount, decimals);
  return stakeContract.methods
    .deposit(amountConvert)
    .send({ from: account })
    .on('error', function (error) {
      Logger.log(`stake error ${error}`);
      cb(null, { ...error, description: error.message });
    })
    .on('transactionHash', (tx) => {
      waitForReceipt(tx, cb, 0, cbLog, cbProcessing);
      return tx.transactionHash;
    })
  // .on('confirmation', (confirmationNumber, receipt) => {
  //   Logger.log(`confirmationNumber ${confirmationNumber} | receipt ${receipt}`);
  // })
  // .on('receipt', function (receipt) {
  //   Logger.log('receipt' + receipt); // contains the new contract address
  //   // cb(receipt);
  // });
}

// BACK-UP
// export const unstake = async (stakeContract, amount, decimals = 18, account) => {
//   return stakeContract.methods
//     .withdraw(new BigNumber(amount).times(BIG_TEN.pow(decimals)).toFixed().toString())
//     .send({ from: account })
//     .on('transactionHash', (tx) => {
//       return tx.transactionHash
//     })
// };

export const unstake = (stakeContract, amount, account, cb = () => { }, decimals = 18, cbLog = () => { }) => {
  const amountConvert = convertAmount(amount, decimals);
  return stakeContract.methods
    .withdraw(amountConvert)
    .send({ from: account })
    .on('error', function (error) {
      Logger.log(`unStake error ${error}`);
      cb(null, { ...error, description: error.message });
    })
    .on('transactionHash', (tx) => {
      waitForReceipt(tx, cb, 0, cbLog);
      return tx.transactionHash;
    })
}

export const exit = async (sousChefContract, amount, account, cb = () => { }, cbLog = () => { }) => {
  return sousChefContract.methods
    .withdraw(amount)
    .send({ from: account })
    .on('error', function (error) {
      Logger.log(`exit error ${error}`);
      cb(null, { ...error, description: error.message });
    })
    .on('transactionHash', (tx) => {
      waitForReceipt(tx, cb, 0, cbLog);
      return tx.transactionHash;
    })
};

export const claim = (sousChefContract, account, cb = () => { }) => {
  return sousChefContract.methods
    .withdraw('0')
    .send({ from: account })
    .on('error', function (error) {
      Logger.log(`claim error ${error}`);
      cb(null, { ...error, description: error.message });
    })
    .on('transactionHash', (tx) => {
      waitForReceipt(tx, cb, 0);
      return tx.transactionHash;
    })
}

export const harvest = async (stakeContract, account) => {
  return stakeContract.methods
    .withdraw('0')
    .send({ from: account })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
};

export const withdrawByDepositId = async (sousChefContract, depositId, account, cb = () => { }, cbLog = () => { }, cbProcessing = () => { }) => {
  return sousChefContract.methods
    .withdraw(depositId)
    .send({ from: account })
    .on('error', function (error) {
      Logger.log(`exit error ${error}`);
      cb(null, { ...error, description: error.message });
    })
    .on('transactionHash', (tx) => {
      waitForReceipt(tx, cb, 0, cbLog, cbProcessing);
      return tx.transactionHash;
    })
};


export const getAllowance = async (tokenContract, account, contractAddress) => {
  return tokenContract.methods
    .allowance(account, contractAddress)
    .call();
};

export const getBalanceOf = async (tokenContract, account) => {
  return tokenContract.methods
    .balanceOf(account)
    .call();
};

export const getUserInfo = async (stakeContract, account) => {
  return stakeContract.methods
    .userInfo(account)
    .call();
};

export const getPendingReward = async (stakeContract, account) => {
  return stakeContract.methods
    .pendingReward(account)
    .call();
};

export const pendingReward = async (stakeContract, account) => {
  return await stakeContract.methods.pendingReward(account).call()
}

export const getRewardPerBlock = async (stakeContract) => {
  return stakeContract.methods
    .rewardPerBlock()
    .call();
};

export const getStartBlock = async (stakeContract) => {
  return stakeContract.methods
    .startBlock()
    .call();
};

export const getEndBlock = async (stakeContract) => {
  return stakeContract.methods
    .bonusEndBlock()
    .call();
};

// V2: Block for withdraw. Allow user can withdraw if block.number >= withdrawBlock
export const getWithdrawBlock = async (stakeContract) => {
  try {
    return Number(await stakeContract.methods.withdrawBlock().call());
  } catch (e) {
    return 0;
  }
};

export const getEnableLockToUser = async (stakeContract) => {
  try {
    return Boolean(await stakeContract.methods.enableLockToUser().call());
  } catch (e) {
    return false;
  }
};

export const getLockDuration = async (stakeContract) => {
  try {
    return Number(await stakeContract.methods.lockDuration().call());
  } catch (e) {
    return 0;
  }
};

// V2: Withdraw mode
// 0: Apply withdrawBlock to both (stake + reward)
// 1: Apply withdrawBlock to stake
// 2: Apply withdrawBlock to reward
export const getWithdrawMode = async (stakeContract) => {
  try {
    return Number(await stakeContract.methods.withdrawMode().call());
  } catch (e) {
    return 3
  }
};

export const getTotalStakingTokens = async (stakeContract) => {
  return stakeContract.methods
    .totalStakingTokens()
    .call();
};

export const getIsFrozen = async (stakeContract) => {
  return stakeContract.methods.isFrozen().call()
};

export const getMinDepositAmount = async (stakeContract) => {
  return stakeContract.methods.minDepositAmount().call();
};

export const getICOTickets = async (poolICOContract, account) => {
  return poolICOContract.methods.getICOTickets(account).call();
}


export const waitForReceipt = (hash, cb, timeout, cbLog = () => { }, cbProcessing = (hast = '') => { }) => {
  return window.web3 ? window.web3.eth.getTransactionReceipt(hash, function (error, receipt) {
    cbProcessing(hash)
    if (timeout >= (CONTRACT_TIMEOUT * 60 * 1000)) {
      return cb({ code: ERROR_CODE_TIME_OUT, description: MESSAGE_TIMEOUT_MINT });
    }
    if (error) {
      Logger.log(`waitForReceipt ${error}`);
      cb({ ...error, description: error.message });
    }
    if (receipt !== null) {
      Logger.log(`waitForReceipt receipt ${receipt} ${error}`);
      const status = get(receipt, 'status', false);
      // Transaction went through
      if (cb) {
        if (status) {
          cb(receipt);
          cbLog(receipt)
        } else {
          cb(null, null);
        }
      }
    } else {
      // Try again in 1 second
      window.setTimeout(function () {
        waitForReceipt(hash, cb, (timeout + 1000), cbLog);
      }, 1000);
    }
  }) : '';
}

const convertAmount = (amount, decimals = 18) => {
  if (amount === undefined || amount === null) {
    return new BigNumber(0).times(BIG_TEN.pow(decimals)).toFixed(0).toString()
  }

  if (BigNumber.isBigNumber(amount)) {
    return amount.times(BIG_TEN.pow(decimals)).toFixed(0).toString();
  }

  return new BigNumber(amount).times(BIG_TEN.pow(decimals)).toFixed(0).toString()
}

export const contractCall = (contract, method, defaultVal, ...params) => {
  try {
    return contract.methods[method](...params)
      .call()
      .catch(ex => {
        Logger.info('contractCall fail', method, params, ex.message)
        return defaultVal
      })
  } catch (ex) {
    // log ex, prevent app die
    Logger.info('contractCall fail', method, params, ex.message)
    return Promise.resolve(defaultVal)
  }
}