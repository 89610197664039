import http from '../http';

export default class OwnershipApi {
    static allSettings() {
        return http.get('/settings', {
            params: {
                limit: 30,
                skip: 0
            }
        })
    }
}
