import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniqBy } from 'lodash';

import { exportItems } from './utils';
import { fetchOwnerships } from '../../actions/ownership';

function useMintedList(props) {
    const dispatch = useDispatch();

    const { offset, isConnected, isEndCountdown } = props;

    const [mintedList, setMintedList] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);

    const account = useSelector(state => state.user?.userAccount?.accounts[0] ?? '');
    const wrongNetwork = useSelector(state => state.common?.wrongNetwork);
    const mintedRes = useSelector(state => state.ownership?.mintedRes?.result);

    /* LOAD FIRST */
    useEffect(() => {
        if (!isEndCountdown) return;
        if (isConnected) { 
            return setFirstLoad(true);
        }

        return setMintedList([]);
    }, [isConnected, isEndCountdown, account]);

    /* FETCH DATA FIRST */
    useEffect(() => {
        if (wrongNetwork) return;
        if (!isConnected) return;
        if (!isEndCountdown) return;
        if (offset > 0) return;

        dispatch(fetchOwnerships('', account, 10, 0))
            .then(res => {
                setFirstLoad(false);
                setMintedList(() => exportItems(res?.data));
                setHasMore(res?.data?.length > 9);
            })
            .catch(err => {
                return err;
            })
    }, [
        dispatch,
        offset,
        account,
        mintedRes,
        isEndCountdown,

        isConnected,
        wrongNetwork
    ]);

    /* LOAD MORE */
    useEffect(() => {
        if (!isConnected) return;
        if (offset === 0) return;

        dispatch(fetchOwnerships('', account, 10, offset))
            .then(res => {
                setFirstLoad(false);
                setMintedList(prevState => uniqBy([...prevState, ...exportItems(res?.data)], 'id'));
                setHasMore(res?.data?.length > 9);
            })
            .catch(err => {
                return err;
            })
    }, [
        dispatch,
        offset,
        account,
        mintedRes,
        isConnected
    ]);

    return { mintedList, hasMore, firstLoad };
}

export default useMintedList;