import http from '../http';

export default class UserApi {
    static getUserNonceKey(address, chainId) {
        return http.get(`/wallets/nonce/${address}/chainId/${chainId}`)
    }

    static getToken(address, signKey) {
        return http.post('/wallets/login', {
            address,
            sign: signKey
        })
    }

    static getMeta1() {
        return http.post('/wallets/meta1');
    }
}
