import React from 'react';
// import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';
// import cn from 'classnames';
import styled from 'styled-components';

import close from '../img/close.png';
// import leftFn from '../img/left-fn.png';
// import rightFn from '../img/right-fn.png';
// import button from '../img/button.png';
// import bloodBg from '../img/Items/amateur/Circle_Blood.png';

import amateurSparkle from '../../../lotties/amateur_sparkle.json';

// import { exportMintItem } from '../utils';

const Wrapper = styled.div`
    padding-bottom: 30px;
    width: 389rem;
    background-color: #111111;
    overflow-y: hidden;

    @media (max-width: 767px) {
        width: 100%;
        padding-bottom: 0;
    }

    .header-nav {
        display: flex;
        justify-content: flex-end;
        position: relative;
        z-index: 1000;

        // padding-bottom: 33rem;

        @media (max-width: 767px) {
            padding-bottom: 100rem;
        }

        .close {
            position: absolute;
            width: 22.5rem;
            height: 22.5rem;
            margin: 15rem;
            cursor: pointer;

            &:hover {
                transform: scale(1.005);
                transition: all 1s;
            }

            @media (max-width: 767px) {
                top: 15rem;
                right: 20rem;
                width: 55rem;
                height: 55rem;
            }
        }
    }

    .mint-image {
        display: flex;
        justify-content: center;

        @media (max-width: 767px) {
        }
    }

    .wrapper-info {
        @media (max-width: 767px) {
            position: relative;
            top: -100rem;
        }
    }

    .mint-info {
        padding: 0 30rem;
        position: relative;
        z-index: 100;
        text-align: center;

        @media (max-width: 767px) {
            padding: 0 85rem;
        }

        h2 {
            font-family: Poppins;
            font-size: 22.5rem;
            font-weight: bold;
            line-height: 1.2;
            text-align: center;
            color: #fff;
            margin: 0 10px 10px 10px;

            @media (max-width: 767px) {
                font-size: 50.5rem;
                margin: 0 0 20rem 0;
            }
        }

        p {
            font-family: Poppins;
            font-size: 12.5rem;
            line-height: 1.4;
            text-align: center;
            color: #fff;

            @media (max-width: 767px) {
                font-size: 30.5rem;
            }
        }
    }

    .btn-container {
        margin: 0 30rem;

        &:active {
            transform: scale(0.9);
            transition: all 0.2s;
        }

        @media (max-width: 767px) {
            margin: 0 60rem;
        }
        
        .mint-btn {
            height: 40rem;
            border-radius: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            @media (max-width: 767px) {
                height: 90rem;
            }

            span {
                font-family: Poppins;
                font-size: 15rem;
                font-weight: 600;
                line-height: 1.33;
                text-align: center;
                color: #fff;

                @media (max-width: 767px) {
                    font-size: 36rem;
                }
            }
        }
    }
`;

const MintContent = styled.div`
    width: 100%;

    .info {
        display: flex;
        justify-content: center;
        position: relative;
        // padding: 0 0 9.5rem;

        .image-pro {
            width: 100%;
            height: 370rem;
            position: relative;

            border-top-left-radius: 20rem;
            border-top-right-radius: 20rem;

            margin-bottom: 0rem;
            top: -15rem;

            @media (max-width: 767px) {
                height: 1100rem;
                top: -150rem;
            }
        }

        .mintBg {
            width: 200rem;
            height: 231.5rem;
            position: relative;

            @media (max-width: 767px) {
                width: 480rem;
                height: 540rem;
            }

            @media (min-width: 1024px) and (max-width: 1279px) {
            }

            @media (min-width: 1280px) {
            }
        }
    }
`;


function Step4(props) {
    const { item, onCancel, mintedRes = {} } = props;

    // const mintItem = exportMintItem(mintedRes);

    return (
        <Wrapper
        >
            {/* <img src={leftFn} alt="left" className="layer-left" /> */}

            <div className="header-nav">
                <img src={close} alt="close" onClick={() => onCancel()} className="close" />
            </div>

            <div className="mint-image">
                <MintContent>
                    <div className="info">
                        {/* <img src={bloodBg} alt="bg" className="mintBg" /> */}
                        <div
                            style={{ background: `url(${mintedRes?.thumbnail}) 10% -20% / 110% no-repeat` }}
                            className="image-pro"
                        />

                        <Lottie
                            options={{
                                loop: true,
                                autoplay: false,
                                animationData: amateurSparkle
                            }}
                            style={{
                                transition: `height 0.05s`,
                                position: 'absolute',
                                zIndex: 100
                            }}
                            height={'100%'}
                            isClickToPauseDisabled={true}
                        />
                    </div>
                </MintContent>
            </div>

            <div className='wrapper-info'>
                <div className="mint-info">
                    <h2>Successfully Minted {item.nameLower} Package</h2>
                    <p>Congrats {item.nameLower}! You're ready to survive the Apocalypse. We have added your weapon loadout to your arsenal & wallet.</p>
                </div>

                {/* <div className="btn-container">
                    <Link to={`/nft/${mintedRes.tokenId}`}>
                        <div
                            className="mint-btn"
                            style={{
                                background: `url(${button}) no-repeat center center / cover`
                            }}
                            onClick={() => onCancel()}
                        >
                            <span>Continue</span>
                        </div>
                    </Link>
                </div> */}
            </div>

            {/* <img src={rightFn} alt="right" className="layer-right" /> */}
        </Wrapper>
    );
}

export default Step4;