import { web3InstanceEx } from '../services/web3';
import Logger from 'js-logger';
import BigNumber from 'bignumber.js'

import { createWatcher, aggregate } from '@makerdao/multicall';
import { verifyChainId } from '../utils/web3';
import { getChainIdByConnectType } from './web3';

export const BIG_ZERO = new BigNumber(0)
export const BIG_TEN = new BigNumber(10)

export const configMulticall = chainId => {
    return getProviderMulticall(verifyChainId(chainId));
};

export const getProviderMulticall = async  () => {
    const chainIdConnect = await getChainIdByConnectType()
    const chainId = verifyChainId(chainIdConnect);
    let multicallAddress = process.env.REACT_APP_ETHER_WEB3_PROVIDER_TESTNET_ADDRESS;
    if (chainId === '0x1') {
        multicallAddress = process.env.REACT_APP_ETHER_WEB3_PROVIDER_MAINNET_ADDRESS;
    }
    if (chainId === '0x38') {
        multicallAddress = process.env.REACT_APP_BSC_WEB3_PROVIDER_MAINNET_ADDRESS;
    }
    if (chainId === '0x61') {
        multicallAddress = process.env.REACT_APP_BSC_WEB3_PROVIDER_TESTNET_ADDRESS;
    }

    return {
        multicallAddress,
        web3: window?.web3
    }
};

export const getProviderMulticallByChainId = async (chainIdParam) => {
    const chainId = verifyChainId(chainIdParam);
    let multicallAddress = process.env.REACT_APP_ETHER_WEB3_PROVIDER_TESTNET_ADDRESS;
    if (chainId === '0x1') {
        multicallAddress = process.env.REACT_APP_ETHER_WEB3_PROVIDER_MAINNET_ADDRESS;
    }
    if (chainId === '0x38') {
        multicallAddress = process.env.REACT_APP_BSC_WEB3_PROVIDER_MAINNET_ADDRESS;
    }
    if (chainId === '0x61') {
        multicallAddress = process.env.REACT_APP_BSC_WEB3_PROVIDER_TESTNET_ADDRESS;
    }

    const web3 = await web3InstanceEx();
    return {
        multicallAddress,
        web3: web3
    }
};


// Custom config. Default is BSC mainnet
export const initConfig = (chainId, interval = null) => {
    let config = configMulticall(chainId);

    if (interval === null) {
        config.interval = interval
    }

    return config;
};

// Create Watcher
export const initWatcher = (config, methods) => {
    try {
        const watcher = createWatcher(methods, config);

        // watcher.onError((e) => Logger.error('initWatcher onError', e))

        return watcher;
    } catch (e) {
        Logger.error('initWatcher', e);
    }
};

export const multicallAggregate = async (methods) => {
    try {
        const config = await getProviderMulticall();
        const data = await aggregate(methods, config);
        const { results } = data;
        return results.transformed;
    } catch (e) {
        Logger.error('multicallAggregate', e);
        return {};
    }
};

export const multicallAggregateByChainId = async (methods, chainId) => {
    try {
        const config = await getProviderMulticallByChainId(chainId);
        const data = await aggregate(methods, config);
        const { results } = data;
        return results.transformed;
    } catch (e) {
        Logger.error('multicallAggregateByChainId', e);
        return {};
    }
};

// Call data
export const execute = async (watcher, cb) => {
    try {
        // watcher.onError((e) => Logger.error('initWatcher onError', e))
        watcher.start();

        await watcher.awaitInitialFetch();

        let result = [];
        watcher
            .batch()
            .subscribe((data) => {
                result = arraytoObject(data);
            })
            .unsub()

        watcher.stop();

        cb(result);
    } catch (e) {
        Logger.error('execute', e);
        cb([]);
    }
};


export const getDecimalAmount = (amount, decimals = 18) => {
    return new BigNumber(amount || 0).times(BIG_TEN.pow(decimals))
}

export const getBalanceAmount = (amount, decimals = 18) => {
    return new BigNumber(amount || 0).dividedBy(BIG_TEN.pow(decimals))
}


export const getNumber = (amount) => {
    return getDecimalAmount(amount / 10 ** 18).toNumber();
}

const arraytoObject = (array) => {
    const listMap = [];
    (array || []).forEach(element => {
        listMap.push([element?.type, element?.value])
    });
    const map = new Map(listMap);
    return Object.fromEntries(map);
}