import React, { useRef } from 'react';
import HCaptcha from "@hcaptcha/react-hcaptcha";
import styled from 'styled-components';


const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    #captchaFrame {
        // width: 100%;

        iframe {
            // width: 100% !important;

            .no-touch {
                .no-selection {}
            }

            #anchor {
            }
        }
    }
`;

function HcapchaForm(props) {
    const { getToken } = props;

    const captchaRef = useRef(null);

    // const onLoad = () => {
    //     captchaRef.current.execute();
    // };

    const handleOnChalExpired = () => {}

    const handleOnClose = () => {}

    const handleOnError = () => {}

    const handleOnVerify = token => {
        getToken && getToken(token);
    };
    
    return (
        <Wrapper>
            <HCaptcha
                id="captchaFrame"
                ref={captchaRef}
                sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                onVerify={handleOnVerify}
                onError={handleOnError}
                onClose={handleOnClose}
                onChalExpired={handleOnChalExpired}
            />
        </Wrapper>
    );
}

export default HcapchaForm;