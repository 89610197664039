import http from '../http';
import { LIST_LIMIT_LENGTH } from '../../utils/common';
export default class OwnershipApi {
    static signature(body) {
        return http.post('/ownerships/signature', body);
    }

    static minted(body) {
        return http.post('/ownerships/minted', body);
    }

    static rebootSignature(body) {
        return http.post('/ownerships/reboot-signature', body);
    }

    static rebootMinted(body) {
        return http.post('/ownerships/reboot-minted', body);
    }

    static poolMinted(body) {
        return http.post('/ownerships/poolMinted', body);
    }

    static ownerships({
        weaponType,
        address,
        limit = 20,
        skip = 0
    }) {
        return http.get('/ownerships', {
            params: {
                weaponType,
                address,
                limit,
                skip
            }
        })
    }

    static getNFTDeail(contractAddress, tokenId) {
        return http.get(`/ownerships/${tokenId}`, {
            params: {
                contractAddress
            }
        });
    }

    static getMyListWeapons(
        address,
        weaponType = '',
        limit = LIST_LIMIT_LENGTH,
        skip = 0
    ) {
        return weaponType ? http.get(`/ownerships`, {
            params: {
                weaponType,
                address,
                skip,
                limit
            }
        }) : http.get(`/ownerships`, {
            params: {
                address,
                limit,
                skip
            }
        })
    }
}
