import Logger from 'js-logger';
import poolABI from '../config/abi/pool.json';
import erc20ABI from '../config/abi/erc20.json';
import { createAction, createActions } from "redux-actions";
import {
  getAllowance,
  getBalanceOf,
  getEndBlock,
  getMinDepositAmount,
  getPendingReward,
  getRewardPerBlock,
  getStartBlock,
  getWithdrawBlock,
  getWithdrawMode,
  getTotalStakingTokens,
  getUserInfo,
  getLockDuration,
  getEnableLockToUser,
} from "../utils/callHelpers";
import { web3Instance } from '../services/web3';
import {
  syncStakingInfo,
  syncStakingInfoInternal,
  syncBalanceInfo,
  syncAllowance,
  syncListDeposit,
  syncStakingInfoWithoutConnect
} from '../services/staking';
import PoolsApi from '../services/api/stakings';
import OwnershipApi from '../services/api/ownership';
import { FIXED_APY_DEFAULT, getOffsetData } from '../utils/common';
import { updateWalletBalanceAction } from '../actions/user';
import { converListItem, convertListDeposit } from '../constants/poolData';
import { exportItems } from '../pages/MintWeapons/utils';
import { isEmpty } from 'lodash';

export const setPoolsUserData = createAction('SET_POOLS_USER_DATA');
export const setPoolsPublicData = createAction('SET_POOLS_PUBLIC_DATA');
export const updatePoolsUserData = createAction('UPDATE_POOLS_USER_DATA');

export const fetchPoolsAllowance = async (account, getState) => {
  const chainId = getState().user.chainId;
  const stakingPools = getState().pools?.poolData?.items?.filter(item => parseInt(item.chainId) === parseInt(chainId));

  let allowanceDatas = {};
  const web3 = await web3Instance();
  for (let i = 0; i < stakingPools?.length; i++) {
    const p = stakingPools[i];
    const stakeContract = new web3.eth.Contract(erc20ABI, p.stakeTokenAddress);
    try {
      allowanceDatas[p.id] = await getAllowance(stakeContract, account, p.stakeContractAddress);
    } catch {
      allowanceDatas[p.id] = 0;
    }
  }
  return allowanceDatas;
};

export const fetchPoolsAllowanceMultiCall = async (account, getState) => {
  const chainId = getState().user.chainId;
  const stakingPools = getState().pools?.poolData?.items?.filter(item => parseInt(item.chainId) === parseInt(chainId));

  let allowanceDatas = {};
  for (let i = 0; i < stakingPools?.length; i++) {
    const p = stakingPools[i];
    try {
      const dataSync = await syncAllowance(chainId, p.stakeTokenAddress, p.stakeContractAddress, account);;
      allowanceDatas[p.id] = dataSync?.allowance;
    } catch {
      allowanceDatas[p.id] = 0;
    }
  }
  return allowanceDatas;
};


export const fetchUserBalances = async (account, getState) => {
  // Non BNB pools
  const chainId = getState().user.chainId;
  const stakingPools = getState().pools.data.filter(item => parseInt(item.chainId) === parseInt(chainId));
  let tokenBalances = {};
  const web3 = await web3Instance();
  for (let i = 0; i < stakingPools.length; i++) {
    const p = stakingPools[i];
    const stakeContract = new web3.eth.Contract(erc20ABI, p.stakeTokenAddress);
    try {
      tokenBalances[p.id] = await getBalanceOf(stakeContract, account);
    } catch {
      tokenBalances[p.id] = 0;
    }
  }
  return tokenBalances;
};

export const fetchUserStakeBalances = async (account, getState) => {
  const chainId = getState().user.chainId;
  const stakingPools = getState().pools.data.filter(item => parseInt(item.chainId) === parseInt(chainId));

  const web3 = await web3Instance();
  let userInfos = {};
  for (let i = 0; i < stakingPools.length; i++) {
    const p = stakingPools[i];
    const stakeContract = new web3.eth.Contract(poolABI, p.stakeContractAddress);
    try {
      const returnData = await getUserInfo(stakeContract, account);
      userInfos[p.id] = returnData.amount;
    } catch {
      userInfos[p.id] = 0;
    }
  }
  return userInfos;
};

export const fetchUserPendingRewards = async (account, getState) => {
  const chainId = getState().user.chainId;
  const stakingPools = getState().pools.data.filter(item => parseInt(item.chainId) === parseInt(chainId));

  const web3 = await web3Instance();
  let pendingRewards = {};
  for (let i = 0; i < stakingPools.length; i++) {
    const p = stakingPools[i];
    const stakeContract = new web3.eth.Contract(poolABI, p.stakeContractAddress);
    try {
      pendingRewards[p.id] = await getPendingReward(stakeContract, account);
    } catch {
      pendingRewards[p.id] = 0
    }
  }
  return pendingRewards;
};

export const fetchUserDepositTimes = async (account, getState) => {
  const chainId = getState().user.chainId;
  const stakingPools = getState().pools.data.filter(item => parseInt(item.chainId) === parseInt(chainId));

  const web3 = await web3Instance();
  let userDepositTimes = {};
  for (let i = 0; i < stakingPools.length; i++) {
    const p = stakingPools[i];
    const stakeContract = new web3.eth.Contract(poolABI, p.stakeContractAddress);
    try {
      userDepositTimes[p.id] = Number(await stakeContract.methods.userDepositTimes(account).call());
    } catch {
      userDepositTimes[p.id] = 0
    }
  }
  return userDepositTimes;
};

export const fetchRewardPerBlock = async (getState) => {
  const chainId = getState().user.chainId;
  const stakingPools = getState().pools.data.filter(item => parseInt(item.chainId) === parseInt(chainId));

  const web3 = await web3Instance();
  let rewardPerBlocks = {};
  for (let i = 0; i < stakingPools.length; i++) {
    const p = stakingPools[i];
    const stakeContract = new web3.eth.Contract(poolABI, p.stakeContractAddress);
    try {
      rewardPerBlocks[p.id] = await getRewardPerBlock(stakeContract);
    } catch {
      rewardPerBlocks[p.id] = 0;
    }
  }
  return rewardPerBlocks;
};

export const fetchPoolsBlockLimits = async (getState) => {
  const chainId = getState().user.chainId;
  const stakingPools = getState().pools.data.filter(item => parseInt(item.chainId) === parseInt(chainId));

  const web3 = await web3Instance();
  let blockLimits = {};
  for (let i = 0; i < stakingPools.length; i++) {
    const p = stakingPools[i];
    const stakeContract = new web3.eth.Contract(poolABI, p.stakeContractAddress);
    try {
      const startBlock = await getStartBlock(stakeContract);
      const endBlock = await getEndBlock(stakeContract);
      const withdrawBlock = await getWithdrawBlock(stakeContract);
      const withdrawMode = await getWithdrawMode(stakeContract);
      const lockDuration = await getLockDuration(stakeContract);
      const enableLockToUser = await getEnableLockToUser(stakeContract);
      blockLimits[p.id] = {
        startBlock,
        endBlock,
        withdrawBlock,
        withdrawMode,
        lockDuration,
        enableLockToUser
      }
    } catch {
      blockLimits[p.id] = {
        startBlock: 0,
        endBlock: 0,
        withdrawBlock: 0,
        withdrawMode: 3,
        lockDuration: 0,
        enableLockToUser: false
      }
    }
  }
  return blockLimits;
};

export const fetchPoolsTotalStaking = async (getState) => {
  const chainId = getState().user.chainId;
  const stakingPools = getState().pools.data.filter(item => parseInt(item.chainId) === parseInt(chainId));

  const web3 = await web3Instance();
  let totalStaked = {};
  for (let i = 0; i < stakingPools.length; i++) {
    const p = stakingPools[i];
    const stakeContract = new web3.eth.Contract(poolABI, p.stakeContractAddress);
    try {
      totalStaked[p.id] = await getTotalStakingTokens(stakeContract);
    } catch {
      totalStaked[p.id] = 0;
    }
  }
  return totalStaked;
};

export const fetchPoolFrozen = async (getState) => {
  const chainId = getState().user.chainId;
  const stakingPools = getState().pools.data.filter(item => parseInt(item.chainId) === parseInt(chainId));

  let frozens = {};
  for (let i = 0; i < stakingPools.length; i++) {
    const p = stakingPools[i];
    frozens[p.id] = false;
  }
  return frozens;
};


export const fetchPoolMinStakeValue = async (getState) => {
  const chainId = getState().user.chainId;
  const stakingPools = getState().pools.data.filter(item => parseInt(item.chainId) === parseInt(chainId));
  const web3 = await web3Instance();

  let minAmounts = {};
  for (let i = 0; i < stakingPools.length; i++) {
    const p = stakingPools[i];
    const stakeContract = new web3.eth.Contract(poolABI, p.stakeContractAddress);
    try {
      minAmounts[p.id] = await getMinDepositAmount(stakeContract);
    } catch {
      minAmounts[p.id] = 0;
    }
  }
  return minAmounts;
};


// Thunks
export const fetchPoolsPublicDataAsync = () => async (dispatch, getState) => {
  try {
    const rewardPerBlocks = await fetchRewardPerBlock(getState);
    const blockLimits = await fetchPoolsBlockLimits(getState);
    const totalStakings = await fetchPoolsTotalStaking(getState);
    const frozens = await fetchPoolFrozen(getState);
    const minAmounts = await fetchPoolMinStakeValue(getState);

    const chainId = getState().user.chainId;
    const poolsConfig = getState().pools.data.filter(item => parseInt(item.chainId) === parseInt(chainId));
    const liveData = poolsConfig.map((pool) => {
      return {
        id: pool.id,
        rewardPerBlock: rewardPerBlocks[pool.id],
        ...blockLimits[pool.id],
        totalStaked: totalStakings[pool.id],
        minAmount: minAmounts[pool.id],
        isFrozen: frozens[pool.id]
      }
    });
    dispatch(setPoolsPublicData(liveData));
  } catch (e) {

  }
};

export const updateUserAllowanceMulti = (id, account) => async (dispatch, getState) => {
  const allowances = await fetchPoolsAllowanceMultiCall(account, getState);
  dispatch(updatePoolsUserData({ id, field: 'allowance', value: allowances[id] }));
};

export const updateUserBalance = (id, account) => async (dispatch, getState) => {
  const tokenBalances = await fetchUserBalances(account, getState);
  dispatch(updatePoolsUserData({ id, field: 'stakingTokenBalance', value: tokenBalances[id] }));
};

export const updateUserStakedBalance = (id, account) => async (dispatch, getState) => {
  const stakedBalances = await fetchUserStakeBalances(account, getState);
  dispatch(updatePoolsUserData({ id, field: 'stakedBalance', value: stakedBalances[id] }));
};

export const updateUserPendingReward = (id, account) => async (dispatch, getState) => {
  const pendingRewards = await fetchUserPendingRewards(account, getState);
  dispatch(updatePoolsUserData({ id, field: 'pendingReward', value: pendingRewards[id] }));
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// const { setPoolsDataRequest, setPoolsDataSuccess } = createActions({
//   SET_POOLS_DATA_REQUEST: () => { },
//   SET_POOLS_DATA_SUCCESS: data => ({ data })
// });
// export const fetchStakingPoolsData = () => (dispatch) => {
//   dispatch(setPoolsDataRequest());
//   return dispatch(setPoolsDataSuccess(POOL_DATA));
// };
export const {
  fetchPoolDataRequest,
  fetchPoolDataSuccess,
  fetchPoolDataFail,
  fetchPoolDataClean
} = createActions({
  FETCH_POOL_DATA_REQUEST: () => { },
  FETCH_POOL_DATA_SUCCESS: data => ({ data }),
  FETCH_POOL_DATA_FAIL: error => ({ error }),
  FETCH_POOL_DATA_CLEAN: data => ({ data })
});
export const fetchInitApiSuccess = createAction('FETCH_INIT_STAKING_API_SUCCESS');

// const poolCallContracts = (pools) => {
//   return (pools || []).filter(item => isCallContract(item));
// }

// const isCallContract = (item) => {
//   return item.status === STAKING_TYPES.LIVE
//     || item.status === STAKING_TYPES.COMPLETED
//     || item.stakeContractAddress !== undefined
//   // && (parseInt(item.chainId) === parseInt(chainId) || chainId === '')
// }


/**
 * CALL API BE
 * @param {*} queries 
 * @param {*} nextStep 
 * @returns 
 */
export const fetchStakingPoolsData = (queries = {}) => async (dispatch, getState) => {
  const { poolData } = getState().pools;

  // Prevent double call while requesting
  if (poolData.requesting) {
    return Promise.resolve()
  }
  dispatch(fetchPoolDataRequest())

  return PoolsApi.getPools(queries)
    .then(({ data }) => {
      dispatch(fetchPoolDataClean())

      const { limit, items, skip } = data;

      const isEnd = (items || []).length < limit;

      if (!isEnd) {
        (items || []).pop();
      }

      const listItem = (items || []).map(item => {
        item.isFetchContract = false;
        item.isFetchContractWithoutConnect = false;
        item.requestingContractWithoutConnect = false;
        return item;
      })

      const listFinal = converListItem(listItem);

      const result = {
        items: listFinal,
        limit: limit - 1,
        skip,
        isEnd
      }
      dispatch(fetchPoolDataSuccess(result));
      return result;
    })
    .catch(error => {
      dispatch(fetchPoolDataFail(error))
    })
}

export const {
  fetchPoolByIdWithoutConnectRequest,
  fetchPoolByIdRequest,
  fetchPoolByIdSuccess,
  fetchPoolByIdFail,
} = createActions({
  FETCH_POOL_BY_ID_WITHOUT_CONNECT_REQUEST: data => ({ data }),
  FETCH_POOL_BY_ID_REQUEST: () => { },
  FETCH_POOL_BY_ID_SUCCESS: data => ({ data }),
  FETCH_POOL_BY_ID_FAIL: error => ({ error }),
});
/**
 * SYNC DATA POOL CONTRACT BY ID_POOL - CONNECTED
 * @param {*} account 
 * @param {*} idPool 
 * @returns 
 */
export const fetchDataContractById = (account, idPool) => async (dispatch, getState) => {
  try {

    const chainId = getState().user.chainId;
    const pools = getState().pools.poolData?.items;
    const stakingPools = (pools || []).find(item => {
      return item.id === idPool
    })

    let dataPoolContract = {};

    try {
      dataPoolContract = await syncStakingInfo(chainId, stakingPools.stakeTokenAddress, stakingPools.stakeContractAddress, account)
      // console.log('🚀 ~ file: pools.js ~ line 416 ~ fetchDataContractById ~ dataPoolContract', dataPoolContract);
    } catch (ex) {
      Logger.info('fetchPoolsPublic', ex)
    }

    let dataDeposit = {};
    try {
      dataDeposit = await syncListDeposit(chainId, stakingPools.stakeContractAddress, account, dataPoolContract?.depositId)
    } catch (ex) {
      Logger.info('fetchPoolsPublic', ex)
    }

    if (isEmpty(dataPoolContract)) {
      dispatch(fetchPoolByIdSuccess(stakingPools));
      return;
    }

    const { listDeposit, myEarnings } = convertListDeposit(dataDeposit, dataPoolContract?.depositId);


    const resultData = {
      ...stakingPools,
      id: stakingPools.id,
      totalStaked: dataPoolContract?.currentStakedPerPool || 0,
      isFrozen: dataPoolContract?.isFrozen,
      apy: parseInt(dataPoolContract?.fixedAPY) / FIXED_APY_DEFAULT * 100 || stakingPools?.apy,
      startTime: dataPoolContract?.startTime,
      endTime: dataPoolContract?.endTime,

      allowance: dataPoolContract?.allowance,
      stakingTokenBalance: dataPoolContract?.stakingTokenBalance,
      stakedBalance: dataPoolContract?.amount,
      pendingReward: dataPoolContract?.pendingReward,
      status: stakingPools?.status,

      lockDuration: dataPoolContract?.lockDuration,
      total: dataPoolContract?.nftStakedRequired,
      progress: dataPoolContract?.nftStakedProgress,
      listDeposit,
      myEarnings,

      isFetchContract: true
    }

    dispatch(fetchPoolByIdSuccess(resultData));
  } catch (e) {
    Logger.info('fetchDataContractByItem', e);
  }
}


/**
 * SYNC DATA POOL CONTRACT BY ID_POOL -- NOT CONNECT
 * @param {*} idPool 
 * @returns 
 */
export const fetchDataContractByIdWithoutConnect = (idPool) => async (dispatch, getState) => {
  try {
    const pools = getState().pools.poolData?.items;
    const stakingPools = (pools || []).find(item => {
      return item.id === idPool
    })
    const { requestingContractWithoutConnect } = stakingPools;

    // Prevent double call while requesting
    if (requestingContractWithoutConnect) {
      return Promise.resolve()
    }

    if (!stakingPools?.chainId || !stakingPools?.stakeTokenAddress || !stakingPools?.stakeContractAddress) {
      return Promise.resolve()
    }

    dispatch(fetchPoolByIdWithoutConnectRequest(idPool));
    let dataPoolContract = {};

    try {
      dataPoolContract = await syncStakingInfoWithoutConnect(
        stakingPools?.chainId,
        stakingPools?.stakeTokenAddress,
        stakingPools?.stakeContractAddress);
    } catch (ex) {
      Logger.info('fetchPoolsPublic', ex)
    }

    if (isEmpty(dataPoolContract)) {
      return;
    }

    const resultData = {
      ...stakingPools,
      id: stakingPools.id,
      totalStaked: dataPoolContract?.currentStakedPerPool || 0,
      isFrozen: dataPoolContract?.isFrozen,
      apy: parseInt(dataPoolContract?.fixedAPY) / FIXED_APY_DEFAULT * 100 || stakingPools?.apy,
      status: stakingPools?.status,
      lockDuration: dataPoolContract?.lockDuration,
      total: dataPoolContract?.nftStakedRequired,

      isFetchContractWithoutConnect: true,
      requestingContractWithoutConnect: false
    }
    dispatch(fetchPoolByIdSuccess(resultData));
  } catch (e) {
    Logger.info('fetchDataContractByItem', e);
  }
}


/**
 * [INTERVAL] SYNC DATA POOL CONTRACT BY ID_POOL 
 * @param {*} account 
 * @param {*} idPool 
 * @returns 
 */
export const fetchDataContractByIdInterval = (account, idPool) => async (dispatch, getState) => {
  try {
    const chainId = getState().user.chainId;
    const pools = getState().pools.poolData?.items;
    const stakingPools = (pools || []).find(item => {
      return item.id === idPool
    })

    let dataPoolContract = {};

    try {
      dataPoolContract = await syncStakingInfoInternal(chainId, stakingPools.stakeContractAddress, account);
    } catch (ex) {
      Logger.info('fetchDataContractByIdInterval', ex)
    }

    let dataDeposit = {};
    try {
      dataDeposit = await syncListDeposit(chainId, stakingPools.stakeContractAddress, account, dataPoolContract?.depositId)
    } catch (ex) {
      Logger.info('fetchPoolsPublic', ex)
    }

    const { listDeposit, myEarnings } = convertListDeposit(dataDeposit, dataPoolContract?.depositId);

    const resultData = {
      ...stakingPools,
      stakedBalance: dataPoolContract?.amount,
      pendingReward: dataPoolContract?.pendingReward,
      totalStaked: dataPoolContract?.currentStakedPerPool || 0,
      isFetchContract: true,
      listDeposit,
      myEarnings
    }

    dispatch(fetchPoolByIdSuccess(resultData));
  } catch (e) {
    Logger.info('fetchDataContractByIdInterval', e);
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * CALL LOAD MORE
 * @param {*} nextStep 
 * @returns 
 */
export const fetchMorePoolData = () => (dispatch, getState) => {
  const { isEnd, offsetNext } = getOffsetData(getState().pools.poolData);

  // Skip if is end
  if (isEnd) {
    return Promise.resolve();
  }

  return dispatch(fetchMorePoolDataAction(offsetNext))
};

export const {
  fetchMorePoolDataRequest,
  fetchMorePoolDataSuccess,
  fetchMorePoolDataFail,
} = createActions({
  FETCH_MORE_POOL_DATA_REQUEST: () => { },
  FETCH_MORE_POOL_DATA_SUCCESS: data => ({ data }),
  FETCH_MORE_POOL_DATA_FAIL: error => ({ error }),
});
export const fetchMorePoolDataAction = (skip) => (dispatch, getState) => {
  const { selectedCategory: type, query, poolData } = getState().pools;

  // Prevent double call while requesting
  if (poolData.requestingLoadMore) {
    return Promise.resolve()
  }

  dispatch(fetchMorePoolDataRequest());

  return PoolsApi.getPools({ skip, type, search: query })
    .then(({ data }) => {
      const { limit, items, skip } = data;

      const isEnd = (items || []).length < limit;

      if (!isEnd) {
        (items || []).pop();
      }
      const listItem = (items || []).map(item => {
        item.isFetchContract = false;
        return item;
      })

      const dataResult = {
        isEnd,
        limit: limit - 1,
        skip,
        items: [...poolData?.items, ...listItem]
      }
      dispatch(fetchMorePoolDataSuccess(dataResult));
      return data;
    })
    .catch(error => {
      dispatch(fetchMorePoolDataFail(error));
      return false;
    });
};

/**
 * UPDATE BALANCE 
 * @param {*} id 
 * @param {*} account 
 * @returns 
 */
export const updateBalancePools = createAction('UPDATE_BALANCE_POOL');
export const updateBalanceAllPools = createAction('UPDATE_BALANCE_ALL_POOL');

export const updateDataPoolEx = (id, account) => async (dispatch, getState) => {
  const chainId = getState().user.chainId;
  const pools = getState().pools.poolData?.items;
  const stakingPools = (pools || []).find(item => {
    return item.id === id
  })

  dispatch(updateWalletBalanceAction(account))

  let dataPoolContract = {};

  try {
    dataPoolContract = await syncBalanceInfo(chainId, stakingPools.stakeTokenAddress, stakingPools.stakeContractAddress, account);
  } catch (ex) {
    Logger.info('updateDataPoolEx', ex)
  }

  dispatch(updateBalancePools({
    id,
    totalStaked: dataPoolContract?.currentStakedPerPool,
    pendingReward: dataPoolContract?.pendingReward,
    stakedBalance: dataPoolContract?.stakedBalance,
    stakingTokenBalance: dataPoolContract?.stakingTokenBalance
  }))
}


export const updateDataPool = (id, account) => async (dispatch, getState) => {
  const chainId = getState().user.chainId;
  const pools = getState().pools.poolData?.items;
  const stakingPools = (pools || []).find(item => {
    return item.id === id
  })

  dispatch(updateWalletBalanceAction(account))

  let dataPoolContract = {};

  try {
    dataPoolContract = await syncBalanceInfo(chainId, stakingPools.stakeTokenAddress, stakingPools.stakeContractAddress, account);
    // console.log('🚀 ~ file: pools.js ~ line 608 ~ updateDataPool ~ dataPoolContract', JSON.stringify(dataPoolContract));
  } catch (ex) {
    Logger.info('updateDataPoolEx', ex)
  }

  let dataDeposit = {};
  try {
    dataDeposit = await syncListDeposit(chainId, stakingPools.stakeContractAddress, account, dataPoolContract?.depositId)
  } catch (ex) {
    Logger.info('fetchPoolsPublic', ex)
  }

  const { listDeposit, myEarnings } = convertListDeposit(dataDeposit, dataPoolContract?.depositId);

  dispatch(updateBalanceAllPools({
    id,
    stakeTokenAddress: stakingPools?.stakeTokenAddress,
    totalStaked: dataPoolContract?.currentStakedPerPool,
    // pendingReward: dataPoolContract?.pendingReward,
    stakedBalance: dataPoolContract?.amount,
    stakingTokenBalance: dataPoolContract?.stakingTokenBalance,

    // total: dataPoolContract?.nftStakedRequired,
    progress: dataPoolContract?.nftStakedProgress,
    listDeposit,
    myEarnings,
  }))
}

// --------------------------------------------------------
// const calculateStatus = (endTime, status) => {
//   const now = moment().unix() * 1000;

//   // LIVE
//   // UPCOMING
//   // => using BO
//   if (status === STAKING_TYPES.LIVE || status === STAKING_TYPES.UPCOMING) {
//     return status;
//   }

//   // COMPLETED : using Contract
//   if (now > endTime)
//     return STAKING_TYPES.COMPLETED;


//   return STAKING_TYPES.LIVE;
// }


export const {
  fetchResultStakeMintRequest,
  fetchResultStakeMintSuccess,
  fetchResultStakeMintFail,
  fetchResultStakeMintClean
} = createActions({
  FETCH_RESULT_STAKE_MINT_REQUEST: () => { },
  FETCH_RESULT_STAKE_MINT_SUCCESS: data => ({ data }),
  FETCH_RESULT_STAKE_MINT_FAIL: error => ({ error }),
  FETCH_RESULT_STAKE_MINT_CLEAN: data => ({ data })
});
export const fetchResultStakeMint = (tranxHash) => async (dispatch, getState) => {
  const { resultStakeMint } = getState().pools;

  // Prevent double call while requesting
  if (resultStakeMint.requesting) {
    return Promise.resolve()
  }

  const body = { transactionHash: tranxHash };
  dispatch(fetchResultStakeMintRequest())

  return OwnershipApi.poolMinted(body)
    .then(({ data }) => {
      const result = exportItems(data);
      dispatch(fetchResultStakeMintSuccess(result));
      return data;
    })
    .catch(error => {
      dispatch(fetchPoolDataFail(error))
    })
}