import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie'
import SwiperCore, {
    Parallax,
    Pagination,
    Navigation,
    EffectFade
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import Variant from '../../../pages/NFT/Variant';
import { getDescriptionByVariantSku } from '../../../utils/common'

import {
    // CARD_LIST,
} from './constants';
import usePackages from './usePackages';

import Wrapper, {
    // TitlePage,
    SwiperList,
    SwiperItem,
    NftInfoStyle
} from './styles';

import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
import { isEmpty } from 'lodash';

SwiperCore.use([Parallax, Pagination, Navigation]);

function SwiperCustom(props) {
    const [activeItem, setActiveItem] = useState('');

    const {
        pathname,
        data,
        requesting
    } = props;

    const {
        isMobile,
        isTablet,
        isDesktop
    } = useSelector(state => state.common);

    const { allPackages } = usePackages({ pathname, data });
    // console.log('🚀 ~ file: index.jsx ~ line 55 ~ SwiperCustom ~ requesting', requesting);
    // console.log('🚀 ~ file: index.jsx ~ line 53 ~ SwiperCustom ~ allPackages', allPackages);

    useEffect(() => {
        if (isTablet || isMobile) {
            setActiveItem(0);
        }
    }, [isTablet, isMobile, isDesktop]);

    const handleOnSlideChange = (e) => {
        if (isTablet || isMobile) {
            switch (e.realIndex) {
                case 0:
                    setActiveItem('ASSASSIN');
                    break;

                case 1:
                    setActiveItem('ZOMBIE KILLER');
                    break;

                case 2:
                    setActiveItem('AMATEUR');
                    break;

                case 3:
                    setActiveItem('SURVIVOR');
                    break;

                default:
                    break;
            }
        } else {
            switch (e.realIndex) {
                case 0:
                    setActiveItem('ZOMBIE KILLER');
                    break;

                case 1:
                    setActiveItem('AMATEUR');
                    break;

                case 2:
                    setActiveItem('SURVIVOR');
                    break;

                case 3:
                    setActiveItem('ASSASSIN');
                    break;

                default:
                    break;
            }
        }
    }




    return (
        <Wrapper>
            <SwiperList>
                <Swiper
                    spaceBetween={isDesktop ? 70 : 50}
                    slidesPerView={(isTablet || isMobile) ? 1 : 1}
                    onSlideChange={handleOnSlideChange}
                    onSwiper={handleOnSlideChange}
                    modules={[EffectFade]}
                    effect={'cube'}
                    navigation={allPackages && allPackages?.length > 1 && !requesting ? true : false}
                    loop={allPackages && allPackages?.length > 1 && !requesting ? true : false}
                    allowTouchMove={false}
                >
                    {requesting && isEmpty(allPackages) ?
                        <SwiperSlide>
                            <SwiperItem>
                                <div className="wrapped-null">
                                    <div className="content-null">
                                        <span
                                            className='img-null'
                                        />
                                    </div>
                                    <div className="bottom-null">
                                        <p></p>
                                    </div>
                                </div>
                            </SwiperItem>
                        </SwiperSlide> :
                        <>
                            {allPackages && allPackages.map((item, index) => (
                                <SwiperSlide
                                    key={index}
                                >
                                    {({ isActive }) => {
                                        return <SwiperItem isActive={index === activeItem} type={item.type}>
                                            <div
                                                className="wrapped"
                                            >
                                                {/* <h1 className="heading">{item?.package?.name}</h1> */}

                                                <div className="content">

                                                    <NftInfoStyle>
                                                        <div className="info">
                                                            <Variant item={item} />
                                                        </div>
                                                    </NftInfoStyle>


                                                    <Lottie
                                                        options={{
                                                            loop: false,
                                                            autoplay: false,
                                                            animationData: item.lottie
                                                        }}
                                                        style={{
                                                            display: item.name === activeItem ? 'block' : 'none',
                                                            transition: `height 0.05s`
                                                        }}
                                                        isStopped={item.name !== activeItem}
                                                    />

                                                    <Lottie
                                                        options={{
                                                            loop: true,
                                                            autoplay: false,
                                                            animationData: item.sparkleLottie
                                                        }}
                                                        style={{
                                                            display: item.name === activeItem ? 'block' : 'none',
                                                            transition: `height 0.05s`,
                                                            position: 'absolute'
                                                        }}
                                                        height={'65%'}
                                                        isStopped={item.name !== activeItem}
                                                        isClickToPauseDisabled={true}
                                                    />
                                                </div>

                                                <div className="bottom">
                                                    <p
                                                        style={{
                                                            backgroundImage: `linear-gradient(to bottom, ${item.start}, ${item.end}), linear-gradient(to bottom, #fff, #fff)`
                                                        }}
                                                        className="text-gradient"
                                                    >
                                                        {/* {item.price} {item.symbol} */}
                                                    </p>
                                                    <p>{getDescriptionByVariantSku(item?.variants)} </p>
                                                </div>

                                                {/* {getSoldOut?.value ? (
                                                    <div className="sold-out">
                                                        <img src={soldGlow} alt="sold" className="bottom-mint-btn" />
                                                        <img src={soldInner} alt="inner" className="top-mint-btn" />
                                                        <span>SOLD OUT</span>
                                                    </div>
                                                ) : (
                                                    <div className="parent">
                                                        <img src={glow} alt="mint" className="bottom-mint-btn" />
                                                        <img src={inner} alt="mint" className="top-mint-btn" onClick={() => handleOnClick(item)} />
                                                        <span onClick={() => handleOnClick(item)}>{!isConnected ? 'CONNECT' : item.buttonLbl}</span>
                                                    </div>
                                                )} */}
                                            </div>

                                            {/* {item.name !== activeItem && <div className="overlay" />} */}
                                        </SwiperItem>
                                    }}
                                </SwiperSlide>
                            ))}
                        </>}
                </Swiper>
            </SwiperList>
        </Wrapper>
    );
}

export default SwiperCustom;
