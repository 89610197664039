
import BigNumber from 'bignumber.js'

export const PAGE_NFT = '/nft';

export const CONTRACT_ADDRESS = {
    1: {
        CODE: '0X1',
        DOMAIN_URL: process.env.REACT_APP_CONTRACT_ETH_MAINNET_DOMAIN_URL
    },
    4: {
        CODE: '0X4',
        DOMAIN_URL: process.env.REACT_APP_CONTRACT_ETH_RINKEBY_DOMAIN_URL
    },
    56: {
        CODE: '0X38',
        DOMAIN_URL: process.env.REACT_APP_CONTRACT_BSC_MAINNET_DOMAIN_URL
    },
    97: {
        CODE: '0X61',
        DOMAIN_URL: process.env.REACT_APP_CONTRACT_BSC_TESTNET_DOMAIN_URL
    }
};

export const getContractByChainId = (chainId) => {
    if ((typeof chainId !== 'number' && typeof chainId !== 'string') || chainId === '') {
        return CONTRACT_ADDRESS[1];
    }

    if (typeof chainId === 'number') {
        return CONTRACT_ADDRESS[chainId]
    }
    if (chainId.startsWith('0x') && typeof chainId === 'string') {
        return CONTRACT_ADDRESS[parseInt(chainId)];
    }
    return CONTRACT_ADDRESS[1];
}

export const TYPE_STAKE_UNSTAKE = {
    STAKE: {
        CODE: 'STAKE',
        TITLE: 'Staking in Pool',
    },
    UNSTAKE: {
        CODE: 'UNSTAKE',
        TITLE: 'Unstake from Pool'
    }
}

export const TYPE_STAKE_UNSTAKE_LIST = Object.values(TYPE_STAKE_UNSTAKE);

export const STAKING_TYPES = {
    LIVE: 'LIVE',
    COMPLETED: 'COMPLETED',
    UPCOMING: 'UPCOMING',
}


export const WITHDRAW_MODE = [
    {
        STAKE_DISABLE: false,
        UNSTAKE_DISABLE: false,
        CLAIM_DISABLE: false,
        EXIT_DISABLE: false
    }
]

export const LIST_LIMIT_LENGTH = 3;
export const PAGE_TRANSITION_TIMEOUT = 600;
export const CONTRACT_TIMEOUT = 5;
export const ERROR_CODE_TIME_OUT = 408;
export const MESSAGE_TIMEOUT_MINT = 'There was a timeout while attempting to mint your transaction.'
export const MAX_UINT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const KNIFE = 'knife';
export const PISTOL = 'pistol';
export const MP5 = 'mp5';
export const AKM = 'akm';
export const DB = 'db';
export const F1 = 'f1';
export const M1A = 'm1a';
export const BASEBALL_BAT = 'baseballbat';
export const AXE = 'axe';
export const RPG = 'rpg';
export const R870 = 'r870';

export const WEAPON_SKINS = [
    F1,
    PISTOL,
    MP5,
    DB,
    AKM,
    M1A,
    BASEBALL_BAT,
    AXE,
    RPG,
    R870
]

export const BREAKPOINTS = {
    SM_MAX: 767,
    MD_MIN: 768,
    MD_MAX: 1023,
    LG_MIN: 1024,
    LG_MIN_CUSTOM: 1279
};

export const SEPARATOR_THOUSAND = ',';
export const SEPARATOR_DECIMAL = '.'
export const PREFIX_NOT_EQUAL_BN = '~'

export const DEFAULT_ERR_MESSAGE = { title: '', text: '' };
export const DEFAULT_MINT_STAKE_DATA = { mintNumber: new BigNumber(0), stakeAmount: new BigNumber(0) };

export const KEY_REPLACE_DESCRIPT = '{restTokens}';