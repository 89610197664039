import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

import blockIcon from './img/block.png';
import cancelIcon from './img/cancel.png';

import { setToast } from '../../actions/common';

const Wrapper = styled.div`
    .Toastify__toast-container--top-right {
        width: 413rem;
        padding: 0;

        top: 90.5rem;
        right: 96.5rem;

        @media screen and (max-width: 767px) {
            display: none;
        }
    }

    .Toastify__toast-container--top-center {
        left: 70rem;
        right: 70rem;
        top: 400rem;

        @media screen and (max-width: 767px) {
            width: auto;
        }
    }

    .Toastify__toast {
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
    }

    .Toastify__toast-body {
        width: 100%;
        margin: 0;
        padding: 0;

        .toast-content {
            display: grid;
            grid-template-columns: 15.6% 76% 8.4%;

            .toast-left-content {
                background-image: radial-gradient(circle at 0 0, #f00, #af0000);
                display: flex;
                justify-content: center;
                align-items: center;
    
                width: 64.5rem;
                min-height: 95rem;

                @media screen and (max-width: 767px) {
                    width: 153rem;
                    min-height: 240rem;
                }

                .block {
                    width: 38rem;
                    height: 38.5rem;

                    @media screen and (max-width: 767px) {
                        width: 90rem;
                        height: 90rem;
                    }
                }
            }
        
            .toast-right-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                padding: 0 20px;

                h2 {
                    font-family: Poppins;
                    font-size: 20rem;
                    font-weight: bold;
                    line-height: 1.6;
                    text-align: center;
                    color: #fff;

                    margin-bottom: 0;

                    @media screen and (max-width: 767px) {
                        font-size: 50rem;
                    }
                }

                p {
                    font-family: Poppins;
                    font-size: 12.5rem;
                    font-style: normal;
                    line-height: 1.6;
                    text-align: center;
                    color: #fff;

                    margin-bottom: 0;

                    @media screen and (max-width: 767px) {
                        font-size: 32.5rem;
                    }
                }
            }
        }
    }

    .toast-container {
        border-radius: 7.5rem;
        border: solid 1.5rem #202429;
        background-color: #0f1014;

        @media screen and (max-width: 767px) {
            border-radius: 7.5px;
            border: solid 1.5px #202429;
            background-color: #0f1014;
        }

        .close {
            width: 22.5rem;
            height: 22.5rem;

            margin: 10rem 10rem 0 0;

            @media screen and (max-width: 767px) {
                width: 56rem;
                height: 56rem;

                margin: 27rem 27rem 0 0;
            }
        }
    }
`;

function UndeadToast() {
    const dispatch = useDispatch();

    const { hasToast = {}, isMobile } = useSelector(state => state.common);

    useEffect(() => {
        if (!hasToast) return;
    
        toast(
            <div className="toast-content">
                <section className="toast-left-content">
                    <img src={blockIcon} alt="block" className="block" />
                </section>

                <section className="toast-right-content">
                    <h2>{hasToast.title}</h2>
                    <p>{hasToast.detail}</p>
                </section>

                <section>
                    <img
                        src={cancelIcon}
                        alt="close"
                        className="close"
                        onClick={() => dispatch(setToast(null))}
                    />
                </section>
            </div>
        )
    }, [dispatch, hasToast]);

    return (
        <Wrapper>
            <ToastContainer
                position={isMobile ? 'top-center' : 'top-right'}
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={() => {}}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={1}
                toastClassName={'toast-container'}
                closeButton={null}
                onClick={() => dispatch(setToast(null))}
            />
        </Wrapper>
    );
}

export default UndeadToast;