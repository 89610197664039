import {
    getNumber, getBalanceAmount, multicallAggregate, multicallAggregateByChainId
} from './multicall';
import { isSameChainId } from '../utils/web3';
import { getChainIdByConnectType } from './web3';
/**
 * MultiCall 
 */


/**-----------------------------------------------------------------------------------
 * INIT DATA
 * @param {*} tokenAddress 
 * @param {*} stakeContractAddress 
 * @param {*} account 
 * @returns 
 */
const getMethodsUserData = (tokenAddress, stakeContractAddress, account) => {
    return [
        {
            target: stakeContractAddress,
            call: ['currentStakedPerPool()(uint256)'],
            returns: [['currentStakedPerPool', (val) => getBalanceAmount(val)]],
        },
        {
            target: stakeContractAddress,
            call: ['startTime()(uint256)'],
            returns: [['startTime', (val) => getNumber(val) * 1000]],
        }, {
            target: stakeContractAddress,
            call: ['endTime()(uint256)'],
            returns: [['endTime', (val) => getNumber(val) * 1000]],
        }, {
            target: stakeContractAddress,
            call: ['fixedAPY()(uint256)'],
            returns: [['fixedAPY', (val) => getNumber(val)]],
        }, {
            target: stakeContractAddress,
            call: ['lockDuration()(uint256)'],
            returns: [['lockDuration', (val) => getNumber(val)]],
        }, {
            target: stakeContractAddress,
            call: ['maxStakedPerPool()(uint256)'],
            returns: [['maxStakedPerPool', (val) => getBalanceAmount(val)]],
        },
        {
            target: stakeContractAddress,
            call: ['isFrozen()(bool)'],
            returns: [['isFrozen', (val) => val]],
        },
        {
            target: tokenAddress,
            call: ['allowance(address,address)(uint256)', account, stakeContractAddress],
            returns: [['allowance', (val) => getBalanceAmount(val).toString()]],
        },
        {
            target: tokenAddress,
            call: ['balanceOf(address)(uint256)', account],
            returns: [['stakingTokenBalance', (val) => getBalanceAmount(val)]],
        },
        {
            target: stakeContractAddress,
            call: ['userInfo(address)(address,uint256,uint256,uint256,bool)', account],
            returns: [
                ['addr', (val) => val],
                ['amount', (val) => getBalanceAmount(val)],
                ['claimed', (val) => getBalanceAmount(val)],
                ['depositId', (val) => getNumber(val)],
                ['registered', (val) => val]],
        },
        {
            target: stakeContractAddress,
            call: ['nftStakedRequired()(uint256)'],
            returns: [['nftStakedRequired', (val) => getBalanceAmount(val)]],
        },
        {
            target: stakeContractAddress,
            call: ['nftStakedProgress(address)(uint256)', account],
            returns: [['nftStakedProgress', (val) => getBalanceAmount(val)]],
        },
    ]
}

const extractStakingData = async (tokenAddress, stakeContractAddress, account) => {
    const methodsCallDataPool = getMethodsUserData(tokenAddress, stakeContractAddress, account);

    return await multicallAggregate(methodsCallDataPool);
};

export const syncStakingInfo = async (chainId, tokenAddress, stakeContractAddress, account) => {
    const chainIdConnect = await getChainIdByConnectType();
    if (!isSameChainId(chainId, chainIdConnect))
        return {};

    return new Promise(resolve => {
        extractStakingData(tokenAddress, stakeContractAddress, account).then(smartData => {
            resolve({
                ...smartData
            })
        });
    });
}
/**-----------------------------------------------------------------------------------
 * INIT DATA WITHOUT CONNECT
 * @param {*} tokenAddress 
 * @param {*} stakeContractAddress 
 * @param {*} account 
 * @returns 
 */
const getMethodsUserDataWithoutConnect = (tokenAddress, stakeContractAddress) => {
    return [
        {
            target: stakeContractAddress,
            call: ['currentStakedPerPool()(uint256)'],
            returns: [['currentStakedPerPool', (val) => getBalanceAmount(val)]],
        },
        {
            target: stakeContractAddress,
            call: ['fixedAPY()(uint256)'],
            returns: [['fixedAPY', (val) => getNumber(val)]],
        }, {
            target: stakeContractAddress,
            call: ['lockDuration()(uint256)'],
            returns: [['lockDuration', (val) => getNumber(val)]],
        },
        {
            target: stakeContractAddress,
            call: ['isFrozen()(bool)'],
            returns: [['isFrozen', (val) => val]],
        },
        {
            target: stakeContractAddress,
            call: ['nftStakedRequired()(uint256)'],
            returns: [['nftStakedRequired', (val) => getBalanceAmount(val)]],
        },
    ]
}

const extractStakingDataWithoutConnect = async (chainId, tokenAddress, stakeContractAddress) => {
    const methodsCallDataPool = getMethodsUserDataWithoutConnect(tokenAddress, stakeContractAddress);

    return await multicallAggregateByChainId(methodsCallDataPool, chainId);
};

export const syncStakingInfoWithoutConnect = async (chainId, tokenAddress, stakeContractAddress) => {
    return new Promise(resolve => {
        extractStakingDataWithoutConnect(chainId, tokenAddress, stakeContractAddress).then(smartData => {
            resolve({
                ...smartData
            })
        });
    });
}


/**-----------------------------------------------------------------------------------
 * INTERVAL
 * @param {*} stakeContractAddress 
 * @param {*} account 
 * @returns 
 */
const getMethodsUserDataInterval = (stakeContractAddress, account) => {
    return [
        {
            target: stakeContractAddress,
            call: ['currentStakedPerPool()(uint256)'],
            returns: [['currentStakedPerPool', (val) => getBalanceAmount(val)]],
        },
        {
            target: stakeContractAddress,
            call: ['userInfo(address)(address,uint256,uint256,uint256,bool)', account],
            returns: [
                ['addr', (val) => val],
                ['amount', (val) => getBalanceAmount(val)],
                ['claimed', (val) => getBalanceAmount(val)],
                ['depositId', (val) => getNumber(val)],
                ['registered', (val) => val]],
        },
    ]
}

const extractUserStakingInfoInternal = async (stakeContractAddress, account) => {
    const methodsCallDataPool = getMethodsUserDataInterval(stakeContractAddress, account);

    return await multicallAggregate(methodsCallDataPool);
};

export const syncStakingInfoInternal = async (chainId, stakeContractAddress, account) => {
    const chainIdConnect = await getChainIdByConnectType();
    if (!isSameChainId(chainId, chainIdConnect))
        return {};

    return new Promise(resolve => {
        extractUserStakingInfoInternal(stakeContractAddress, account).then(smartData => {
            resolve({
                ...smartData
            })
        }
        );
    });
}

/**-----------------------------------------------------------------------------------
 * UPDATE DATA BALANCE
 * @param {*} stakeContractAddress 
 * @param {*} account 
 * @returns 
 */
const getMethodsBalanceInfo = (tokenAddress, stakeContractAddress, account) => {
    return [
        {
            target: stakeContractAddress,
            call: ['currentStakedPerPool()(uint256)'],
            returns: [['currentStakedPerPool', (val) => getBalanceAmount(val)]],
        },
        {
            target: stakeContractAddress,
            call: ['userInfo(address)(address,uint256,uint256,uint256,bool)', account],
            returns: [
                ['addr', (val) => val],
                ['amount', (val) => getBalanceAmount(val)],
                ['claimed', (val) => getBalanceAmount(val)],
                ['depositId', (val) => getNumber(val)],
                ['registered', (val) => val]],
        },
        {
            target: tokenAddress,
            call: ['balanceOf(address)(uint256)', account],
            returns: [['stakingTokenBalance', (val) => getBalanceAmount(val)]],
        },
        // {
        //     target: stakeContractAddress,
        //     call: ['nftStakedRequired()(uint256)'],
        //     returns: [['nftStakedRequired', (val) => getBalanceAmount(val)]],
        // },
        {
            target: stakeContractAddress,
            call: ['nftStakedProgress(address)(uint256)', account],
            returns: [['nftStakedProgress', (val) => getBalanceAmount(val)]],
        },
    ]
}

const extractBalanceInfo = async (tokenAddress, stakeContractAddress, account) => {
    const methodsCallDataPool = getMethodsBalanceInfo(tokenAddress, stakeContractAddress, account);

    return await multicallAggregate(methodsCallDataPool);
};

export const syncBalanceInfo = async (chainId, tokenAddress, stakeContractAddress, account) => {
    const chainIdConnect = await getChainIdByConnectType();
    if (!isSameChainId(chainId, chainIdConnect))
        return {};

    return new Promise(resolve => {
        extractBalanceInfo(tokenAddress, stakeContractAddress, account).then(smartData => {
            resolve({
                ...smartData
            })
        }
        );
    });
}

// GET ALLOWANCE
const getMethodAllowance = (tokenAddress, stakeContractAddress, account) => {
    return [
        {
            target: tokenAddress,
            call: ['allowance(address,address)(uint256)', account, stakeContractAddress],
            returns: [['allowance', (val) => getBalanceAmount(val).toString()]],
        }
    ]
}

const extractDataAllowance = async (tokenAddress, stakeContractAddress, account) => {
    const methodsCallDataPool = getMethodAllowance(tokenAddress, stakeContractAddress, account);

    return await multicallAggregate(methodsCallDataPool);
};

export const syncAllowance = async (chainId, tokenAddress, stakeContractAddress, account) => {
    const chainIdConnect = await getChainIdByConnectType();
    if (!isSameChainId(chainId, chainIdConnect))
        return {};

    return new Promise(resolve => {
        extractDataAllowance(tokenAddress, stakeContractAddress, account).then(smartData => {
            resolve({
                ...smartData
            })
        });
    });
}

/**-----------------------------------------------------------------------------------
 * GET LIST DEPOSITE
 * @param {*} tokenAddress 
 * @param {*} stakeContractAddress 
 * @param {*} account 
 * @returns 
 */
const methodsListDeposit = (stakeContractAddress, account, depositId) => {
    return {
        target: stakeContractAddress,
        call: ['depositInfos(address,uint256)(uint256,uint256,uint256,uint256,uint256,uint256,uint256,uint256)', account, depositId],
        returns: [
            [`id-${depositId}`, (val) => getNumber(val)],
            [`amount-${depositId}`, (val) => getBalanceAmount(val)],
            [`reward-${depositId}`, (val) => getBalanceAmount(val)],
            [`lockedFrom-${depositId}`, (val) => getNumber(val)],
            [`lockedTo-${depositId}`, (val) => getNumber(val)],
            [`fixedAPY-${depositId}`, (val) => getNumber(val)],
            [`lastRewardTime-${depositId}`, (val) => getNumber(val)],
            [`depositTime-${depositId}`, (val) => getNumber(val)]
        ],
    }
}

const methodsListPendingRewardByDepositId = (stakeContractAddress, account, depositId) => {
    return {
        target: stakeContractAddress,
        call: ['getPendingReward(address,uint256)(uint256)', account, depositId],
        returns: [
            [`pendingReward-${depositId}`, (val) => getBalanceAmount(val)]
        ],
    }
}


const extractDepositData = async (stakeContractAddress, account, depositLength) => {
    let listMethod = [];
    for (let i = 0; i < depositLength; i++) {
        listMethod.push(methodsListDeposit(stakeContractAddress, account, i))
        listMethod.push(methodsListPendingRewardByDepositId(stakeContractAddress, account, i))
    }

    return await multicallAggregate(listMethod);
};

export const syncListDeposit = async (chainId, stakeContractAddress, account, depositLength) => {
    if (depositLength === 0) {
        return {};
    }

    const chainIdConnect = await getChainIdByConnectType();
    if (!isSameChainId(chainId, chainIdConnect))
        return {};

    return new Promise(resolve => {
        extractDepositData(stakeContractAddress, account, depositLength).then(smartData => {
            resolve({
                ...smartData
            })
        });
    });
}

const extractDepositDataById = async (stakeContractAddress, account, id) => {
    let listMethod = [];
    listMethod.push(methodsListDeposit(stakeContractAddress, account, id))
    listMethod.push(methodsListPendingRewardByDepositId(stakeContractAddress, account, id))

    return await multicallAggregate(listMethod);
};

export const syncListDepositById = async (chainId, stakeContractAddress, account, id) => {
    const chainIdConnect = await getChainIdByConnectType();
    if (!isSameChainId(chainId, chainIdConnect))
        return {};

    return new Promise(resolve => {
        extractDepositDataById(stakeContractAddress, account, id).then(smartData => {
            resolve({
                ...smartData
            })
        });
    });
}