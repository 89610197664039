import React from 'react';
import Lottie from 'react-lottie';
import cn from 'classnames';
import { isEmpty } from 'lodash';

import bloodBg from '../../../assets/img/Circle_Blood.png';
import amateurSparkle from '../../../lotties/amateur_sparkle.json';
import assassinSparkle from '../../../lotties/assassin_sparkle.json';
import survivorSparkle from '../../../lotties/survivor_sparkle.json';
import killerSparkle from '../../../lotties/killer_sparkle.json';


const Variant = ({ item }) => {
    if (isEmpty(item)) return <img src={bloodBg} alt="bg" className="mintBg" />;

    const renderLottieSparkle = (name) => {
        if (!name) return amateurSparkle;
        if (name === 'AMATEUR') return amateurSparkle;
        if (name === 'ASSASSIN') return assassinSparkle;
        if (name === 'SURVIVOR') return survivorSparkle;
        if (name === 'ZOMBIE KILLER') return killerSparkle;

        return amateurSparkle;
    };

    return <>
        <img src={bloodBg} alt="bg" className="mintBg" />

        {!isEmpty(item.assets?.Knife) && (
            <img
                src={item.assets?.Knife}
                alt="knife"
                className={cn('knife', {
                    'amateurKnife': item.title === 'AMATEUR',
                    'survivorKnife': item.title === 'SURVIVOR',
                    'assassinKnife': item.title === 'ASSASSIN',
                    'killerKnife': item.title === 'ZOMBIE KILLER',
                    'rookieKnife': item.title === 'ROOKIE',
                    'commanderKnife': item.title === 'COMMANDER',
                    'veteranKnife': item.title === 'VETERAN'
                })}
            />
        )}

        {!isEmpty(item.assets?.Pistol) && (
            <img
                src={item.assets?.Pistol}
                alt="pistol"
                className={cn('pistol', {
                    'amateurPistol': item.title === 'AMATEUR',
                    'survivorPistol': item.title === 'SURVIVOR',
                    'assassinPistol': item.title === 'ASSASSIN',
                    'killerPistol': item.title === 'ZOMBIE KILLER',
                    'rookiePistol': item.title === 'ROOKIE',
                    'commanderPistol': item.title === 'COMMANDER',
                    'veteranPistol': item.title === 'VETERAN'
                })}
            />
        )}

        {!isEmpty(item.assets['DB']) && (
            <img
                src={item.assets['DB']}
                alt="short"
                className={cn('shortGuns', {
                    'amateurShort': item.title === 'AMATEUR',
                    'survivorShort': item.title === 'SURVIVOR',
                    'assassinShort': item.title === 'ASSASSIN',
                    'killerShort': item.title === 'ZOMBIE KILLER',
                    'rookieShort': item.title === 'ROOKIE',
                    'commanderShort': item.title === 'COMMANDER',
                    'veteranShort': item.title === 'VETERAN'
                })}
            />
        )}

        {!isEmpty(item.assets['BaseballBat']) && (
            <img
                src={item.assets['BaseballBat']}
                alt="short"
                className={cn('shortGuns', {
                    'amateurShort': item.title === 'AMATEUR',
                    'survivorShort': item.title === 'SURVIVOR',
                    'assassinShort': item.title === 'ASSASSIN',
                    'killerShort': item.title === 'ZOMBIE KILLER',
                    'rookieShort': item.title === 'ROOKIE',
                    'commanderShort': item.title === 'COMMANDER',
                    'veteranShort': item.title === 'VETERAN'
                })}
            />
        )}

        {!isEmpty(item.assets['RPG']) && (
            <img
                src={item.assets['RPG']}
                alt="short"
                className={cn('shortGuns', {
                    'amateurShort': item.title === 'AMATEUR',
                    'survivorShort': item.title === 'SURVIVOR',
                    'assassinShort': item.title === 'ASSASSIN',
                    'killerShort': item.title === 'ZOMBIE KILLER',
                    'rookieShort': item.title === 'ROOKIE',
                    'commanderShort': item.title === 'COMMANDER',
                    'veteranShort': item.title === 'VETERAN'
                })}
            />
        )}

        {!isEmpty(item.assets['F1']) && (
            <img
                src={item.assets['F1']}
                alt="long"
                className={cn('longGuns', {
                    'amateurLong': item.title === 'AMATEUR',
                    'survivorLong': item.title === 'SURVIVOR',
                    'assassinLong': item.title === 'ASSASSIN',
                    'killerLong': item.title === 'ZOMBIE KILLER',
                    'rookieLong': item.title === 'ROOKIE',
                    'commanderLong': item.title === 'COMMANDER',
                    'veteranLong': item.title === 'VETERAN'
                })}
            />
        )}

        {!isEmpty(item.assets['AKM']) && (
            <img
                src={item.assets['AKM']}
                alt="long"
                className={cn('longGuns', {
                    'amateurLong': item.title === 'AMATEUR',
                    'survivorLong': item.title === 'SURVIVOR',
                    'assassinLong': item.title === 'ASSASSIN',
                    'killerLong': item.title === 'ZOMBIE KILLER',
                    'rookieLong': item.title === 'ROOKIE',
                    'commanderLong': item.title === 'COMMANDER',
                    'veteranLong': item.title === 'VETERAN'
                })}
            />
        )}

        {!isEmpty(item.assets['MP5']) && (
            <img
                src={item.assets['MP5']}
                alt="long"
                className={cn('longGuns', {
                    'amateurLong': item.title === 'AMATEUR',
                    'survivorLong': item.title === 'SURVIVOR',
                    'assassinLong': item.title === 'ASSASSIN',
                    'killerLong': item.title === 'ZOMBIE KILLER',
                    'rookieLong': item.title === 'ROOKIE',
                    'commanderLong': item.title === 'COMMANDER',
                    'veteranLong': item.title === 'VETERAN'
                })}
            />
        )}

        {!isEmpty(item.assets['R870']) && (
            <img
                src={item.assets['R870']}
                alt="long"
                className={cn('longGuns', {
                    'amateurLong': item.title === 'AMATEUR',
                    'survivorLong': item.title === 'SURVIVOR',
                    'assassinLong': item.title === 'ASSASSIN',
                    'killerLong': item.title === 'ZOMBIE KILLER',
                    'rookieLong': item.title === 'ROOKIE',
                    'commanderLong': item.title === 'COMMANDER',
                    'veteranLong': item.title === 'VETERAN'
                })}
            />
        )}

        {!isEmpty(item.assets['M1A']) && (
            <img
                src={item.assets['M1A']}
                alt="long"
                className={cn('longGuns', {
                    'amateurLong': item.title === 'AMATEUR',
                    'survivorLong': item.title === 'SURVIVOR',
                    'assassinLong': item.title === 'ASSASSIN',
                    'killerLong': item.title === 'ZOMBIE KILLER',
                    'rookieLong': item.title === 'ROOKIE',
                    'commanderLong': item.title === 'COMMANDER',
                    'veteranLong': item.title === 'VETERAN'
                })}
            />
        )}

        {!isEmpty(item.assets?.Perk) && (
            <img
                src={item.assets?.Perk}
                alt="perks"
                className={cn('perks', {
                    'veteranPerks': item.title === 'VETERAN'
                })}
            />
        )}

        {!isEmpty(item.assets?.Axe) && (
            <img
                src={item.assets?.Axe}
                alt="axe"
                className={cn('axe')}
            />
        )}

        <Lottie
            options={{
                loop: true,
                autoplay: true,
                animationData: renderLottieSparkle(item.title)
            }}
            style={{
                transition: `height 0.05s`,
                position: 'absolute',
                zIndex: 70
            }}
            height={'100%'}
            isClickToPauseDisabled={true}
        />
    </>
}

export default Variant;