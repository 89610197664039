import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router'
import cn from 'classnames';

import leftLayer from './img/left-test.png';
import rightLayer from './img/right.png';
import mintNone from './img/plus.png';
// import minted from './img/mintedBg.png';
import noneBg from './img/none-bg.svg';
import openSeaLogo from '../../assets/opensea.png';
import rentLogo from '../../assets/rent.png';
import { ReactComponent as ViewImage } from '../../assets/svg/view.svg';
import buttonBg from '../WhiteList/img/button-bg.png';
import loadingData from '../../lotties/loading.json';

import useMyWeapon from './useMyWeapon';
import Animations from '../../components/Animations';
// import { useFetchNFTDetail } from '../../hooks/useFetchNFTDetail';
import { PAGE_NFT, WEAPON_SKINS } from '../../utils/constants';
import {
    getExplorerUrl,
    // getOpenSeaUrl,
    getOpenSeaUrlByAddress,
    // getContractUndeadNFT,
    splitAddress
} from '../../utils/common';
// import Variant from './Variant';
import Nav from '../Home/LandingPageHeader/Header';
import ItemSkin from './ItemSkin';
import RenInfoModal from './RenInfoModal';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import {
    setIsMobile,
    setIsTablet,
    setIsDesktop,
} from '../../actions/common';
import { fetchNFTDetail, fetchMyWeapons, fetchMyWeaponsClean } from '../../actions/ownership';

import Wrapper, {
    BubbleLayer,
    WeaponContent,
    MyWeaponsWrapper,
    NftDetailStyle,
    NftInfoStyle,
    NftListItem,
    ViewAddress,
    ButtonRedStyle,
    AddessOwnershipStyle
} from './styles';
import { isEmpty } from 'lodash';

const BREAKPOINTS = {
    SM_MAX: 767,
    MD_MIN: 768,
    MD_MAX: 1023,
    LG_MIN: 1024,
    LG_MIN_CUSTOM: 1279
};

const hasNoSkinAttributesList = [1,2,3,4];

function MyWeapons(props) {
    const dispatch = useDispatch();
    const history = useHistory()

    const { contractAddress = '', tokenId = '' } = props.match.params;

    const [visibleRentInfo, setVisibleRentInfo] = useState(false);
    const [offset, setOffset] = useState(0);

    const {
        isMobile,
        isTablet,
        isDesktop,
        isEndCountdown
    } = useSelector(state => state.common);

    const isConnected = useSelector(state => state.user.connectWallet.isConnect);
    const account = useSelector(state => (state.user.userAccount.accounts ? state.user.userAccount.accounts[0] : ''))
    const chainId = useSelector(state => state.user.chainId);

    // const listMyWeapons = useSelector(state => state.ownership.myWeapons.result);
    const nftDetail = useSelector(state => state.ownership.nftDetail.data);
    const requestLoading = useSelector(state => state.ownership.nftDetail.requesting);
    const nftDetailError = useSelector(state => state?.ownership?.nftDetail?.error?.status);

    const listLoading = useSelector(state => state.ownership?.ownershipsRes?.requesting);
    const { loadingConnectAccount } = useSelector(state => state.user ?? false);

    const { weaponList, hasMore, firstLoad } = useMyWeapon({ offset, isConnected });

    // LOAD MORE
    const observer = useRef();
    const lastElement = useCallback(node => {
        if (listLoading) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && hasMore) {
                    setOffset(prevState => prevState + 10);
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        });

        if (node) observer.current.observe(node);
    }, [listLoading, hasMore]);

    const loading = requestLoading || nftDetail === undefined;

    // useFetchNFTDetail(tokenId, account, contractAddress, () => history.push('/nft-notfound'));
    
    const onResize = useCallback(() => {
        if ((window.innerWidth < BREAKPOINTS.MD_MIN) && !isMobile) {
            dispatch(setIsMobile())
        } else if ((window.innerWidth > BREAKPOINTS.SM_MAX) && (window.innerWidth < BREAKPOINTS.LG_MIN_CUSTOM) && !isTablet) {
            dispatch(setIsTablet())
        } else if ((window.innerWidth > BREAKPOINTS.LG_MIN_CUSTOM) && !isDesktop) {
            dispatch(setIsDesktop())
        }
    }, [dispatch, isMobile, isTablet, isDesktop]);

    useEffect(() => {
        onResize();
        window.addEventListener('onload', onResize);
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
            window.removeEventListener('onload', onResize);
        }
    }, [onResize, dispatch]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        loadingConnectAccount && setOffset(0);
    }, [loadingConnectAccount]);

    useEffect(() => {
        // if (!isConnected) return;

        dispatch(fetchNFTDetail(contractAddress, tokenId))
            .then(() => {
                if (nftDetailError === 404) {
                    history.push('/nft-notfound')
                }
            })

        if (account) {
            dispatch(fetchMyWeapons(account))
        } else {
            dispatch(fetchMyWeaponsClean())
        }

    }, [dispatch, account, tokenId, nftDetailError, contractAddress, history]);

    const renderBubbleLayer = () => (
        <BubbleLayer>
            <section className="bubble">
                <img src={leftLayer} alt="left" className="left" />
                <div className="left-bubble" />
            </section>
            <section className="bubble">
                <img src={rightLayer} alt="right" className="right" />
                <div className="right-bubble" />
            </section>
        </BubbleLayer>
    );

    const renderMyWeapons = () => {
        return (
            <MyWeaponsWrapper>
                <h2 className="title">My Weapons</h2>
                <p className="description">Weapon Loadouts found in your connected wallet will appear here</p>

                <div className="mint-list">
                    {firstLoad ? (
                        new Array(isMobile ? 2 : 3).fill({}).map((e, i) => (
                            <div
                                key={i}
                                className="loading"
                            >
                                <div className="heading-loading" />

                                <div className="content-loading" />

                                <div className="footer-loading">
                                    <section className="left-loading" />
                                    <section className="right-loading" />
                                </div>
                            </div>
                        ))
                    ) : (
                        !isEmpty(weaponList) && [...weaponList, {}, {}].map((e, i) => (
                            <div
                                key={i}
                                className={cn('mint-item', {
                                    'nope': !e.id,
                                    'border': e.id
                                })}
                                // style={{ border: 'solid 2rem #202429' }}
                                ref={weaponList.length === i + 1 ? lastElement : null}
                            >
                                {!e.id ? (
                                    <div className="no-content">
                                        <img
                                            src={noneBg}
                                            style={{ width: '100%' }}
                                            alt="border"
                                        />
                                        <img
                                            src={mintNone}
                                            alt="none"
                                            className="none"
                                            onClick={() => handleOnTop()}
                                        />
                                    </div>
                                ) : (
                                    <WeaponContent
                                        style={{ background: `url(${e.thumbnail}) no-repeat center center / cover` }}
                                        title={e?.package?.description}
                                        fontSizeWeb={e.fontSize}
                                        fontSizeMobile={e.fontSizeMobile}
                                        onClick={() => onViewDetail(e)}
                                    >
                                        {/* <div className="top">
                                            <h2 className="heading">{e?.title}</h2>
                                        </div> */}
        
                                        {/* <div className="info">
                                            <Variant item={e} />
                                        </div> */}
        
                                        {/* <div className="bottom">
                                            <p className="detail">
                                                {e.description}
                                            </p>
                                            <div style={{ background: `url(${minted}) no-repeat center center / cover` }}
                                                className="view-detail-btn"
                                                onClick={() => onViewDetail(e)}
                                            >
                                                <span className="text-btn">View Detail</span>
                                            </div>
                                        </div> */}
                                    </WeaponContent>
                                )}
                            </div>
                        ))
                    )}
                </div>

                {!firstLoad && listLoading && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '80rem'
                        }}
                    >
                        <div className="loading-more">
                            <Animations animationData={loadingData} />
                        </div>
                    </div>
                )}
            </MyWeaponsWrapper>
        )
    }

    const handleOnTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    const onViewDetail = (e) => {
        handleOnTop();
        history.push(`${PAGE_NFT}/${e?.contractAddress}/${e?.tokenId}`)
        // history.push(`${PAGE_NFT}/${e?.tokenId}`)
    }

    return (
        <Wrapper>
            <Nav isStaking={isEndCountdown} />
            {renderBubbleLayer()}

            {/* NFT Detail */}
            <NFTDetail
                {...props}
                {...{ nftDetail, chainId, setVisibleRentInfo, isMobile, account, loading }}
            />

            {/* My weapons */}
            {renderMyWeapons()}

            <RenInfoModal
                isVisible={visibleRentInfo}
                onClose={() => setVisibleRentInfo(false)}
                nftDetail={nftDetail}
                isMobile={isMobile}
            />
        </Wrapper>
    );
}

const NFTDetail = (props) => {
    const { nftDetail, setVisibleRentInfo, isMobile, account, loading } = props;

    const viewBscscan = (contractNFT) => {
        const url = `${getExplorerUrl(process.env.REACT_APP_CHAIN_ID)}/token/${contractNFT}`

        window.open(url, '_blank')
    }

    const viewOpenSea = (contract, tokenId) => {
        const url = `${getOpenSeaUrlByAddress(process.env.REACT_APP_CHAIN_ID, contract)}/${tokenId}`

        window.open(url, '_blank')
    }

    return (
        loading ? (
            <NftDetailStyle>
                <NftInfoStyle>
                    <div className="loading">
                        <div className="heading-loading" />

                        <div className="content-loading" />

                        <div className="footer-loading" />
                    </div>
                </NftInfoStyle>

                <NftListItem>
                    <div className="loading">
                        <div className="heading-loading">
                            <div className="heading" />
                        </div>

                        <div className="content-loading">
                            <div className="primary" />
                            <div className="item" />
                        </div>

                        <div className="footer-loading">
                            <div className="list-button">
                                <div className="parent" />
                                <div className="parent" />
                            </div>
                            <div className="address" />
                        </div>
                    </div>
                </NftListItem>
            </NftDetailStyle>
        )  : (
            <NftDetailStyle>
                <NftInfoStyle
                    style={{ background: `url(${nftDetail.thumbnail}) no-repeat center center / cover` }}
                >
                    {/* <div className="top"></div>

                    <div className="info">
                        <Variant item={nftDetail?.itemSku} />
                    </div>

                    <div className="bottom">
                        <h2 className="heading">{nftDetail?.title}</h2>
                    </div> */}
                </NftInfoStyle>

                <NftListItem>
                    <div className="nft-detail">
                        <div className="top">
                            <h2 className="heading">{nftDetail?.package?.name} Loadout</h2>
                            <div className="primary">Contract Address</div>
                            <div className="transaction-hash-group">
                                <div className="transaction-hash">{nftDetail?.contractAddress}</div>
                                <ViewAddress onClick={() => viewBscscan(nftDetail?.contractAddress)}>
                                    <ViewImage />
                                </ViewAddress>
                            </div>
                        </div>

                        {hasNoSkinAttributesList.includes(nftDetail.type) ? (
                            <div className="info">
                                <div className="primary-bottom">Skin Attributes</div>
                                <div className="list-item-skin">
                                    {(nftDetail?.variants || []).filter(item => WEAPON_SKINS.includes((item?.itemSku || '').toLowerCase()))
                                        .map((item, index) =>
                                            <ItemSkin key={index} item={item} />
                                    )}
                                </div>
                            </div>
                        ) : null}

                        <div className="bottom">
                            <ButtonRedStyle>
                                <div
                                    className="parent rent-btn"
                                    onClick={() => setVisibleRentInfo(true)}
                                    style={{
                                        background: `url(${buttonBg}) no-repeat center / 100% 100%`
                                    }}
                                >
                                    <img src={rentLogo} alt="guns" className="guns" />
                                    <span>Rent</span>
                                </div>
                                <div
                                    className="parent open-btn"
                                    style={{
                                        background: `url(${buttonBg}) no-repeat center / 100% 100%`
                                    }}
                                    onClick={() => viewOpenSea(nftDetail?.contractAddress, nftDetail?.tokenId)}
                                >
                                    <img src={openSeaLogo} alt="opensea" className="opensea" />
                                    <span>OpenSea</span>
                                </div>
                            </ButtonRedStyle>
                        </div>

                        <AddessOwnershipStyle>
                            <span className='address-icon'>
                                {/* <img className='addess-image' src={WalletIcon} alt='' /> */}
                                {/* <div ref={iconContainerRef} className={'address-image'} /> */}
                                <Jazzicon paperStyles={{ maxWidth: '100%', maxHeight: '100%' }} d
                                    iameter={isMobile ? 11 : 14} seed={jsNumberForAddress(account || '')} />
                            </span>
                            <div className='label-address'>Owner:</div>
                            <div className='address'>{splitAddress(nftDetail?.address)}</div>
                        </AddessOwnershipStyle>
                    </div>
                </NftListItem>
            </NftDetailStyle>
        )
    )
}

export default MyWeapons;
