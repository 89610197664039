// import { Skeleton } from 'antd'
import React from 'react'
import styled from 'styled-components'
import Balance from '../../../Balance'
import { isIncludeTilde, convertContentFloat } from '../../../../utils/common';

const Wrapper = styled.div`
  font-family: Poppins;
  background-color: #0d0d0f;
  padding: 1rem 29rem 0 29rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media only screen and (max-width: 767px) {
    padding: 2rem 100rem 0;
  }
`

const Title = styled.span`
  font-family: Poppins;
  font-size: 12rem;
  font-weight: 500;
  line-height: 1.5;
  
  text-align: left;
  color: #8e8e8e;

  @media only screen and (max-width: 767px) {
    font-size: 39.4rem;
  }
`
const Content = styled.span`
  font-family: Poppins;
  font-size: 12rem;
  font-weight: bold;
  line-height: 1.5;
  color: #cacaca;
  text-align: left;
  margin-right: 5rem;

  @media only screen and (max-width: 767px) {
    font-size: 39.4rem;
  }
`

const ContentBalance = styled.div`
  font-size: 12rem;
  font-weight: bold;
  line-height: 1.5;
  color: #cacaca;
  text-align: left;
  margin-right: 5rem;

  @media only screen and (max-width: 767px) {
    font-size: 39.4rem;
  }
`

// const WrapperSkeleton = styled.div`
//   display: flex;
//   align-items: center;

//   span {
//     width: 150rem !important;
//     height: 17rem !important;
//     border-radius: 5rem;
//     @media (max-width: 1280px) {
//       width: 150rem !important;
//       height: 18rem !important;
//     }
//     @media only screen and (max-width: 768px) {
//       width: 512rem !important;
//       height: 54rem !important;
//       border-radius: 15rem;
//     }
//   }
// `

const SuccessInfo = ({ title, content = '', isDate, unit, isMobile = false, isTablet = false, isLoading = false }) => {

  const renderDataContent = () => {
    return isDate ? (
      <Content>{content}</Content>
    ) : (
      <ContentBalance>
        <Balance
          fontWeight="600"
          fontSize={isMobile ? "39.4rem" : "12rem"}
          value={convertContentFloat(content)}
          decimals={2}
          unit={unit}
          fontW
          lineHeight={1.25}
          color={'rgb(202, 202, 202)'}
          isTilde={isIncludeTilde(content, '~')}
        />
      </ContentBalance>
    )
  }

  // const renderLoading = () => {
  //   return <WrapperSkeleton>
  //     <Skeleton.Input />
  //   </WrapperSkeleton>
  // }

  const renderLoading = () => {
    return isDate ? <Content>{content}</Content> : <Content>{`${content} ${unit}`}</Content>
  }

  return (
    <Wrapper>
      <Title>{title}</Title>
      {content === null || isLoading ? renderLoading() : renderDataContent()}
    </Wrapper>
  )
}

export default SuccessInfo
