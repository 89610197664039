import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Modal } from 'antd';

import topBuble from '../../MintWeapons/img/left-step3.png';
import bottomBuble from '../../MintWeapons/img/bottom-right.png';
import logo from '../../../assets/img/logo.png';

const Wrapper = styled.div`
    padding: 0 22rem 30rem;
    position: relative;
    overflow: hidden;

    @media (max-width: 767px) {
        padding: 0 40rem 60rem;
    }

    .layer-bottom {
        position: absolute;
        bottom: -18rem;
        right: 0rem;
        width: 104rem;
        height: 130rem;
        border-top-left-radius: 12rem;

        @media (max-width: 767px) {
            width: 210rem;
            height: 250rem;
            border-top-left-radius: 24rem;
        }
    }

    .header-nav {
        display: flex;
        justify-content: flex-end;
        position: relative;

        padding-bottom: 20rem;

        @media (max-width: 767px) {
            padding-bottom: 79rem;
        }

        .layer {
            position: absolute;
            left: -35rem;
            top: 0;
            width: 92.5rem;
            height: 130rem;
            border-top-left-radius: 12rem;

            @media (max-width: 767px) {
                border-top-left-radius: 24rem;
                width: 220rem;
                height: 250rem;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
            width: 197rem;
            height: 129rem;
            object-fit: cover;

            margin-bottom: 7.5rem;

            @media (max-width: 767px) {
                width: 550rem;
                height: auto;
            }
        }

        .title {
            font-size: 22.5rem;
            font-weight: bold;
            line-height: 1.2;
            text-align: center;
            color: #fff;

            margin: 0 40rem 8rem;

            @media (max-width: 767px) {
                margin: 0 140rem 8rem;
                font-size: 60rem;
            }
        }

        .detail {
            font-size: 12rem;
            line-height: 1.5;
            text-align: center;
            color: #8e8e8e;

            margin: 0 20rem 26rem;

            @media (max-width: 767px) {
                margin: 0 10rem 36rem;
                font-size: 38rem;
            }
        }
    }

    .btn-container {
        border-radius: 2.5rem;
        background-image: radial-gradient(circle at 0 0, #f00, #af0000);

        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;
        z-index: 100;

        width: 100%;
        height: 40rem;

        font-size: 15rem;
        font-weight: 600;
        line-height: 1.33;
        text-align: center;
        color: #fff;

        cursor: pointer;
        transition: all 0.4s;

        &:hover {
            transform: scale(1.02);
        }

        @media (max-width: 767px) {
            border-radius: 10rem;
            height: 110rem;
            font-size: 40rem;
        }
    }
`;

function StakingUnavailable(props) {
    const { isVisible, onCancel } = props;

    const { isMobile } = useSelector(state => state?.common);

    return (
        <Modal
            visible={isVisible}
            closable={false}
            centered
            onCancel={onCancel}
            bodyStyle={{ 
                padding: 0,
                backgroundColor: '#0d0d0f',
                borderRadius: 7.5,
                border: 'solid 1rem #202429'
            }}
            width={isMobile ? '80%' : '374rem'}
        >
            <Wrapper>
                <div className="header-nav">
                    <img src={topBuble} alt="left" className="layer" />
                </div>

                <div className="content">
                    <img src={logo} alt="logo" className="logo" />

                    <p className="title">Staking pools are currently unavailable</p>

                    <p className="detail">If you have staked $UNDEAD, you will be able to withdraw at the end of the lockup period</p>
                </div>

                <div
                    className="btn-container"
                    onClick={() => onCancel()}
                >
                    I understand
                </div>

                <img src={bottomBuble} alt="" className="layer-bottom" /> 
            </Wrapper>
        </Modal>
    );
}

export default React.memo(StakingUnavailable);