import React from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'
import Variant from '../Variant';

import './style.css';
import left from '../../../assets/img/leftBackground3.png';
import minted from '../img/mintedBg.png';

const RenInfoModal = ({
    isVisible,
    onClose,
    nftDetail,
    isMobile
}) => {
    return (
        <Modal
            visible={isVisible}
            onCancel={onClose}
            wrapClassName={isMobile ? 'rent-info-modal-mobile' : 'rent-info-modal'}
            width={isMobile ? '880rem' : '389rem'}
            centered
        >
            <Wrapper>
                <BackgroundWrapper>
                    <div className="header-nav">
                        <img src={left} alt="left" className="left" />
                    </div>
                </BackgroundWrapper>

                <NftDetailStyle>
                    <NftInfoStyle>
                        <div className="info">
                            <Variant item={nftDetail} />
                        </div>
                    </NftInfoStyle>
                </NftDetailStyle>

                <BodyDescription>
                    <TitleMint> {`Rent Loadout`} </TitleMint>
                    <BodyMint> {`Undead Blocks allows weapon loadout holders to earn passive income by renting their weapons to players from around the globe. Coming Q3 2022.`} </BodyMint>

                    <ButtonRedStyle>
                        <div style={{ background: `url(${minted}) no-repeat center center / cover` }}
                            className="view-detail-btn"
                            onClick={onClose}
                        >
                            <span className="text-btn">Coming Soon </span>
                        </div>
                    </ButtonRedStyle>
                </BodyDescription>
            </Wrapper>
        </Modal>
    )
}

export default RenInfoModal
const Wrapper = styled.div`
`
const BackgroundWrapper = styled.div`
    .header-nav {
        display: flex;
        justify-content: flex-end;
        position: relative;

        .left {
            position: absolute;
            left: 0;
            top: 0;
            width: 92.5rem;
            height: 113rem;

            border-top-left-radius: 12rem;

            @media (max-width: 767px) {
                width: 210rem;
                height: 258rem;
            }
        }
    }
`;

const BodyDescription = styled.div`
  display: flex;
  padding: 0rem 30rem 30rem 30rem;
  flex-direction: column;
  align-items: center;

  @media (max-width: 767px) {
    padding: 0rem 84rem 65rem 84rem;
}
`

const TitleMint = styled.span`
    font-family: Poppins;
    font-size: 22.5rem;
    font-weight: bold;
    line-height: 1.89;
    text-align: center;
    color: #fff;

  @media (max-width: 767px) {
    font-size: 52rem;
  }
`

const BodyMint = styled.p`
    font-family: Poppins;
    font-size: 15rem;
    line-height: 1.33;
    text-align: center;
    color: #fff;

    @media (max-width: 767px) {
        padding: 0 13rem;
        font-size: 35rem;
    }
`

const ButtonRedStyle = styled.div`
  width: 100%;
  cursor: pointer;

  .view-detail-btn {
    height: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2.5rem;

    @media (max-width: 767px) {
        height: 90rem;
    }

    &:active {
        transform: scale(0.9);
        transition: all 0.2s;
    }

    &:hover {
        span {
            transform: scale(1.05);
            transition: all 0.2s;
        }
    }

    span {
        font-family: Poppins;
        font-size: 15rem;
        font-weight: 600;
        line-height: 1.33;
        text-align: center;
        color: #fff;

        @media (max-width: 767px) {
            font-size: 33rem;
        }
    }
  }
`


export const NftDetailStyle = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0;

    @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        padding: 40rem 85rem 0 85rem;
    }
`

export const NftInfoStyle = styled.div`
    object-fit: contain;
    border-radius: 7.5rem;
    background-color: #0d0d0f;
    width: 100%;
    padding: 0 0 0 0;

    @media (max-width: 767px) {
        width: 100% ;
    }

    .info {
        display: flex;
        justify-content: center;
        position: relative;
        padding: 29rem 0 9.5rem;

        .mintBg {
            width: 179rem;
            height: 205.5rem;

            &.no-variant {
                width: 179rem;
                height: auto;

                @media (max-width: 767px) {
                    width: 470rem;
                    height: auto;
                }
            }

            @media (max-width: 767px) {
                width: 470rem;
                height: 520rem;
            }
        }

        .knife {
            position: absolute;
            z-index: 40;

            &.amateurKnife {
                width: 179rem;

                @media (max-width: 767px) {
                    width: 470rem;
                    top: 15rem;
                }
            }

            &.survivorKnife {
                width: 199rem;
                height: 215rem;
                bottom: 10rem;
                left: 44%;
                transform: translateX(-44%);

                @media (max-width: 767px) {
                    width: 470rem;
                    height: 98%;

                    bottom: 5rem;
                }
            }

            &.assassinKnife {
                width: 199rem;
                bottom: 11rem;
                right: 100rem;

                @media (max-width: 767px) {
                    width: 470rem;
                    right: 140rem;
                    bottom: 9rem;
                }
            }

            &.killerKnife {
                bottom: 7rem;
                width: 219rem;
                right: 90rem;

                @media (max-width: 767px) {
                    width: 500rem;
                    right: 110rem;
                    bottom: 12rem;
                }
            }

            &.rookieKnife {
                width: 200rem;
                height: 220rem;
                bottom: 9rem;
                left: 42%;
                -webkit-transform: translateX(-39%);
                -ms-transform: translateX(-39%);
                transform: translateX(-39%);

                @media (max-width: 767px) {
                    width: 520rem;
                    height: auto;
                    bottom: 5rem;
                    left: 39%;
                }
            }

            &.commanderKnife {
                width: 190rem;
                bottom: 7rem;
                left: 88rem;

                @media (max-width: 767px) {
                    width: 500rem;
                    left: 70rem;
                    bottom: 0rem;
                }
            }
        }

        .pistol {
            position: absolute;
            z-index: 30;

            &.amateurPistol {
                width: 179rem;

                @media (max-width: 767px) {
                    width: 470rem;
                    right: 130rem;
                    bottom: 15rem;
                }
            }

            &.survivorPistol {
                width: 200rem;
                bottom: 11rem;
                left: 80rem;

                @media (max-width: 767px) {
                    width: 470rem;
                    left: 60rem;
                    bottom: 22rem;
                }
            }

            &.assassinPistol {
                bottom: 6rem;
                width: 200rem;

                @media (max-width: 767px) {
                    width: 470rem;
                }
            }

            &.killerPistol {
                bottom: 7rem;
                width: 220rem;

                @media (max-width: 767px) {
                    width: 470rem;
                    bottom: 30rem;
                    left: 90rem;
                }
            }

            &.rookiePistol {
                width: 210rem;
                bottom: 10rem;
                left: 93rem;

                @media (max-width: 767px) {
                    width: 545rem;
                    left: 85rem;
                    bottom: 8rem;
                }
            }


            &.commanderPistol {
                width: 190rem;
                bottom: 9rem;
                left: 95rem;

                @media (max-width: 767px) {
                    width: 500rem;
                    left: 95rem;
                    bottom: 6rem;
                }
            }

            &.veteranPistol {
                width: 190rem;
                right: 110rem;
                bottom: 12rem;

                @media (max-width: 767px) {
                    width: 500rem;
                    left: 70rem;
                    bottom: 12rem;
                }
            }
        }

        .shortGuns {
            position: absolute;
            top: 0;
            width: 179rem;
            z-index: 20;

            &.amateurShort {

            }

            &.survivorShort {
                width: 179rem;

                @media (max-width: 767px) {
                    width: 470rem;
                }
            }

            &.assassinShort {
                top: 20rem;
                right: 110rem;
                width: 195rem;

                @media (max-width: 767px) {
                    width: 470rem;
                    right: 120rem;
                    top: 10rem;
                }
            }

            &.killerShort {
                top: 25rem;
                width: 210rem;
                right: 90rem;

                @media (max-width: 767px) {
                    width: 500rem;
                    right: 125rem;
                    top: 20rem;
                }
            }

            &.rookieShort {
                width: 179rem;

                @media (max-width: 767px) {
                    width: 750rem;
                }
            }

            &.commanderShort {
                width: 180rem;
                top: 15rem;
                right: 108rem;

                @media (max-width: 767px) {
                    width: 460rem;
                    right: 124rem;
                    top: -15rem;
                }
            }

            &.veteranShort {
                width: 190rem;
                right: 108rem;
                top: 25rem;

                @media (max-width: 767px) {
                    width: 530rem;
                    top: -12rem;
                    right: 122rem;
                }
            }
        }

        .longGuns {
            position: absolute;
            z-index: 10;

            &.amateurLong {
                @media (max-width: 767px) {
                    width: 470rem;
                    right: 107rem;
                }
            }

            &.survivorLong {
                width: 199rem;
                right: 105rem;
                top: 19rem;

                @media (max-width: 767px) {
                    width: 470rem;
                    right: 120rem;
                }
            }

            &.assassinLong {
                top: 23rem;
                right: 110rem;
                width: 180rem;

                @media (max-width: 767px) {
                    width: 470rem;
                    right: 128rem;
                    top: 10rem;
                }
            }

            &.killerLong {
                width: 209rem;
                top: 25rem;
                right: 93rem;

                @media (max-width: 767px) {
                    width: 500rem;
                    right: 95rem;
                    top: 14rem;
                }
            }

            &.rookieLong {
                width: 220rem;
                right: 88rem;
                top: 20rem;
                z-index: 30;

                @media (max-width: 767px) {
                    width: 540rem;
                    top: 10rem;
                    right: 74rem;
                }
            }

            &.commanderLong {
                width: 180rem;
                right: 107.5rem;
                top: 12rem;

                @media (max-width: 767px) {
                    width: 450rem;
                    top: -20rem;
                    right: 130rem;
                }
            }

            &.veteranLong {
                width: 200rem;
                left: 77.5rem;
                top: 15rem;

                @media (max-width: 767px) {
                    width: 495rem;
                    top: 0rem;
                    left: 78rem;
                }
            }
        }

        .perks {
            position: absolute;
            width: 230rem;
            height: 260rem;
            bottom: 0.5rem;
            right: 90rem;

            z-index: 30;

            @media (max-width: 767px) {
                width: 470rem;
                height: 550rem;
                right: 150rem;
                bottom: 0rem;
            }

            &.veteranPerks {
                width: 200rem;
                height: auto;
                bottom: 10rem;
                right: 105rem;

                @media (max-width: 767px) {
                    width: 500rem;
                    height: auto;
                    right: 118rem;
                    bottom: 17rem;
                }
            }
        }

        .axe {
            position: absolute;
            position: absolute;
            width: 240rem;
            bottom: 3rem;
            left: 60rem;

            z-index: 50;

            @media (max-width: 767px) {
                width: 650rem;
                left: -10rem;
                bottom: -15rem;
            }
        }
    }
`;