import { handleActions } from "redux-actions";

const initialState = {
    allPackages: {
        result: [],
        requesting: false,
        error: null
    }
};

let packageReducer = handleActions({
    /** FETCH ALL PACKAGES **/
    FETCH_ALL_PACKAGES_REQUEST: (state) => ({
        ...state,
        allPackages: {
            ...state.allPackages,
            requesting: true
        }
    }),
    FETCH_ALL_PACKAGES_SUCCESS: (state, { payload }) => ({
        ...state,
        allPackages: {
            ...state.allPackages,
            requesting: false,
            result: payload.data,
            error: null
        }
    }),
    FETCH_ALL_PACKAGES_FAIL: (state, { payload }) => ({
        ...state,
        allPackages: {
            ...state.allPackages,
            requesting: false,
            error: payload.error
        }
    }),
}, initialState);

export default packageReducer;