import styled from 'styled-components'
import WithDirection from '../../../../settings/withDirection'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  background: #0d0d0f;
  border-radius: 15px;
  border: solid 1.5px #202429;
  min-height: 278px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 4.5px 7.8px 45px 0 rgba(34, 34, 34, 0.05);
  transition: all 1s cubic-bezier(0.17, 0.67, 0.23, 0.99);

  @media (max-width: 767px) {
    margin-bottom: 25rem;
  }
`
export const Banner = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  @media (max-width: 767px) {
    height: auto;
  }
`

export const ViewOpenSea = styled.div`
  position: absolute;
  left: 10rem;
  bottom: 10rem;
  z-index: 100;

  cursor: pointer;

  :hover {
    transform: scale(1.01);
    transition: all .2s;
  }

  @media (max-width: 767px) {
    left: 30rem;
    bottom: 30rem;
  }

  .view-btn {
    width: ${props => props.desktopSize};
    height: 34.5rem;

    @media (max-width: 767px) {
      width: ${props => props.mobileSize};
      height: auto;
    }
  }
`;

export const Info = styled.div``
export const ButtonUnlockWallet = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15rem;
  margin: 11rem 25rem 20rem 25rem;

  @media (max-width: 767px) {
    margin: 20rem 80rem 100rem;
  }
`
export const ButtonControl = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15rem;
  margin: 11rem 25rem 20rem 25rem;

  @media (max-width: 767px) {
    margin: 20rem 80rem 0rem;
  }
`

export const Background = styled.div`
  width: 100%;
`

export const LabelMintProgress = styled.span`
  font-family: Poppins;
  font-weight: 600;
  font-size: 12rem;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding: 4rem 29rem 0 29rem;

  @media (max-width: 767px) {
    padding: 35rem 100rem 0;
    font-size: 40rem;
  }
`

export const TitleDescription = styled.span`
  font-family: Poppins;
  font-size: 11rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.68;
  letter-spacing: normal;
  text-align: left;
  color: #8e8e8e;
  padding: 0 15rem 0 29rem;

  @media (max-width: 767px) {
    padding: 0 48rem 0 100rem;
    font-size: 34rem;
  }
`

export const BannerHeader = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15rem 29rem;

  @media (max-width: 767px) {
    padding: 51.2rem;
  }
`

export const InfoGroupBottom = styled.div`
  margin: 2rem 0 0 0;

  @media (max-width: 767px) {
    margin: 38rem 0 0 0;
  }
`

export const styleImageLottie = {
  transition: `height 0.05s`,
  width: '150rem',
  height: '170rem',
  position: 'absolute',
  zIndex: 2,
  top: '35rem',
  right: 0,
  left: 0,
}

export const styleImageLottieTablet = {
  transition: `height 0.05s`,
  width: '234rem',
  height: '170rem',
  position: 'absolute',
  zIndex: 2,
  top: 65,
  right: 0,
  left: 0,
}

export const styleImageLottieMobile = {
  transition: `height 0.05s`,
  width: '50%',
  height: '550rem',
  position: 'absolute',
  zIndex: 2,
  top: '35rem',
  right: 0,
  left: 0,
}

export const styleImageLottieSparkle = {
  transition: `height 0.05s`,
  height: '170rem',
  width: '35%',
  position: 'absolute',
  zIndex: 2,
  top: 35,
  right: 0,
  left: 0,
}

export const ImageBackground = styled.img`
  width: 100%;
  height: 230rem;
  border-radius: 14px 14px 0 0;

  @media (max-width: 767px) {
    height: 744rem;
  }
`

export const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 29rem 0;
  margin-top: 2rem;

  @media (max-width: 767px) {
    padding: 30rem 100rem 0;
    align-items: center;
  }
`

export const LabelProgress = styled.p`
  font-family: Poppins;
  font-size: 12rem;
  font-weight: 600;
  text-align: left;
  color: #fff;
  margin-right: 5rem;
  white-space: nowrap;

  margin-bottom: 0;

  @media screen and (max-width: 767px) {
    margin-right: 20rem;
    font-size: 41rem;
  }
`

export const ProgressBar = styled.div`
  width: 100%;
  height: 11.5rem;
  border-radius: 3.5rem;
  border: 1rem solid #202429;
  background-color: #0d0d0f;
  box-sizing: border-box;

  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    height: 40rem;
    border-radius: 20rem;
  }

  .progressInner {
    height: 100%;
    border-radius: inherit;
    background-image: linear-gradient(to top, #980000, #f00);

    @media screen and (max-width: 767px) {
    }
  }

  // .ant-progress-inner {
  //   height: 12rem;
  //   border: solid 1px #202429;
  //   background-color: #0d0d0f;

  //   @media screen and (max-width: 767px) {
  //     height: 50rem;
  //   }
  // }

  // .ant-progress-bg {
  //   height: 12rem !important;
  //   background-image: linear-gradient(to top, #980000, #f00);


  //   @media screen and (max-width: 767px) {
  //     height: 45rem !important;
  //   }
  // }

  // .ant-progress-outer {
  //   @media screen and (max-width: 767px) {
  //     display: flex;
  //   }
  // }
`

export const ProgressInfo = styled.div`
  font-family: Poppins;
  font-size: 9.5rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.68;
  letter-spacing: normal;
  text-align: center;
  color: #828283;
  margin-top: 3px;

  @media screen and (max-width: 767px) {
    font-size: 32.5rem;
  }
`

export const ImgItem = styled.div`
  img{
    height: 170rem;
    position: absolute;
    margin: 0 auto;
    top: 30rem;
    z-index: 2;
    right: 0;
    left: 0;

    @media (max-width: 767px) {
      height: 570rem;
      top: 85rem;
    }
  }
`

export default WithDirection(Wrapper)
