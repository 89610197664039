import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from 'ethers';

export const getWalletConnectProvider = async () => {
    if (window.provider) {
        return window.provider;
    }

    const POLLING_INTERVAL = 12000;
    const provider = new WalletConnectProvider({
      infuraId: process.env.REACT_APP_INFURA_ID,
      rpc: {
        1: process.env.REACT_APP_ETHER_WEB3_PROVIDER_MAINNET_HTTPS,
        5: process.env.REACT_APP_ETHER_WEB3_PROVIDER_TESTNET_HTTPS,
      },
      bridge: 'https://bridge.walletconnect.org',
      qrcode: true,
      pollingInterval: POLLING_INTERVAL
    });

    const supportedChainIds = [1, 5];

    if (!provider.wc.connected) {
      await provider.wc.createSession({
        chainId: process.env.REACT_APP_DEV === '1' ? supportedChainIds[1] : supportedChainIds[0]
      });
    }

    await provider.enable();

    const web3 = new Web3(provider);
    window.web3 = web3;
    window.web3.eth.transactionPollingTimeout = 2700;
    window.provider = provider;
    window.ethersProvider = new ethers.providers.Web3Provider(window.provider, 'any');

    return provider;
}