import React from 'react'
import { InputNumber } from 'antd'
import styled from 'styled-components'
const InputNumberElement = styled(InputNumber)`
  display: block;
  width: auto;
  border-radius: 5px;
  overflow: hidden;
  padding: 15px;

  font-size: 15px;
  line-height: 12px;
  text-align: left;

  .ant-input-number-handler-wrap {
    display: none;
  }

  &.ant-input-number-disabled {
    background-color: unset !important;
  }
  input {
    color: #fff;
    font-size: 17px;
    font-weight: 600;
  }
`
export default function NumberInput(props) {
  const { inputClass, id, value, placeholder, onChange, onFocus, min, max, step, disabled } = props
  return (
    <InputNumberElement
      className={inputClass}
      {...{ id, value, placeholder, onFocus, min, max, disabled, step }}
      onChange={value => onChange(value)}
      bordered={false}
    />
  )
}
