import React from 'react'
import styled from 'styled-components'
// import { Skeleton } from 'antd'
import { getBalanceNumber } from '../../../../utils/formatBalance'
import { getRoi, tokenEarnedPerThousandDollarsCompounding } from '../../../../utils/compoundApyHelpers';
import { useGetApiPrice } from '../../../../state/hooks';
import Balance from "../../../Balance";
import { getPoolApr } from "../../../../utils/apr";
import { TooltipText, useTooltip } from '@pancakeswap-libs/uikit'

// const WrapperSkeleton = styled.div`
//   span {
//     width: 60rem !important;
//     height: 17rem !important;
//     border-radius: 5rem;
//     @media (max-width: 1280px) {
//       width: 75rem !important;
//       height: 18rem !important;
//     }
//     @media only screen and (max-width: 768px) {
//       width: 256rem !important;
//       height: 54rem !important;
//       border-radius: 15rem;
//     }
//   }
// `

const Wrapper = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
`
const Content = styled.div`
  font-size: 17.5rem;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.44rem;
  color: #fff;

  @media (max-width: 767px) {
    font-size: 51.2rem;
  }
`

const InfoStakingRight = ({
  title,
  pool,
  stakingTokenPrice,
  isAutoVault = false,
  compoundFrequency = 1,
  performanceFee = 0,
  isDisableTooltip = true,
  isMobile = false,
  isTablet = false,
  isLoading = false
}) => {
  const {
    totalStaked,
    rewardPerBlock,
    apy,
    stakeTokenDecimals
  } = pool;

  const tooltipContent = isAutoVault
    ? 'APY includes compounding, APR doesn’t. This pool’s CAKE is compounded automatically, so we show APY.'
    : 'This pool’s rewards aren’t compounded automatically, so we show APR';

  const { targetRef, tooltip, tooltipVisible } = useTooltip(tooltipContent, { placement: 'bottom-end' });

  const earningTokenPrice = useGetApiPrice(pool.coinSymbol) ?? 0;
  const apr = getPoolApr(
    stakingTokenPrice,
    earningTokenPrice,
    getBalanceNumber(totalStaked, stakeTokenDecimals),
    getBalanceNumber(rewardPerBlock, stakeTokenDecimals),
    apy);

  // special handling for tokens like tBTC or BIFI where the daily token rewards for $1000 dollars will be less than 0.001 of that token
  const isHighValueToken = Math.round(earningTokenPrice / 1000) > 0;
  const roundingDecimals = isHighValueToken ? 4 : 2;

  const earningsPercentageToDisplay = () => {
    if (isAutoVault) {
      const oneThousandDollarsWorthOfToken = 1000 / earningTokenPrice;
      const tokenEarnedPerThousand365D = tokenEarnedPerThousandDollarsCompounding({
        numberOfDays: 365,
        farmApr: apr,
        tokenPrice: earningTokenPrice,
        roundingDecimals,
        compoundFrequency,
        performanceFee,
      });
      return getRoi({
        amountEarned: tokenEarnedPerThousand365D,
        amountInvested: oneThousandDollarsWorthOfToken,
      })
    }
    return apr
  };

  const renderDataContent = () => {
    return (
      <Wrapper>
        {!isDisableTooltip && tooltipVisible && tooltip}
        <TooltipText ref={targetRef} fontSize={isMobile ? "51.2rem" : "16rem"}
          color={'#ffffff'}
          style={{ textDecoration: 'none', fontWeight: '500', lineHeight: '1.33' }}>{title}</TooltipText>
        <Balance
          fontSize={isMobile ? "51.2rem" : "16rem"}
          value={earningsPercentageToDisplay()}
          decimals={2}
          unit="%"
          bold
          lineHeight={1.25}
          color={'#ffffff'}
        />
      </Wrapper>
    )
  }

  const renderLoading = () => {
    return <Wrapper>
      <TooltipText ref={targetRef} fontSize={isMobile ? "51.2rem" : isTablet ? "23.4rem" : "16rem"}
        color={'#ffffff'}
        style={{ textDecoration: 'none', fontWeight: '500', lineHeight: '1.25' }}>{title}</TooltipText>
      {/* <WrapperSkeleton>
        <Skeleton.Input />
      </WrapperSkeleton> */}
      <Content>
        {apy.toFixed(2)}%
      </Content>
    </Wrapper>
  }

  return (
    isLoading ? renderLoading() : renderDataContent()
  )
}

export default InfoStakingRight
