import { LIST_LIMIT_LENGTH } from '../../utils/common';
import http from '../http';

export default class PoolsApi {
    static getPools({
        skip = 0,
        limit = LIST_LIMIT_LENGTH,
    }) {
        return http.get('/stakings', {
            params: {
                skip,
                limit
            }
        });
    }
}
