import React from 'react'
import styled from 'styled-components'
import img2 from './../assets/img/Person.png'
import laptop from './../assets/img/laptop.png'
import gunScreen from './../assets/video/gun-screen.mp4'
import VideoAnimation from './Video'
import BGSection from './../assets/img/bg-gun-2.jpg'
import BGSectionMobile from './../assets/img/bg2-section-mobile.jpg'

const Wrapper = styled.div`
  width: 100%;

  @media screen and (max-width: 2560px) {
    /* padding: 0px 110px 0px 0px; */
  }
  @media screen and (max-width: 1920px) {
    /* padding: 0px 60px 0px 0px; */
  }
  @media screen and (max-width: 768px) {
    background: url(${BGSectionMobile});
    background-size: cover;
    padding: 0px;
  }
  background: url(${BGSection});
  background-size: cover;
`

const MainContent = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const RightBlock = styled.div`
  width: 100%;
  padding: 64px 36px;

  @media screen and (min-width: 769px) {
    width: 66%;
    padding: 60px 80px 50px 0px;
  }
  @media screen and (min-width: 1280px) {
    width: 66%;
    padding: 70px 100px 50px 0px;
  }

  @media screen and (min-width: 1920px) {
    width: 70%;
    padding: 70px 130px 50px 0px;
  }
  @media screen and (min-width: 2560px) {
    width: 70%;
    padding: 70px 180px 50px 0px;
  }
`
const TextBlock = styled.div`
  margin-bottom: 25px;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

const TextTitle = styled.div`
  font-size: 29px;
  color: #ffff;
  font-weight: 600;
  text-align: center;

  @media screen and (min-width: 769px) {
    font-size: 28px;
    text-align: right;
  }
  @media screen and (min-width: 1280px) {
    font-size: 32.5px;
    text-align: right;
  }
  @media screen and (min-width: 1440px) {
    font-size: 35px;
    text-align: right;
  }
  @media screen and (min-width: 1920px) {
    font-size: 45px;
    text-align: right;
  }
`

const TextDescription = styled.div`
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  font-size: 13px;
  padding: 0;

  @media screen and (min-width: 769px) {
    font-size: 14px;
    text-align: right;
    padding: 0;
  }
  @media screen and (min-width: 1280px) {
    font-size: 15px;
    text-align: right;
    padding-left: 10%;
  }
  @media screen and (min-width: 1440px) {
    font-size: 18px;
    text-align: right;
  }
  @media screen and (min-width: 1920px) {
    font-size: 23px;
    text-align: right;
  }
`

const ImgOne = styled.div`
  width: 100%;
  position: relative;
  @media screen and (min-width: 769px) {
    width: 100%;
    margin-left: 9%;
  }
  @media screen and (min-width: 1280px) {
    width: 103%;
    margin-left: 7%;
  }

  img {
    width: 100%;
    height: auto;
  }
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: auto;
  }
`

const LeftBlock = styled.div`
  width: 34%;
  margin-top: 60px;
  position: relative;

  @media screen and (min-width: 1920px) {
    background-size: 100%;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const LeftImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 50px;
  height: 100%;
  z-index: 1;
  @media screen and (min-width: 1280px) {
    left: 65px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const ComponentTwo = () => {
  return (
    <Wrapper>
      <MainContent>
        <LeftBlock>
          <LeftImage src={img2} alt={''} />
        </LeftBlock>
        <RightBlock>
          <TextBlock>
            <TextTitle>Collect & Upgrade Your Weapons</TextTitle>
            <TextDescription>
              Equip your player with powerful weapon NFTs and upgrade them to increase damage, ammo capacity & accuracy.
            </TextDescription>
          </TextBlock>
          <ImgOne>
            <img src={laptop} alt="" />
            <VideoAnimation video={gunScreen} />
          </ImgOne>
        </RightBlock>
      </MainContent>
    </Wrapper>
  )
}

export default ComponentTwo
