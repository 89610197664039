import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CARD_LIST } from './constants';
import { fetchAllPackages } from '../../../actions/package';

function usePackages(props) {
    const dispatch = useDispatch();

    const { pathname } = props;

    const [allPackages, setAllPackages] = useState(Array(3).fill({}));

    const { requesting } = useSelector(state => state.packages?.allPackages);

    useEffect(() => {
        dispatch(fetchAllPackages())
            .then(res => {
                handlePackages(res?.data, setAllPackages)
            })
            .catch(err => err)
        
        return () => {
            setAllPackages(Array(3).fill({}));
        }
    }, [dispatch, pathname]);

    return { allPackages, requesting };
}

export default usePackages;

const handlePackages = (items, setPackages) => {
    if (!items) return;

    const cards = CARD_LIST.map(card => {
        const pkg = items.find(item => item.type === card.type);

        return ({
            ...card,
            ...pkg
        })
    })

    setPackages(cards);
}