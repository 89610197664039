import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd';

import viewTransaction from '../../assets/img/viewTransaction.png';
import success from '../../assets/img/successIcon.png';
import button from '../../assets/img/button.png';
import left from '../../assets/img/leftBackground.png';
import left2 from '../../assets/img/leftBackground2.png';
import right2 from '../../assets/img/rightBackground2.png';
import right from '../../assets/img/rightBackground.png';

// import Loading from '../../components/Loading/Loading';
import Animations from '../../components/Animations';
import loadingData from '../../lotties/loading.json';

import style from './style.css';
import { isEmpty } from 'lodash';

const Wrapper = styled.div`
    .loading {
        width: 118rem;
        height: 118rem;
        pointer-events: none;

        @media screen and (max-width: 767px) {
            width: 350rem;
            height: 350rem;
        }
    }

    .left {
        position: absolute;
        right: 0;
        top: 0;
        width: 95rem;
        height: 98.6rem;

        @media (max-width: 767px) {
            width: 285rem;
            height: 300rem;
        }
    }

    .right {
        position: absolute;
        left: 0;
        top: 90rem;
        width: 80rem;
        height: 144.5rem;

        @media (max-width: 767px) {
            top: 280rem;
            width: 242rem;
            height: 440rem;
        }
    }

    .left-2 {
        position: absolute;
        left: 0;
        top: 0;

        width: 92.5rem;
        height: 130rem;

        @media (max-width: 767px) {
            width: 277rem;
            height: 380rem;
        }
    }

    .right-2 {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 105rem;
        height: 110rem;

        @media (max-width: 767px) {
            width: 300rem;
            height: 300rem;
        }
    }

    .image {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30rem 15rem 15rem 15rem;

        @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 80rem 60rem 70rem;
        }

        .layer {
            width: 108rem;
            height: 108rem;
        }

        span svg {
            width: 100%;
            height: 100%;
            fill: #fff;
        }

        .loading-modal {
            background: transparent;
        }
    }

    .image-success {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30rem 0 15rem 0;

        @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 80rem 0;
        }

        .layer {
            width: 108rem;
            height: 108rem;

            @media (max-width: 767px) {
                width: 325rem;
                height: 325rem;
            }
        }

        span svg {
            width: 100%;
            height: 100%;
            fill: #fff;
        }

        .loading-modal {
            background: transparent;
        }
    }    


    .text {
        font-size: 22.5rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;

        color: #fff;

        z-index: 1000;

        padding-top: 15rem;
        margin-bottom: 3rem;

        @media (max-width: 767px) {
            font-size: 65rem;
            margin-bottom: 10rem;
        }
    }
    
    .text-success {
        font-size: 22rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        color: #fff;
        padding: 28rem 20rem 0;

        z-index: 1000;
        margin-bottom: 10rem;

        @media (max-width: 767px) {
            font-size: 65rem;
            margin-bottom: 10rem;
            padding: 100rem 50rem 0;
        }
    }

    .description {
        font-size: 12rem;
        line-height: 1.5;
        text-align: center;

        color: #8e8e8e;

        margin-bottom: 0;

        @media (max-width: 767px) {
            font-size: 33rem;
        }
    }
`;


const ButtonStyle = styled.div`
    height: 40rem;
    width: 100% !important;
    margin-bottom: 30rem;

    background: url(${button});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    justify-content: center;
    align-items: center;

    border: none; 

    color: #ffffff;

    cursor: pointer;

    font-size: 15rem;
    font-weight: 600;
    line-height: 1.33;
    text-align: center;

    @media (max-width: 767px) {
        height: 120rem;
        font-size: 40rem;
        margin-bottom: 80rem;
    }

    &.ant-btn-loading:before {
        display: none;
    }

    .ant-btn-loading-icon {
        color: #ffffff;
    }

    &:active {
        transform: scale(0.9);
        transition: all 0.2s;
    }

    :hover,
    :active,
    :focus {
      background: url(${button});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      span{
        color: #ffffff;
      }
    }
`

export const ViewAddress = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 12.5rem;
    cursor: pointer;
    margin-left: 6px;

    img {
        width: 10rem;
        height: 10.5rem;

        @media (max-width: 767px) {
            height: 28rem;
            width: 28rem;
        }
    }
`

export default function SuccessModal(props) {
    const {
        visible,
        isSuccess,
        onCancel,
        onViewTransaction,
        title,
        text,
        chainId,
        isMobile,
        hash,
        isShowButtonClose = true,
        ...restProps
    } = props;

    const antIcon = <LoadingOutlined style={{ fontSize: isMobile ? '60rem' : '24rem', color: 'white', fontWeight: 'bold' }} spin />;

    return (
        <Modal
            {...restProps}
            centered
            onCancel={onCancel}
            maskClosable={isSuccess}
            visible={visible}
            bodyClass={style.wrapper}
            wrapClassName={'success-modal'}
            width={'374rem'}
        >
            <Wrapper>
                {isSuccess ? (
                    <>
                        <div className="header-nav">
                            <img src={right} alt="right" className="right" />
                            <img src={left} alt="left" className="left" />
                        </div>

                        <div className={'image-success'}>
                            <img src={success} alt="success" className="layer" />
                            <p className={'text-success'}>
                                {text || 'You successfully staked'}
                            </p>
                        </div>

                        <div className={'buttonsSuccess'}>
                            {isShowButtonClose ? <ButtonStyle onClick={onViewTransaction}  >
                                View Transaction
                            </ButtonStyle> : <ButtonStyle onClick={onCancel} >
                                Close
                            </ButtonStyle>}
                        </div>
                    </>
                ) : (
                    <>
                        <img src={left2} alt="left2" className="left-2" />
                        <img src={right2} alt="right2" className="right-2" />

                        <div className={'image'}>
                            {/* <Loading isCustom wrapClassname={'loading-modal'} /> */}

                            <div className="loading">
                                <Animations animationData={loadingData} />
                            </div>

                            <p className={'text'}>
                                {title || 'Staking UNDEAD Tokens'}
                            </p>

                            <p className={'description'}>
                                {text || 'Please wait while we stake your UNDEAD & mint your weapon NFTs. This process can take a couple minutes.'}
                            </p>
                        </div>

                        <div className={'buttonsSuccess'}>
                            {isShowButtonClose ? <ButtonStyle onClick={!isEmpty(hash) ? onViewTransaction : () => { }}  >
                                {!isEmpty(hash) ?
                                    <>
                                        View Transaction
                                        <ViewAddress>
                                            <img src={viewTransaction} alt="viewTransaction" />
                                        </ViewAddress>
                                    </> : <Spin indicator={antIcon} />
                                }
                            </ButtonStyle> : <ButtonStyle onClick={onCancel} >
                                Close
                            </ButtonStyle>}
                        </div>
                    </>
                )}
            </Wrapper>
        </Modal >
    )
}
