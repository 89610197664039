import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;
    z-index: 100;
    min-height: 770rem;

    &.notShow {
        opacity: 0;
    }

    @media screen and (max-width: 767px) {
        min-height: 1900rem;
    }

    @media screen and (min-width: 768px) and (max-width: 1279px) {
        min-height: 1000rem;
    }
`

export const TitleWrapper = styled.div`
    .title-img {
        width: 52.03vw;
        height: 127rem;

        position: absolute;
        top: 158rem;
        left: 307rem;

        @media screen and (max-width: 767px) {
            width: 84.74vw;
            height: 71px;

            position: absolute;
            top: 300rem;
            left: 50%;
            transform: translateX(-50%);
        }

        @media screen and (min-width: 768px) and (max-width: 1279px) {
            top: 300rem;
        }
    }
`;

export const CountDownWrapper = styled.div`
    .container {
        .item {
            .timer-countdown {
                width: 46.13vw;
                position: absolute;
                top: 307.5rem;
                left: 344.5rem;
                right: 345rem;

                @media screen and (max-width: 767px) {
                    top: 1400rem;
                    width: 85%;
                    left: 50%;
                    transform: translateX(-50%);
                    right: 0;
                }

                @media screen and (min-width: 768px) and (max-width: 1279px) {
                    top: 450rem;
                }
            }
        }
    }
`;

export const ContentWrapper = styled.div`
    img {
        width: 33.71vw;
        height: 40rem;

        position: absolute;
        top: 502.5rem;
        left: 31.44vw;

        @media screen and (max-width: 767px) {
            width: 68vw;
            height: 29px;
            top: 1750rem;
            left: 50%;
            transform: translateX(-50%);
        }

        @media screen and (min-width: 768px) and (max-width: 1279px) {
            top: 670rem;
        }
    }
`;

export default WithDirection(Wrapper);