import React from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { Modal } from 'antd';
import styled from 'styled-components';

import Animations from '../Animations'
import loadingData from '../../lotties/loading.json'

import left from '../../pages/MintWeapons/img/left-step3.png';
import right from '../../pages/MintWeapons/img/bottom-right.png';
import button from '../../pages/MintWeapons/img/button.png';

import { checkWrongNetwork } from '../../actions/common';

const Wrapper = styled.div`
    padding: 29.5rem 30rem 30rem;
    position: relative;

    @media screen and (max-width: 767px) {
        padding: 67rem 65rem 69rem;
    }

    .layer-left {
        position: absolute;
        left: 0rem;
        top: 0rem;
        width: 108.5rem;
        height: 124.5rem;
        border-top-left-radius: 12px;

        @media screen and (max-width: 767px) {
            width: 246rem;
            height: 284rem;
        }
    }

    .layer-right {
        position: absolute;
        right: 0rem;
        bottom: 0rem;

        width: 104rem;
        height: 106rem;

        @media screen and (max-width: 767px) {
            width: 236rem;
            height: 242rem;
        }
    }

    .content {
        display: flex;
        flex-direction: columns;
        justify-content: center;

        .loading {
            width: 118rem;
            height: 118rem;

            @media screen and (max-width: 767px) {
                width: 268rem;
                height: 268rem;
            }
        }
    }

    .footer {
        padding: 10rem 0 32rem;
        text-align: center;

        @media screen and (max-width: 767px) {
            padding: 18rem 0 70rem;
        }

        h2 {
            font-family: Poppins;
            font-size: 22.5rem;
            font-weight: bold;
            line-height: 1.89;
            text-align: center;
            color: #fff;
            margin-bottom: 0;

            @media screen and (max-width: 767px) {
                font-size: 52.5rem;
            }
        }

        p {
            font-family: Poppins;
            font-size: 12.5rem;
            line-height: 1.6;
            text-align: center;
            color: #fff;
            margin-bottom: 0;

            @media screen and (max-width: 767px) {
                font-size: 28.5rem;
            }
        }
    }

    .btn-container {
        cursor: pointer;
        position: relative;

        &:active {
            transform: scale(0.9);
            transition: all 0.2s;
        }

        &:hover {
            .mint-btn {
                transform: scale(1.05);
                transition: all 0.2s;
            }
        }

        @media (max-width: 767px) {
        }
        
        .mint-btn {
            height: 40rem;
            border-radius: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 767px) {
                height: 90rem;
            }

            span {
                font-family: Poppins;
                font-size: 15rem;
                font-weight: 600;
                line-height: 1.33;
                text-align: center;
                color: #fff;

                @media (max-width: 767px) {
                    font-size: 36rem;
                }
            }
        }
    }
`;

function WrongNetworkModal() {
    const dispatch = useDispatch();

    const { wrongNetwork } = useSelector(state => state.common || false);
    const { isMobile } = useSelector(state => state.common);

    return (
        <Modal
            visible={wrongNetwork}
            closable={false}
            centered
            width={isMobile ? '892rem' : '389rem'}
            bodyStyle={{ 
                padding: 0,
                backgroundColor: '#0f1014',
                borderRadius: '7.5rem',
                border: 'solid 0.5rem #202429'
            }}
            zIndex={3000}
        >
            <Wrapper>
                <div className="layer">
                    <img src={left} alt="left" className="layer-left" />
                    <img src={right} alt="right" className="layer-right" />
                </div>

                <div className="content">
                    <div className="loading">
                        <Animations animationData={loadingData} />
                    </div>
                </div>

                <div className="footer">
                    <h2>Wrong Network</h2>
                    <p>Please change your network to ethereum mainnet.</p>
                </div>

                <div
                    className="btn-container"
                    onClick={() => dispatch(checkWrongNetwork(false))}
                >
                    <div
                        className="mint-btn"
                        style={{
                            background: `url(${button}) no-repeat center center / cover`
                        }}
                    >
                        <span>Close</span>
                    </div>
                </div>
            </Wrapper>
        </Modal>
    );
}

export default WrongNetworkModal;