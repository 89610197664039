
// KNIFE
import AmateurKnife from './img/Items/amateur/Knife_1.png';
import SurvivorKnife from './img/Items/survivor/Knife_1.png';
import AssassinKnife from './img/Items/assassin/Knife_1.png';
import KillerKnife from './img/Items/killer/Knife_1.png';
// import RookieKnife from './img/Items/rookie/Knife_1.png';
import CommanderKnife from './img/Items/commander/Knife_1.png';

// AMATEUR
import AmateurPistol_1 from './img/Items/amateur/Pistol_1.png';
import AmateurPistol_2 from './img/Items/amateur/Pistol_2.png';
import AmateurPistol_3 from './img/Items/amateur/Pistol_3.png';
import AmateurPistol_4 from './img/Items/amateur/Pistol_4.png';
import AmateurPistol_5 from './img/Items/amateur/Pistol_5.png';
import AmateurPistol_6 from './img/Items/amateur/Pistol_6.png';
import AmateurPistol_7 from './img/Items/amateur/Pistol_7.png';
import AmateurPistol_8 from './img/Items/amateur/Pistol_8.png';
import AmateurPistol_9 from './img/Items/amateur/Pistol_9.png';

// SURVIVOR
import SurvivorPistol_1 from './img/Items/survivor/Pistol_1.png';
import SurvivorPistol_2 from './img/Items/survivor/Pistol_2.png';
import SurvivorPistol_3 from './img/Items/survivor/Pistol_3.png';
import SurvivorPistol_4 from './img/Items/survivor/Pistol_4.png';
import SurvivorPistol_5 from './img/Items/survivor/Pistol_5.png';
import SurvivorPistol_6 from './img/Items/survivor/Pistol_6.png';
import SurvivorPistol_7 from './img/Items/survivor/Pistol_7.png';
import SurvivorPistol_8 from './img/Items/survivor/Pistol_8.png';
import SurvivorPistol_9 from './img/Items/survivor/Pistol_9.png';

import SurvivorLongGun_1 from './img/Items/survivor/MP5_1.png';
import SurvivorLongGun_2 from './img/Items/survivor/MP5_2.png';
import SurvivorLongGun_3 from './img/Items/survivor/MP5_3.png';
import SurvivorLongGun_4 from './img/Items/survivor/MP5_4.png';
import SurvivorLongGun_5 from './img/Items/survivor/MP5_5.png';
import SurvivorLongGun_6 from './img/Items/survivor/MP5_6.png';
import SurvivorLongGun_7 from './img/Items/survivor/MP5_7.png';
import SurvivorLongGun_8 from './img/Items/survivor/MP5_8.png';
import SurvivorLongGun_9 from './img/Items/survivor/MP5_9.png';

// ASSASSIN
import AssassinPistol_1 from './img/Items/assassin/Pistol_1.png';
import AssassinPistol_2 from './img/Items/assassin/Pistol_2.png';
import AssassinPistol_3 from './img/Items/assassin/Pistol_3.png';
import AssassinPistol_4 from './img/Items/assassin/Pistol_4.png';
import AssassinPistol_5 from './img/Items/assassin/Pistol_5.png';
import AssassinPistol_6 from './img/Items/assassin/Pistol_6.png';
import AssassinPistol_7 from './img/Items/assassin/Pistol_7.png';
import AssassinPistol_8 from './img/Items/assassin/Pistol_8.png';
import AssassinPistol_9 from './img/Items/assassin/Pistol_9.png';

import AssassinLongGun_1 from './img/Items/assassin/AKM_1.png';
import AssassinLongGun_2 from './img/Items/assassin/AKM_2.png';
import AssassinLongGun_3 from './img/Items/assassin/AKM_3.png';
import AssassinLongGun_4 from './img/Items/assassin/AKM_4.png';
import AssassinLongGun_5 from './img/Items/assassin/AKM_5.png';
import AssassinLongGun_6 from './img/Items/assassin/AKM_6.png';
import AssassinLongGun_7 from './img/Items/assassin/AKM_7.png';
import AssassinLongGun_8 from './img/Items/assassin/AKM_8.png';
import AssassinLongGun_9 from './img/Items/assassin/AKM_9.png';

import AssassinShortGun_1 from './img/Items/assassin/DB_1.png';
import AssassinShortGun_2 from './img/Items/assassin/DB_2.png';
import AssassinShortGun_3 from './img/Items/assassin/DB_3.png';
import AssassinShortGun_4 from './img/Items/assassin/DB_4.png';
import AssassinShortGun_5 from './img/Items/assassin/DB_5.png';
import AssassinShortGun_6 from './img/Items/assassin/DB_6.png';
import AssassinShortGun_7 from './img/Items/assassin/DB_7.png';
import AssassinShortGun_8 from './img/Items/assassin/DB_8.png';
import AssassinShortGun_9 from './img/Items/assassin/DB_9.png';

import AssassinPerk_1 from './img/Items/assassin/Perk_1.png';
import AssassinPerk_2 from './img/Items/assassin/Perk_2.png';
import AssassinPerk_3 from './img/Items/assassin/Perk_3.png';
import AssassinPerk_4 from './img/Items/assassin/Perk_4.png';

// ZOMBIE KILLER
import KillerPistol_1 from './img/Items/killer/Pistol_1.png';
import KillerPistol_2 from './img/Items/killer/Pistol_2.png';
import KillerPistol_3 from './img/Items/killer/Pistol_3.png';
import KillerPistol_4 from './img/Items/killer/Pistol_4.png';
import KillerPistol_5 from './img/Items/killer/Pistol_5.png';
import KillerPistol_6 from './img/Items/killer/Pistol_6.png';
import KillerPistol_7 from './img/Items/killer/Pistol_7.png';
import KillerPistol_8 from './img/Items/killer/Pistol_8.png';
import KillerPistol_9 from './img/Items/killer/Pistol_9.png';

import KillerLongGun_1 from './img/Items/killer/F1_1.png';
import KillerLongGun_2 from './img/Items/killer/F1_2.png';
import KillerLongGun_3 from './img/Items/killer/F1_3.png';
import KillerLongGun_4 from './img/Items/killer/F1_4.png';
import KillerLongGun_5 from './img/Items/killer/F1_5.png';
import KillerLongGun_6 from './img/Items/killer/F1_6.png';
import KillerLongGun_7 from './img/Items/killer/F1_7.png';
import KillerLongGun_8 from './img/Items/killer/F1_8.png';
import KillerLongGun_9 from './img/Items/killer/F1_9.png';

import KillerShortGun_1 from './img/Items/killer/DB_1.png';
import KillerShortGun_2 from './img/Items/killer/DB_2.png';
import KillerShortGun_3 from './img/Items/killer/DB_3.png';
import KillerShortGun_4 from './img/Items/killer/DB_4.png';
import KillerShortGun_5 from './img/Items/killer/DB_5.png';
import KillerShortGun_6 from './img/Items/killer/DB_6.png';
import KillerShortGun_7 from './img/Items/killer/DB_7.png';
import KillerShortGun_8 from './img/Items/killer/DB_8.png';
import KillerShortGun_9 from './img/Items/killer/DB_9.png';

// ROOKIE 
import RookiePistol_1 from './img/Items/rookie/Pistol_1.png';
import RookiePistol_2 from './img/Items/rookie/Pistol_2.png';
import RookiePistol_3 from './img/Items/rookie/Pistol_3.png';
import RookiePistol_4 from './img/Items/rookie/Pistol_4.png';
import RookiePistol_5 from './img/Items/rookie/Pistol_5.png';
import RookiePistol_6 from './img/Items/rookie/Pistol_6.png';
import RookiePistol_7 from './img/Items/rookie/Pistol_7.png';
import RookiePistol_8 from './img/Items/rookie/Pistol_8.png';
import RookiePistol_9 from './img/Items/rookie/Pistol_9.png';

import RookieShortGun_1 from './img/Items/rookie/R870_1.png';
import RookieShortGun_2 from './img/Items/rookie/R870_2.png';
import RookieShortGun_3 from './img/Items/rookie/R870_3.png';
import RookieShortGun_4 from './img/Items/rookie/R870_4.png';
import RookieShortGun_5 from './img/Items/rookie/R870_5.png';
import RookieShortGun_6 from './img/Items/rookie/R870_6.png';
import RookieShortGun_7 from './img/Items/rookie/R870_7.png';
import RookieShortGun_8 from './img/Items/rookie/R870_8.png';
import RookieShortGun_9 from './img/Items/rookie/R870_9.png';

// COMMANDER
import CommanderPistol_1 from './img/Items/commander/Pistol_1.png';
import CommanderPistol_2 from './img/Items/commander/Pistol_2.png';
import CommanderPistol_3 from './img/Items/commander/Pistol_3.png';
import CommanderPistol_4 from './img/Items/commander/Pistol_4.png';
import CommanderPistol_5 from './img/Items/commander/Pistol_5.png';
import CommanderPistol_6 from './img/Items/commander/Pistol_6.png';
import CommanderPistol_7 from './img/Items/commander/Pistol_7.png';
import CommanderPistol_8 from './img/Items/commander/Pistol_8.png';
import CommanderPistol_9 from './img/Items/commander/Pistol_9.png';

import CommanderLongGun_1 from './img/Items/commander/M1A_1.png';
import CommanderLongGun_2 from './img/Items/commander/M1A_2.png';
import CommanderLongGun_3 from './img/Items/commander/M1A_3.png';
import CommanderLongGun_4 from './img/Items/commander/M1A_4.png';
import CommanderLongGun_5 from './img/Items/commander/M1A_5.png';
import CommanderLongGun_6 from './img/Items/commander/M1A_6.png';
import CommanderLongGun_7 from './img/Items/commander/M1A_7.png';
import CommanderLongGun_8 from './img/Items/commander/M1A_8.png';
import CommanderLongGun_9 from './img/Items/commander/M1A_9.png';

import CommanderShortGun_1 from './img/Items/commander/BaseballBat_1.png';
import CommanderShortGun_2 from './img/Items/commander/BaseballBat_2.png';
import CommanderShortGun_3 from './img/Items/commander/BaseballBat_3.png';
import CommanderShortGun_4 from './img/Items/commander/BaseballBat_4.png';
import CommanderShortGun_5 from './img/Items/commander/BaseballBat_5.png';
import CommanderShortGun_6 from './img/Items/commander/BaseballBat_6.png';
import CommanderShortGun_7 from './img/Items/commander/BaseballBat_7.png';
import CommanderShortGun_8 from './img/Items/commander/BaseballBat_8.png';
import CommanderShortGun_9 from './img/Items/commander/BaseballBat_9.png';

// VETERAN 
import VeteranPistol_1 from './img/Items/veteran/Pistol_1.png';
import VeteranPistol_2 from './img/Items/veteran/Pistol_2.png';
import VeteranPistol_3 from './img/Items/veteran/Pistol_3.png';
import VeteranPistol_4 from './img/Items/veteran/Pistol_4.png';
import VeteranPistol_5 from './img/Items/veteran/Pistol_5.png';
import VeteranPistol_6 from './img/Items/veteran/Pistol_6.png';
import VeteranPistol_7 from './img/Items/veteran/Pistol_7.png';
import VeteranPistol_8 from './img/Items/veteran/Pistol_8.png';
import VeteranPistol_9 from './img/Items/veteran/Pistol_9.png';

import VeteranLongGun_1 from './img/Items/veteran/AKM_1.png';
import VeteranLongGun_2 from './img/Items/veteran/AKM_2.png';
import VeteranLongGun_3 from './img/Items/veteran/AKM_3.png';
import VeteranLongGun_4 from './img/Items/veteran/AKM_4.png';
import VeteranLongGun_5 from './img/Items/veteran/AKM_5.png';
import VeteranLongGun_6 from './img/Items/veteran/AKM_6.png';
import VeteranLongGun_7 from './img/Items/veteran/AKM_7.png';
import VeteranLongGun_8 from './img/Items/veteran/AKM_8.png';
import VeteranLongGun_9 from './img/Items/veteran/AKM_9.png';

import VeteranShortGun_1 from './img/Items/veteran/RPG_1.png';
import VeteranShortGun_2 from './img/Items/veteran/RPG_2.png';
import VeteranShortGun_3 from './img/Items/veteran/RPG_3.png';
import VeteranShortGun_4 from './img/Items/veteran/RPG_4.png';
import VeteranShortGun_5 from './img/Items/veteran/RPG_5.png';
import VeteranShortGun_6 from './img/Items/veteran/RPG_6.png';
import VeteranShortGun_7 from './img/Items/veteran/RPG_7.png';
import VeteranShortGun_8 from './img/Items/veteran/RPG_8.png';
import VeteranShortGun_9 from './img/Items/veteran/RPG_9.png';

import VeteranAxe_1 from './img/Items/veteran/Axe_1.png';
import VeteranAxe_2 from './img/Items/veteran/Axe_2.png';
import VeteranAxe_3 from './img/Items/veteran/Axe_3.png';
import VeteranAxe_4 from './img/Items/veteran/Axe_4.png';
import VeteranAxe_5 from './img/Items/veteran/Axe_5.png';
import VeteranAxe_6 from './img/Items/veteran/Axe_6.png';
import VeteranAxe_7 from './img/Items/veteran/Axe_7.png';
import VeteranAxe_8 from './img/Items/veteran/Axe_8.png';
import VeteranAxe_9 from './img/Items/veteran/Axe_9.png';

import VeteranPerk_1 from './img/Items/veteran/Perk_1.png';
import VeteranPerk_2 from './img/Items/veteran/Perk_2.png';
import VeteranPerk_3 from './img/Items/veteran/Perk_3.png';
import VeteranPerk_4 from './img/Items/veteran/Perk_4.png';


// SINGLE - WEAPONS
import AKM_1 from '../../assets/img/SingleWeapon/AKM_1.png'
import AKM_2 from '../../assets/img/SingleWeapon/AKM_2.png'
import AKM_3 from '../../assets/img/SingleWeapon/AKM_3.png'
import AKM_4 from '../../assets/img/SingleWeapon/AKM_4.png'
import AKM_5 from '../../assets/img/SingleWeapon/AKM_5.png'
import AKM_6 from '../../assets/img/SingleWeapon/AKM_6.png'
import AKM_7 from '../../assets/img/SingleWeapon/AKM_7.png'
import AKM_8 from '../../assets/img/SingleWeapon/AKM_8.png'
import AKM_9 from '../../assets/img/SingleWeapon/AKM_9.png'

import Db_1 from '../../assets/img/SingleWeapon/Db_1.png'
import Db_2 from '../../assets/img/SingleWeapon/Db_2.png'
import Db_3 from '../../assets/img/SingleWeapon/Db_3.png'
import Db_4_1 from '../../assets/img/SingleWeapon/Db_4_1.png'
// import Db_4_2 from '../../assets/img/SingleWeapon/Db_4_2.png'
import Db_5 from '../../assets/img/SingleWeapon/Db_5.png'
import Db_6 from '../../assets/img/SingleWeapon/Db_6.png'
import Db_7 from '../../assets/img/SingleWeapon/Db_7.png'
import Db_8 from '../../assets/img/SingleWeapon/Db_8.png'
import Db_9 from '../../assets/img/SingleWeapon/Db_9.png'

import MP5_1 from '../../assets/img/SingleWeapon/MP5_1.png'
import MP5_2 from '../../assets/img/SingleWeapon/MP5_2.png'
import MP5_3 from '../../assets/img/SingleWeapon/MP5_3.png'
import MP5_4_1 from '../../assets/img/SingleWeapon/MP5_4_1.png'
// import MP5_4_2 from '../../assets/img/SingleWeapon/MP5_4_2.png'
import MP5_5 from '../../assets/img/SingleWeapon/MP5_5.png'
import MP5_6 from '../../assets/img/SingleWeapon/MP5_6.png'
import MP5_7 from '../../assets/img/SingleWeapon/MP5_7.png'
import MP5_8 from '../../assets/img/SingleWeapon/MP5_8.png'
import MP5_9 from '../../assets/img/SingleWeapon/MP5_9.png'

import Pistol_1 from '../../assets/img/SingleWeapon/Pistol_1.png'
import Pistol_2 from '../../assets/img/SingleWeapon/Pistol_2.png'
import Pistol_3 from '../../assets/img/SingleWeapon/Pistol_3.png'
import Pistol_4 from '../../assets/img/SingleWeapon/Pistol_4.png'
import Pistol_5 from '../../assets/img/SingleWeapon/Pistol_5.png'
import Pistol_6 from '../../assets/img/SingleWeapon/Pistol_6.png'
import Pistol_7 from '../../assets/img/SingleWeapon/Pistol_7.png'
import Pistol_8 from '../../assets/img/SingleWeapon/Pistol_8.png'
import Pistol_9 from '../../assets/img/SingleWeapon/Pistol_9.png'

import F1_1 from '../../assets/img/SingleWeapon/F1_1.png'
import F1_2 from '../../assets/img/SingleWeapon/F1_2.png'
import F1_3 from '../../assets/img/SingleWeapon/F1_3.png'
import F1_4 from '../../assets/img/SingleWeapon/F1_4.png'
import F1_5 from '../../assets/img/SingleWeapon/F1_5.png'
import F1_6 from '../../assets/img/SingleWeapon/F1_6.png'
import F1_7 from '../../assets/img/SingleWeapon/F1_7.png'
import F1_8 from '../../assets/img/SingleWeapon/F1_8.png'
import F1_9 from '../../assets/img/SingleWeapon/F1_9.png'

import Axe_1 from '../../assets/img/SingleWeapon/Axe_1.png'
import Axe_2 from '../../assets/img/SingleWeapon/Axe_2.png'
import Axe_3 from '../../assets/img/SingleWeapon/Axe_3.png'
import Axe_4 from '../../assets/img/SingleWeapon/Axe_4.png'
import Axe_5 from '../../assets/img/SingleWeapon/Axe_5.png'
import Axe_6 from '../../assets/img/SingleWeapon/Axe_6.png'
import Axe_7 from '../../assets/img/SingleWeapon/Axe_7.png'
import Axe_8 from '../../assets/img/SingleWeapon/Axe_8.png'
import Axe_9 from '../../assets/img/SingleWeapon/Axe_9.png'

import RPG_1 from '../../assets/img/SingleWeapon/RPG_1.png'
import RPG_2 from '../../assets/img/SingleWeapon/RPG_2.png'
import RPG_3 from '../../assets/img/SingleWeapon/RPG_3.png'
import RPG_4 from '../../assets/img/SingleWeapon/RPG_4.png'
import RPG_5 from '../../assets/img/SingleWeapon/RPG_5.png'
import RPG_6 from '../../assets/img/SingleWeapon/RPG_6.png'
import RPG_7 from '../../assets/img/SingleWeapon/RPG_7.png'
import RPG_8 from '../../assets/img/SingleWeapon/RPG_8.png'
import RPG_9 from '../../assets/img/SingleWeapon/RPG_9.png'

import R870_1 from '../../assets/img/SingleWeapon/R870_1.png'
import R870_2 from '../../assets/img/SingleWeapon/R870_2.png'
import R870_3 from '../../assets/img/SingleWeapon/R870_3.png'
import R870_4 from '../../assets/img/SingleWeapon/R870_4.png'
import R870_5 from '../../assets/img/SingleWeapon/R870_5.png'
import R870_6 from '../../assets/img/SingleWeapon/R870_6.png'
import R870_7 from '../../assets/img/SingleWeapon/R870_7.png'
import R870_8 from '../../assets/img/SingleWeapon/R870_8.png'
import R870_9 from '../../assets/img/SingleWeapon/R870_9.png'

import BaseballBat_1 from '../../assets/img/SingleWeapon/BaseballBat_1.png'
import BaseballBat_2 from '../../assets/img/SingleWeapon/BaseballBat_2.png'
import BaseballBat_3 from '../../assets/img/SingleWeapon/BaseballBat_3.png'
import BaseballBat_4 from '../../assets/img/SingleWeapon/BaseballBat_4.png'
import BaseballBat_5 from '../../assets/img/SingleWeapon/BaseballBat_5.png'
import BaseballBat_6 from '../../assets/img/SingleWeapon/BaseballBat_6.png'
import BaseballBat_7 from '../../assets/img/SingleWeapon/BaseballBat_7.png'
import BaseballBat_8 from '../../assets/img/SingleWeapon/BaseballBat_8.png'
import BaseballBat_9 from '../../assets/img/SingleWeapon/BaseballBat_9.png'

import M1A_1 from '../../assets/img/SingleWeapon/M1A_1.png'
import M1A_2 from '../../assets/img/SingleWeapon/M1A_2.png'
import M1A_3 from '../../assets/img/SingleWeapon/M1A_3.png'
import M1A_4 from '../../assets/img/SingleWeapon/M1A_4.png'
import M1A_5 from '../../assets/img/SingleWeapon/M1A_5.png'
import M1A_6 from '../../assets/img/SingleWeapon/M1A_6.png'
import M1A_7 from '../../assets/img/SingleWeapon/M1A_7.png'
import M1A_8 from '../../assets/img/SingleWeapon/M1A_8.png'
import M1A_9 from '../../assets/img/SingleWeapon/M1A_9.png'


export const SINGLE_WEAPON = {
    Knife: [AmateurKnife],
    Pistol: [Pistol_1, Pistol_2, Pistol_3, Pistol_4, Pistol_5, Pistol_6, Pistol_7, Pistol_8, Pistol_9],
    AKM: [AKM_1, AKM_2, AKM_3, AKM_4, AKM_5, AKM_6, AKM_7, AKM_8, AKM_9],
    DB: [Db_1, Db_2, Db_3, Db_4_1, Db_5, Db_6, Db_7, Db_8, Db_9],
    MP5: [MP5_1, MP5_2, MP5_3, MP5_4_1, MP5_5, MP5_6, MP5_7, MP5_8, MP5_9],
    Perk: [],
    F1: [F1_1, F1_2, F1_3, F1_4, F1_5, F1_6, F1_7, F1_8, F1_9],
    M1A: [
        M1A_1,
        M1A_2,
        M1A_3,
        M1A_4,
        M1A_5,
        M1A_6,
        M1A_7,
        M1A_8,
        M1A_9
    ],
    BaseballBat: [
        BaseballBat_1,
        BaseballBat_2,
        BaseballBat_3,
        BaseballBat_4,
        BaseballBat_5,
        BaseballBat_6,
        BaseballBat_7,
        BaseballBat_8,
        BaseballBat_9
    ],
    Axe: [
        Axe_1,
        Axe_2,
        Axe_3,
        Axe_4,
        Axe_5,
        Axe_6,
        Axe_7,
        Axe_8,
        Axe_9
    ],
    RPG: [
        RPG_1,
        RPG_2,
        RPG_3,
        RPG_4,
        RPG_5,
        RPG_6,
        RPG_7,
        RPG_8,
        RPG_9
    ],
    R870: [
        R870_1,
        R870_2,
        R870_3,
        R870_4,
        R870_5,
        R870_6,
        R870_7,
        R870_8,
        R870_9
    ]
}

export const AMATEUR_ASSETS = {
    Knife: [AmateurKnife],
    Pistol: [
        AmateurPistol_1,
        AmateurPistol_2,
        AmateurPistol_3,
        AmateurPistol_4,
        AmateurPistol_5,
        AmateurPistol_6,
        AmateurPistol_7,
        AmateurPistol_8,
        AmateurPistol_9
    ],
    AKM: [],
    DB: [],
    Perk: [],
    MP5: [],
    F1: [],
    R870: [],
    Axe: [],
    M1A: [],
    BaseballBat: [],
    RPG: []
}

export const ASSASSIN_ASSETS = {
    Knife: [AssassinKnife],
    Pistol: [
        AssassinPistol_1,
        AssassinPistol_2,
        AssassinPistol_3,
        AssassinPistol_4,
        AssassinPistol_5,
        AssassinPistol_6,
        AssassinPistol_7,
        AssassinPistol_8,
        AssassinPistol_9
    ],
    AKM: [
        AssassinLongGun_1,
        AssassinLongGun_2,
        AssassinLongGun_3,
        AssassinLongGun_4,
        AssassinLongGun_5,
        AssassinLongGun_6,
        AssassinLongGun_7,
        AssassinLongGun_8,
        AssassinLongGun_9
    ],
    DB: [
        AssassinShortGun_1,
        AssassinShortGun_2,
        AssassinShortGun_3,
        AssassinShortGun_4,
        AssassinShortGun_5,
        AssassinShortGun_6,
        AssassinShortGun_7,
        AssassinShortGun_8,
        AssassinShortGun_9
    ],
    Perk: [
        AssassinPerk_1,
        AssassinPerk_2,
        AssassinPerk_3,
        AssassinPerk_4
    ],
    MP5: [],
    F1: [],
    R870: [],
    Axe: [],
    M1A: [],
    BaseballBat: [],
    RPG: []
}

export const SURVIVOR_ASSETS = {
    Knife: [SurvivorKnife],
    Pistol: [
        SurvivorPistol_1,
        SurvivorPistol_2,
        SurvivorPistol_3,
        SurvivorPistol_4,
        SurvivorPistol_5,
        SurvivorPistol_6,
        SurvivorPistol_7,
        SurvivorPistol_8,
        SurvivorPistol_9
    ],
    R870: [],
    AKM: [],
    DB: [],
    Perk: [],
    MP5: [
        SurvivorLongGun_1,
        SurvivorLongGun_2,
        SurvivorLongGun_3,
        SurvivorLongGun_4,
        SurvivorLongGun_5,
        SurvivorLongGun_6,
        SurvivorLongGun_7,
        SurvivorLongGun_8,
        SurvivorLongGun_9
    ],
    F1: [],
    Axe: [],
    M1A: [],
    BaseballBat: [],
    RPG: []
}

export const KILLER_ASSETS = {
    Knife: [KillerKnife],
    Pistol: [
        KillerPistol_1,
        KillerPistol_2,
        KillerPistol_3,
        KillerPistol_4,
        KillerPistol_5,
        KillerPistol_6,
        KillerPistol_7,
        KillerPistol_8,
        KillerPistol_9
    ],
    AKM: [],
    DB: [
        KillerShortGun_1,
        KillerShortGun_2,
        KillerShortGun_3,
        KillerShortGun_4,
        KillerShortGun_5,
        KillerShortGun_6,
        KillerShortGun_7,
        KillerShortGun_8,
        KillerShortGun_9
    ],
    Perk: [],
    MP5: [],
    F1: [
        KillerLongGun_1,
        KillerLongGun_2,
        KillerLongGun_3,
        KillerLongGun_4,
        KillerLongGun_5,
        KillerLongGun_6,
        KillerLongGun_7,
        KillerLongGun_8,
        KillerLongGun_9
    ],
    R870: [],
    Axe: [],
    M1A: [],
    BaseballBat: [],
    RPG: []
}

export const ROOKIE_ASSETS = {
    Knife: [SurvivorKnife],
    Pistol: [
        RookiePistol_1,
        RookiePistol_2,
        RookiePistol_3,
        RookiePistol_4,
        RookiePistol_5,
        RookiePistol_6,
        RookiePistol_7,
        RookiePistol_8,
        RookiePistol_9
    ],
    R870: [
        RookieShortGun_1,
        RookieShortGun_2,
        RookieShortGun_3,
        RookieShortGun_4,
        RookieShortGun_5,
        RookieShortGun_6,
        RookieShortGun_7,
        RookieShortGun_8,
        RookieShortGun_9
    ],
    AKM: [],
    DB: [],
    Perk: [],
    MP5: [],
    F1: [],
    Axe: [],
    M1A: [],
    BaseballBat: [],
    RPG: []
}

export const COMMANDER_ASSETS = {
    Knife: [CommanderKnife],
    Pistol: [
        CommanderPistol_1,
        CommanderPistol_2,
        CommanderPistol_3,
        CommanderPistol_4,
        CommanderPistol_5,
        CommanderPistol_6,
        CommanderPistol_7,
        CommanderPistol_8,
        CommanderPistol_9
    ],
    R870: [],
    AKM: [],
    DB: [],
    Perk: [],
    MP5: [],
    F1: [],
    Axe: [],
    M1A: [
        CommanderLongGun_1,
        CommanderLongGun_2,
        CommanderLongGun_3,
        CommanderLongGun_4,
        CommanderLongGun_5,
        CommanderLongGun_6,
        CommanderLongGun_7,
        CommanderLongGun_8,
        CommanderLongGun_9
    ],
    BaseballBat: [
        CommanderShortGun_1,
        CommanderShortGun_2,
        CommanderShortGun_3,
        CommanderShortGun_4,
        CommanderShortGun_5,
        CommanderShortGun_6,
        CommanderShortGun_7,
        CommanderShortGun_8,
        CommanderShortGun_9
    ],
    RPG: []
}

export const VETERAN_ASSETS = {
    Knife: [],
    Pistol: [
        VeteranPistol_1,
        VeteranPistol_2,
        VeteranPistol_3,
        VeteranPistol_4,
        VeteranPistol_5,
        VeteranPistol_6,
        VeteranPistol_7,
        VeteranPistol_8,
        VeteranPistol_9
    ],
    R870: [],
    AKM: [
        VeteranLongGun_1,
        VeteranLongGun_2,
        VeteranLongGun_3,
        VeteranLongGun_4,
        VeteranLongGun_5,
        VeteranLongGun_6,
        VeteranLongGun_7,
        VeteranLongGun_8,
        VeteranLongGun_9
    ],
    DB: [],
    Perk: [
        VeteranPerk_1,
        VeteranPerk_2,
        VeteranPerk_3,
        VeteranPerk_4
    ],
    MP5: [],
    F1: [],
    Axe: [
        VeteranAxe_1,
        VeteranAxe_2,
        VeteranAxe_3,
        VeteranAxe_4,
        VeteranAxe_5,
        VeteranAxe_6,
        VeteranAxe_7,
        VeteranAxe_8,
        VeteranAxe_9
    ],
    M1A: [],
    BaseballBat: [],
    RPG: [
        VeteranShortGun_1,
        VeteranShortGun_2,
        VeteranShortGun_3,
        VeteranShortGun_4,
        VeteranShortGun_5,
        VeteranShortGun_6,
        VeteranShortGun_7,
        VeteranShortGun_8,
        VeteranShortGun_9
    ]
}

export const AMATEUR_ITEM = {
    title: "AMATEUR",
    description: "Knife + Pistol",
    assets: AMATEUR_ASSETS,
    fontSize: 25,
    fontSizeMobile: 39
}

export const ASSASSIN_ITEM = {
    title: "ASSASSIN",
    description: "Knife + AK47 + Pistol + Shotgun + 1 random perk",
    assets: ASSASSIN_ASSETS,
    fontSize: 15,
    fontSizeMobile: 23
}

export const SURVIVOR_ITEM = {
    title: "SURVIVOR",
    description: "Knife + MP5 + Pistol",
    assets: SURVIVOR_ASSETS,
    fontSize: 20,
    fontSizeMobile: 31
}

export const KILLER_ITEM = {
    title: "ZOMBIE KILLER",
    description: "Knife + F1 + Pistol + Shotgun + 4 perks + Grenade",
    assets: KILLER_ASSETS,
    fontSize: 15,
    fontSizeMobile: 23
}

export const ROOKIE_ITEM = {
    title: "ROOKIE",
    description: "Knife + R870 + Pistol",
    assets: ROOKIE_ASSETS,
    fontSize: 20,
    fontSizeMobile: 31
}

export const COMMANDER_ITEM = {
    title: "COMMANDER",
    description: "Knife + M1A + Pistol + BaseballBat",
    assets: COMMANDER_ASSETS,
    fontSize: 15,
    fontSizeMobile: 23
}

export const VETERAN_ITEM = {
    title: "VETERAN",
    description: "AKM + RPG + Pistol + Axe + 1 random perk",
    assets: VETERAN_ASSETS,
    fontSize: 15,
    fontSizeMobile: 23
}

export const INSTANCE_ITEMS = [AMATEUR_ITEM, SURVIVOR_ITEM, ASSASSIN_ITEM, KILLER_ITEM, ROOKIE_ITEM, COMMANDER_ITEM, VETERAN_ITEM];