import React from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'
import { getLinkSwap } from '../../utils/common'
// import { BASE_EXCHANGE_URL } from '../../config'

import orangeGlow from '../../assets/img/buttonBg/orange-glow.png';
import orangeInner from '../../assets/img/buttonBg/orangeRoot.png';
import silverGlow from '../../assets/img/buttonBg/gray-glow.png';
import silverInner from '../../assets/img/buttonBg/silverRoot.png'

import './style.css'

const NotEnoughTokensModal = ({
  isVisible,
  onClose,
  heading,
  pool,
  isMobile
}) => {
  return (
    <Modal
      centered
      width={isMobile ? '1100rem' : '374rem'}
      title={heading}
      visible={isVisible}
      onCancel={onClose}
      wrapClassName={'not-enough-tokens-modal'}
    >
      <div className='body-description'>
        <Info>
          <Title>Insufficient {pool.coinSymbol} balance</Title>
          <Description>You will need to buy {pool.coinSymbol} to stake in this pool!</Description>
        </Info>
      </div>

      <ButtonContainer>
        <ButtonOrange
          onClick={() =>
            window.open(getLinkSwap(), '_blank')
          }
        >
          <img src={orangeGlow} alt="" className="orange-glow" />
          <img src={orangeInner} alt="" className="orange-inner" />
          <span className={'btn-label'}>Buy {pool.coinSymbol}</span>
        </ButtonOrange>

        <ButtonSilver onClick={onClose}>
          <img src={silverGlow} alt="" className="silver-glow" />
          <img src={silverInner} alt="" className="silver-inner" />
          <span className={'btn-label'}>Close Windows</span>
        </ButtonSilver>

        {/* <ButtonSubmit>
          <div className='background'>
            <Button
              // onClick={() => window.open(`${BASE_EXCHANGE_URL}/#/swap?outputCurrency=${pool.stakeTokenAddress}`)}
              onClick={() =>
                window.open('https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x310C8F00b9dE3c31Ab95ea68feb6C877538f7947&chainId=1', '_blank')
              }
            >
              Buy {pool.coinSymbol}
            </Button>
          </div>

          <div className='background-silver'>
            <Button onClick={onClose}>
              Close Windows
            </Button>
          </div>
        </ButtonSubmit> */}
      </ButtonContainer>
    </Modal>
  )
}

export default NotEnoughTokensModal

const Info = styled.div`
`

const Title = styled.div`
  font-size: 18rem;
  margin-bottom: 20rem;
  font-weight: bold;
  color: #df1e33;

  @media (max-width: 767px) {
    font-size: 45rem;
  }
`

const Description = styled.div`
  font-size: 18rem;
  color: #ffffff;

  @media (max-width: 767px) {
    font-size: 38rem;
  }
`

const ButtonContainer = styled.div`
  padding: 20rem 15rem 30rem;
  position: relative;

  @media (max-width: 767px) {
    padding: 40rem 30rem 60rem;
  }
`;

const ButtonOrange = styled.div`
  height: 35rem;
  width: 100%;
  margin-bottom: 15rem;

  position: relative;
  cursor: pointer;

  :hover {
    transform: scale(1.015);
    transition: all .2s;
  }

  @media (max-width: 767px) {
    height: 70rem;
    margin-bottom: 70rem;
  }

  .orange-glow {
    width: 100%;
  }

  .orange-inner {
    position: absolute;
    top: 0.5rem;
    left: 50%;
    transform: translateX(-50%);

    width: 94%;
  }

  .btn-label {
    position: absolute;
    left: 50%;
    top: 92%;
    transform: translate(-50%, -50%);
    z-index: 50;

    font-size: 15rem;
    font-weight: 600;
    text-align: center;
    color: #000;
    margin-bottom: 0;

    @media (max-width: 767px) {
      top: 140%;
      font-size: 40rem;
    }
  }
`

const ButtonSilver = styled.div`
  height: 35rem;
  width: 100%;
  margin-bottom: 10rem;

  position: relative;

  position: relative;
  cursor: pointer;

  :hover {
    transform: scale(1.015);
    transition: all .2s;
  }

  @media (max-width: 767px) {
    height: 70rem;
    margin-bottom: 100rem;
  }

  .silver-glow {
    width: 100%;
  }

  .silver-inner {
    position: absolute;
    top: 0.5rem;
    left: 50%;
    transform: translateX(-50%);

    width: 94%;
  }

  .btn-label {
    position: absolute;
    left: 50%;
    top: 92%;
    transform: translate(-50%, -50%);
    z-index: 50;

    font-size: 15rem;
    font-weight: 600;
    text-align: center;
    color: #000;
    margin-bottom: 0;

    @media (max-width: 767px) {
      top: 140%;
      font-size: 40rem;
    }
  }
`;