import React from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Lottie from 'react-lottie';
import cn from 'classnames';
import styled from 'styled-components';

import close from '../img/close.png';
import leftFn from '../img/left-fn.png';
import rightFn from '../img/right-fn.png';
import button from '../img/button.png';
import bloodBg from '../img/Items/amateur/Circle_Blood.png';

import amateurSparkle from '../../../lotties/amateur_sparkle.json';

import { exportMintItem } from '../utils';

const Wrapper = styled.div`
    padding-bottom: 30px;

    @media (max-width: 767px) {
        padding-bottom: 60rem;
    }

    .layer-right {
        position: absolute;
        right: 0;
        top: 45%;
        transform: translateY(-50%);
        width: 107rem;
        height: 145rem;

        @media (max-width: 767px) {
            width: 227rem;
            height: 290rem;
        }
    }

    .layer-left {
        position: absolute;
        left: 10.5rem;
        top: 65rem;
        width: 72rem;
        height: 70.5rem;

        @media (max-width: 767px) {
            top: 125rem;
            width: 165rem;
            height: 165rem;
        }
    }

    .header-nav {
        display: flex;
        justify-content: flex-end;
        position: relative;

        padding-bottom: 33rem;

        @media (max-width: 767px) {
            padding-bottom: 79rem;
        }

        .close {
            position: absolute;
            width: 22.5rem;
            height: 22.5rem;
            margin: 15rem;
            cursor: pointer;

            &:hover {
                transform: scale(1.005);
                transition: all 1s;
            }

            @media (max-width: 767px) {
                top: 15rem;
                right: 20rem;
                width: 55rem;
                height: 55rem;
            }
        }
    }

    .mint-image {
        display: flex;
        justify-content: center;

        @media (max-width: 767px) {
        }
    }

    .mint-info {
        padding: 0 30rem;
        position: relative;
        z-index: 100;
        text-align: center;

        @media (max-width: 767px) {
            padding: 0 85rem;
        }

        h2 {
            font-family: Poppins;
            font-size: 22.5rem;
            font-weight: bold;
            line-height: 1.2;
            text-align: center;
            color: #fff;
            margin: 0 10px 10px 10px;

            @media (max-width: 767px) {
                font-size: 50.5rem;
                margin: 0 0 20rem 0;
            }
        }

        p {
            font-family: Poppins;
            font-size: 12.5rem;
            line-height: 1.4;
            text-align: center;
            color: #fff;

            @media (max-width: 767px) {
                font-size: 30.5rem;
            }
        }
    }

    .btn-container {
        margin: 0 30rem;

        &:active {
            transform: scale(0.9);
            transition: all 0.2s;
        }

        @media (max-width: 767px) {
            margin: 0 60rem;
        }
        
        .mint-btn {
            height: 40rem;
            border-radius: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            @media (max-width: 767px) {
                height: 90rem;
            }

            span {
                font-family: Poppins;
                font-size: 15rem;
                font-weight: 600;
                line-height: 1.33;
                text-align: center;
                color: #fff;

                @media (max-width: 767px) {
                    font-size: 36rem;
                }
            }
        }
    }
`;

const MintContent = styled.div`
    width: 100%;

    .info {
        display: flex;
        justify-content: center;
        position: relative;
        padding: 0 0 9.5rem;

        .mintBg {
            width: 200rem;
            height: 231.5rem;
            position: relative;

            @media (max-width: 767px) {
                width: 480rem;
                height: 540rem;
            }

            @media (min-width: 1024px) and (max-width: 1279px) {
            }

            @media (min-width: 1280px) {
            }
        }

        .knife {
            position: absolute;
            z-index: 40;

            &.amateurKnife {
                width: 200rem;

                @media (max-width: 767px) {
                    width: 470rem;
                }
            }

            &.survivorKnife {
                width: 220rem;
                height: 240rem;
                bottom: 10rem;
                left: 42.5%;
                transform: translateX(-42.5%);

                @media (max-width: 767px) {
                    width: 520rem;
                    height: 560rem;

                    bottom: 10rem;
                }
            }

            &.assassinKnife {
                width: 199rem;
                bottom: 12rem;
                right: 100rem;

                @media (max-width: 767px) {
                    width: 500rem;
                    right: 210rem;
                }
            }

            &.killerKnife {
                bottom: 10rem;
                width: 229rem;
                right: 85rem;

                @media (max-width: 767px) {
                    width: 520rem;
                    right: 180rem;
                    bottom: 10rem;
                }
            }
        }

        .pistol {
            position: absolute;
            z-index: 30;

            &.amateurPistol {
                width: 200rem;
                z-index: 40;

                @media (max-width: 767px) {
                    width: 470rem;
                }
            }

            &.survivorPistol {
                width: 225rem;
                bottom: 13rem;
                left: 65rem;

                @media (max-width: 767px) {
                    width: 520rem;
                    left: 140rem;
                    bottom: 18rem;
                }
            }

            &.assassinPistol {
                bottom: 8rem;
                width: 215rem;

                @media (max-width: 767px) {
                    width: 520rem;
                    bottom: 0;
                }
            }

            &.killerPistol {
                bottom: 14rem;
                width: 230rem;

                @media (max-width: 767px) {
                    width: 530rem;
                    bottom: 15rem;
                }
            }
        }

        .shortGuns {
            position: absolute;
            top: 0;
            width: 179rem;
            z-index: 20;

            &.amateurShort {

            }

            &.survivorShort {
                width: 179rem;

                @media (max-width: 767px) {
                    width: 300rem;
                }
            }

            &.assassinShort {
                top: 0rem;
                right: 100rem;
                width: 210rem;

                @media (max-width: 767px) {
                    width: 470rem;
                    right: 230rem;
                    top: 10rem;
                }
            }

            &.killerShort {
                top: -4rem;
                width: 230rem;
                right: 78rem;

                @media (max-width: 767px) {
                    width: 500rem;
                    right: 220rem;
                    top: 0rem;
                }
            }
        }

        .longGuns {
            position: absolute;
            z-index: 10;

            &.amateurLong {
                @media (max-width: 767px) {
                    width: 300rem;
                    right: 107rem;
                }
            }

            &.survivorLong {
                width: 219rem;
                right: 97rem;
                top: -10rem;

                @media (max-width: 767px) {
                    width: 520rem;
                    right: 217rem;
                }
            }

            &.assassinLong {
                top: -5rem;
                right: 98.5rem;
                width: 210rem;

                @media (max-width: 767px) {
                    width: 500rem;
                    right: 220rem;
                    top: -10rem;
                }
            }

            &.killerLong {
                width: 220rem;
                top: -5rem;
                right: 85rem;

                @media (max-width: 767px) {
                    width: 545rem;
                    right: 184rem;
                    top: -20rem;
                }
            }
        }

        .perks {
            position: absolute;
            width: 250rem;
            height: 280rem;
            bottom: 1rem;
            right: 85rem;

            z-index: 30;

            @media (max-width: 767px) {
                width: 550rem;
                height: 580rem;
                right: 200rem;
                bottom: 4rem;
            }
        }
    }
`;


function Step4(props) {
    const { item, onCancel, mintedRes = {} } = props;

    const mintItem = exportMintItem(mintedRes);

    return (
        <Wrapper>
            <img src={leftFn} alt="left" className="layer-left" />

            <div className="header-nav">
                <img src={close} alt="close" onClick={() => onCancel()} className="close" />
            </div>


            <div className="mint-image">
                <MintContent>
                    <div className="info">
                        <img src={bloodBg} alt="bg" className="mintBg" />

                        {!isEmpty(mintItem.assets?.Knife) && (
                            <img
                                src={mintItem.assets?.Knife}
                                alt="knife"
                                className={cn('knife', {
                                    'amateurKnife': mintItem.title === 'AMATEUR',
                                    'survivorKnife': mintItem.title === 'SURVIVOR',
                                    'assassinKnife': mintItem.title === 'ASSASSIN',
                                    'killerKnife': mintItem.title === 'ZOMBIE KILLER',
                                })}
                            />
                        )}

                        {!isEmpty(mintItem.assets?.Pistol) && (
                            <img
                                src={mintItem.assets?.Pistol}
                                alt="pistol"
                                className={cn('pistol', {
                                    'amateurPistol': mintItem.title === 'AMATEUR',
                                    'survivorPistol': mintItem.title === 'SURVIVOR',
                                    'assassinPistol': mintItem.title === 'ASSASSIN',
                                    'killerPistol': mintItem.title === 'ZOMBIE KILLER',
                                })}
                            />
                        )}

                        {!isEmpty(mintItem.assets['DB']) && (
                            <img
                                src={mintItem.assets['DB']}
                                alt="short"
                                className={cn('shortGuns', {
                                    'amateurShort': mintItem.title === 'AMATEUR',
                                    'survivorShort': mintItem.title === 'SURVIVOR',
                                    'assassinShort': mintItem.title === 'ASSASSIN',
                                    'killerShort': mintItem.title === 'ZOMBIE KILLER'
                                })}
                            />
                        )}

                        {!isEmpty(mintItem.assets['F1']) && (
                            <img
                                src={mintItem.assets['F1']}
                                alt="long"
                                className={cn('longGuns', {
                                    'amateurLong': mintItem.title === 'AMATEUR',
                                    'survivorLong': mintItem.title === 'SURVIVOR',
                                    'assassinLong': mintItem.title === 'ASSASSIN',
                                    'killerLong': mintItem.title === 'ZOMBIE KILLER'
                                })}
                            />
                        )}

                        {!isEmpty(mintItem.assets['AKM']) && (
                            <img
                                src={mintItem.assets['AKM']}
                                alt="long"
                                className={cn('longGuns', {
                                    'amateurLong': mintItem.title === 'AMATEUR',
                                    'survivorLong': mintItem.title === 'SURVIVOR',
                                    'assassinLong': mintItem.title === 'ASSASSIN',
                                    'killerLong': mintItem.title === 'ZOMBIE KILLER'
                                })}
                            />
                        )}

                        {!isEmpty(mintItem.assets['MP5']) && (
                            <img
                                src={mintItem.assets['MP5']}
                                alt="long"
                                className={cn('longGuns', {
                                    'amateurLong': mintItem.title === 'AMATEUR',
                                    'survivorLong': mintItem.title === 'SURVIVOR',
                                    'assassinLong': mintItem.title === 'ASSASSIN',
                                    'killerLong': mintItem.title === 'ZOMBIE KILLER'
                                })}
                            />
                        )}

                        {!isEmpty(mintItem.assets?.Perk) && (
                            <img
                                src={mintItem.assets?.Perk}
                                alt="perks"
                                className={cn('perks')}
                            />
                        )}

                        <Lottie
                            options={{
                                loop: true,
                                autoplay: false,
                                animationData: amateurSparkle
                            }}
                            style={{
                                transition: `height 0.05s`,
                                position: 'absolute',
                                zIndex: 70
                            }}
                            height={'100%'}
                            isClickToPauseDisabled={true}
                        />
                    </div>
                </MintContent>
            </div>

            <div className="mint-info">
                <h2>Successfully Minted {item.nameLower} Package</h2>
                <p>Congrats {item.nameLower}! You're ready to survive the Apocalypse. We have added your weapon loadout to your arsenal & wallet.</p>
            </div>

            <div className="btn-container">
                <Link to={`/nft/${mintedRes.tokenId}`}>
                    <div
                        className="mint-btn"
                        style={{
                            background: `url(${button}) no-repeat center center / cover`
                        }}
                        onClick={() => onCancel()}
                    >
                        <span>Continue</span>
                    </div>
                </Link>
            </div>

            <img src={rightFn} alt="right" className="layer-right" />
        </Wrapper>
    );
}

export default Step4;