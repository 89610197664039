import React from 'react'
import styled from 'styled-components'
import Animations from '../Animations'
import loadingData from '../../lotties/loading.json'
import BGLoadingMobile from '../../assets/img/bg_loading_mobile.jpg'
import BGLoadingDesktop from '../../assets/img/bg_loading_desktop.jpg'

const LoadingWrapper = styled.div`
  position: ${props => props.isCustom ? 'static' : 'fixed'};
  width: ${props => props.isCustom ? '130px' : '100%'};
  height: ${props => props.isCustom ? '130px' : '100%'};
  z-index: 1;
  background-image: url(${BGLoadingDesktop});
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
  background-color: #000000;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  ${({ loaded }) =>
    loaded &&
    `
    animation-name: run;
    animation-duration: 0.5s;
    `}
  @keyframes run {
    0% {
      left: 0;
    }
    100% {
      left: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    background-image: url(${BGLoadingMobile});
    background-position: center;
    background-size: cover;
  }
`

const LoadingContent = styled.div`
  width: 360px;
  @media screen and (max-width: 768px) {
    width: 230px;
  }
`

const Loading = ({ loaded, isCustom, wrapClassname }) => {
  return (
    <LoadingWrapper loaded={loaded} isCustom={isCustom} className={wrapClassname}>
      <LoadingContent>
        <Animations animationData={loadingData} />
      </LoadingContent>
    </LoadingWrapper>
  )
}

export default Loading
