import React, { useEffect, useState, useContext } from 'react'
import Lottie from 'react-lottie'
import BigNumber from 'bignumber.js'
import { useDispatch } from 'react-redux'
import { get, isEmpty } from 'lodash'
import cn from 'classnames';
// import { Progress } from 'antd';

import Image from '../../../Image';
import StakeProjectModal from '../../../StakingProjectModal'
import { SuccessModalContainer } from '../../../SuccessModal';
import SuccessMintStakeModal from '../../../SuccessMintStakeModal'
import StakingInfomationModal from '../../../StakingInfomationModal'
import WithdrawClaimScheduleModal from '../../../WithdrawClaimScheduleModal';
import ButtonStaking from './ButtonStaking'
import InfoLockPeriod from './InfoLockPeriod'
import InfoStakingRight from './InfoStakingRight'
import SuccessInfo from './SuccessInfo'
import TransactionLoading from '../../../TransactionLoading'
import TitleStaking from './TitleStaking';
import { toggleWalletModal, setToast } from '../../../../actions/common'
import { useGetApiPrice } from '../../../../state/hooks'
import { onApprove } from '../../../../hooks/onApprove'
import { useTokenContract } from '../../../../hooks/useContract'
import { getEnvDisableButton, getDescription } from '../../../../utils/common'
import { switchNetwork } from '../../../../actions/user'
import {
  handleResult,
  getExplorerUrl,
  formatNumber,
  getMonthsShort,
  getMonths,
  getPercentString,
  getPercentNumber,
  getInfoProgress
} from '../../../../utils/common';
import {
  PREFIX_NOT_EQUAL_BN,
  DEFAULT_ERR_MESSAGE,
  DEFAULT_MINT_STAKE_DATA,
} from '../../../../utils/constants';
import NotEnoughTokensModal from '../../../NotEnoughTokensModal'
import {
  fetchDataContractById,
  fetchDataContractByIdInterval,
  fetchDataContractByIdWithoutConnect
} from '../../../../actions/pools';
import { IntervalContext } from '../../../../context/IntervalContext';
import { isAllowStakeCheck, isAllowEnableCheck } from './itemHelper';

import imgInfomation from '../../../../assets/img/information.png';
import defaultImgPool from '../../../../assets/img/RookiePool.png';
import Wrapper, {
  Banner, Info, ButtonControl, ButtonUnlockWallet,
  Background, LabelMintProgress, TitleDescription,
  BannerHeader, InfoGroupBottom, ImageBackground, ProgressWrapper,
  LabelProgress, ProgressBar, ProgressInfo, ImgItem,
  styleImageLottie, styleImageLottieTablet, styleImageLottieMobile,
  ViewOpenSea,
  // styleImageLottieSparkle
} from './styles';

export default function StakingItem(props) {
  const refeshPage = () => window.location.reload()

  const dispatch = useDispatch();
  const { intervalAddListener, intervalRemoveListener } = useContext(IntervalContext);

  const {
    pool = {},
    pools,
    account,
    chainId,
    viewOpenSea,

    isMobile,
    isTablet,
    isConnected
  } = props;

  const {
    id,
    apy,
    lottie,
    poolTitle,
    background,
    description,
    stakeTokenSymbol,
    rewardTokenSymbol,

    // totalStaked,
    listDeposit,
    lockDuration,
    openseaUrl = '',

    isLotte,
    isLoading,
    isFetchContract,
    isFetchContractWithoutConnect
  } = pool;
  // console.log('🚀 ~ file: index.jsx ~ line 69 ~ StakingItem ~ pool', pool);

  const myEarnings = new BigNumber(pool?.myEarnings || 0)
  const stakedBalance = new BigNumber(pool?.stakedBalance || 0)
  const totalStakedConvert = new BigNumber(pool?.totalStaked || 0)
  const allowance = new BigNumber(pool?.allowance || 0)
  const stakingTokenBalance = new BigNumber(pool?.stakingTokenBalance || 0)
  const progress = new BigNumber(pool?.progress || 0)
  const total = new BigNumber(pool?.total || 0)

  const isLoadingInternal = !isFetchContract || isLoading === true;

  const [isVisibleStakeModal, setIsVisibleStakeModal] = useState(false)
  const [isVisibleTokensModal, setIsVisibleTokensModal] = useState(false)
  const [isVisibleExit, setIsVisibleExit] = useState(false)
  const [isVisibleInfoModal, setIsVisibleInfoModal] = useState(false)
  const [isVisibleTransaction, setIsVisibleTransaction] = useState(false)
  const [isVisibleWithdrawSchedule, setIsVisibleWithdrawSchedule] = useState(false)
  const [isMintStake, setIsMintStake] = useState(false);

  const [requestedApproval, setRequestedApproval] = useState(false)
  const [mintStakeData, setMintStakeData] = useState(DEFAULT_MINT_STAKE_DATA);
  const [dataProcessing, setDataProcessing] = useState(DEFAULT_ERR_MESSAGE);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSuccessMintStake, setIsSuccessMintStake] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [transaction, setTransaction] = useState('');
  const [hash, setHash] = useState('');

  const isAllowStake = isAllowStakeCheck(pool);
  const isAllowEnable = isAllowEnableCheck(pool);

  const stakingTokenPrice = useGetApiPrice(stakeTokenSymbol) ?? 0
  const stakingTokenContract = useTokenContract(pool.stakeTokenAddress)

  useEffect(() => {
    // Without Connect
    if (isEmpty(account) && isConnected === false && (!isFetchContractWithoutConnect || isFetchContractWithoutConnect === false)) {
      dispatch(fetchDataContractByIdWithoutConnect(id))
      return;
    }

    if (isEmpty(account) && isConnected === false && isFetchContractWithoutConnect === true) {
      const key = `fetchDataContractWithoutConnectByIdInterval-${id}`
      intervalAddListener(
        key,
        () => dispatch(fetchDataContractByIdWithoutConnect(id)),
        10
      );
      return () => intervalRemoveListener(key);
    }

    // Connected
    if (!isEmpty(account) && isConnected === true && isFetchContract === false) {
      dispatch(fetchDataContractById(account, id))
    }

    if (!isEmpty(account) && isConnected === true && isFetchContract === true) {
      const key = `fetchDataContractByIdInterval-${account}-${id}`
      intervalAddListener(
        key,
        () => dispatch(fetchDataContractByIdInterval(account, id)),
        10
      );
      return () => intervalRemoveListener(key);
    }
  }, [dispatch, account, isConnected, pool, isFetchContract, id, intervalAddListener, intervalRemoveListener, isFetchContractWithoutConnect])

  const onClose = () => {
    setIsVisibleStakeModal(false)
    setIsVisibleExit(false)
    setIsVisibleTokensModal(false)
    setIsVisibleInfoModal(false)
    setIsVisibleTransaction(false)
    setIsAction(false);
    setIsVisibleWithdrawSchedule(false);
    setIsSuccessMintStake(false)
    setIsSuccess(false);
    setHash('')
    setIsMintStake(false);
  }

  const onCloseSuccessStake = () => {
    onClose();
    setIsSuccess(true);
    setDataProcessing(DEFAULT_ERR_MESSAGE)
  }

  const onCloseSuccessMintStake = () => {
    onClose();
    setIsSuccessMintStake(true);
    setDataProcessing({
      title: 'You successfully minted & staked',
      text: `Congratulations, you have staked ${formatNumber(mintStakeData?.stakeAmount || 0, '', 2)} UNDEAD & Minted ${mintStakeData.mintNumber} Weapon loadouts. You can now view your loadouts on the "My Weapons" screen.`
    })
  }

  const callBackStakeProcessing = (hashInput) => {
    setHash(hashInput)
    setIsAction(true)
    if (isMintStake) {
      setDataProcessing({
        title: 'Staking & Minting Weapons',
        text: 'Please wait while we stake your UNDEAD & mint your weapon NFTs. This process can take a couple minutes.'
      })
    } else {
      setDataProcessing({
        title: 'Staking UNDEAD Tokens',
        text: 'Please wait while we stake your UNDEAD. This process can take a couple minutes.'
      })
    }
  }

  const loadingStake = (isLoadingInternal, isMintStake) => {
    if (isLoadingInternal) {
      setIsSuccess(false);
      setIsVisibleTransaction(true);
      if (isMintStake) {
        setDataProcessing({ title: 'Receiving Transaction', text: 'Approve the transaction in your wallet to stake UNDEAD and mint your weapon NFTs.' })
      } else {
        setDataProcessing({ title: 'Receiving Transaction', text: 'Approve the transaction in your wallet to stake UNDEAD in the staking pool.' })
      }
    } else {
      setIsSuccess(false);
      setIsAction(false);
      setIsSuccessMintStake(false);
      setDataProcessing(DEFAULT_ERR_MESSAGE)
    }
  }

  const loadingExit = (isLoadingInternal) => {
    if (isLoadingInternal) {
      setIsSuccess(false);
      setIsAction(true)
      setDataProcessing({
        title: 'Withdrawing UNDEAD',
        text: 'Please wait while we withdraw your UNDEAD. This process can take a couple minutes.'
      })
    } else {
      setDataProcessing({ title: '', text: 'You successfully withdrew UNDEAD' })
      onClose();
      setIsSuccess(true);
    }

  }


  const onEventApprove = async () => {
    try {
      setRequestedApproval(true)
      setIsAction(true)
      setDataProcessing({
        title: 'Approving the staking pool',
        text: 'Please wait while we approve to stake & mint your weapon NFTs. This process can take a couple of minutes.'
      })
      // setIsVisibleTransaction(true);
      // setDataProcessing({ title: 'Receiving Transaction', text: 'Approve the transaction in your wallet to stake UNDEAD and mint your weapon NFTs.' })
      await onApprove(dispatch, stakingTokenContract, id, account, pools,
        handleResultApprove,
        (hashCb) => {
          setHash(hashCb)
        });
    } catch (e) {
      onClose();
    }
  }

  const handleResultApprove = (receipt, error = null) => {
    handleResult(() => {
      dispatch(setToast({ title: 'Approve Timeout', detail: error?.message }));
      setRequestedApproval(false)
      onClose();
    }, () => {
      dispatch(setToast({ title: 'Error', detail: 'Please try again. Confirm the transaction and make sure you are paying enough gas!' }));
      setRequestedApproval(false)
      onClose();
    }, () => {
      dispatch(setToast(null));
      setRequestedApproval(false)
      onClose();

      setDataProcessing({ title: '', text: 'Successfully approved' })
      setIsSuccess(true);
    }, receipt, error)
  }

  const onDeposit = () => {
    if (stakingTokenBalance.gt(0.01)) {
      setIsVisibleStakeModal(true)
    }

    if ((listDeposit || [])?.length > 0) {
      setIsVisibleStakeModal(true)
      return;
    }

    setIsVisibleTokensModal(true)
  }

  return (
    <>
      <Wrapper>

        {/* HEADER GROUP */}
        <Banner>
          <BannerHeader>
            <InfoLockPeriod
              title={`Lock`}
              content={getMonthsShort(lockDuration) === 'N/A' ? '0 Mo' : getMonthsShort(lockDuration)}
              isMobile={isMobile}
              isTablet={isTablet}
              isLoading={isLoadingInternal}
            />
            <InfoStakingRight
              title={'APY'}
              pool={pool}
              stakingTokenPrice={stakingTokenPrice}
              content={apy}
              isMobile={isMobile}
              isTablet={isTablet}
              // isLoading={isLoadingInternal}
            />
          </BannerHeader>


          <Background>
            <ImageBackground src={background} alt='' />
            {isLotte ? (
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: lottie,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  }
                }}
                style={isMobile ? styleImageLottieMobile : isTablet ? styleImageLottieTablet : styleImageLottie}
                // style={isMobile ? styleImageLottieMobile : styleImageLottie}
              />
            ) : (
              <ImgItem>
                <Image src={lottie ? lottie : defaultImgPool} />
              </ImgItem>
            )}

            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: pool.lottieSparkle,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                }
              }}
              // style={styleImageLottieSparkle}
              style={isMobile ? styleImageLottieMobile : styleImageLottie}
            />
          </Background>

          {openseaUrl && (
            <ViewOpenSea
              desktopSize={viewOpenSea?.size?.width}
              mobileSize={viewOpenSea?.size?.mobileWidth}
              onClick={() => window.open(openseaUrl, '_blank')}
            >
              <img
                src={viewOpenSea?.src}
                alt=""
                className={cn('view-btn')}
              />
            </ViewOpenSea>
          )}
        </Banner>

        {/* TITLE */}
        <TitleStaking
          title={poolTitle}
          isLoading={isLoadingInternal}
          imgInfomation={imgInfomation}
          setIsVisibleInfoModal={setIsVisibleInfoModal}
        />
        <LabelMintProgress>Mint Progress</LabelMintProgress>
        <TitleDescription>
          {getDescription(description, total, progress)}
        </TitleDescription>

        {/* PROGRESS GROUP */}
        <ProgressWrapper>
          <LabelProgress>{`${getPercentString(total, progress, isLoadingInternal)}%`}</LabelProgress>

          <ProgressBar>
            <div className={'progressInner'} style={{ width: `${getPercentNumber(total, progress, isLoadingInternal)}%` }} />
            {/* <Progress percent={getPercentNumber(total, progress, isLoadingInternal)} showInfo={false} /> */}
          </ProgressBar>
        </ProgressWrapper>
        <ProgressInfo>{`${getInfoProgress(total, progress, isLoadingInternal)} ${stakeTokenSymbol}`}</ProgressInfo>

        {/* INFO GROUP */}
        <Info>
          <InfoGroupBottom>
            <SuccessInfo
              title={'Lock Period'}
              content={getMonths(lockDuration) === 'N/A' ? '0 Month' : getMonths(lockDuration)}
              isDate={true}
              isMobile={isMobile}
              isTablet={isTablet}
              isLoading={isLoadingInternal}
            />
            <SuccessInfo
              title={'Total Staked'}
              content={totalStakedConvert.gt(0) ? formatNumber(totalStakedConvert || 0, PREFIX_NOT_EQUAL_BN, 2) : new BigNumber(0)}
              unit={` ${stakeTokenSymbol}`}
              isMobile={isMobile}
              isTablet={isTablet}
              isLoading={isLoadingInternal}
            />
            <SuccessInfo
              title={'My Stake'}
              content={stakedBalance.gt(0) ? formatNumber(stakedBalance || 0, PREFIX_NOT_EQUAL_BN, 2) : new BigNumber(0)}
              unit={` ${stakeTokenSymbol}`}
              isMobile={isMobile}
              isTablet={isTablet}
              isLoading={isLoadingInternal}
            />
            <SuccessInfo
              title={'My Earnings'}
              content={myEarnings.gt(0) ? formatNumber(myEarnings || 0, PREFIX_NOT_EQUAL_BN, 2) : new BigNumber(0)}
              unit={` ${rewardTokenSymbol}`}
              isMobile={isMobile}
              isTablet={isTablet}
              isLoading={isLoadingInternal}
            />
          </InfoGroupBottom>
        </Info>

        <ButtonGroup   {...props}
          {...{
            dispatch, isLoadingInternal, requestedApproval,
            isVisibleExit, setIsVisibleExit, isAllowStake, isAllowEnable,
            onDeposit, onEventApprove, refeshPage
          }}
        />

        {/* -- POP-UP GROUP -- */}
        {isVisibleTokensModal && <NotEnoughTokensModal
          heading={`${stakeTokenSymbol} required`}
          pool={pool}
          isVisible={isVisibleTokensModal}
          onClose={onClose}
          isMobile={isMobile}
        />}

        {isVisibleStakeModal && <StakeProjectModal
          heading={'Stake in Pool'}
          isHideHeader={true}
          stakingMax={stakingTokenBalance}
          stakingTokenBalanceParam={stakingTokenBalance}
          allowance={allowance}
          stakingTokenPrice={+stakingTokenPrice}
          account={account}
          pools={pools}
          pool={pool}
          onClose={onClose}
          onCloseSuccess={onCloseSuccessStake}
          onCloseSuccessMintStake={onCloseSuccessMintStake}
          loadingStake={loadingStake}
          setTransaction={setTransaction}
          onShowWithdrawSchedule={() => setIsVisibleWithdrawSchedule(true)}
          isMobile={isMobile}
          isTablet={isTablet}
          isVisible={isVisibleStakeModal}
          isLoading={isLoadingInternal}

          isUnstake={false}
          isStake={true}
          isMintStake={isMintStake}
          setIsMintStake={setIsMintStake}
          setMintStakeData={setMintStakeData}
          callBackStakeProcessing={callBackStakeProcessing}
        />}


        {isVisibleInfoModal && <StakingInfomationModal
          pool={pool}
          isVisible={isVisibleInfoModal}
          stakingTokenPrice={+stakingTokenPrice}
          onClose={onClose}
          isMobile={isMobile}
          isTablet={isTablet}
          isLoading={isLoadingInternal}
        />}

        {isVisibleTransaction && <TransactionLoading
          isVisible={isVisibleTransaction}
          title={dataProcessing.title}
          text={dataProcessing.text}
          onClose={onClose}
          isMobile={isMobile} />}

        {/* Processing Modal Stake */}
        {isSuccess && <SuccessModalContainer
          chainId={chainId}
          visible={isSuccess}
          isSuccess={true}
          title={dataProcessing.title}
          text={dataProcessing.text}
          link={`${getExplorerUrl(`0x${parseInt(chainId).toString(16)}`)}/tx/${hash}`}
          onCancel={() => setIsSuccess(false)}
          isMobile={isMobile}
          hash={hash}
          isShowButtonClose={false}
        />}

        {/* Success Modal Stake */}
        {isAction && <SuccessModalContainer
          chainId={chainId}
          visible={isAction}
          isSuccess={false}
          title={dataProcessing.title}
          text={dataProcessing.text}
          link={`${getExplorerUrl(`0x${parseInt(chainId).toString(16)}`)}/tx/${hash}`}
          onCancel={() => { }}
          isMobile={isMobile}
          hash={hash}
          isShowButtonClose={true}
        />}

        {/* Success Modal Mint+stake */}
        {isSuccessMintStake && <SuccessMintStakeModal
          visible={isSuccessMintStake}
          chainId={chainId}
          title={dataProcessing.title}
          text={dataProcessing.text}
          link={`${getExplorerUrl(`0x${parseInt(chainId).toString(16)}`)}/tx/${transaction?.transactionHash}`}
          transactionHash={transaction?.transactionHash}
          onCancel={() => setIsSuccessMintStake(false)}
          isMobile={isMobile}
          isConnected={isConnected}
          imagePool={lottie}
        />}


        {isVisibleWithdrawSchedule && <WithdrawClaimScheduleModal
          account={account}
          pool={pool}
          pools={pools}
          isVisible={isVisibleWithdrawSchedule}
          onClose={onClose}
          onClosePopup={() => setIsVisibleWithdrawSchedule(false)}
          loadingFunc={loadingExit}
          onCloseLoading={() => loadingExit(false)}
          setTransaction={setTransaction}

          isMobile={isMobile}
          isTablet={isTablet}
          isLoading={isLoadingInternal}
          setHash={setHash}
        />}


        {/* {isVisibleProjectModalUnstake && <StakeProjectModal
          heading={'Unstake'}
          pool={pool}
          isVisible={isVisibleProjectModalUnstake}
          onClose={onClose}
          onCloseSuccess={onCloseSuccessUnStake}
          stakingMax={stakedBalance}
          stakingTokenPrice={+stakingTokenPrice}
          account={account}
          isUnstake={true}
          loadingStake={loadingStake}
          pools={pools}
        />}

        {isVisible && <StakeConfirmModal
          heading={'UNDEAD Harvest'}
          pool={pool}
          isVisible={isVisible}
          onClose={onClose}
          rewardDebt={pendingReward}
          currencyExchange={pendingReward.times(+stakingTokenPrice)}
          account={account}
          pools={pools}
          isMobile={isMobile}
        />} 

        {isVisibleExit && <StakeConfirmModal
          heading={'Withdraw & Claim'}
          pool={pool}
          isVisible={isVisibleExit}
          onClose={onClose}
          rewardDebt={stakedBalance}
          pendingReward={pendingReward}
          currencyExchange={stakedBalance.plus(pendingReward).times(+stakingTokenPrice)}
          account={account}
          isWithdraw={true}
          pools={pools}
          loadingFunc={loadingExit}
          setTransaction={setTransaction}
          isMobile={isMobile}
        />} */}

      </Wrapper>
    </>
  )
}




const ButtonGroup = (props) => {
  const { dispatch, account, isConnected, isLoadingInternal, chainId,
    requestedApproval, pool, isAllowEnable,
    onDeposit, onEventApprove, refeshPage
    // isVisibleExit, isAllowUnStake, setIsVisibleExit,
  } = props;

  const isDisableAllButton = getEnvDisableButton();
  const { stakeTokenSymbol, disable } = pool;
  // const stakedBalance = new BigNumber(pool?.stakedBalance || 0)
  const allowance = new BigNumber(pool?.allowance || 0)
  const poolChainId = get(pool, 'chainId')
  const wrongNetwork = parseInt(poolChainId) !== chainId

  if (isEmpty(account) || isConnected === false) {
    return <ButtonUnlockWallet>
      <ButtonStaking
        disabled={isDisableAllButton}
        title={'CONNECT WALLET'}
        event={async () => {
          if (wrongNetwork) {
            switchNetwork(poolChainId);
          }
          dispatch(toggleWalletModal(true))
        }}
      />
    </ButtonUnlockWallet>
  } else if (!isEmpty(account) && wrongNetwork && isConnected) {
    return <ButtonUnlockWallet>
      <ButtonStaking
        disabled={isDisableAllButton}
        title={'Switch Network'}
        event={async () => {
          if (!account) {
            dispatch(toggleWalletModal(true))
          } else if (wrongNetwork) {
            switchNetwork(poolChainId, refeshPage)
          }
        }}
      />
    </ButtonUnlockWallet>
  } else if (!isEmpty(account) && !wrongNetwork && isConnected) {
    return disable ? (
      <ButtonUnlockWallet>
        <ButtonStaking title={'Enable'} loading={requestedApproval} event={onEventApprove} disabled={isDisableAllButton || !isAllowEnable} />
      </ButtonUnlockWallet>
    ) :
      allowance.gt(0) ? (
        <ButtonControl>
          <ButtonStaking title={`Stake ${stakeTokenSymbol}`} pool={pool} event={onDeposit}
            disabled={isDisableAllButton} />
          {/* <ButtonStaking
          title={`Harvest`}
          content={pool.coinSymbol}
          event={() => setIsVisible(!isVisible)}
          disabled={isFrozen || !isAllowClaim || stakedBalance.eq(0)}
        />
        <ButtonStaking
          title={'Withdraw'}
          content={pool.coinSymbol}
          event={() => setIsVisibleProjectModalUnstake(!isVisibleProjectModalUnstake)}
          disabled={isFrozen || !isAllowUnStake ||  stakedBalance.eq(0)}
        />
          <ButtonStaking
            title={'Exit Withdraw & Claim'}
            event={() => setIsVisibleExit(!isVisibleExit)}
            disabled={!isAllowUnStake || stakedBalance.eq(0) || isDisableAllButton}
          /> */}
        </ButtonControl>
      ) : (
        <ButtonUnlockWallet>
          <ButtonStaking disabled={isLoadingInternal || isDisableAllButton || !isAllowEnable} 
            title={'Enable'} loading={requestedApproval} event={onEventApprove} />
        </ButtonUnlockWallet>
      )
  }
}