import { createActions } from "redux-actions";

import SettingApi from '../services/api/setting';

const { fetchAllSettingsRequest, fetchAllSettingsSuccess, fetchAllSettingsFail } = createActions({
    FETCH_ALL_SETTINGS_REQUEST: () => {},
    FETCH_ALL_SETTINGS_SUCCESS: data => ({ data }),
    FETCH_ALL_SETTINGS_FAIL: error => ({ error }),
});

export const fetchAllSettings = () => (dispatch) => {
    dispatch(fetchAllSettingsRequest());

    return SettingApi.allSettings()
        .then(({ data }) => {
            dispatch(fetchAllSettingsSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(fetchAllSettingsFail(error));
            return error;
        });
}