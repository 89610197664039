import { ethers } from 'ethers';
import fromExponential from 'from-exponential';
import { prettyNum, ROUNDING_MODE } from 'pretty-num';
import { formatNumber } from "./common";
import { get } from 'lodash';

export const formatUnitsToString = (amount, decimals = 18, prefix = '', fixedAmount = 0) => {
  return formatNumber(ethers.utils.formatUnits(String(amount || 0), decimals), prefix, fixedAmount);
};

export const formatUnits = (amount, decimals = 18) => {
  return ethers.utils.formatUnits(fromExponential(amount || 0), decimals);
};

export const getNameFromBlockchain = blockchain => {
  if (blockchain === 'ethereum' || blockchain === 'Ethereum') {
    return 'Ethereum';
  }
  if (blockchain === 'bsc') {
    return 'Binance Smart Chain';
  }
  return 'Polkadot';
};

// 1e18 => 1
export const fromWei = (amount, decimals = 18) => {
  return ethers.utils.formatUnits(prettyNum(fromExponential(amount || 0), {
    precision: decimals,
    roundingMode: ROUNDING_MODE.DOWN,
  }), decimals);
};


export const getInitWeb3Data = (chainId = '0x61') => {
  if (!chainId) {
    chainId = get(window, 'ethereum.chainId')
  }
  const data = {
    '0x61': {
      // bsc test net
      network: 'bsc',
      chainId: '0x61',
      gpadAddress: process.env.REACT_APP_GAMEPAD_TOKEN_ADDRESS_TESTNET,
      busdAddress: process.env.REACT_APP_BUSD_TOKEN_ADDRESS_TESTNET,
    },
    '0x38': {
      network: 'bsc',
      chainId: '0x38',
      gpadAddress: process.env.REACT_APP_GAMEPAD_TOKEN_ADDRESS_MAINNET,
      busdAddress: process.env.REACT_APP_BUSD_TOKEN_ADDRESS_MAINNET
    },
  }
  if (!data[chainId]) {
    return {}
  }
  return data[chainId]
}


export const verifyChainId = chainId => {
  if (typeof chainId !== 'number' && typeof chainId !== 'string') {
      return '0x38';
  }
  if (typeof chainId === 'number') {
      return '0x' + chainId.toString(16);
  }
  if (!chainId.startsWith('0x')) {
      return '0x' + parseInt(chainId.replace(/\D+/g, '')).toString(16);
  }
  return chainId;
};

export const isSameChainId = (chainId1, chainId2) => {
  if (verifyChainId(chainId1) === verifyChainId(chainId2)) {
      return true;
  }
  return false;
};

export const isSameAddress = (add1, add2) => {
  const addressFormat1 = (add1 || '').toUpperCase();
  const addressFormat2 = (add2 || '').toUpperCase();

  if (addressFormat1 === addressFormat2) {
      return true;
  }
  return false;
};

export const getProvider = chainId => {
  if (chainId === '0x1') {
      return process.env.REACT_APP_ETHER_WEB3_PROVIDER_MAINNET_HTTPS;
  }
  if (chainId === '0x38') {
      return process.env.REACT_APP_BSC_WEB3_PROVIDER_MAINNET_HTTPS;
  }
  if (chainId === '0x61') {
      return process.env.REACT_APP_BSC_WEB3_PROVIDER_TESTNET_HTTPS;
  }
  return process.env.REACT_APP_ETHER_WEB3_PROVIDER_TESTNET_HTTPS;
};

export const getSocketProvider = chainId => {
  if (chainId === '0x1') {
      return process.env.REACT_APP_ETHER_WEB3_SOCKET_PROVIDER_MAINNET_HTTPS;
  }
  if (chainId === '0x38') {
      return process.env.REACT_APP_BSC_WEB3_SOCKET_PROVIDER_MAINNET_HTTPS;
  }
  if (chainId === '0x61') {
      return process.env.REACT_APP_BSC_WEB3_SOCKET_PROVIDER_TESTNET_HTTPS;
  }
  return process.env.REACT_APP_ETHER_WEB3_SOCKET_PROVIDER_TESTNET_HTTPS;
};

export const getChainName = chainId => {
  chainId = verifyChainId(chainId);
  if (chainId === '0x1') {
      return 'Ethereum Mainnet';
  }
  if (chainId === '0x38') {
      return 'Binance Smart Chain Mainnet';
  }
  if (chainId === '0x61') {
      return 'Binance Smart Chain Testnet';
  }
  return 'Ethereum Goerli';
}

export const labelViewOnNetwork = () => {
  if (['0x1', '0x5'].includes(verifyChainId(window?.ethereum?.chainId || '0x38'))) {
      return 'View on Etherscan'
  }
  return 'View on BscScan'
}

export const getBlockExplorerUrls = chainId => {
  if (chainId === '0x1') {
      return 'https://etherscan.io/';
  }
  if (chainId === '0x38') {
      return 'https://bscscan.com/';
  }
  if (chainId === '0x61') {
      return 'https://testnet.bscscan.com/';
  }
  return 'https://rinkeby.etherscan.io/'
};

export const getAccountSymbol = (chainId = undefined) => {
  chainId = chainId ? verifyChainId(chainId) : verifyChainId(window?.ethereum?.chainId);
  if (['0x1', '0x5'].includes(chainId)) return 'ETH';
  return 'BNB';
}