import React from 'react'
import styled from 'styled-components'

const Title = styled.span`
  font-size: 15rem;
  line-height: 1.33;
  letter-spacing: 0.38rem;
  text-align: left;
  color: #fff;


  @media (max-width: 767px) {
    font-size: 51.2rem;
  }
`

const Content = styled.span`
  font-size: 17.5rem;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.44rem;
  color: #fff;


  @media (max-width: 767px) {
    font-size: 51.2rem;
  }
`

const Wrapper = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
`
const InfoLockPeriod = ({ title, content, isMobile = false, isTablet = false, isLoading = false }) => {
  const renderDataContent = () => {
    return (
      <Wrapper>
        <Title>{title}</Title>
        <Content>{content}</Content>
      </Wrapper>
    )
  }

  const renderLoadingDefault = () => {
    return <Wrapper>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </Wrapper>
  }

  return (
    isLoading ? renderLoadingDefault() : renderDataContent()
  )
}

export default InfoLockPeriod
