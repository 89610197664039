import React from 'react';

import Nav from '../Home/LandingPageHeader/Header';

import leftLayer from '../MintWeapons/img/left-test.png';
import rightLayer from './img/1.png';
import leftMan from './img/left-man.png';
import rightMan from './img/new.png';
import errorText from './img/404.png';
import notFound from './img/not-found.png';

import Wrapper from './styles';

function NotFound() {
    return (
        <Wrapper>
            <Nav isNotFound />

            <div className="layer">
                <img src={leftLayer} alt="left" className="left-bubble" />
                <img src={rightLayer} alt="right" className="right-bubble" />
            </div>

            <div className="content">
                <img src={errorText} alt="error" className="error" />
                <img src={notFound} alt="not-found" className="not-found" />

                <h2>The page you are looking for can not be found</h2>
            </div>

            <div className="footer">
                <img src={leftMan} alt="left" className="left-man" />
                <img src={rightMan} alt="right" className="right-man" />
            </div>
        </Wrapper>
    );
}

export default NotFound;