import React from 'react';

import cn from 'classnames';
import styled from 'styled-components';

import close from '../img/close.png';
import left from '../img/left-1.png';
import button from '../img/button.png';

import Hcapcha from '../../../components/Hcapcha'

const Wrapper = styled.div`
    padding-bottom: 30rem;

    @media (max-width: 767px) {
        padding-bottom: 60rem;
    }

    .header-nav {
        display: flex;
        justify-content: flex-end;
        position: relative;
        padding-bottom: 29rem;

        @media (max-width: 767px) {
            padding-bottom: 59rem;
        }

        .layer {
            position: absolute;
            left: 0;
            top: 0;
            width: 92rem;
            height: 113rem;
            border-top-left-radius: 12rem;

            @media (max-width: 767px) {
                width: 210rem;
                height: 260rem;
                top: 0rem;
            }
        }

        .close {
            position: absolute;
            width: 22.5rem;
            height: 22.5rem;
            margin: 15rem;
            cursor: pointer;
            z-index: 20;

            &:hover {
                transform: scale(1.005);
                transition: all 1s;
            }

            @media (max-width: 767px) {
                top: 15rem;
                right: 20rem;
                width: 55rem;
                height: 55rem;
            }
        }
    }

    .mint-image {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 2;

        @media (max-width: 767px) {
            min-height: 270rem;
        }

        img {
            width: 230rem;
            margin-right: ${props => (props.name === 'SURVIVOR' || props.name === 'ASSASSIN') ? 15 : 0}px;

            @media (max-width: 767px) {
                width: 530rem;
            }
        }
    }

    .mint-info {
        padding: 0 30rem;
        position: relative;
        z-index: 100;
        text-align: center;

        @media (max-width: 767px) {
            margin-top: 10rem;
            padding: 0 85rem;
        }

        h2 {
            font-family: Poppins;
            font-size: 22.5rem;
            font-weight: bold;
            line-height: 1.2;
            text-align: center;
            color: #fff;
            margin-top: 10rem;

            @media (max-width: 767px) {
                font-size: 52.5rem;
                margin: 0 0 20rem 0;
            }
        }

        p {
            font-family: Poppins;
            font-size: 15rem;
            line-height: 1.4;
            text-align: center;
            color: #fff;

            span {
                font-weight: 600;
            }

            @media (max-width: 767px) {
                font-size: 35.5rem;
            }
        }
    }

    .hcapcha-container {
        margin-bottom: 21rem;
        padding: 0 30rem;

        .alert {
            font-family: Poppins;
            font-size: 15rem;
            line-height: 1.33;
            text-align: center;
            color: #920304;
            margin-bottom: 12rem;

            @media (max-width: 767px) {
                font-size: 35.5rem;
                margin-bottom: 24rem;
            }
        }
    }

    .btn-container {
        margin: 0 30rem;

        &.actived {
            :active {
                transform: scale(0.9);
                transition: all 0.2s;
            }
        }

        @media (max-width: 767px) {
            margin: 0 60rem;
        }
        
        .mint-btn {
            height: 40rem;
            border-radius: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.not-allowed {
                cursor: not-allowed;
                opacity: 0.5;
                user-select: none;
            }

            @media (max-width: 767px) {
                height: 90rem;
            }

            span {
                font-family: Poppins;
                font-size: 15rem;
                font-weight: 600;
                line-height: 1.33;
                text-align: center;
                color: #fff;

                @media (max-width: 767px) {
                    font-size: 36rem;
                }
            }
        }
    }
`;

function Step1(props) {
    const {
        item,
        onCancel,
        onMint,
        hasCaptcha = {},
        getTokenCaptcha,
        tokenCaptcha = ''
    } = props;

    const styling = hasCaptcha?.value && {
        'not-allowed': !tokenCaptcha,
        'actived': tokenCaptcha
    }

    return (
        <Wrapper name={item.name}>
            <div className="header-nav">
                <img src={left} alt="left" className="layer" />
                <img src={close} alt="close" onClick={() => onCancel()} className="close" />
            </div>

            <div className="mint-image">
                <img src={item.img} alt='img' />
            </div>

            <div className="mint-info">
                <h2>Mint {item.nameLower} Package</h2>
                <p>{item.detail} <br /> <span>Playable in game</span></p>
            </div>

            {hasCaptcha?.value && (
                <div className="hcapcha-container">
                    <p className="alert">Please check the box below to procceed.</p>
                    <Hcapcha getToken={getTokenCaptcha} />
                </div>
            )}

            <div
                className="btn-container"
            >
                <div
                    className={cn('mint-btn', styling)}
                    style={{
                        background: `url(${button}) no-repeat center center / cover`
                    }}
                    onClick={() => !hasCaptcha?.value ? onMint() : (tokenCaptcha && onMint())}
                >
                    <span>Mint</span>
                </div>
            </div>
        </Wrapper>
    );
}

export default Step1;
