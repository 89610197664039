import { useEffect, useCallback } from 'react'
import { BREAKPOINTS } from '../utils/constants';
import {
  setIsMobile,
  setIsTablet,
  setIsDesktop,
} from '../actions/common';

export const useResize = (dispatch, isMobile, isTablet, isDesktop) => {
  
  const onResize = useCallback(() => {
    if ((window.innerWidth < BREAKPOINTS.MD_MIN) && !isMobile) {
      dispatch(setIsMobile())
    } else if ((window.innerWidth > BREAKPOINTS.SM_MAX) && (window.innerWidth < BREAKPOINTS.LG_MIN_CUSTOM) && !isTablet) {
      dispatch(setIsTablet())
    } else if ((window.innerWidth > BREAKPOINTS.LG_MIN_CUSTOM) && !isDesktop) {
      dispatch(setIsDesktop())
    }
  }, [dispatch, isMobile, isTablet, isDesktop]);


  useEffect(() => {
    onResize();
    window.addEventListener('onload', onResize);
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('onload', onResize);
    }
  }, [onResize, dispatch]);
}
