import { find, floor, get, map, parseInt, random, times } from 'lodash'
import moment from 'moment';
import momentTimezone from 'moment-timezone'
import Web3 from 'web3'
import { BigNumber } from 'bignumber.js'
import prettyNum, { ROUNDING_MODE, PRECISION_SETTING } from 'pretty-num';
import fromExponential from 'from-exponential';
import { ERROR_CODE_TIME_OUT, SEPARATOR_THOUSAND, PREFIX_NOT_EQUAL_BN, KEY_REPLACE_DESCRIPT } from './constants';
import { verifyChainId } from './web3';

function toPlainString(num) {
  return ('' + num).replace(/(-?)(\d*)\.?(\d+)e([+-]\d+)/, function (a, b, c, d, e) {
    return e < 0 ? b + '0.' + Array(1 - e - c.length).join(0) + c + d : b + c + d + Array(e - d.length + 1).join(0)
  })
}

export const shortAddress = address => {
  if (address.length < 10) return address
  const one = address.slice(0, 6)
  const two = address.slice(address.length - 5, address.length)
  return [one, two].join('...')
}

export const isAVAX = chainId => ['0xa86a', '0xa869'].includes(chainId)

export const contractAddress = chainId => {
  const address = {
    '0x1': Web3.utils.toChecksumAddress('0xc77aab3c6d7dab46248f3cc3033c856171878bd5'),
    '0x3': Web3.utils.toChecksumAddress('0x8733caa60eda1597336c0337efde27c1335f7530'),
    // BSC Mainntet not available yet
    '0x38': Web3.utils.toChecksumAddress('0x7536592bb74b5d62eb82e8b93b17eed4eed9a85c'),
    '0x61': Web3.utils.toChecksumAddress('0x8007d9ed1328027bc107c432e4ef554b0ceb4de8'),
    '0xa86a': Web3.utils.toChecksumAddress('0x88aDa02f6fCE2F1A833cd9B4999D62a7ebb70367'),
    '0xa869': Web3.utils.toChecksumAddress('0x91Ddb0842e40157BFa445e8020DA2A584098B780'),
  }
  return address[chainId] || address['0x1']
}

export const lockTokenContractAddress = chainId => {
  const address = {
    '0x1': contractAddress('0x1'),
    '0x3': contractAddress('0x3'),
    '0x38': contractAddress('0x38'),
    '0x61': contractAddress('0x61'),
    '0xa86a': contractAddress('0xa86a'),
    '0xa869': contractAddress('0xa869'),
  }
  return address[chainId] || address['0x1']
}

export const defaultFormatDate = 'MM/DD/YYYY'
const MEME_FEE_RATE = +process.env.REACT_APP_MEME_FEE_RATE || 0.05

export const oldContactAddress = {
  '0x1': '0xdbf72370021babafbceb05ab10f99ad275c6220a',
  '0x3': '0x7f207d66240fbe8db3f764f6056b6be8725cc90a',
}

export const convertTokenAmount = ({ decimals, value = 0, toFixed = 1, noFixed = false }) => {
  let denominator = '1'
  for (let i = 0; i < decimals; i++) {
    denominator += '0'
  }

  if (noFixed) {
    return value / Number(denominator)
  }

  return fixedFloat(toPlainString(value / Number(denominator)), toFixed)
}

const fixedFloat = (input, decimals) => {
  const arr = ('' + input).split('.')
  if (arr.length === 1) return decimals === 0 ? input : [input, '.', times(decimals, () => '0').join('')].join('')
  const int = arr[0]
  const max = arr[1].length
  const dec = arr[1].substr(0, decimals > max ? max : decimals)
  return decimals === 0
    ? int
    : [int, '.', dec.length >= decimals ? dec : dec + times(decimals - dec.length, () => '0').join('')].join('')
}

export const getTotalForSaleNoFee = totalForSale => {
  return +(totalForSale - MEME_FEE_RATE * totalForSale).toFixed(0)
  // return totalForSale
}
export const getView = (width) => {
  let newView = 'MobileView';
  if (width > 1220) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
};

export const getFileFromType = (data, type) => {
  return find(data, { type })
}

export const convertVestingToString = ({ value, type, duration }, symbol = '') => {
  // {value: 1000, type: 'weekly', duration: 1}
  if (type === 'weekly') {
    return [`Weekly for ${duration} weeks`, `${formatNumber((value / duration).toFixed(0), '', 0)} ${symbol}/week`]
  }
  if (type === 'monthly') {
    return [`Monthly for ${duration} months`, `${formatNumber((value / duration).toFixed(0), '', 0)} ${symbol}/month`]
  }

  return [`${duration} ${type}`, `${formatNumber((value / duration).toFixed(0), '', 0)} ${symbol}`]
}
export const convertDurationToMinuteString = value => {
  const duration = moment.duration(parseInt(value), 'seconds');
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  if (hours > 0) {
    return `${hours}h ${minutes}min ${seconds}sec`;
  }
  if (minutes > 0) {
    return `${minutes}min ${seconds}sec`;
  }
  return `${seconds}sec`;
};

export const convertDurationToMinute = (value, notShowMinute) => {
  const minute = floor(value / 60, 0);
  const seconds = floor(value % 60, 0);
  if (notShowMinute) {
    return `${minute < 10 ? `${minute}` : minute}`;
  }
  // return `${minute < 10 ? `0${minute}` : minute}:${seconds < 10 ? `0${seconds}` : seconds}`;
  return `${minute < 10 ? `${minute}` : minute}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

export const createDefaultUploadFile = (data, isSoundFile) => {
  let name = get(data, 'name', '');
  let url = get(data, 'url', '');
  const id = get(data, 'id', '');
  if (!isSoundFile) {
    const imageData = getFileFromType(data, 'medium', '');
    name = get(imageData, 'name', '');
    url = get(imageData, 'url', '');
  }
  // uid must be string number
  return [{
    uid: `${moment()
      .valueOf()}`, id, name, url, status: 'done',
  }];
};

export const createDefaultFileImage = data => {
  const id = get(data, 'id');
  const name = get(data, 'data.0.name');
  const url = get(data, 'data.0.url');

  if (!id) {
    return null;
  }
  return [{ uid: id, name, url, status: 'done', id }];
};

export const createDefaultFileMp4 = (data) => {
  const id = get(data, 'id');
  const name = get(data, 'data.0.name');
  const url = get(data, 'data.0.url');

  if (!id) {
    return null;
  }

  // uid must be string number
  return [{ uid: id, name, url, status: 'done', id }];
};

export const getDurationAudio = (file) => {
  return new Promise((resolve) => {
    const objectURL = URL.createObjectURL(file);
    const myAudio = new Audio([objectURL]);
    myAudio.addEventListener('canplaythrough', () => {
      URL.revokeObjectURL(objectURL);
      resolve({
        file,
        duration: myAudio.duration,
      });
    }, false);
  });
};

export const upperCaseFirstLetter = (text) => {
  return `${text.charAt(0)
    .toUpperCase()}${text.slice(1)}`;
};

export const getQueryParam = (name, defaultData = '') => {
  const q = window.location.search.match(new RegExp('[?&]' + name + '=([^&#]*)'));
  return q ? q[1] : defaultData;
};

export const isImage = url => (/(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/.test(url));

export const widthModal = (width) => {
  if (width < 768) {
    return width;
  }
  if (width > 767 && width < 992) {
    return width / 2 + 100;
  }
  if (width > 991 && width < 1025) {
    return width / 2;
  }
  if (width > 1024 && width < 1220) {
    return width / 2;
  }
  return width / 3;
};

export const getAvatarBg = () => {
  const number = random(1, 8);
  return {
    blue: `${process.env.PUBLIC_URL}/images/avatar-bg/${number}-blue.png`,
    gray: `${process.env.PUBLIC_URL}/images/avatar-bg/${number}-gray.png`,
  };
};

export const getHeightOffsetTopAnimations = () => {
  const height = document.getElementsByClassName('active-animation');
  const listHeightAnimation = map(height, ele => {
    const heightTemp = get(ele, 'offsetTop', 0);
    return heightTemp;
  });
  return listHeightAnimation;
};

export const formatCurrency = (currency, fixed = 2) => {
  if (!currency) {
    return '';
  }
  if (typeof currency === 'number') {
    return currency.toFixed(fixed).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }
  return currency
    // eslint-disable-next-line
    .replace(/[(a-zA-Z)\s\_\,\-]+/g, '')
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

export const formatBigNumber = (valueBN, prefix = '', fixed_amount = 2, thousands_separator = ',') => {
  const value = BigNumber.isBigNumber(valueBN) ? valueBN.toNumber() : valueBN;

  const newValue = normalizeValue(value);

  if (isNaN(value)) {
    return 'N/A';
  }

  const newAmount = prettyNum(fromExponential(newValue || 0), {
    precision: fixed_amount,
    precisionSetting: PRECISION_SETTING.FIXED,
    roundingMode: ROUNDING_MODE.DOWN,
  });

  const usePrefix = (new BigNumber(newAmount)).comparedTo(value) < 0;

  return (usePrefix ? prefix : '') + separateThousands(newAmount, thousands_separator);
};

export const isIncludeTilde = (content, prefix = '', fixed_amount = 2, thousands_separator = ',') => {
  if (!content) {
    return false;
  }

  if (BigNumber.isBigNumber(content)) {
    return formatBigNumber(content, prefix, fixed_amount, fixed_amount).includes(prefix);
  }

  return content.includes(prefix);
}

export const formatNumber = (value, prefix = '', fixed_amount = 2, thousands_separator = ',') => {
  const newValue = normalizeValue(value);

  if (isNaN(value)) {
    return 'NaN';
  }

  const newAmount = prettyNum(fromExponential(newValue || 0), {
    precision: fixed_amount,
    precisionSetting: PRECISION_SETTING.FIXED,
    roundingMode: ROUNDING_MODE.DOWN,
  });

  const usePrefix = (new BigNumber(newAmount)).comparedTo(value) < 0;

  return (usePrefix ? prefix : '') + separateThousands(newAmount, thousands_separator);
};



// coinChainId
// = 0: Ethereum
// = 1: Bsc
// = 2: Avax
export const getCoinChainId = chainId => {
  if (['0x1', '0x3'].includes(chainId)) {
    return 0
  } else if (['0x38', '0x61'].includes(chainId)) {
    return 1
  } else if (['0xa86a', '0xa869'].includes(chainId)) {
    return 2
  } else {
    return -1
  }
}

export const getTimeLeft = (start, end) => {
  const dateLocked = moment.unix(start)
  const unlockDate = moment.unix(end)
  const duration = moment.duration(unlockDate.diff(moment()))
  const years = duration.get('y')
  const months = duration.get('M')
  const days = duration.get('d')
  const hours = duration.get('h')
  const minutes = duration.get('m')
  const seconds = duration.get('s')

  const yearsString = `${years} ${years > 1 ? 'Years' : 'Year'}`
  const monthsString = `${months} ${months > 1 ? 'Months' : 'Month'}`
  const daysString = `${days} ${days > 1 ? 'Days' : 'Day'}`
  const hoursString = `${hours} ${hours > 1 ? 'Hours' : 'Hour'}`
  const minutuesString = `${minutes} ${minutes > 1 ? 'Minutes' : 'Minute'}`
  const secondsString = `${seconds} ${seconds > 1 ? 'Seconds' : 'Second'}`

  const response = {
    timeLeft: 0,
    timeLeftString: 'No Locks',
    format: 60,
    type: 'n',
    percent: 0,
  }

  if (years) {
    response.timeLeft = years
    response.timeLeftString = yearsString
    response.format = 10
    response.type = 'y'
  } else if (months) {
    response.timeLeft = months
    response.timeLeftString = monthsString
    response.format = 12
    response.type = 'M'
  } else if (days) {
    response.timeLeft = days
    response.timeLeftString = daysString
    response.format = 30
    response.type = 'd'
  } else if (hours) {
    response.timeLeft = hours
    response.timeLeftString = hoursString
    response.format = 24
    response.type = 'h'
  } else if (minutes) {
    response.timeLeft = minutes
    response.timeLeftString = minutuesString
    response.format = 60
    response.type = 'm'
  } else if (seconds) {
    response.timeLeft = seconds
    response.timeLeftString = secondsString
    response.format = 60
    response.type = 'm'
  }
  response.percentLeft = (unlockDate.diff(moment()) / unlockDate.diff(dateLocked)) * 100
  return response
}

function normalizeValue(value) {
  if (typeof value === 'string') {
    return parseFloat(value)
  }

  return value;
}

export function separateThousands(x, s) {
  const arrSplitValue = x.split('.');
  if (arrSplitValue && arrSplitValue.length < 2) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, s);
  }
  const firstNumber = arrSplitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, s);
  return `${firstNumber}.${arrSplitValue[1]}`;

  // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, s);
}

export function formatBlockchainAddress(address, precision = 4, precisionEnd) {
  if (!address) {
    return ''
  }

  return `${address.slice(0, precision + 2)}...${address.slice(precisionEnd ? -precisionEnd : -precision)}`
}

export const haveContract = chainId => ['0x1', '0x5', '0x38', '0x61'].includes(chainId);

export const getApologyContractAddress = () => {
  let address;

  process.env.REACT_APP_DEV === '1'
    ? address = `${process.env.REACT_APP_APOLOGY_CONTRACT_TESTNET}`
    : address = `${process.env.REACT_APP_APOLOGY_CONTRACT_MAINNET}`

  return address;
}

export const getApologyContractUrl = () => {
  let url;

  process.env.REACT_APP_DEV === '1'
    ? url = `${getExplorerUrl(process.env.REACT_APP_CHAIN_ID)}/token/${process.env.REACT_APP_APOLOGY_CONTRACT_TESTNET}`
    : url = `${getExplorerUrl(process.env.REACT_APP_CHAIN_ID)}/token/${process.env.REACT_APP_APOLOGY_CONTRACT_MAINNET}`

  return url;
}

export const getExplorerUrl = chainIdInput => {
  const chainId = verifyChainId(chainIdInput)
  const urls = {
    '0x1': process.env.REACT_APP_BLOCK_EXPLORER_URL_ETH_MAINNET,
    '0x5': process.env.REACT_APP_BLOCK_EXPLORER_URL_ETH,
    '0x38': process.env.REACT_APP_BLOCK_EXPLORER_URL_BSC_MAINNET,
    '0x61': process.env.REACT_APP_BLOCK_EXPLORER_URL_BSC_TESTNET,
  };
  if (haveContract(chainId)) {
    return urls[chainId];
  }
  return urls['0x5'];
};

export const getOpenSeaUrl = chainIdInput => {
  const chainId = verifyChainId(chainIdInput)
  const urls = {
    '0x1': process.env.REACT_APP_OPENSEA_MAINNET_HTTPS,
    '0x5': process.env.REACT_APP_OPENSEA_TESTNET_HTTPS,
    '0x38': process.env.REACT_APP_OPENSEA_MAINNET_HTTPS,
    '0x61': process.env.REACT_APP_OPENSEA_TESTNET_HTTPS,
  };
  if (haveContract(chainId)) {
    return `${urls[chainId]}/${process.env.REACT_APP_UNDEAD_NFT}`
  }
  return `${urls['0x5']}/${process.env.REACT_APP_UNDEAD_NFT}`;
};

export const getOpenSeaUrlByAddress = (chainIdInput, addressContractNft) => {
  const chainId = verifyChainId(chainIdInput)
  const urls = {
    '0x1': process.env.REACT_APP_OPENSEA_MAINNET_HTTPS,
    '0x5': process.env.REACT_APP_OPENSEA_TESTNET_HTTPS,
    '0x38': process.env.REACT_APP_OPENSEA_MAINNET_HTTPS,
    '0x61': process.env.REACT_APP_OPENSEA_TESTNET_HTTPS,
  };
  if (haveContract(chainId)) {
    return `${urls[chainId]}/${addressContractNft}`
  }
  return `${urls['0x5']}/${addressContractNft}`;
};

export const getProvider = chainId => {
  const address = {
    '0x1': 'https://mainnet.infura.io',
    '0x3': 'wss://restless-snowy-snowflake.ropsten.quiknode.pro/eaa781c59ad6621bb58f94a1c5fac1194ef72b7a/',
    '0x38': 'https://bsc-dataseed1.ninicoin.io',
    '0x61': 'https://data-seed-prebsc-1-s1.binance.org:8545/'
  };
  return address[chainId] || address['0x1'];
};

export const FIXED_APY_DEFAULT = process.env.REACT_FIXED_APY_DEFAULT || 10000;

export const STEP_ACTION = {
  STEP_INIT: 'INIT',
  STEP_FETCH_REST_DONE: 'FETCH_REST_DONE',
  STEP_CALL_CONTRACT_DONE: 'INTERVAL',

  STEP_FORCE_RELOAD: 'FORCE_RELOAD'
}

export const dateFormatString = 'MMM, DD YYYY - HH:mm z';
export const MAX_UINT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const isFakeListenProject = () => process.env.REACT_APP_ENABLE_FAKE_LISTEN_PROJECT === 'true';

export const LIST_LIMIT_LENGTH = 20;
export const MINIMUM_SEARCH_LENGTH = 3;

export const STAKING_CATEGORY_TYPES = {
  ALL: '',
  NEWEST: 'Newest',
  HIGHEST_APY: 'Highest APY',
  ENDED: 'Ended'
};

export const STAKING_TYPES = {
  LIVE: 'LIVE',
  COMPLETED: 'COMPLETED',
  UPCOMING: 'UPCOMING',
}


export function getOffsetData(data) {
  const isEnd = data.isEnd;
  const offsetNext = data.offset === null ? 0 : data.offset + LIST_LIMIT_LENGTH;

  return {
    isEnd,
    offsetNext
  }
}

export const handleResult = (handleTimeout, handleFailed, handleSuccess, receipt, error = null) => {
  // ERROR
  if (error) {
    // TIMEOUT
    if (error?.code === ERROR_CODE_TIME_OUT) {
      // user rejected tx or didn't go thru
      handleTimeout()
      return;
    }

    // FAILED
    if (error?.receipt?.transactionHash) {
      // user rejected tx or didn't go thru
      handleFailed()
      return;
    }

    // Reject
    // user rejected tx or didn't go thru
    handleFailed();
    return;
  }


  // SUCCESS
  if (receipt && receipt?.transactionHash) {
    handleSuccess();
  }
}

export const isNotEqual = (value, prefix = '', fixed_amount = 2) => {
  const newValue = normalizeValue(value);

  if (isNaN(value)) {
    return 'N/A';
  }

  const newAmount = prettyNum(fromExponential(newValue || 0), {
    precision: fixed_amount,
    roundingMode: ROUNDING_MODE.DOWN,
  });

  const usePrefix = (new BigNumber(newAmount)).comparedTo(value) < 0;

  return usePrefix ? prefix : '';
};

export const getPublicUrl = () => window.location.host.includes('localhost') ? '/' : process.env.REACT_APP_CDN_LINK + '/';

export const getEnvDisableButton = () => {
  const envDisableButton = process.env.REACT_APP_DISABLE_BUTTON_STAKE_PAGE;
  if (envDisableButton === 1 || envDisableButton === '1') {
    return true;
  }

  return false;
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatNumberEx = (value, prefix = '', fixed_amount = 2, thousands_separator = ',') => {
  const newValue = normalizeValue(value);

  if (isNaN(value)) {
    return 'N/A';
  }

  const newAmount = prettyNum(fromExponential(newValue || 0), {
    precision: fixed_amount,
    roundingMode: ROUNDING_MODE.DOWN,
  });

  const usePrefix = (new BigNumber(newAmount)).comparedTo(value) < 0;

  return (usePrefix ? prefix : '') + separateThousands(newAmount, thousands_separator);
};

export const getContractUndeadNFT = () => {
  const undeadNftAddress = process.env.REACT_APP_DEV === '1'
    ? process.env.REACT_APP_UNDEAD_NFT_TESTNET_ADDRESS
    : process.env.REACT_APP_UNDEAD_NFT_MAINNET_ADDRESS

  return (undeadNftAddress || '').toLowerCase();
}

export const splitAddress = (address = '') => {
  return `${address.substring(0, 5)}...${address.substr(address.length - 4)}`
}


export const convertContentFloat = (content) => {
  if (!content) {
    return 0;
  }

  if (typeof content === 'string') {
    const newValue = (content || '')
      .replaceAll(SEPARATOR_THOUSAND, '')
      .replaceAll(PREFIX_NOT_EQUAL_BN, '');

    return parseFloat(newValue)
  }

  return content;
}

export const compareTime = (time1, time2) => {
  let convertTime1 = time1;
  let convertTime2 = time2;
  if (isSecond(time1)) {
    convertTime1 = time1 * 1000;
  }
  if (isSecond(time2)) {
    convertTime2 = time2 * 1000;
  }

  return convertTime1 >= convertTime2;
}

export const isSecond = time => time / 1000000000 < 10;

export const convertDisplayTime = (time) => {
  if (!time) {
    return 'N/A'
  }
  // second
  const timeCheck = time / 1000000000;
  if (timeCheck < 10) {
    return 'N/A'
  }
  return momentTimezone(time).tz('America/New_York').format(dateFormatString)
}

// export const getMonths = (time) => {
//   if (!time) {
//     return 'N/A'
//   }
//   // second
//   const timeCheck = time / 1000000000;
//   if (timeCheck < 10) {
//     return 'N/A'
//   }

//   const now = moment().unix() * 1000;

//   const diffMonths = Math.round(moment(time).diff(now, 'months', true));

//   if (diffMonths === 1) {
//     return `${diffMonths} Month`
//   }

//   return `${diffMonths} Months`
// }


const getMonthNumberDuration = (time) => {
  if (!time) {
    return 0;
  }

  const { month } = convertSecondToDuration(time)

  return month;
}

const getDayNumberDuration = (time) => {
  if (!time) {
    return 0;
  }

  const { day } = convertSecondToDuration(time)

  return day;
}

export const getMonths = (time) => {
  const month = getMonthNumberDuration(time);

  if (month < 1) {
    const day = getDayNumberDuration(time);

    if (day <= 1) {
      return `${day} Day`
    }

    return `${day} Days`
  }

  if (month === 1) {
    return `${month} Month`
  }

  return `${month} Months`
}

/**
 * 
 * @param {*} t : second
 * @returns 
 */
export const convertSecondToDuration = (t) => {
  let year,
    month,
    day,
    hour,
    minute,
    second;

  let monthRemain,
    dayRemain,
    hourRemain,
    minuteRemain,
    secondRemain;

  second = t; // Math.floor(t / 1000);
  minute = Math.floor(second / 60);
  secondRemain = second % 60;
  hour = Math.floor(minute / 60);
  minuteRemain = minute % 60;
  day = Math.floor(hour / 24);
  hourRemain = hour % 24;
  month = Math.floor(day / 30);
  dayRemain = day % 30;
  year = Math.floor(month / 12);
  monthRemain = month % 12;

  return {
    year, month, day, hour, minute, second,
    monthRemain, dayRemain, hourRemain, minuteRemain, secondRemain
  };
}

/**
 * 
 * @param {*} t : second
 * @returns 
 */
 export const convertSecondToDurationEx= (t) => {
  let year,
    month,
    day,
    hour,
    minute,
    second;

  second = t; // Math.floor(t / 1000);
  minute = Math.floor(second / 60);
  second = second % 60;
  hour = Math.floor(minute / 60);
  minute = minute % 60;
  day = Math.floor(hour / 24);
  hour = hour % 24;
  month = Math.floor(day / 30);
  day = day % 30;
  year = Math.floor(month / 12);
  month = month % 12;

  return {
    year, month, day, hour, minute, second,
  };
}

export const getMonthsShort = (time) => {
  const month = getMonthNumberDuration(time);

  if (month < 1) {
    const day = getDayNumberDuration(time);
    return `${day}D`
  }

  return `${month}Mo`
}

export const getPercent = (total, progress, isLoadingInternal) => {
  if (isLoadingInternal) {
    return 0;
  }

  if (!progress || !total || !BigNumber.isBigNumber(total) || !BigNumber.isBigNumber(progress)) {
    return 0;
  }

  const rate = progress.div(total);
  const percent = rate.multipliedBy(100);

  return percent;
}


export const getPercentString = (total, progress, isLoadingInternal) => {
  const percent = getPercent(total, progress, isLoadingInternal);

  return formatNumber(percent || 0, '', 0)
}

export const getPercentNumber = (total, progress, isLoadingInternal) => {
  return getPercent(total, progress, isLoadingInternal);
}

export const getInfoProgress = (total, progress, isLoadingInternal) => {
  if (isLoadingInternal) {
    return `0/${formatNumber(total || 0, '', 0)}`;
  }

  return `${formatNumber(progress || 0, '', 0)}/${formatNumber(total || 0, '', 0)}`;
}


export const FORMAT_DATE_MMDDYY = 'DD/MM/YY'
export const formatDateByFormat = (date, formatString = FORMAT_DATE_MMDDYY) => {
  if (!date) {
    return 'N/A'
  }
  const dateConvert = new Date(date);
  return moment(dateConvert).format(formatString);
}

export const convertDisplayTimeByFormat = (time, formatString = FORMAT_DATE_MMDDYY) => {
  if (!time) {
    return 'N/A'
  }
  // second
  let timeParse = time;
  if (isSecond(timeParse)) {
    timeParse = timeParse * 1000;
  }
  return moment(timeParse).format(formatString);
}


export const replaceKey = (root, key, strReplace) => {
  return (root || '').replace(key, strReplace);
}

export const getDescription = (description, total, progress) => {
  if (!total || !progress) {
    if (!total) {
      return replaceKey(description, KEY_REPLACE_DESCRIPT, 0);
    }
    return replaceKey(description, KEY_REPLACE_DESCRIPT, total);
  }

  const value = total.minus(progress).integerValue(BigNumber.ROUND_UP);

  const valueFormat = numberWithCommas(formatNumber(value || 0, '', 0));

  return replaceKey(description, KEY_REPLACE_DESCRIPT, valueFormat);
}

export const getDescriptionByVariantSku = (variants) => {
  let resultStr = '';
  for (let i = 0; i < (variants||[]).length; i++) {
    if (i === 0){
      resultStr += `${(variants[i]?.itemSku || '')}`
      continue;
    }
    resultStr += ` + ${(variants[i]?.itemSku || '')}`
  }
  return resultStr
}

// https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x310C8F00b9dE3c31Ab95ea68feb6C877538f7947&chainId=mainnet&chain=mainnet
export const getLinkSwap = () =>{
  let link = '';
  if (process.env.REACT_APP_DEV === '1') {
    link = `${process.env.REACT_APP_LINK_SWAP}?inputCurrency=ETH&outputCurrency=${process.env.REACT_APP_UNDEAD_TOKEN_TESTNET_ADDRESS}&chainId=testnet&chain=goerli`
  } else {
    link = `${process.env.REACT_APP_LINK_SWAP}?inputCurrency=ETH&outputCurrency=${process.env.REACT_APP_UNDEAD_TOKEN_MAINNET_ADDRESS}&chainId=mainnet&chain=mainnet`
  }

  return link;
}