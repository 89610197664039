import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { StakingListContainer } from '../../../components/CommonStaking/StakingList'
import {
  fetchMorePoolData
} from '../../../actions/pools';

import Header from '../../Home/LandingPageHeader/Header'

import leftLayer from '../../../assets/img/leftLayer.png';
import rightLayer from '../../../assets/img/rightLayer.png';
// import layer from '../../../assets/img/bloodBackground.png';

import StakingUnavailableModal from '../StakingUnavailable';

const Wrapper = styled.div`
  background: #000000;
  width: 100%;
  min-height: 100vh;
  padding: 126rem 0 0 0;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    padding: 350rem 0 0 0;
  }
`
const Info = styled.div`
  margin: 0rem 95rem 27rem;
  position: relative;
  
  @media only screen and (max-width: 767px) {
    margin: 0rem 88rem 98rem;
  }
`
const Title = styled.div`
  font-size: 32.5rem;
  font-weight: bold;
  line-height: 0.92;
  text-align: left;
  color: #fff;

  margin-bottom: 14.5rem;

  @media (max-width: 767px) {
    font-size: 74rem;
    margin-bottom: 35rem;
  }
`
const Discription = styled.p`
  font-size: 15rem;
  line-height: 1.5;
  text-align: left;
  color: #fff;

  @media (max-width: 767px) {
    font-size: 35rem;
  }
`
const BubbleLayer = styled.div`
    position: relative;
    pointer-events: none;

    @media screen and (max-width: 767px) {
      display: none;
    }

    .left {
        position: absolute;
        left: -20rem;
        top: -110rem;
        width: 384rem;
        height: 720rem;
        opacity: 0.6;
    }

    .right {
        position: absolute;
        top: 75rem;
        opacity: 0.6;
        right: -30rem;

        width: 535.5rem;
    }

    .center {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        max-height: 600rem;
    }
`;


export function EarnAPYWeapons({ chainId, account, isConnected, isMobile, isTablet, isShowPopup, onClosePopup }) {
  const dispatch = useDispatch();

  const pools = useSelector(state => state.pools?.poolData?.items || []);
  const requesting = useSelector(state => state.pools?.poolData?.requesting);
  const requestingLoadMore = useSelector(state => state.pools?.requestingLoadMore);

  const {
    limit,
    skip,
    isEnd
  } = useSelector(state => state.pools?.poolData);

  const onLoadMore = () => dispatch(fetchMorePoolData());

  const renderBubbleLayer = () => (
    <BubbleLayer>
      <img src={leftLayer} alt="left" className="left" />
      <img src={rightLayer} alt="right" className="right" />
      {/* <img src={layer} alt="center" className="center" /> */}
    </BubbleLayer>
  );

  return (
    <Wrapper>
      {renderBubbleLayer()}

      <Header isStaking={true} />
  
      <Info>
        <Title>Earn APY and Weapons</Title>
        <Discription>
          Stake UNDEAD & get rewarded UNDEAD tokens. Stake minimum amount & mint a free NFT weapons package.
        </Discription>
      </Info>

      <StakingListContainer
        {...{
          isConnected,
          chainId,
          account,
          pools,
          requesting,

          limit,
          skip,
          isEnd,

          onLoadMore,
          requestingLoadMore,
          isMobile,
          isTablet
        }}
      />

      <StakingUnavailableModal
        isVisible={isShowPopup}
        onCancel={onClosePopup}
      />
    </Wrapper>
  )
}
