// import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components'

import rookieOpenSea from './img/rookie-opensea.png';
import commanderOpenSea from './img/commander-opensea.png';
import veteranOpenSea from './img/veteran-opensea.png';

import StakingItem from './StakingItem'

const Wrapper = styled.div``
const UL = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 90rem 22rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 28.5rem;

  // @media (min-width: 768px) and (max-width: 832px) {
  //   grid-template-columns: 1fr 1fr;
  // }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    padding: 0 89rem 53.5rem;
  }
`
const LI = styled.li``;

const openSeaButtons = [
  { id: '0', src: rookieOpenSea, size: { width: '92.5rem', mobileWidth: '205rem' }},
  { id: '1', src: commanderOpenSea, size: { width: '116.5rem', mobileWidth: '253rem' }},
  { id: '2', src: veteranOpenSea, size: { width: '96.5rem', mobileWidth: '213rem' }}
];

export default function StakingList(props) {
  const { pools } = props
  return (
    <Wrapper>
      <UL>
        {pools &&
          pools.map((item, i) => (
            <LI key={`${i}${item?.id ?? ''}`}>
              <StakingItem {...props}
                {...{
                  pool: item,
                  viewOpenSea: openSeaButtons[i]
                }} />
            </LI>
          ))}
      </UL>
    </Wrapper>
  )
}
