import React from 'react';
import { useSelector } from 'react-redux';
import styled from "styled-components";
import { Button, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons'
import cn from 'classnames';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

export default function ProviderList(props) {
  const { onSelect, onRepeat, selectedItem, requesting, error, items } = props
  const itemList = selectedItem ? [selectedItem] : items

  const { isMobile } = useSelector(state => state.common);
  const provider = window?.ethereum || window?.web3?.currentProvider;

  const handleAllowClick = (item) => {
    !selectedItem && onSelect && onSelect(item);
  }

  return (
    <ListWallets>
          {error && (
            <ErrorMessage>
              <WalletName>Error Connecting</WalletName>
              <TryAgain
                onClick={() => {
                  onRepeat && onRepeat(selectedItem)
                }}>
                Try again
              </TryAgain>
            </ErrorMessage>
          )}

          {requesting && (
            <Initialize>
              <div>
                <Spin indicator={antIcon} /> Initializing...
              </div>
            </Initialize>
          )}

          {itemList && itemList.map((item, i) => {
            let isAllow = true;

            if (item.name === 'MetaMask' && !provider?.isMetaMask) {
              isAllow = false;
            }

            if (item.name === 'GameStop' && !window?.gamestop?.isGamestop) {
              isAllow = false;
            }

            if (item.name === 'TrustWallet' && !provider?.isTrust) {
              isAllow = false;
            }

            return (
                <Wallet
                  isMobile={isMobile}
                  key={i}
                  onClick={() => isAllow && handleAllowClick(item)}
                  className={cn('', { 'not-allowed': !isAllow })}
                >
                  <WalletInfo>
                    <WalletName>{item.name}</WalletName>
                    {selectedItem && item.description && <WalletInfo>{item.description}</WalletInfo>}
                  </WalletInfo>
                  <WalletIcon src={item.picture} />
                </Wallet>
              )
            })
          }
        </ListWallets>
  )
}

const ListWallets = styled.div`
  display: flex;
  flex-direction: column;
`

const Wallet = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  padding: 15px;
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 8px;
  margin-bottom: 12px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    border-color: #df1e33;
  }

  &.not-allowed {
    cursor: not-allowed;
    opacity: 0.3;

    &:hover {
      border-color: #ffffff;
    }
  }
`

const WalletInfo = styled.div`
  flex: 1;
  color: #ffffff;
`

const WalletName = styled.div`
  font-weight: bold;
`

const WalletIcon = styled.img`
  max-width: 27px;
  border-radius: 6px;
`

const Initialize = styled.div`
  display: flex;
  justify-content: start;
  min-height: 60px;
  padding: 15px;
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 8px;
  margin-bottom: 12px;
  color: #ffffff;
  font-weight: bold;
`
const ErrorMessage = styled.div`
  display: flex;
  justify-content: start;
  min-height: 60px;
  padding: 15px;
  align-items: center;
  border: 1px solid #df1e33;
  border-radius: 8px;
  margin-bottom: 12px;
  color: #df1e33;
`

const TryAgain = styled(Button)`
  background: #e8edef;
  color: #111;
  margin-left: 10px;
  &:hover,
  &:active,
  &:focus {
    opacity: 0.75;
    background: #e8edef;
    color: #6f6f79;
    border-color: transparent;
  }
`
