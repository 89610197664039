import React from 'react'
import styled from 'styled-components'
import solider from './../assets/img/solider.png'
import bg_component_3 from './../assets/img/bg_component_3.jpg'
import laptop from './../assets/img/laptop.png'
import BGSectionMobile from './../assets/img/bg2-section-mobile.jpg'
import VideoAnimation from './Video'
import ReviewScreen from './../assets/video/lobby-screen-1.mp4'

const Wrapper = styled.div`
  background: #111114;
  position: relative;
  background-image: url(${bg_component_3});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 769px) {
    background: url(${BGSectionMobile});
    background-size: cover;
  }
`

const MainContent = styled.div`
  display: flex;
  z-index: 1;
  @media screen and (max-width: 768px) {
    position: unset;
  }
`

const LeftBlock = styled.div`
  width: 100%;
  padding: 64px 36px;

  @media screen and (min-width: 769px) {
    width: 66%;
    padding: 60px 0 50px 80px;
  }
  @media screen and (min-width: 1280px) {
    width: 66%;
    padding: 70px 0 50px 100px;
  }
  @media screen and (min-width: 1920px) {
    width: 70%;
    padding: 70px 0 50px 130px;
  }
  @media screen and (min-width: 2560px) {
    width: 70%;
    padding: 70px 0 50px 180px;
  }
`
const TextBlock = styled.div`
  margin-bottom: 25px;
  @media screen and (min-width: 1280px) {
    margin-bottom: 38px;
  }
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

const TextTitle = styled.div`
  font-size: 29px;
  color: #ffff;
  font-weight: 600;
  text-align: center;

  @media screen and (min-width: 769px) {
    font-size: 28px;
    text-align: left;
  }
  @media screen and (min-width: 1280px) {
    font-size: 32.5px;
    text-align: left;
  }
  @media screen and (min-width: 1440px) {
    font-size: 35px;
    text-align: left;
  }
  @media screen and (min-width: 1920px) {
    font-size: 45px;
    text-align: left;
  }
`

const TextDescription = styled.div`
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  font-size: 13px;
  padding: 0;

  @media screen and (min-width: 769px) {
    font-size: 14px;
    text-align: left;
    padding: 0;
  }
  @media screen and (min-width: 1280px) {
    font-size: 15px;
    text-align: left;
    padding: 0;
    padding-right: 10%;
  }
  @media screen and (min-width: 1440px) {
    font-size: 18px;
    text-align: left;
  }
  @media screen and (min-width: 1920px) {
    font-size: 23px;
    text-align: left;
  }
`

const ImgOne = styled.div`
  width: 100%;
  position: relative;
  @media screen and (min-width: 769px) {
    width: 100%;
    margin-left: -9%;
  }
  @media screen and (min-width: 1280px) {
    width: 103%;
    margin-left: -9%;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: auto;
  }
`
const RightBlock = styled.div`
  width: 34%;
  margin-top: 60px;
  position: relative;
  z-index: 2;
  img {
    position: absolute;
    height: 100%;
    width: auto;
    right: 30px;
    @media screen and (min-width: 1280px) {
      padding-right: 101px;
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const ComponentThree = () => {
  return (
    <Wrapper>
      <MainContent>
        <LeftBlock>
          <TextBlock>
            <TextTitle>Survive with Friends</TextTitle>
            <TextDescription>
              Bring the squad together and jump into endless waves of undead zombies. Choose to play with random players
              or your closest allies.
            </TextDescription>
          </TextBlock>
          <ImgOne>
            <img src={laptop} alt="" />
            <VideoAnimation video={ReviewScreen} />
          </ImgOne>
        </LeftBlock>
        <RightBlock>
          <img src={solider} alt="" />
        </RightBlock>
      </MainContent>
    </Wrapper>
  )
}

export default ComponentThree
