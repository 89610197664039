import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const Wrapper = styled.div`
    background: #0f1014;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 767px) {
        display: block;
        flex-direction: unset;
        align-items: unset;
        justify-content: unset;

        padding-top: 380rem;
    }

    .layer {
        .left-bubble {
            position: absolute;
            left: 0;
            top: 0;
            width: 31.25vw;
    
            @media screen and (max-width: 767px) {
                width: 69.5vw;
                height: 65.8vh;
                top: 80rem;
                left: -100rem;
            }
        }
    
        .right-bubble {
            position: absolute;
            right: -0.5vw;
            top: 3.6vh;
            width: 44.5vw;
    
            @media screen and (max-width: 767px) {
                top: unset;
                bottom: -7%;
                width: 90%;
                right: -2%;
            }
        }
    }

    .content {
        position: absolute;
        z-index: 10;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        @media screen and (max-width: 767px) {
            position: relative;
            top: unset;
            left: unset;
            transform: unset;
            margin-bottom: 50rem;

            padding: 0 174rem;
        }

        .error {
            width: 365rem;
            height: 193.5rem;

            @media screen and (max-width: 767px) {
                width: 832rem;
                height: 441rem;
            }
        }
        
        .not-found {
            width: 395.5rem;
            height: 91.5rem;

            @media screen and (max-width: 767px) {
                width: 895rem;
                height: 215rem;
            }
        }

        h2 {
            font-family: Poppins;
            font-size: 22.5rem;
            font-weight: bold;
            line-height: 1.89;
            text-align: center;
            color: #fff;
            margin-bottom: 0;
            text-transform: uppercase;

            @media screen and (max-width: 767px) {
                font-size: 52rem;
            }
        }
    }

    .footer {
        @media screen and (max-width: 767px) {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            height: 100%;
        }

        .left-man {
            position: absolute;
            top: 14%;
            left: -10%;
            width: 33.5%;

            @media screen and (max-width: 767px) {
                position: absolute;
                left: -21%;
                width: 80%;
                top: 0;
            }
        }

        .right-man {
            position: absolute;
            top: 13.05vh;
            right: -28.5vw;
            width: 76.5vw;

            @media screen and (max-width: 767px) {
                position: absolute;
                right: -80%;
                width: 200%;
                top: 0;
            }
        }
    }
`;

export default WithDirection(Wrapper);