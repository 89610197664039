import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const Wrapper = styled.div`
    .sold-out {
        position: relative;

        display: flex;
        justify-content: center;

        width: 110rem;
        height: 26rem;
        margin: 0 auto 0 auto;

        cursor: not-allowed;
        opacity: 0.7;

        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */

        @media (max-width: 767px) {
            height: 21px;
            width: 257rem;
        }
                    

        .bottom-mint-btn {
            position: absolute;
            top: 50%;

            height: 34rem;
            width: 100%;

            transform: translateY(-50%);

            @media (max-width: 767px) {
                height: 21px;
            }
        }

        .top-mint-btn {
            position: absolute;
            transform: translateY(-50%);
            top: 47%;

            width: 91%;
            height: 24rem;

            cursor: not-allowed;

            @media (max-width: 767px) {
                height: 21px;
            }
        }

        span {
            top: 50%;
            position: absolute;

            font-family: Poppins;
            font-size: 9.5rem;
            font-weight: 600;
            line-height: 1;
            text-align: center;

            color: #000;

            transform: translateY(-50%);
            cursor: not-allowed;

            @media (max-width: 767px) {
                font-size: 23rem;
            }
        }
    }

    .parent {
        display: flex;
        justify-content: center;

        width: 110rem;
        height: 26rem;
        margin: 0 auto 0 auto;

        position: relative;

        &:hover {
            transform: scale(1.010);
            transition: all 1s;

            span {
                top: 50%;
            }
        }
        
        @media (max-width: 767px) {
            height: 21px;
            width: 257rem;
        }

        &:active {
            transform: scale(0.9);
            transition: all 0.2s;
        }

        .bottom-mint-btn {
            position: absolute;
            top: 50%;

            width: 100%;
            height: 33rem;

            transform: translateY(-50%);

            @media (max-width: 767px) {
                height: 21px;
            }
        }

        .top-mint-btn {
            width: 91%;
            height: 24rem;

            position: absolute;
            transform: translateY(-50%);
            top: 50%;

            cursor: not-allowed;

            @media (max-width: 767px) {
                height: 21px;
            }
        }

        span {
            top: 50%;
            position: absolute;

            font-family: Poppins;
            font-size: 9.5rem;
            font-weight: 600;
            line-height: 1;
            text-align: center;

            color: #000;

            transform: translateY(-50%);
            cursor: pointer;
            
            @media screen and (max-width: 767px) {
                font-size: 23rem;
            }
        }
    }
`;
export default WithDirection(Wrapper);