import moment from 'moment';
import { formatNumber } from '../utils/common';
import { updateDataPool } from '../actions/pools'
import { storeTransactionLog } from '../actions/user';
import UseWeb3 from './useWeb3'
import { withdrawByDepositId, getPoolContractEx } from '../utils/callHelpers'

export const onWithdrawByDepositId = async (dispatch, sourceId, account, stakingPoolsData, depositId, amount, cb, callBackStakeProcessing = () => { }) => {
    const web3 = UseWeb3()
    const pool = stakingPoolsData.find(pool => pool.id === sourceId)
    const contract = getPoolContractEx(pool.stakeContractAddress, web3)

    const callbackLog = (transactionId) => {
        let log = {
            chainId: pool?.chainId,
            walletAddress: account,
            date: moment().valueOf(),
        };
        log.eventName = `Exit ${formatNumber(amount || 0, '~', 2)} ${pool?.rewardTokenSymbol} from "${pool?.poolTitle}" pool.`
        log.transactionHash = transactionId?.transactionHash;
        dispatch(storeTransactionLog(log))

        dispatch(updateDataPool(sourceId, account));
    }

    const transactionId = await withdrawByDepositId(contract, depositId, account, cb, callbackLog, callBackStakeProcessing);
    return transactionId;

}
