import { createAction } from "redux-actions";

const updatePricesSuccess = createAction('UPDATE_PRICES_SUCCESS');
export const fetchPrices = () => async (dispatch) => {
  try {
    const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=undead&vs_currencies=usd');
    const undeadData = (await response.json());

    const busdResp = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=busd&vs_currencies=usd');
    const busdData = await busdResp.json();
    dispatch(updatePricesSuccess({
      undead: undeadData['undead'] ? undeadData['undead']['usd'] : 0,
      busd: busdData['busd']['usd']
    }));
  } catch (e) {
    console.error(e);
  }
};
