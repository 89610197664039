import moment from 'moment';
import { formatNumber } from '../utils/common';
import { updateDataPool } from '../actions/pools'
import { storeTransactionLog } from '../actions/user';
import UseWeb3 from './useWeb3'
import { unstake, exit, getPoolContractEx } from '../utils/callHelpers'
import BigNumber from 'bignumber.js';

export const onUnstake = async (dispatch, sourceId, account, stakingPoolsData, amount, decimals, cb, callBackStakeProcessing = () => { }, isExit = false, isClaim = false) => {
    const web3 = UseWeb3()
    const pool = stakingPoolsData.find(pool => pool.id === sourceId)
    const contract = getPoolContractEx(pool.stakeContractAddress, web3)

    // EXIT
    if (isExit) {
        const callbackLog = (transactionId) => {
            let log = {
                chainId: pool?.chainId,
                walletAddress: account,
                date: moment().valueOf(),
            };
            const amountExit = (pool?.pendingReward || new BigNumber(0)).plus(pool?.stakedBalance);
            log.eventName = `Exit ${formatNumber(amountExit || 0, '~', 2)} ${pool?.rewardTokenSymbol} from "${pool?.poolTitle}" pool.`
            // log.display = `Exit ${formatNumber(pool?.stakedBalance || 0, '~', 2)} ${pool?.rewardTokenSymbol} from "${pool?.poolTitle}" pool.`
            log.transactionHash = transactionId?.transactionHash;
            dispatch(storeTransactionLog(log))
        }

        const transactionId = await exit(contract, amount, account, cb, callbackLog);
        dispatch(updateDataPool(sourceId, account));
        return transactionId;
    }

    // CLAIM
    if (isClaim) {
        const callbackLog = (transactionId) => {
            let log = {
                chainId: pool?.chainId,
                walletAddress: account,
                date: moment().valueOf(),
            };
            log.eventName = `Claim ${formatNumber(pool?.pendingReward || 0, '~', 2)} ${pool?.rewardTokenSymbol} from "${pool?.poolTitle}" pool.`
            // log.display = `Claim ${formatNumber(pool?.pendingReward || 0, '~', 2)} ${pool?.rewardTokenSymbol} from "${pool?.poolTitle}" pool.`
            log.transactionHash = transactionId?.transactionHash;
            dispatch(storeTransactionLog(log))
            dispatch(storeTransactionLog(log))
        }

        const transactionId = await unstake(contract, amount, account, cb, callbackLog);
        dispatch(updateDataPool(sourceId, account));
        return transactionId;
    }

    // UNSTAKE
    const callbackLog = (transactionId) => {
        let log = {
            chainId: pool?.chainId,
            walletAddress: account,
            date: moment().valueOf(),
        };
        log.eventName = `Unstake ${formatNumber(amount || 0, '~', 2)} ${pool?.rewardTokenSymbol} from "${pool?.poolTitle}" pool.`
        // log.display = `Unstake ${formatNumber(amount || 0, '~', 2)} ${pool?.rewardTokenSymbol} from "${pool?.poolTitle}" pool.`
        log.transactionHash = transactionId?.transactionHash;
        dispatch(storeTransactionLog(log))
    }

    const transactionId = await unstake(contract, amount, account, cb, decimals)
    dispatch(updateDataPool(sourceId, account, cb, callbackLog));
    return transactionId;

}
