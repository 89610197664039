import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import Lottie from 'react-lottie';

import leftLayer from '../MintWeapons/img/left-test.png';
import rightLayer from '../MintWeapons/img/right.png';
import leftMan from '../MintWeapons/img/leftman.png';
import rightMan from '../MintWeapons/img/rightman.png';
import rightArrow from '../MintWeapons/img/right-arrow.png';
import copyIcon from '../../assets/img/copy.png';

import undeadHeading from './img/undead-token.png';
import whitePaper from './img/whitepaper.png';
import nowTradingBig from './img/contract-address.png';
import undeadToken from '../../lotties/undead_token.json';

import Nav from '../Home/LandingPageHeader/Header';

import Wrapper, {
    BubbleLayer,
    HeaderBanner,
    ContentWrapper,
    DocumentWrapper
} from './styles';

function TokenAddress() {
    const [copied, setCopied] = useState(false);

    const {
        isMobile,
    } = useSelector(state => state?.common ?? {});

    const copyClipboard = (value) => {
        setCopied(true);
        navigator.clipboard.writeText(value);

        setTimeout(() => {
            setCopied(false);
        }, 2000)
    };

    const renderBubbleLayer = () => (
        <BubbleLayer>
            <section className="bubble">
                <img src={leftLayer} alt="left" className="left" />
            </section>

            <section className="bubble">
                <img src={rightLayer} alt="right" className="right" />
            </section>
        </BubbleLayer>
    );

    const renderHeaderBanner = () => (
        <HeaderBanner>
            <div className="header-layout">
                <section className="left-layer">
                    <img src={leftMan} alt="left" className="left-header" />
                </section>

                <section className="right-layer">
                    <img src={rightMan} alt="right" className="right-header" style={{ paddingRight: '20rem' }} />
                </section>
            </div>
        </HeaderBanner>
    );

    const renderDocument = () => (
        <DocumentWrapper>
            <div className="box">
                <img src={whitePaper} alt="icon" className="left" />

                <section className="center">
                    <h2>Undead Blocks Official WhitePaper Now Live</h2>
                    <p>Learn more about the Undead Blocks ecosystem on our official whitepaper.</p>
                </section>

                <a
                    href="https://undead-blocks-team.gitbook.io/undead-blocks-whitepaper/gameplay/introduction"
                    target="_blank"
                    rel="noreferrer"
                    className="read-btn"
                >
                    <p>White Paper</p>
                    <img src={rightArrow} alt="arrow" />
                </a>
            </div>
        </DocumentWrapper>
    );

    return (
        <Wrapper>
            <Nav isStaking />

            {renderBubbleLayer()}
            {renderHeaderBanner()}

            <ContentWrapper>
                <img src={undeadHeading} alt="" className={'heading'} />

                <section className={'tokenAnimation'}>
                    <Lottie
                        width={isMobile ? '1250rem' : '500rem'}
                        height={isMobile ? '1100rem' : '400rem'}
                        options={{
                            loop: true,
                            autoplay: false,
                            animationData: undeadToken,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice'
                            }
                        }}
                        style={{ pointerEvents: 'none' }}
                    />
                </section>

                <section
                    className={cn('preparingAnimation')}
                >

                    <img src={nowTradingBig} alt="" className="nowTrading" />

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => copyClipboard('0x310C8F00b9dE3c31Ab95ea68feb6C877538f7947')}
                    >
                        <p
                            className={cn('description')}
                        >
                            0x310C8F00b9dE3c31Ab95ea68feb6C877538f7947
                        </p>

                        <img src={copyIcon} alt={'list'} className="copy" />
                    </div>

                    {copied && (
                        <section className={'copyText'}>
                            Copied
                        </section>
                    )}
                </section>

                {renderDocument()}
            </ContentWrapper>
        </Wrapper>
    );
}

export default TokenAddress;