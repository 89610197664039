import cn from 'classnames';
import React from 'react';

import style from './NoItems.css';

export default function NoItems(props) {
    const { wrapperClass } = props;

    return (
        <div className={cn(style.container, wrapperClass)}>
            <div className={'noItems'}>
                <h3 className={'noItemsHead'}>
                    No items found
                </h3>
                <p className={'noItemsText'}>
                    Come back soon! Or try to browse something
                    for you on our marketplace
                </p>
            </div>
        </div>
    )
}