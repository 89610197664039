import amateur from '../../../lotties/amateur.json';
import survivor from '../../../lotties/survivor.json';
import assassin from '../../../lotties/assassin.json';
import killer from '../../../lotties/killer.json';
import amateurSparkle from '../../../lotties/amateur_sparkle.json';
import assassinSparkle from '../../../lotties/assassin_sparkle.json';
import survivorSparkle from '../../../lotties/survivor_sparkle.json';
import killerSparkle from '../../../lotties/killer_sparkle.json';

import amateurImg from '../img/amateurBg.png';
import survivorImg from '../img/survivorBg.png';
import assassinImg from '../img/assassinBg.png';
import killerImg from '../img/zombieBg.png';

export const LIMIT_PACKAGES_LENGTH = 20;
export const SKIP_PACKAGES_LENGTH = 0; 

export const CARD_LIST = [
    {
        type: 2,
        img: assassinImg,
        name: 'ASSASSIN',
        nameLower: 'Assassin',
        price: '0.5',
        detail: 'Knife + AK47 + Pistol + Shotgun + 1 random perk',
        lottie: assassin,
        sparkleLottie: assassinSparkle,
        start: '#e8c24a',
        end: '#ba9420',
        buttonLbl: 'MINT',
        fontSize: 15,
        fontSizeMobile: 8.9,
        minted: '0',
        totalMinted: '0',
        level: 5,
        perk: 3
    },
    {
        type: 3,
        img: killerImg,
        name: 'ZOMBIE KILLER',
        nameLower: 'Zombie Killer',
        price: '1',
        detail: 'Knife + F1 + Pistol + Shotgun + 4 perks + Grenade',
        lottie: killer,
        sparkleLottie: killerSparkle,
        start: '#cdf1f1',
        end: '#7dafb1',
        buttonLbl: 'MINT',
        fontSize: 15,
        fontSizeMobile: 8.9,
        minted: '0',
        totalMinted: '0',
        level: 2,
        perk: null
    },
    {
        type: 0,
        img: amateurImg,
        name: 'AMATEUR',
        nameLower: 'Amateur',
        price: '0.1',
        detail: 'Knife + Pistol',
        lottie: amateur,
        sparkleLottie: amateurSparkle,
        start: '#d48f56',
        end: '#915330',
        buttonLbl: 'MINT',
        fontSize: 25,
        fontSizeMobile: 15,
        minted: '0',
        totalMinted: '0',
        level: 8,
        perk: null
    },
    {
        type: 1,
        img: survivorImg,
        name: 'SURVIVOR',
        nameLower: 'Survivor',
        price: '0.3',
        detail: 'Knife + MP5 + Pistol',
        lottie: survivor,
        sparkleLottie: survivorSparkle,
        start: '#fff',
        end: '#9c9c9c',
        buttonLbl: 'MINT',
        fontSize: 20,
        fontSizeMobile: 12,
        minted: '0',
        totalMinted: '0',
        level: 3,
        perk: null
    },
];

export const CARD_LIST_MOBILE = [
    {
        type: 3,
        img: killerImg,
        name: 'ZOMBIE KILLER',
        nameLower: 'Zombie Killer',
        price: '1',
        detail: 'Knife + F1 + Pistol + Shotgun + 4 perks + Grenade',
        lottie: killer,
        sparkleLottie: killerSparkle,
        start: '#cdf1f1',
        end: '#7dafb1',
        buttonLbl: 'MINT',
        fontSize: 15,
        fontSizeMobile: 8.9
    },
    {
        type: 0,
        img: amateurImg,
        name: 'AMATEUR',
        nameLower: 'Amateur',
        price: '0.1',
        detail: 'Knife + Pistol',
        lottie: amateur,
        sparkleLottie: amateurSparkle,
        start: '#d48f56',
        end: '#915330',
        buttonLbl: 'MINT',
        fontSize: 25,
        fontSizeMobile: 15
    },
    {
        type: 1,
        img: survivorImg,
        name: 'SURVIVOR',
        nameLower: 'Survivor',
        price: '0.3',
        detail: 'Knife + MP5 + Pistol',
        lottie: survivor,
        sparkleLottie: survivorSparkle,
        start: '#fff',
        end: '#9c9c9c',
        buttonLbl: 'MINT',
        fontSize: 20,
        fontSizeMobile: 12
    },
    {
        type: 2,
        img: assassinImg,
        name: 'ASSASSIN',
        nameLower: 'Assassin',
        price: '0.5',
        detail: 'Knife + AK47 + Pistol + Shotgun + 1 random perk',
        lottie: assassin,
        sparkleLottie: assassinSparkle,
        start: '#e8c24a',
        end: '#ba9420',
        buttonLbl: 'MINT',
        fontSize: 15,
        fontSizeMobile: 8.9
    },
];
