import { handleActions } from "redux-actions";

const initialState = {
    signRes: {
        result: null,
        requesting: false,
        error: null
    },
    mintedRes: {
        result: null,
        requesting: false,
        error: null
    },  
    rebootSignRes: {
        result: null,
        requesting: false,
        error: null
    },
    rebootMintedRes: {
        result: null,
        requesting: false,
        error: null
    },
    ownershipsRes: {
        result: null,
        requesting: false,
        error: null
    },
    myWeapons: {
        requesting: false,
        error: null,
        result: [],
        limit: 20,
        skip: 0
      },
    nftDetail: {
        requesting: false,
        error: null,
        data: {},
    },
    afterMinted: 0
};

let ownershipReducer = handleActions({
    /** MINTED SUCCESS **/
    GET_AFTER_MINTED: (state, { payload }) => ({
        ...state,
        afterMinted: payload
    }),

    /** SIGNATURE **/
    SIGNATURE_REQUEST: (state) => ({
        ...state,
        signRes: {
            ...state.signRes,
            requesting: true
        }
    }),
    SIGNATURE_SUCCESS: (state, { payload }) => ({
        ...state,
        signRes: {
            ...state.signRes,
            requesting: false,
            result: payload.data,
            error: null
        }
    }),
    SIGNATURE_FAIL: (state, { payload }) => ({
        ...state,
        signRes: {
            ...state.signRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** MINT **/
    MINT_REQUEST: (state) => ({
        ...state,
        mintedRes: {
            ...state.mintedRes,
            requesting: true
        }
    }),
    MINT_SUCCESS: (state, { payload }) => ({
        ...state,
        mintedRes: {
            ...state.mintedRes,
            requesting: false,
            result: payload.data,
            error: null
        }
    }),
    MINT_FAIL: (state, { payload }) => ({
        ...state,
        mintedRes: {
            ...state.mintedRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** REBOOT SIGNATURE **/
    REBOOT_SIGNATURE_REQUEST: (state) => ({
        ...state,
        rebootSignRes: {
            ...state.rebootSignRes,
            requesting: true
        }
    }),
    REBOOT_SIGNATURE_SUCCESS: (state, { payload }) => ({
        ...state,
        rebootSignRes: {
            ...state.rebootSignRes,
            requesting: false,
            result: payload.data,
            error: null
        }
    }),
    REBOOT_SIGNATURE_FAIL: (state, { payload }) => ({
        ...state,
        rebootSignRes: {
            ...state.rebootSignRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** MINT **/
    REBOOT_MINT_REQUEST: (state) => ({
        ...state,
        rebootMintedRes: {
            ...state.rebootMintedRes,
            requesting: true
        }
    }),
    REBOOT_MINT_SUCCESS: (state, { payload }) => ({
        ...state,
        rebootMintedRes: {
            ...state.rebootMintedRes,
            requesting: false,
            result: payload.data,
            error: null
        }
    }),
    REBOOT_MINT_FAIL: (state, { payload }) => ({
        ...state,
        rebootMintedRes: {
            ...state.rebootMintedRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** FETCH OWNERSHIP **/
    FETCH_OWNERSHIPS_REQUEST: (state) => ({
        ...state,
        ownershipsRes: {
            ...state.ownershipsRes,
            requesting: true
        }
    }),
    FETCH_OWNERSHIPS_SUCCESS: (state, { payload }) => ({
        ...state,
        ownershipsRes: {
            ...state.ownershipsRes,
            requesting: false,
            result: payload.data,
            error: null
        }
    }),
    FETCH_OWNERSHIPS_FAIL: (state, { payload }) => ({
        ...state,
        ownershipsRes: {
            ...state.ownershipsRes,
            requesting: false,
            error: payload.error
        }
    }),
    // FETCH NFT DETAIL
    FETCH_NFT_DETAIL_REQUEST: (state) => ({
        ...state,
        nftDetail: {
            ...state.nftDetail,
            requesting: true
        }
    }),
    FETCH_NFT_DETAIL_SUCCESS: (state, { payload }) => ({
        ...state,
        nftDetail: {
            ...state.nftDetail,
            ...payload.data,
            requesting: false
        }
    }),
    FETCH_NFT_DETAIL_FAIL: (state, { payload }) => ({
        ...state,
        nftDetail: {
            ...state.nftDetail,
            requesting: false,
            error: payload.error
        }
    }),
    FETCH_NFT_DETAIL_CLEAN: (state) => ({
        ...state,
        nftDetail: {
            requesting: false,
            error: null,
            data: {},
        }
    }),
    // FETCH LIST MY WEAPONS
    FETCH_MY_WEAPONS_REQUEST: (state) => ({
        ...state,
        myWeapons: {
            ...state.myWeapons,
            requesting: true
        }
    }),
    FETCH_MY_WEAPONS_SUCCESS: (state, { payload }) => ({
        ...state,
        myWeapons: {
            ...state.myWeapons,
            ...payload.data,
            requesting: false
        }
    }),
    FETCH_MY_WEAPONS_FAIL: (state, { payload }) => ({
        ...state,
        myWeapons: {
            ...state.myWeapons,
            requesting: false,
            error: payload.error
        }
    }),
    FETCH_MY_WEAPONS_CLEAN: (state) => ({
        ...state,
        myWeapons: {
            requesting: false,
            error: null,
            result: [],
            limit: 20,
            skip: 0
        }
    }),
}, initialState);

export default ownershipReducer;